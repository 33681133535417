import { useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';
import Select from '../../select';

import { ModalType } from '../../../constants/ui';
import { CRYPTOCURRENCY_LIST } from '../../../constants/payment';
import { ICryptocurrency } from '../../../models/cryptocurrency';

interface Props {
  setOpened: (value: boolean) => void;
  cryptocurrency: ICryptocurrency;
  setCryptocurrency: (value: ICryptocurrency) => void;
}

type EditEvent = React.ChangeEvent<HTMLInputElement>;

const CryptocurrencyEdit = ({ setOpened, cryptocurrency, setCryptocurrency }: Props) => {
  const [isCryptocurrencySelectOpen, setCryptocurrencySelectOpen] = useState(false);
  const [temporaryCryptocurrency, setTemporaryCryptocurrency] = useState({
    cryptocurrency: cryptocurrency.cryptocurrency,
    account: cryptocurrency.account,
  });
  const [keepOpen, setKeepOpen] = useState(true);

  const handleCryptocurrencyChange = (value: { id: number; name: string }) => {
    setTemporaryCryptocurrency({
      ...temporaryCryptocurrency,
      cryptocurrency: value,
    });
  };
  const handleAccountChange = (event: EditEvent) => {
    setTemporaryCryptocurrency({ ...temporaryCryptocurrency, account: event.target.value });
  };
  const handleSave = () => {
    setCryptocurrency({ ...temporaryCryptocurrency });
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">Cryptocurrency Account Information</h3>
      <div className="modal__vertical-flex" style={{ marginTop: '20px' }}>
        <Select
          isOpen={isCryptocurrencySelectOpen}
          selectedOption={temporaryCryptocurrency.cryptocurrency}
          setIsOpen={setCryptocurrencySelectOpen}
          setSelectedOption={handleCryptocurrencyChange}
          optionList={CRYPTOCURRENCY_LIST}
        />

        <input
          className="form__input"
          name="account"
          type="text"
          placeholder="Crypto Wallet account"
          value={temporaryCryptocurrency.account}
          onChange={handleAccountChange}
        />

        <Button primary long onClick={handleSave}>
          Add Cryptocurrency Account
        </Button>
      </div>
    </Modal>
  );
};

export default CryptocurrencyEdit;
