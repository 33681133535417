import { classNames } from '../../utils/class-names';
import cls from './three-dots.module.scss';

interface Props {
  className?: string;
  onClick: () => void;
  border?: boolean;
  disabled?: boolean;
}

export const ThreeDots = ({ className, onClick, border, disabled }: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.border]: border,
  };

  return (
    <button
      className={classNames([cls.button, className], mods)}
      disabled={disabled}
      onClick={onClick}>
      <span className={cls.dot} />
      <span className={cls.dot} />
      <span className={cls.dot} />
    </button>
  );
};
