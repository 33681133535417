import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface PaypalState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export const paypalSlice = createSlice({
  name: 'paypal',
  initialState,
  reducers: {
    setIsLoading: (state: PaypalState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default paypalSlice.reducer;

export const getIsLoading = (state: RootState) => state.paypal.isLoading;

// import { ActionType } from './actions';

// const initialState = {
//   name: undefined,
//   email: undefined,
// };

// const paypalReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_PAYPAL_NAME:
//       return {
//         ...state,
//         name: action.payload,
//       };

//     case ActionType.SET_PAYPAL_EMAIL:
//       return {
//         ...state,
//         email: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export default paypalReducer;

// export const selectPaypalName = (state: RootState) => state.paypal.name;
// export const selectPaypalEmail = (state: RootState) => state.paypal.email;
