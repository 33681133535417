import AuthService from '../service/auth-service';
import { AppDispatch } from '../store/store';
import { uiSlice } from '../store/ui/reducer';
import { authSlice } from '../store/auth/reducer';

import { IUser, IRegisterFormUser, IRegisterServerUser } from '../models/user-model';
import { adoptRegisterUserToServer, adoptUserToClient } from '../adapters/user-adapter';

import { errorsToString } from '../helpers/error';
import { log } from '../helpers/log';
import { removeToken, setToken } from '../utils/user';
import { UIType } from '../constants/ui';
import { adoptNetworkListToClient } from '../adapters/network-adapter';
import { networksSlice } from '../store/networks/reducer';

export const login =
  (email: string, password: string, remember: boolean) => async (dispatch: AppDispatch) => {
    const { addNotification, setType } = uiSlice.actions;
    const { setAuthenticated, setUser, setIsLoading } = authSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await AuthService.login(email, password);

      setToken(remember, response.data.token);

      dispatch(setUser(adoptUserToClient(response.data.user)));
      dispatch(
        setType(
          response.data?.user?.profile_type === 'influencer'
            ? UIType.INFLUENCER
            : UIType.ADVERTISER,
        ),
      );
      dispatch(setAuthenticated(true));
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `Wrong 'Email' or 'Password'`,
        }),
      );

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const loginWithFacebook =
  (access_token: string, id: string, picture: string, remember: boolean) =>
  async (dispatch: AppDispatch) => {
    const { addNotification, setType } = uiSlice.actions;
    const { setAuthenticated, setUser, setIsLoading } = authSlice.actions;

    dispatch(setIsLoading(true));

    try {
      console.group('facebook login request');
      console.log('to Kami:');
      console.log({ name: 'facebook', access_token, id, remember });
      const response = await AuthService.loginWithFacebook(
        { name: 'facebook', access_token, id, picture, remember },
        remember,
      );

      console.log('from Kami:');
      console.log(response.data);

      setToken(remember, response.data.token);

      dispatch(setUser(adoptUserToClient(response.data.user)));
      dispatch(
        setType(
          response.data?.user?.profile_type === 'influencer'
            ? UIType.INFLUENCER
            : UIType.ADVERTISER,
        ),
      );
      dispatch(setAuthenticated(true));
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `Something went wrong with facebook login`,
        }),
      );

      log(err);
    } finally {
      dispatch(setIsLoading(false));
      console.groupEnd();
    }
  };

export const loginWithGoogle =
  (access_token: string, id: string, picture: string, remember: boolean) =>
  async (dispatch: AppDispatch) => {
    const { addNotification, setType } = uiSlice.actions;
    const { setAuthenticated, setUser, setIsLoading } = authSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await AuthService.loginWithGoogle(
        { name: 'google', access_token, id, picture, remember },
        remember,
      );

      setToken(remember, response.data.token);

      dispatch(setUser(adoptUserToClient(response.data.user)));
      dispatch(
        setType(
          response.data?.user?.profile_type === 'influencer'
            ? UIType.INFLUENCER
            : UIType.ADVERTISER,
        ),
      );
      dispatch(setAuthenticated(true));
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `Something went wrong with google login`,
        }),
      );

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const sendFacebookAccessToken =
  (access_token: string, id: string, network_id: number) => async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading } = authSlice.actions;
    const { setNetworkList } = networksSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await AuthService.sendFacebookAccessToken({ access_token, id, network_id });
      console.log(response);
      dispatch(setNetworkList(adoptNetworkListToClient(response.data)));
    } catch (err: any) {
      console.log(err);
      dispatch(
        addNotification({
          type: 'error',
          message: `Something went wrong with facebook token`,
        }),
      );

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const sendInstagramAccessToken =
  (access_token: string, id: string, network_id: number) => async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading } = authSlice.actions;
    const { setNetworkList } = networksSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await AuthService.sendInstagramAccessToken({ access_token, id, network_id });

      dispatch(setNetworkList(adoptNetworkListToClient(response.data)));
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `Something went wrong with instagram token`,
        }),
      );

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const logout = () => async (dispatch: AppDispatch) => {
  // const { addNotification } = uiSlice.actions;
  const { setAuthenticated, setUser, setIsLoading } = authSlice.actions;

  dispatch(setIsLoading(true));

  try {
    await AuthService.logout();

    dispatch(setUser({} as IUser));
  } catch (err: any) {
    // server return 401 error on /logout if success 0_o
    // dispatch(
    //   addNotification({
    //     type: 'error',
    //     message: `${err.response.status}: ${err.response.data.error} (${
    //       err.response?.data?.message
    //     } ${errorsToString(err.response?.data?.errors)}) `,
    //   }),
    // );
    // log(err);
  } finally {
    removeToken();

    dispatch(setAuthenticated(false));
    dispatch(setIsLoading(false));
  }
};

export const register = (user: IRegisterFormUser) => async (dispatch: AppDispatch) => {
  const { addNotification } = uiSlice.actions;
  const { setAuthenticated, setUser, sendEmailVerification, setIsLoading } = authSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const adoptedUser: IRegisterServerUser = adoptRegisterUserToServer(user);
    const response = await AuthService.register(adoptedUser);

    setToken(false, response.data.token);

    dispatch(setUser(adoptUserToClient(response.data.user)));
    dispatch(setAuthenticated(true));
    dispatch(sendEmailVerification(true));

    dispatch(sendEmail());
  } catch (err: any) {
    dispatch(
      addNotification({
        type: 'error',
        message: `${err.response.status}: ${err.response.data.error} (${
          err.response?.data?.message
        } ${errorsToString(err.response?.data?.errors)}) `,
      }),
    );
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const sendEmail = () => async (dispatch: AppDispatch) => {
  const { addNotification } = uiSlice.actions;
  const { sendEmailVerification, setIsLoading } = authSlice.actions;

  dispatch(setIsLoading(true));

  try {
    await AuthService.sendEmail();
    dispatch(sendEmailVerification(false));
    dispatch(
      addNotification({
        type: 'success',
        message: `Email has been sent successfully`,
      }),
    );
  } catch (err: any) {
    dispatch(
      addNotification({
        type: 'error',
        message: `${err.response.status}: ${err.response.data.error} (${
          err.response?.data?.message
        } ${errorsToString(err.response?.data?.errors)}) `,
      }),
    );
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const emailVerification =
  (userId: string, token: string) => async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading, setAuthenticated, setUser } = authSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await AuthService.emailVerification(userId, token);
      dispatch(setUser(adoptUserToClient(response.data.user)));
      dispatch(setAuthenticated(true));

      dispatch(
        addNotification({
          type: 'success',
          message: `Email has been verified successfully`,
        }),
      );
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `${err.response.status}: ${err.response.data.error} (${
            err.response?.data?.message
          } ${errorsToString(err.response?.data?.errors)}) `,
        }),
      );
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const sendPasswordEmail = (email: string) => async (dispatch: AppDispatch) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading } = authSlice.actions;

  dispatch(setIsLoading(true));

  try {
    await AuthService.sendPasswordEmail(email);

    dispatch(
      addNotification({
        type: 'success',
        message: `Email has been sent successfully`,
      }),
    );
  } catch (err: any) {
    dispatch(
      addNotification({
        type: 'error',
        message: `${err.response.status}: ${err.response.data.error} (${
          err.response?.data?.message || ''
        } ${errorsToString(err.response?.data?.validator)}) `,
      }),
    );
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const sendNewPassword =
  (token: string, password1: string, password2: string) => async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading } = authSlice.actions;
    dispatch(setIsLoading(true));

    try {
      await AuthService.sendNewPassword(token, password1, password2);

      dispatch(
        addNotification({
          type: 'success',
          message: `New password has been set.`,
        }),
      );
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `${err.response.status}: ${err.response.data.error} (${
            err.response?.data?.message || ''
          } ${errorsToString(err.response?.data?.validator)}) `,
        }),
      );
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
