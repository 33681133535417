import { TD, TR } from '../../components/table';
import { BASE_URL } from '../../constants/ui';

import { IPresentationDetail } from '../../models/presentation';

import noImage from '../../image/no-image.gif';

interface Props {
  detailRow: IPresentationDetail;
  onDeleteClick: (detailRow: IPresentationDetail) => void;
}

const PresentationDetailRow = ({ detailRow, onDeleteClick }: Props) => {
  const deleteHandler = () => {
    onDeleteClick(detailRow);
  };

  return (
    <TR>
      <TD minimize>
        <img
          className="avatar__image"
          src={detailRow.avatarUrl ? BASE_URL + detailRow.avatarUrl : noImage}
          width="46"
          height="46"
          alt="influencer avatar"
        />
      </TD>
      <TD minimize>{detailRow.title}</TD>
      <TD>{detailRow.itemType}</TD>
      <TD minimize>
        <button
          className="button button--transparent button--delete js-button-delete"
          style={{ top: 'auto', right: 'auto' }}
          type="button"
          onClick={deleteHandler}>
          Delete
        </button>
      </TD>
    </TR>
  );
};

export { PresentationDetailRow };
