import { useState, ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import ChatButton from '../../components/chat-button';

import { AppRoute } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getType } from '../../store/ui/reducer';
import { register } from '../../api/auth';

import { UIType } from '../../constants/ui';
import bgImage from '../../image/bg-image.png';

import typeCompany from '../../image/type-company.png';
import typeBrand from '../../image/type-brand.png';
import typeAgency from '../../image/type-agency.png';
import TermsConditions from '../../components/modals/terms-and-conditions';
import { IRegisterFormUser } from '../../models/user-model';

const SignUp = () => {
  const [modalTermsOpened, setModalTermsOpened] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [companyType, setCompanyType] = useState('company');
  const [termsAgreement, setTermsAgreement] = useState('');

  const dispatch = useAppDispatch();
  const uiType = useAppSelector(getType);

  const handleEmailChanged = (evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value);
  const handleFirstNameChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setFirstName(evt.target.value);
  const handleLastNameChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setLastName(evt.target.value);
  const handleCompanyChanged = (evt: ChangeEvent<HTMLInputElement>) => setCompany(evt.target.value);
  const handlePassword1Changed = (evt: ChangeEvent<HTMLInputElement>) =>
    setPassword1(evt.target.value);
  const handlePassword2Changed = (evt: ChangeEvent<HTMLInputElement>) =>
    setPassword2(evt.target.value);
  const handleCompanyTypeChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setCompanyType(evt.target.value);
  };
  const handleAgreeChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setTermsAgreement(evt.target.value);

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    const formUser: IRegisterFormUser = {
      email,
      firstName,
      lastName,
      company,
      phone,
      password1,
      password2,
      companyType,
      termsAgreement,
      uiType,
    };

    dispatch(register(formUser));
  };

  return (
    <main className="login-page__wrapper">
      <div className="login-page__bg-container">
        <img className="login-page__bg-image" src={bgImage} alt="an influencer on a street" />
        <div className="login-page__bg-flex">
          <h1>Welcome to Influencer Union!</h1>
          <p className="login-page__description">
            Register to become a member of Influencer Union's professional advertising network and
            earn money by building partnerships with brands and businesses
          </p>
        </div>
      </div>
      <div className="login-page__container">
        <h1 className="login-page__title">Create your account</h1>
        <form className="login-page__form login-form login-form--sign-up" onSubmit={handleSubmit}>
          <label className="login-form__field login-form__field--sign-up">
            <span className="login-form__label">Email</span>
            <input
              className="login-form__input"
              type="email"
              name="email"
              value={email}
              onChange={handleEmailChanged}
              placeholder="Enter your email address"
              required
            />
          </label>
          <div className="login-form__flex-field login-form__flex-field--sign-up">
            <label className="login-form__field login-form__field--sign-up">
              <span className="login-form__label">First name</span>
              <input
                className="login-form__input"
                type="text"
                name="firstName"
                value={firstName}
                onChange={handleFirstNameChanged}
                placeholder="Enter your first name"
                required
              />
            </label>
            <label className="login-form__field login-form__field--sign-up">
              <span className="login-form__label">Last name</span>
              <input
                className="login-form__input"
                type="text"
                name="lastName"
                value={lastName}
                onChange={handleLastNameChanged}
                placeholder="Enter your last name"
                required
              />
            </label>
          </div>

          {uiType !== UIType.INFLUENCER && (
            <label className="login-form__field login-form__field--sign-up">
              <span className="login-form__label">Company</span>
              <input
                className="login-form__input"
                type="text"
                name="company"
                value={company}
                onChange={handleCompanyChanged}
                placeholder="Enter your company name"
                required
              />
            </label>
          )}
          <label className="login-form__field login-form__field--sign-up">
            <span className="login-form__label">Phone</span>
          </label>
          <div className="login-form__flex-field login-form__flex-field--sign-up">
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={setPhone}
              buttonClass="form__flag"
              inputClass="form__phone-number"
              dropdownClass="form__country-container"
            />
          </div>
          <label className="login-form__field login-form__field--sign-up">
            <span className="login-form__label">Password</span>
            <input
              className="login-form__input"
              type="password"
              name="password1"
              value={password1}
              onChange={handlePassword1Changed}
              placeholder="Enter your password"
              required
            />
          </label>
          <label className="login-form__field login-form__field--sign-up">
            <span className="login-form__label">Confirm password</span>
            <input
              className="login-form__input"
              type="password"
              name="password2"
              value={password2}
              onChange={handlePassword2Changed}
              placeholder="Confirm your password"
              required
            />
          </label>
          <p className="login-form__description login-form__description--sign-up">
            Already have an Influencer Union account? <Link to={AppRoute.LOGIN}>Sign In</Link>
          </p>

          {uiType !== UIType.INFLUENCER && (
            <>
              <p className="login-form__description" style={{ fontSize: '18px' }}>
                Profile type
              </p>
              <div className="login-form__flex-field login-form__flex-field--sign-up">
                <div className="login-form__type">
                  <img src={typeCompany} alt="company" />
                  <input
                    className="visually-hidden"
                    id="company"
                    name="type"
                    value="company"
                    type="radio"
                    checked={companyType === 'company'}
                    onChange={handleCompanyTypeChanged}
                  />
                  <label className="checkbox login-form__type-radiobox" htmlFor="company"></label>
                </div>
                <div className="login-form__type">
                  <img src={typeBrand} alt="brand" />
                  <input
                    className="visually-hidden"
                    id="brand"
                    name="type"
                    value="brand"
                    type="radio"
                    checked={companyType === 'brand'}
                    onChange={handleCompanyTypeChanged}
                  />
                  <label className="checkbox login-form__type-radiobox" htmlFor="brand"></label>
                </div>
                <div className="login-form__type">
                  <img src={typeAgency} alt="agency" />
                  <input
                    className="visually-hidden"
                    id="agency"
                    name="type"
                    value="agency"
                    type="radio"
                    checked={companyType === 'agency'}
                    onChange={handleCompanyTypeChanged}
                  />
                  <label className="checkbox login-form__type-radiobox" htmlFor="agency"></label>
                </div>
              </div>
            </>
          )}

          <div className="login-form__flex-field">
            <button className="button button--red" type="submit">
              Sign up
            </button>
            <input
              className="visually-hidden"
              id="remember-me"
              name="remember-me"
              type="checkbox"
              value={termsAgreement}
              onChange={handleAgreeChanged}
              required
            />
            <label className="login-form__term-text checkbox" htmlFor="remember-me">
              Agree to the{' '}
              <button type="button" onClick={() => setModalTermsOpened(true)}>
                Terms & Conditions
              </button>
            </label>
          </div>
        </form>
      </div>

      {modalTermsOpened && <TermsConditions setOpened={() => setModalTermsOpened(false)} />}

      <ChatButton />
    </main>
  );
};

export default SignUp;
