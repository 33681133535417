import { configureStore, combineReducers } from '@reduxjs/toolkit';

import uiReducer from './ui/reducer';
import authReducer from './auth/reducer';
import billingReducer from './billing/reducer';
import campaignReducer from './campaign/reducer';
import influencerReducer from './influencer/reducer';
import categoriesReducer from './categories/reducer';
import creditCardReducer from './credit-card/reducer';
import eTransferReducer from './e-transfer/reducer';
import networksReducer from './networks/reducer';
import paymentReducer from './payment/reducer';
import paypalReducer from './paypal/reducer';
import wireReducer from './wire/reducer';
import wireBankReducer from './wire-bank/reducer';
import rateCardsReducer from './rate-cards/reducer';
import myListsReducer from './my-list/reducer';
import filterReducer from './filter/reducer';
import noteReducer from './note/reducer';
import presentationReducer from './presentation/reducer';
import reportReducer from './report/reducer';
import additionalInfoReducer from './additional-info/reducer';
import messageReducer from './message/reducer';
import influencerAnalytics from './influencer-analytics/reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  billing: billingReducer,
  campaign: campaignReducer,
  influencer: influencerReducer,
  categories: categoriesReducer,
  creditCard: creditCardReducer,
  eTransfer: eTransferReducer,
  networks: networksReducer,
  rateCards: rateCardsReducer,
  payment: paymentReducer,
  paypal: paypalReducer,
  wire: wireReducer,
  wireBank: wireBankReducer,
  myLists: myListsReducer,
  filter: filterReducer,
  note: noteReducer,
  presentation: presentationReducer,
  report: reportReducer,
  additionalInfo: additionalInfoReducer,
  message: messageReducer,
  influencerAnalytics: influencerAnalytics,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

// export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
