import { ICampaign, IServerNewCampaign, IServerCampaign } from '../models/campaign';
import { formatStringFromDateForServer } from '../utils/format';

export const adoptCampaignToClient = (campaign: IServerCampaign): ICampaign => {
  return {
    id: Number(campaign.id) || 0,
    userId: Number(campaign.user_id) || 0,
    title: campaign.title || '',
    bookmark: Boolean(campaign.bookmark),
    details: campaign.details || '',
    goals: campaign.goals || '',
    instructions: campaign.instructions || '',
    campaignHashtag: campaign.campaign_hashtag || '',
    category: Number(campaign.category) || 0,
    hashtags: campaign.hashtags,
    categoryName: campaign.category_name || '',
    image: campaign.image || '',
    budget: Number(campaign.budget),
    status: Number(campaign.status),
    influencers: Number(campaign.influencer_count) || 0,
    startDate: campaign.start_date,
    endDate: campaign.end_date,
    public: Boolean(campaign.public),
    posts: Number(campaign.posts_count) || 0,
    avgEngagement: Number(campaign.avg_engagement) || 0,
    companyName: campaign.company_name || '',
    note: Boolean(campaign.note),
  };
};

export const adoptCampaignListToClient = (data: IServerCampaign[]): ICampaign[] => {
  if (!Array.isArray(data)) return [];
  return data.map(adoptCampaignToClient);
};

export const adoptCampaignToServer = (campaign: ICampaign): IServerCampaign => {
  return {
    id: campaign.id.toString(),
    user_id: campaign.userId.toString(),
    title: campaign.title,
    bookmark: Number(campaign.bookmark),
    details: campaign.details,
    goals: campaign.goals,
    instructions: campaign.instructions,
    campaign_hashtag: campaign.campaignHashtag,
    category: campaign.category.toString(),
    hashtags: campaign.hashtags,
    category_name: campaign.categoryName,
    image: campaign.image + '/noimage/',
    budget: campaign.budget.toString(),
    status: campaign.status.toString(),
    influencer_count: campaign.influencers.toString(),
    start_date: formatStringFromDateForServer(new Date(campaign.startDate)) + ' 00:00:00',
    end_date: formatStringFromDateForServer(new Date(campaign.endDate)) + ' 23:59:59',
    public: campaign.public ? '1' : '0',
  };
};

export const adoptCampaignListToServer = (data: ICampaign[]): IServerCampaign[] => {
  return data.map(adoptCampaignToServer);
};

export const adoptNewCampaignToServer = (campaign: ICampaign): IServerNewCampaign => {
  const adoptedCampaign = adoptCampaignToServer(campaign);
  const { id, user_id, ...rest } = adoptedCampaign;

  return rest;
};
