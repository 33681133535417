import { useEffect, useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';
import Select from '../../select/select';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getCampaignInfluencerList } from '../../../api/influencer';
import { selectCampaignInfluencerList } from '../../../store/influencer/reducer';
import { ModalType } from '../../../constants/ui';
import { ICampaign } from '../../../models/campaign';
import { IInfluencer } from '../../../models/influencer';

interface Props {
  setOpened: (value: boolean) => void;
  campaign: ICampaign;
  onCampaignAnalyticsClick: (campaign: ICampaign) => void;
  onInfluencerAnalyticsClick: (influencer: IInfluencer | undefined) => void;
}

const CampaignMore = ({
  setOpened,
  campaign,
  onCampaignAnalyticsClick,
  onInfluencerAnalyticsClick,
}: Props) => {
  const dispatch = useAppDispatch();
  const campaignInfluencerList = useAppSelector<Array<IInfluencer>>(selectCampaignInfluencerList);

  const [isSelectOpen, setSelectOpen] = useState(false);
  const [tempInfluencer, setTempInfluencer] = useState<IInfluencer>();

  useEffect(() => {
    dispatch(getCampaignInfluencerList(campaign.id));
  }, []);

  const campaignAnalyticsHandle = () => {
    onCampaignAnalyticsClick(campaign);
  };
  const influencerAnalyticsHandle = () => {
    onInfluencerAnalyticsClick(tempInfluencer);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen noOutsideClick>
      <h3 className="modal__title">Campaign Analytics</h3>
      <div className="modal__field-wrapper">
        <Button primary onClick={campaignAnalyticsHandle} long>
          View Campaign Analytics
        </Button>
        <h3 className="modal__title" style={{ width: '100%' }}>
          OR
        </h3>
        <div style={{ flex: '1 1 100%' }}>
          <Select
            isOpen={isSelectOpen}
            selectedOption={tempInfluencer}
            setIsOpen={setSelectOpen}
            setSelectedOption={setTempInfluencer}
            optionList={campaignInfluencerList}
          />
        </div>
        <Button primary onClick={influencerAnalyticsHandle} long>
          View Influencer Analytics
        </Button>
      </div>
    </Modal>
  );
};

export default CampaignMore;
