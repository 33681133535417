import { useEffect, useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';

import { ModalType } from '../../../constants/ui';
import { ICreditCard } from '../../../models/credit-card';

interface CardEditProps {
  setOpened: (value: boolean) => void;
  creditCard: ICreditCard;
  setCreditCard: (value: ICreditCard) => void;
}

type EditEvent = React.ChangeEvent<HTMLInputElement>;

const CardEdit = ({ setOpened, creditCard, setCreditCard }: CardEditProps) => {
  const [temporaryCreditCard, setTemporaryCreditCard] = useState({
    name: '',
    number: '',
    expiryMM: '',
    expiryYY: '',
    cvv: '',
  } as ICreditCard);
  const [keepOpen, setKeepOpen] = useState(true);

  useEffect(() => {
    setTemporaryCreditCard({ ...temporaryCreditCard, ...creditCard });
  }, []);

  const handleNameChange = (event: EditEvent) => {
    setTemporaryCreditCard({ ...temporaryCreditCard, name: event.target.value });
  };
  const handleNumberChange = (event: EditEvent) => {
    setTemporaryCreditCard({ ...temporaryCreditCard, number: event.target.value });
  };
  const handleExpiryMMChange = (event: EditEvent) => {
    setTemporaryCreditCard({ ...temporaryCreditCard, expiryMM: event.target.value });
  };
  const handleExpiryYYChange = (event: EditEvent) => {
    setTemporaryCreditCard({ ...temporaryCreditCard, expiryYY: event.target.value });
  };
  const handleCvvChange = (event: EditEvent) => {
    setTemporaryCreditCard({ ...temporaryCreditCard, cvv: event.target.value });
  };
  const handleSave = () => {
    setCreditCard({ ...temporaryCreditCard });
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">Credit Card Information</h3>

      <p className="modal__description modal__description--card">loremipsum@gmail.com</p>
      <div className="modal__field-wrapper">
        <div className="form__icon-wrapper form__icon-wrapper--name">
          <input
            className="form__input form__field form__field--name"
            name="card-name"
            type="text"
            placeholder="Name on Card"
            value={temporaryCreditCard.name}
            onChange={handleNameChange}
          />
        </div>
        <div className="form__icon-wrapper form__icon-wrapper--number">
          <input
            className="form__input form__field form__field--number"
            name="card-number"
            type="text"
            placeholder="Card number"
            maxLength={16}
            value={temporaryCreditCard.number}
            onChange={handleNumberChange}
          />
        </div>
        <div className="form__icon-wrapper form__icon-wrapper--mm">
          <input
            className="form__input form__field form__field--mm"
            name="card-mm"
            type="text"
            placeholder="MM"
            maxLength={2}
            value={temporaryCreditCard.expiryMM}
            onChange={handleExpiryMMChange}
          />
        </div>
        <div className="form__icon-wrapper form__icon-wrapper--yyyy">
          <input
            className="form__input form__field form__field--yyyy"
            name="card-yyyy"
            type="text"
            placeholder="YYYYY"
            maxLength={4}
            value={temporaryCreditCard.expiryYY}
            onChange={handleExpiryYYChange}
          />
        </div>
        <div className="form__icon-wrapper form__icon-wrapper--cvc">
          <input
            className="form__input form__field form__field--cvc"
            name="card-cvc"
            type="text"
            placeholder="CVC"
            maxLength={3}
            value={temporaryCreditCard.cvv}
            onChange={handleCvvChange}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Save Credit Card
        </Button>
      </div>
    </Modal>
  );
};

export default CardEdit;
