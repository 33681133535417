import { useState } from 'react';

import { ModalType } from '../../../constants/ui';

import Modal from '../modal';
import { Button } from '../../button';
import { IETransfer } from '../../../models/e-transfer';

interface Props {
  setOpened: (value: boolean) => void;
  eTransfer: IETransfer;
  setETransfer: (value: IETransfer) => void;
}

type EditEvent = React.ChangeEvent<HTMLInputElement>;

const ETransferEdit = ({ setOpened, eTransfer, setETransfer }: Props) => {
  const [keepOpen, setKeepOpen] = useState(true);
  const [temporaryETransfer, setTemporaryETransfer] = useState<IETransfer>({
    ...eTransfer,
  });

  const handleNameChange = (event: EditEvent) => {
    setTemporaryETransfer({ ...temporaryETransfer, name: event.target.value });
  };
  const handleEmailChange = (event: EditEvent) => {
    setTemporaryETransfer({ ...temporaryETransfer, email: event.target.value });
  };
  const handleSave = () => {
    setETransfer({ ...temporaryETransfer });
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">E-Transfer Account Information</h3>
      <div className="modal__field-wrapper">
        <div className="form__icon-wrapper form__icon-wrapper--name">
          <input
            className="form__input form__field form__field--name"
            name="e-transfer-name"
            type="text"
            placeholder="Name of E-Transfer account"
            value={temporaryETransfer.name}
            onChange={handleNameChange}
          />
        </div>
        <div className="form__icon-wrapper form__icon-wrapper--email">
          <input
            className="form__input form__field form__field--email"
            name="e-transfer-email"
            type="text"
            placeholder="Email address of E-Transfer account"
            value={temporaryETransfer.email}
            onChange={handleEmailChange}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Add E-Transfer Account
        </Button>
      </div>
    </Modal>
  );
};

export default ETransferEdit;
