import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const TableWrapper = ({ className, children }: Props) => {
  return <div className={classNames([cls.tableWrapper, className])}>{children}</div>;
};
