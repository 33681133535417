import { ReportRow } from './row';

import { Table, TBody, TD, TR } from '../table';

import { useAppSelector } from '../../hooks/store';
import { selectStatusFilterList } from '../../store/report/reducer';

import { ICampaignReportList } from '../../models/report';
import { Text } from '../text';
import { Separator } from '../table/separator';
import { ICampaign } from '../../models/campaign';

interface Props {
  reportList: ICampaignReportList;
  onThreeDotsClick: (report: ICampaign) => void;
}

export const ReportTable = ({ reportList, onThreeDotsClick }: Props) => {
  const statusFilterList = useAppSelector(selectStatusFilterList);

  return (
    <Table>
      <TBody>
        {reportList.campaigns
          .filter(report => statusFilterList[report.status])
          .map(report => (
            <ReportRow key={report.id} report={report} onThreeDotsClick={onThreeDotsClick} />
          ))}
      </TBody>
      <tfoot>
        <Separator />
        <TR>
          <TD minimize></TD>
          <TD>
            {reportList.total.campaignCount}
            {reportList.total.campaignCount === 1 ? ' campaign' : ' campaigns'}
          </TD>
          <TD></TD>
          <TD>
            Post & Likes: <Text gray></Text>
          </TD>
          <TD>
            AVG Engagement: <Text gray>{reportList.total.avgEngagement}%</Text>
          </TD>
          <TD>
            Budget: <Text gray>${reportList.total.budget}</Text>
          </TD>
          <TD>
            <Text gray>
              {reportList.total.influencerCount}
              {reportList.total.influencerCount === 1 ? ' influencer' : ' influencers'}
            </Text>
          </TD>
          <TD></TD>
        </TR>
      </tfoot>
    </Table>
  );
};
