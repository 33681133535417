import { Link } from 'react-router-dom';

import { AppRoute } from '../../router/routes';
import { useAppSelector } from '../../hooks/store';
import { getIsAuthenticated } from '../../store/auth/reducer';
import { getType } from '../../store/ui/reducer';

const AppTree = () => {
  const uiType = useAppSelector(getType);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  return (
    <section style={{ display: 'flex', flexDirection: 'column', marginLeft: '50px' }}>
      <style>
        {`
          a {
            padding: 2px 20px;
            color: red;
          }
          a:hover {
            background-color: #fafafa;
          }
        `}
      </style>
      <h3>UI type is: {uiType}</h3>
      <h3>Is authenticated: {isAuthenticated ? 'true' : 'false'}</h3>
      <Link to={AppRoute.HOME}>home</Link>
      <Link to={AppRoute.LOGIN}>login</Link>
      <Link to={AppRoute.REGISTER}>register</Link>
      <Link to={AppRoute.FORGOT_PASSWORD}>forgot password</Link>
      <Link to={AppRoute.EMAIL_VERIFICATION}>email verification</Link>
      <Link to={AppRoute.FIRST_ENTRY}>first entry</Link>
      <Link to={AppRoute.LOGOUT}>logout</Link>
      <Link to="/something-wild">something wild</Link>
    </section>
  );
};

export default AppTree;
