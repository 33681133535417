import { classNames } from '../../utils/class-names';
import { Map } from '../map';
import cls from './icon.module.scss';

interface Props {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  checked?: boolean;
  large?: boolean;

  mailTo?: boolean;
  message?: boolean;
  bookmark?: boolean;
  note?: boolean;
  more?: boolean;
  edit?: boolean;
  duplicate?: boolean;
  archive?: boolean;
}

export const Icon = ({
  className,
  onClick,
  disabled,
  large,
  checked,

  mailTo,
  message,
  bookmark,
  note,
  more,
  edit,
  duplicate,
  archive,
}: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.large]: large,
    [cls.checked]: checked,

    [cls.edit]: edit,
    [cls.archive]: archive,
    [cls.duplicate]: duplicate,
    [cls.mailTo]: mailTo,
    [cls.message]: message,
    [cls.bookmark]: bookmark,
    [cls.note]: note,
    [cls.more]: more,
    [cls.edit]: edit,
    [cls.duplicate]: duplicate,
    [cls.archive]: archive,
  };

  return (
    <button
      className={classNames([cls.icon, className], mods)}
      disabled={disabled}
      onClick={onClick}></button>
  );
};
