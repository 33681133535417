import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
}

export const InfinitySign = ({ className }: Props) => {
  return <div className={classNames([cls.infinity, className])} />;
};
