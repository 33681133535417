import { useContext } from 'react';

import { MessageContext } from './message-context';
import { Contact, Message } from '../../store/message/reducer';

export const useMessage = () => {
  const socket = useContext(MessageContext);

  const getStatus = () => {
    socket.emit('status');
  };

  const sendMewMessage = (roomId: number, contactId: number, message: string) => {
    socket.emit('new-message', {
      chatroom_id: roomId,
      contact_id: contactId,
      text: message,
    });
  };

  const editMessage = (messageId: number, message: Message) => {
    socket.emit('edit-message', {
      message_id: messageId,
      message,
    });
  };

  const sendAcceptRequest = (contact: Contact) => {
    socket.emit('accept-request', {
      chatroom_id: contact.chatroom_id,
      contact_id: contact.id,
    });
  };

  const getCampaignList = () => {
    socket.emit('campaign-list');
  };

  const getContactList = (campaignId: number) => {
    socket.emit('contact-list', campaignId);
  };

  const getMessageList = (roomId: number, count: number, start: string) => {
    socket.emit('messages', {
      chatroom_id: roomId,
      count,
      start,
    });
  };

  const createNewChatRoom = (campaignId: number, contactId: number) => {
    socket.emit('new-chatroom', { campaign_id: campaignId, contact_id: contactId });
  };

  return {
    getStatus,
    getCampaignList,
    getContactList,
    getMessageList,
    sendMewMessage,
    editMessage,
    sendAcceptRequest,
    createNewChatRoom,
  };
};
