import api from '../http';
import { AxiosResponse } from 'axios';
import { ICurrentPaymentPlan } from '../models/payment-plan';

export default class PaymentService {
  static async getPaymentPlanList(): Promise<AxiosResponse> {
    return api.get('/plan-list');
  }
  static async getCurrentPaymentPlan(): Promise<AxiosResponse> {
    return api.get('/payment-plan');
  }
  static async updateCurrentPaymentPlan(data: ICurrentPaymentPlan): Promise<AxiosResponse> {
    return api.post('/payment-plan', data);
  }
  static async getPaymentHistoryList(): Promise<AxiosResponse> {
    return api.get('/payment-history');
  }
}
