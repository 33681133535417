import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getSocialMediaSummary } from '../../../api/influencer-analytics';
import { selectSocialMediaSummary } from '../../../store/influencer-analytics/reducer';

import cls from './social-media-summary.module.scss';
import { classNames } from '../../../utils/class-names';

interface Props {
  id: number;
}

const SocialMediaSummary = (props: Props) => {
  const { id } = props;

  const dispatch = useAppDispatch();

  const socialMediaSummary = useAppSelector(selectSocialMediaSummary);

  useEffect(() => {
    dispatch(getSocialMediaSummary(id));
  }, [dispatch, id]);

  const renderFacebookRow = useCallback(() => {
    return (
      <tr className={classNames([cls.row, cls.fb])}>
        <td>
          {socialMediaSummary?.facebook?.followersCount}
          <span>Followers</span>
        </td>
        <td>
          {socialMediaSummary?.facebook?.viewCount}
          <span>Views</span>
        </td>
        <td>
          {socialMediaSummary?.facebook?.reactionsCount}
          <span>Reactions</span>
        </td>
        <td>
          {socialMediaSummary?.facebook?.commentCount}
          <span>Comments</span>
        </td>
        <td>
          {socialMediaSummary?.facebook?.shareCount}
          <span>Shares</span>
        </td>
      </tr>
    );
  }, [socialMediaSummary]);

  const renderInstagramRow = useCallback(() => {
    return (
      <tr className={classNames([cls.row, cls.in])}>
        <td>
          {socialMediaSummary?.instagram?.followersCount}
          <span>Followers</span>
        </td>
        <td>
          {socialMediaSummary?.instagram?.viewCount}
          <span>Views</span>
        </td>
        <td>
          {socialMediaSummary?.instagram?.reactionsCount}
          <span>Reactions</span>
        </td>
        <td>
          {socialMediaSummary?.instagram?.commentCount}
          <span>Comments</span>
        </td>
        <td>
          {socialMediaSummary?.instagram?.shareCount}
          <span>Shares</span>
        </td>
      </tr>
    );
  }, [socialMediaSummary]);

  const renderGoogleRow = useCallback(() => {
    return (
      <tr className={classNames([cls.row, cls.yo])}>
        <td>
          {socialMediaSummary?.google?.subscriberCount}
          <span>Subscribers</span>
        </td>
        <td>
          {socialMediaSummary?.google?.viewCount}
          <span>Views</span>
        </td>
        <td>
          {socialMediaSummary?.google?.likeCount}
          <span>Likes</span>
        </td>
        <td>
          {socialMediaSummary?.google?.commentCount}
          <span>Comments</span>
        </td>
        <td>
          {socialMediaSummary?.google?.shareCount}
          <span>Shares</span>
        </td>
      </tr>
    );
  }, [socialMediaSummary]);

  return (
    <>
      <div className={cls.header}>Social Media Summary</div>
      <table className={cls['social-media']}>
        <tbody>
          {socialMediaSummary?.facebook && renderFacebookRow()}
          {socialMediaSummary?.instagram && renderInstagramRow()}
          {socialMediaSummary?.google && renderGoogleRow()}
        </tbody>
      </table>
    </>
  );
};

export { SocialMediaSummary };
