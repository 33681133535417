import { Navigate } from 'react-router-dom';

import Login from '../pages/login';
import Register from '../pages/register';
import EmailVerification from '../pages/email-verification';
import Logout from '../pages/logout';
import FirstEntry from '../pages/first-entry';
import Home from '../pages/home/home';
import AppTree from '../pages/app-tree';
import Profile from '../pages/profile';
import Billing from '../pages/billing';
import PaymentPlans from '../pages/payment-plans';
import CampaignList from '../pages/campaign-list';
import Dashboard from '../pages/dashboard';
import MyLists from '../pages/my-lists';
import CampaignReportList from '../pages/campaign-report-list';
import CampaignReport from '../pages/campaign-report';
import Search from '../pages/search';
import SearchResult from '../pages/search-result';
import BookmarkList from '../pages/bookmark-list';
import PresentationList from '../pages/presentation-list';
import PresentationDetails from '../pages/presentation-details';
import ContractList from '../pages/contract-list';
// import CampaignsAnalytics from '../pages/campaigns-analytics';
import InfluencerAnalytics from '../pages/influencer-analytics';
import Messaging from '../pages/messaging';
import ForgotEmailRequest from '../pages/forgot-email-request/forgot-email-request';
import ForgotNewPassword from '../pages/forgot-new-password/forgot-new-password';
import NotFound from '../pages/not-found';
import MyInfluencerList from '../pages/my-influencer-list';

export const enum AppRoute {
  HOME = '/',
  INDEX = '/index.html',

  LOGIN = '/login',
  REGISTER = '/register',
  LOGOUT = '/logout',

  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_FORGOTTEN_PASSWORD = '/password-reset/:type/:token',

  EMAIL_VERIFICATION = '/email-verification',
  EMAIL_VERIFICATION_PARAMS = '/email-verification/:type/:id/:token',

  FIRST_ENTRY = '/first-entry',

  // user menu
  PROFILE = '/profile',
  BILLING = '/billing',
  PAYMENT_PLANS = '/payment_plans',

  // main menu
  DASHBOARD = '/dashboard',
  MY_LISTS = '/my-lists',
  CAMPAIGN_LIST = '/campaign',
  CAMPAIGN_REPORT = '/campaign-report',
  INFLUENCER_ANALYTICS = '/influencer-analytics',
  SEARCH = '/search',
  SEARCH_RESULT = '/search-result',
  BOOKMARK_LIST = '/bookmark',
  PRESENTATION_LIST = '/presentation',
  PRESENTATION_DETAILS = '/presentation',
  CONTRACT_LIST = '/contract',
  MESSAGING = '/messaging',

  // only for 1st load the application
  ADVERTISER = '/advertiser',
  INFLUENCER = '/influencer',

  // FIXME: remove this on production
  APP_TREE = '/tree',

  ANY = '*',
}

interface IRoute {
  path: AppRoute;
  element: React.ReactElement;
}

export const privateRoutes: IRoute[] = [
  { path: AppRoute.HOME, element: <Home /> },
  { path: AppRoute.INDEX, element: <Navigate to={AppRoute.HOME} replace={true} /> },

  { path: AppRoute.LOGIN, element: <Navigate to={AppRoute.HOME} replace={true} /> },
  { path: AppRoute.REGISTER, element: <Navigate to={AppRoute.HOME} replace={true} /> },
  { path: AppRoute.FORGOT_PASSWORD, element: <Navigate to={AppRoute.HOME} replace={true} /> },
  {
    path: AppRoute.CHANGE_FORGOTTEN_PASSWORD,
    element: <Navigate to={AppRoute.HOME} replace={true} />,
  },
  { path: AppRoute.LOGOUT, element: <Logout /> },

  { path: AppRoute.EMAIL_VERIFICATION, element: <Navigate to={AppRoute.HOME} replace={true} /> },

  { path: AppRoute.FIRST_ENTRY, element: <Navigate to={AppRoute.HOME} replace={true} /> },

  { path: AppRoute.ADVERTISER, element: <Navigate to={AppRoute.HOME} replace={true} /> },
  { path: AppRoute.INFLUENCER, element: <Navigate to={AppRoute.HOME} replace={true} /> },

  // user menu
  { path: AppRoute.PROFILE, element: <Profile /> },
  { path: AppRoute.BILLING, element: <Billing /> },
  { path: AppRoute.PAYMENT_PLANS, element: <PaymentPlans /> },

  //main menu
  { path: AppRoute.DASHBOARD, element: <Dashboard /> },
  { path: AppRoute.MY_LISTS, element: <MyLists /> },
  { path: (AppRoute.MY_LISTS + '/:id') as AppRoute, element: <MyInfluencerList /> },
  { path: AppRoute.CAMPAIGN_LIST, element: <CampaignList /> },
  { path: AppRoute.CAMPAIGN_REPORT, element: <CampaignReportList /> },
  { path: (AppRoute.CAMPAIGN_REPORT + '/:id') as AppRoute, element: <CampaignReport /> },
  { path: (AppRoute.INFLUENCER_ANALYTICS + '/:id') as AppRoute, element: <InfluencerAnalytics /> },
  { path: AppRoute.SEARCH, element: <Search /> },
  { path: AppRoute.SEARCH_RESULT, element: <SearchResult /> },
  { path: (AppRoute.SEARCH_RESULT + '/:id') as AppRoute, element: <SearchResult /> },
  { path: AppRoute.BOOKMARK_LIST, element: <BookmarkList /> },
  { path: AppRoute.PRESENTATION_LIST, element: <PresentationList /> },
  { path: (AppRoute.PRESENTATION_DETAILS + '/:id') as AppRoute, element: <PresentationDetails /> },
  { path: AppRoute.CONTRACT_LIST, element: <ContractList /> },
  { path: AppRoute.MESSAGING, element: <Messaging /> },
  { path: (AppRoute.MESSAGING + '/:id') as AppRoute, element: <Messaging /> },
  { path: (AppRoute.MESSAGING + '/:campaignId/:contactId') as AppRoute, element: <Messaging /> },

  // FIXME: remove this on production
  { path: AppRoute.APP_TREE, element: <AppTree /> },
  { path: AppRoute.ANY, element: <NotFound /> },
];

export const publicRoutes: IRoute[] = [
  { path: AppRoute.LOGIN, element: <Login /> },
  { path: AppRoute.REGISTER, element: <Register /> },
  { path: AppRoute.FORGOT_PASSWORD, element: <ForgotEmailRequest /> },
  { path: AppRoute.CHANGE_FORGOTTEN_PASSWORD, element: <ForgotNewPassword /> },

  { path: AppRoute.EMAIL_VERIFICATION_PARAMS, element: <EmailVerification /> },
  { path: AppRoute.EMAIL_VERIFICATION, element: <Navigate to={AppRoute.LOGIN} /> },

  // FIXME: remove this on production
  { path: AppRoute.APP_TREE, element: <AppTree /> },
  { path: AppRoute.ANY, element: <Navigate to="/login" /> },
];

export const emailVerificationRoutes: IRoute[] = [
  { path: AppRoute.EMAIL_VERIFICATION_PARAMS, element: <EmailVerification /> },
  { path: AppRoute.EMAIL_VERIFICATION, element: <EmailVerification /> },
  { path: AppRoute.ANY, element: <Navigate to={AppRoute.EMAIL_VERIFICATION} /> },
];

export const firstEntryRoutes: IRoute[] = [
  { path: AppRoute.FIRST_ENTRY, element: <FirstEntry /> },
  { path: AppRoute.ANY, element: <Navigate to={AppRoute.FIRST_ENTRY} /> },
];
