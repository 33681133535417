import React, { ChangeEvent, useState } from 'react';
import SearchReset from '../../hooks/search-reset';
import { useAppDispatch } from '../../hooks/store';

import { uiSlice } from '../../store/ui/reducer';

interface SearchFormProps {
  placeholder?: string;
  value?: string;
  onInput?: (searchString: string) => void;
  reset?: boolean;
}

const SearchForm = ({
  placeholder = 'type search phrase...',
  value = '',
  onInput,
  reset,
}: SearchFormProps) => {
  const dispatch = useAppDispatch();
  const [temporarySearchString, setTemporarySearchString] = useState(value);

  const submitHandle = (evt: React.FormEvent) => {
    evt.preventDefault();

    const { setSearchString } = uiSlice.actions;

    dispatch(setSearchString(temporarySearchString));
    if (onInput) onInput(temporarySearchString);
  };

  const changeHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setTemporarySearchString(evt.target.value);

  return (
    <form className="search-form" onSubmit={submitHandle}>
      {reset && <SearchReset />}
      <input className="search-form__input" placeholder={placeholder} onChange={changeHandler} />
      <button className="search-form__submit" type="submit">
        GO
      </button>
    </form>
  );
};

export default SearchForm;
