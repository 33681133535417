type Mods = Record<string, boolean | string | undefined>;

export const classNames = (classNameList: (string | undefined)[] = [], mods: Mods = {}): string => {
  return [
    ...classNameList.filter(Boolean),
    ...Object.entries(mods)
      .filter(([_, value]) => Boolean(value))
      .map(([className]) => className),
  ]
    .join(' ')
    .trim();
};
