import { useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';

import { ModalType } from '../../../constants/ui';

interface WireEditProps {
  billingEmail: string;
  setBillingEmail: (billingEmail: string) => void;
  setOpened: (value: boolean) => void;
}

const BillingEmailEdit = ({ billingEmail, setBillingEmail, setOpened }: WireEditProps) => {
  const [temporaryEmail, setTemporaryEmail] = useState(billingEmail);
  const [keepOpen, setKeepOpen] = useState(true);

  const handlerEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemporaryEmail(e.target.value);
  };
  const handlerSave = () => {
    setBillingEmail(temporaryEmail);
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">Billing Email</h3>
      <div className="modal__field-wrapper">
        <div className="form__icon-wrapper form__icon-wrapper--email">
          <input
            className="form__input form__field form__field--email"
            name="email-address"
            type="text"
            placeholder="Billing email"
            value={temporaryEmail}
            onChange={handlerEmailChange}
          />
        </div>

        <Button primary long onClick={handlerSave}>
          Update Email Address
        </Button>
      </div>
    </Modal>
  );
};

export default BillingEmailEdit;
