import Modal from '../modal';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ModalType } from '../../../constants/ui';
import { ViewOptions } from '../../../models/view-options';
import { uiSlice, selectViewOptions } from '../../../store/ui/reducer';

interface Props {
  setOpened: (value: boolean) => void;
}

const as: { id: string; name: keyof ViewOptions; title: string }[] = [
  { id: 'profile', name: 'profile', title: 'Profile' },
  { id: 'social-reach', name: 'socialReach', title: 'Social reach' },
  { id: 'iu-score', name: 'iuScore', title: 'IU Score' },
  { id: 'rate-card', name: 'rateCard', title: 'Rate Card' },
  { id: 'bio', name: 'bio', title: 'Bio' },
  { id: 'location', name: 'location', title: 'Location' },
  { id: 'languages', name: 'languages', title: 'Languages' },
  { id: 'gender', name: 'gender', title: 'Gender' },
  { id: 'age', name: 'age', title: 'Age range' },
  { id: 'ethnicity', name: 'ethnicity', title: 'Ethnicity' },
  { id: 'hashtags', name: 'hashtags', title: 'Hashtags' },
  { id: 'mentions', name: 'mentions', title: 'Mentions' },
  { id: 'posts', name: 'posts', title: 'Number of posts' },
];

const View = ({ setOpened }: Props) => {
  const dispatch = useAppDispatch();
  const { setViewOptions } = uiSlice.actions;
  const options = useAppSelector<ViewOptions>(selectViewOptions);

  const createToggler = (field: keyof ViewOptions) => () =>
    dispatch(setViewOptions({ ...options, [field]: !options[field as keyof ViewOptions] }));

  return (
    <Modal type={ModalType.SIDE} setOpened={setOpened} noEscape noOutsideClick noOverlay>
      <h3 className="modal__title">View</h3>
      <h4 className="">Torn columns on or off to customize the current view.</h4>

      <form className="form modal__form">
        <h4>Columns</h4>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {as.map(item => (
            <>
              <input
                className="visually-hidden"
                id={`view-${item.id}`}
                type="checkbox"
                checked={options[item.name]}
                onChange={createToggler(item.name)}
              />
              <label className="checkbox" htmlFor={`view-${item.id}`}>
                {item.title}
              </label>
            </>
          ))}
        </div>
      </form>
    </Modal>
  );
};

export default View;
