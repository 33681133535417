import { useEffect } from 'react';

import Info from './profile-info';
import { SaveCancelButtons } from '../../components/button';
import NetworkList from '../../components/network-list';

import { networksSlice } from '../../store/networks/reducer';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { getUserNetworkList, updateUserNetworkList } from '../../api/user';
import { getNetworkList } from '../../store/networks/reducer';
import { INetwork } from '../../models/network';

const ProfileNetworkList = () => {
  const dispatch = useAppDispatch();
  const networkList = useAppSelector(getNetworkList);
  const { setNetworkList } = networksSlice.actions;

  useEffect(() => {
    dispatch(getUserNetworkList());
  }, []);

  const handleNetworkChange = (networkList: INetwork[]) => {
    dispatch(setNetworkList(networkList));
  };
  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(getUserNetworkList());
  };
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(updateUserNetworkList(networkList));
  };

  return (
    <>
      <h2 className="tab__title">Network selection</h2>

      <div className="info-block">
        <form className="profile-page__form form" onReset={handleReset} onSubmit={handleSubmit}>
          <NetworkList
            isProfilePage
            networkList={networkList}
            setNetworkList={handleNetworkChange}
          />

          <SaveCancelButtons />
        </form>
      </div>
      <Info left="Edit info" right="Add/edit Networks" />
    </>
  );
};

export default ProfileNetworkList;
