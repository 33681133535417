import { classNames } from '../../../utils/class-names';
import cls from '../widget.module.scss';

const CampaignEngagement = () => {
  return (
    <>
      <p className={cls.header}>Campaign</p>
      <p className={cls.subheader}>Engagement</p>
      <p className={cls.value}>10%</p>
      <div className={cls.flex}>
        <div className={classNames([cls.info, cls.msg])}>3</div>
        <div className={classNames([cls.info, cls.like])}>20</div>
        <div className={classNames([cls.info, cls.play])}>1</div>
      </div>
    </>
  );
};

export { CampaignEngagement };
