import { Chart, GoogleChartOptions } from 'react-google-charts';

const data = [
  ['', '', { role: 'style' }, { role: 'annotation' }],
  ['12-18', 36, '#4688F1', '36%'],
  ['19-25', 36, '#E7453C', '36%'],
  ['26-32', 19, '#F9BB2D', '19%'],
  ['33-39', 6, '#3AA757', '6%'],
  ['40-46', 2, '#FC6D22', '2%'],
  ['47+', 1, '#4CBDC5', '1%'],
];

const options: GoogleChartOptions = {
  chart: {
    title: undefined,
    subtitle: undefined,
  },
  backgroundColor: 'transparent',
  legend: { position: 'left' },
  width: 300,
  height: 200,
  chartArea: { width: '100%', height: '100%' },
  pieHole: 0.5,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  hAxis: {
    textPosition: 'none',
  },
};

const AgeChart = () => {
  return <Chart chartType="PieChart" width="100%" data={data} options={options} />;
};

export { AgeChart };
