import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[] | undefined;
}

export const THead = ({ className, children }: Props) => {
  return <thead className={classNames([cls.thead, className])}>{children}</thead>;
};
