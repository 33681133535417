import { useEffect, useState } from 'react';

import Modal from '../modal';
import Select from '../../select';
import { Button } from '../../button';

import { addInfluencerToMyList, getMyListsList } from '../../../api/my-lists';

import { useAppSelector, useAppDispatch } from '../../../hooks/store';
import { selectMyListsList } from '../../../store/my-list/reducer';

import { BASE_URL, ModalType } from '../../../constants/ui';
import { IInfluencer } from '../../../models/influencer';
import { IMyList } from '../../../models/my-list';

interface Props {
  influencer: IInfluencer;
  setOpened: (value: boolean) => void;
}

interface AdoptedToSelectList {
  id: number;
  name: string;
}

const AddInfluencerToList = ({ influencer, setOpened }: Props) => {
  const dispatch = useAppDispatch();

  const [keepOpen, setKeepOpen] = useState(true);
  const listsList = useAppSelector<Array<IMyList>>(selectMyListsList);

  const [isSelectOpen, setSelectOpen] = useState(false);
  const [selectedList, setSelectedList] = useState({ id: 0, name: '' });
  const [lists, setLists] = useState<Array<AdoptedToSelectList>>([]);

  useEffect(() => {
    dispatch(getMyListsList());
  }, []);

  useEffect(() => {
    const adoptedToSelectLists = listsList.map(list => ({
      id: list.id,
      name: list.title,
    }));

    setLists(adoptedToSelectLists);
  }, [listsList]);

  const handleSave = () => {
    if (!selectedList.id) return;

    const foundList = listsList.find(list => list.id === selectedList.id);

    if (!foundList) return;

    dispatch(addInfluencerToMyList(influencer, foundList));
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <div className="avatar avatar--modal">
        <img
          className="avatar__image"
          src={BASE_URL + influencer.avatar}
          width="75"
          height="75"
          alt="influencer avatar"
        />
      </div>
      <h3 className="modal__title">{influencer.name}</h3>

      <p className="modal__sub-title">
        Add this influencer to your lists by selecting from the drop down list below
      </p>

      <div className="modal__field-wrapper">
        <div style={{ flex: '1 1 100%' }}>
          <Select
            isOpen={isSelectOpen}
            selectedOption={selectedList}
            setIsOpen={setSelectOpen}
            setSelectedOption={setSelectedList}
            optionList={lists}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Add to list
        </Button>
      </div>
    </Modal>
  );
};

export default AddInfluencerToList;
