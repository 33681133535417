import { useState } from 'react';

import Select from '../select';

import { PAYMENT_LIST } from '../../constants/payment';
import AddEditDeleteButtons from '../add-edit-delete-buttons';

interface Props {
  isInfluencer: boolean;
  isBillingPage?: boolean;
  paymentMethod?: { id: number; name: string };
  isCurrentPaymentMethodFilled: boolean;
  onPaymentMethodChange?: (value: { id: number; name: string }) => void;
  setOpenEditModal: () => void;
  deletePaymentInfo: () => void;
  creditCardInfo?: string;
}

const PaymentSection = (props: Props) => {
  const {
    isInfluencer,
    isBillingPage = false,
    paymentMethod,
    isCurrentPaymentMethodFilled,
    onPaymentMethodChange = () => {},
    setOpenEditModal,
    deletePaymentInfo,
    creditCardInfo,
  } = props;

  const [isPaymentMethodSelectOpen, setPaymentMethodSelectOpen] = useState(false);

  return (
    <>
      {isInfluencer && (
        <div className="account-section">
          <h3 className="account-section__title">
            Payment Method ... <span>How Would You Like To Be Paid?</span>
          </h3>
          <div className="info-block" style={{ alignItems: 'flex-start' }}>
            <div>
              <Select
                isOpen={isPaymentMethodSelectOpen}
                selectedOption={paymentMethod}
                setIsOpen={setPaymentMethodSelectOpen}
                setSelectedOption={onPaymentMethodChange}
                optionList={PAYMENT_LIST}
              />

              <p className="info-block__2st-line">
                Please have the required payment method information ready, then select the Add
                button
              </p>
            </div>

            <AddEditDeleteButtons
              isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled}
              setOpenEditModal={setOpenEditModal}
              deletePaymentInfo={deletePaymentInfo}
            />
          </div>
        </div>
      )}
      {!isInfluencer && isBillingPage && (
        <>
          <p className="info-block__1st-line">
            Credit Card:{' '}
            <span>
              {creditCardInfo
                ? `**** **** **** ${creditCardInfo.substring(creditCardInfo.length - 4)}`
                : `None`}
            </span>
          </p>

          <AddEditDeleteButtons
            isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled}
            setOpenEditModal={setOpenEditModal}
            deletePaymentInfo={deletePaymentInfo}
          />
        </>
      )}
      {!isInfluencer && !isBillingPage && (
        <div className="account-section">
          <h3 className="account-section__title">Payment</h3>
          <div className="info-block">
            <div>
              <p className="info-block__1st-line">
                Credit Card:{' '}
                <span>
                  {creditCardInfo
                    ? `**** **** **** ${creditCardInfo.substring(creditCardInfo.length - 4)}`
                    : `None`}
                </span>
              </p>
              <p className="info-block__2st-line">
                Please have the required payment method information ready, then select the Add
                button
              </p>
            </div>

            <AddEditDeleteButtons
              isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled}
              setOpenEditModal={setOpenEditModal}
              deletePaymentInfo={deletePaymentInfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentSection;
