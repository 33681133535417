import React, { useEffect, useState } from 'react';
import { formatCurrencyFromValue } from '../../utils/format';

import cls from './styles.module.scss';
import { classNames } from '../../utils/class-names';

interface Props {
  value: string | number;
  setValue: (value: string) => void;
}

export const Currency = ({ value, setValue }: Props) => {
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(formatCurrencyFromValue(Number(value)));
  }, [value]);

  const focusHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(evt.target.value.replaceAll(/[$\s,]/gi, ''));
  };
  const blurHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(formatCurrencyFromValue(Number(evt.target.value)));
    setValue(evt.target.value);
  };
  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(evt.target.value);
  };

  return (
    <input
      className={classNames([cls.input])}
      type="text"
      placeholder="$0.00"
      maxLength={10}
      value={tempValue || ''}
      onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
    />
  );
};
