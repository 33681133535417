import { useState, useEffect } from 'react';

import PaymentSection from '../../components/payment-section/payment-section';
import CardModal from '../../components/modals/card-edit';
import PayPalModal from '../../components/modals/paypal-edit';
import ETransferModal from '../../components/modals/e-transfer-edit';
import WireModal from '../../components/modals/wire-edit';
import CryptocurrencyModal from '../../components/modals/cryptocurrency-edit';

import { AccountType, PAYMENT_LIST } from '../../constants/payment';

import { ICreditCard } from '../../models/credit-card';

import { ICryptocurrency } from '../../models/cryptocurrency';
import { IETransfer } from '../../models/e-transfer';
import { IWire } from '../../models/wire';

interface Props {
  isInfluencer: boolean;
}

const BillingPaymentSection = ({ isInfluencer }: Props) => {
  // payment information
  const [paymentMethod, setPaymentMethod] = useState({} as any);
  // credit card information
  const [isCardEditModalOpen, setCardEditModalOpen] = useState(false);
  const [creditCard, setCreditCard] = useState({} as ICreditCard);
  // paypal information
  const [isPayPalModalOpen, setPayPalModalOpen] = useState(false);
  const [payPal, setPayPal] = useState({
    name: '',
    email: '',
  });
  // transfer information
  const [isETransferModalOpen, setETransferModalOpen] = useState(false);
  const [eTransfer, setETransfer] = useState<IETransfer>({
    name: '',
    email: '',
  });
  // wire information
  const [isWireModalOpen, setWireModalOpen] = useState(false);
  const [wire, setWire] = useState<IWire>({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    iban: '',
    bankName: '',
    bankAddress1: '',
    bankAddress2: '',
    bankCity: '',
    bankState: '',
    bankCountry: '',
    bankZip: '',
    bankRoutingNumber: '',
    accountType: AccountType.CHECKING,
  });
  // crypto information
  const [isCryptocurrencyModalOpen, setCryptocurrencyModalOpen] = useState(false);
  const [cryptocurrency, setCryptocurrency] = useState<ICryptocurrency>({
    cryptocurrency: undefined,
    account: '',
  });

  //-----------------------------------------------------
  useEffect(() => {
    if (!isInfluencer) {
      setPaymentMethod(PAYMENT_LIST[0]);
    }
  }, [isInfluencer]);

  const isCurrentPaymentMethodFilled = (): boolean => {
    const method = paymentMethod?.id;

    switch (method) {
      case 1: // credit-card
        return Boolean(
          creditCard.name ||
            creditCard.number ||
            creditCard.expiryMM ||
            creditCard.expiryYY ||
            creditCard.cvv,
        );
      case 2: // paypal
        return Boolean(payPal.name || payPal.email);
      case 3: // e-transfer
        return Boolean(eTransfer.name || eTransfer.email);
      case 4: // wire
        return Boolean(
          wire.name || wire.address1 || wire.city || wire.state || wire.country || wire.zip,
        );
      case 5: // cryptocurrency
        return Boolean(cryptocurrency.cryptocurrency || cryptocurrency.account);
      default:
        return false;
    }
  };

  const setOpenEditModal = () => {
    switch (paymentMethod?.id) {
      case 1: // credit-card
        setCardEditModalOpen(true);
        break;
      case 2: // paypal
        setPayPalModalOpen(true);
        break;
      case 3: // e-transfer
        setETransferModalOpen(true);
        break;
      case 4: // wire
        setWireModalOpen(true);
        break;
      case 5: // cryptocurrency
        setCryptocurrencyModalOpen(true);
        break;
      default:
        break;
    }
  };

  const deletePaymentInfo = () => {
    switch (paymentMethod?.id) {
      case 1: // credit-card
        setCreditCard({
          name: '',
          number: '',
          expiryMM: '',
          expiryYY: '',
          cvv: '',
        });
        break;
      case 2: // paypal
        setPayPal({
          name: '',
          email: '',
        });
        break;
      case 3: // e-transfer
        setETransfer({
          name: '',
          email: '',
        });
        break;
      case 4: // wire
        setWire({
          name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zip: '',
          iban: '',
          bankName: '',
          bankAddress1: '',
          bankAddress2: '',
          bankCity: '',
          bankState: '',
          bankCountry: '',
          bankZip: '',
          bankRoutingNumber: '',
          accountType: AccountType.CHECKING,
        });
        break;
      case 5: // cryptocurrency
        setCryptocurrency({
          cryptocurrency: undefined,
          account: '',
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PaymentSection
        isInfluencer={isInfluencer}
        isBillingPage={true}
        paymentMethod={paymentMethod}
        isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled()}
        onPaymentMethodChange={setPaymentMethod}
        setOpenEditModal={setOpenEditModal}
        deletePaymentInfo={deletePaymentInfo}
        creditCardInfo={creditCard.number}
      />
      {isCardEditModalOpen && (
        <CardModal
          setOpened={setCardEditModalOpen}
          creditCard={creditCard}
          setCreditCard={setCreditCard}
        />
      )}
      {isPayPalModalOpen && (
        <PayPalModal setOpened={setPayPalModalOpen} payPal={payPal} setPayPal={setPayPal} />
      )}
      {isETransferModalOpen && (
        <ETransferModal
          setOpened={setETransferModalOpen}
          eTransfer={eTransfer}
          setETransfer={setETransfer}
        />
      )}
      {isWireModalOpen && (
        <WireModal wide setOpened={setWireModalOpen} wire={wire} setWire={setWire} />
      )}
      {isCryptocurrencyModalOpen && (
        <CryptocurrencyModal
          setOpened={setCryptocurrencyModalOpen}
          cryptocurrency={cryptocurrency}
          setCryptocurrency={setCryptocurrency}
        />
      )}
    </>
  );
};

export default BillingPaymentSection;
