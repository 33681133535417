import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaign } from '../../models/campaign';

import { RootState } from '../store';

import { CampaignStatus } from '../../constants/campaign';

interface StatusFilerList {
  [CampaignStatus.DRAFT]: boolean;
  [CampaignStatus.ACTIVE]: boolean;
  [CampaignStatus.CLOSED]: boolean;
  [CampaignStatus.ARCHIVE]: boolean;
}

interface CampaignState {
  isLoading: boolean;
  list: ICampaign[];
  tempCampaign: ICampaign;
  statusFilerList: StatusFilerList;
}

const initialState = {
  isLoading: false,
  list: [] as ICampaign[],
  tempCampaign: {} as ICampaign,
  statusFilerList: {
    [CampaignStatus.DRAFT]: true,
    [CampaignStatus.ACTIVE]: true,
    [CampaignStatus.CLOSED]: false,
    [CampaignStatus.ARCHIVE]: false,
  },
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setIsLoading: (state: CampaignState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCampaignList: (state: CampaignState, action: PayloadAction<ICampaign[]>) => {
      state.list = action.payload;
    },
    setCampaign: (state: CampaignState, action: PayloadAction<ICampaign>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [
          ...state.list.slice(0, index),
          action.payload,
          ...state.list.slice(index + 1),
        ];
      } else {
        state.list.push(action.payload);
      }
    },
    setStatusFilter: (state: CampaignState, action: PayloadAction<StatusFilerList>) => {
      state.statusFilerList = { ...action.payload };
    },
    toggleBookmark: (state: CampaignState, action: PayloadAction<ICampaign>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [
          ...state.list.slice(0, index),
          { ...action.payload, bookmark: !action.payload.bookmark },
          ...state.list.slice(index + 1),
        ];
      } else {
        state.list.push(action.payload);
      }
    },
    deleteBookmark: (state: CampaignState, action: PayloadAction<ICampaign>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [...state.list.slice(0, index), ...state.list.slice(index + 1)];
      }
    },
  },
});

export default campaignSlice.reducer;

export const getIsLoading = (state: RootState) => state.campaign.isLoading;
export const selectCampaignList = (state: RootState) => state.campaign.list;
export const selectCampaign = (state: RootState) => (id: number) =>
  state.campaign.list.find(item => item.id === id);
export const selectStatusFilterList = (state: RootState) => state.campaign.statusFilerList;
