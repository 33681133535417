import { useEffect } from 'react';

import RateCardList from '../../components/rate-card-list';
import Info from './profile-info';
import { SaveCancelButtons } from '../../components/button';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getRateCardsList } from '../../store/rate-cards/reducer';
import { getUserRateCardList, updateUserRateCardList } from '../../api/user';
import { getNetworkList } from '../../store/networks/reducer';

const ProfileRateCardList = () => {
  const dispatch = useAppDispatch();
  const networkList = useAppSelector(getNetworkList);
  const rateCardList = useAppSelector(getRateCardsList);

  useEffect(() => {
    dispatch(getUserRateCardList());
  }, []);

  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(getUserRateCardList());
  };
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(updateUserRateCardList(rateCardList));
  };

  return (
    <>
      <h2 className="tab__title">Rate Card</h2>

      <div className="info-block">
        <form className="profile-page__form form" onReset={handleReset} onSubmit={handleSubmit}>
          <RateCardList rateCardList={rateCardList} networkList={networkList} />

          <SaveCancelButtons />
        </form>
      </div>
      <Info left="Edit info" right="Change Rate Card" />
    </>
  );
};

export default ProfileRateCardList;
