import api from '../http';
import { AxiosResponse } from 'axios';

export default class CampaignService {
  static async getMyListsList(): Promise<AxiosResponse> {
    console.log('getMyListsList:', `/my-list`);
    return api.get(`/my-list`);
  }
  static async addMyList(title: string): Promise<AxiosResponse> {
    console.log('addMyList:', `/my-list`);
    return api.put(`/my-list`, { title });
  }
  static async removeMyList(id: number): Promise<AxiosResponse> {
    console.log('removeMyList:', `/my-list?id=${id}`);
    return api.delete(`/my-list?id=${id}`);
  }
  static async getInfluencerListFromMyList(listID: number): Promise<AxiosResponse> {
    console.log('getInfluencerListFromMyList:', `/my-list-details?id=${listID}`);
    return api.get(`/my-list-details?id=${listID}`);
  }
  static async addInfluencerToMyList(influencerID: number, listID: number): Promise<AxiosResponse> {
    console.log('addInfluencerToMyList:', `/my-list-details`);
    return api.post(`/my-list-details`, { id: listID, influencer_id: influencerID });
  }
  static async removeInfluencerFromMyList(
    influencerID: number,
    listID: number,
  ): Promise<AxiosResponse> {
    console.log(
      'removeInfluencerFromMyList:',
      `/my-list-details?id=${influencerID}&list_id=${listID}`,
    );
    return api.delete(`/my-list-details?id=${influencerID}&list_id=${listID}`);
  }
}
