import { Link, NavLink } from 'react-router-dom';
import { BASE_URL } from '../../constants/ui';
import { useAppSelector } from '../../hooks/store';

import { AppRoute } from '../../router/routes';
import { getUserName, getUserLastName, getUserAvatarUrl } from '../../store/auth/reducer';

const MenuItem = ({ to, children }: { to: AppRoute; children: React.ReactNode }) => (
  <li>
    <NavLink
      className={({ isActive }) => `user-menu__link ${isActive ? `user-menu__link--active` : ``}`}
      to={to}>
      {children}
    </NavLink>
  </li>
);

interface MainMenuProps {
  isInfluencer: boolean;
  isUserMenuOpened: boolean;
  setUserMenuOpened: (isUserMenuOpened: boolean) => void;
}

const UserMenu = ({ isInfluencer, isUserMenuOpened, setUserMenuOpened }: MainMenuProps) => {
  const userName = useAppSelector(getUserName);
  const userLastName = useAppSelector(getUserLastName);
  const userAvatarUrl = useAppSelector(getUserAvatarUrl);
  const toggleUserMenuOpened = () => setUserMenuOpened(!isUserMenuOpened);

  return (
    <div className={`user-menu ${isUserMenuOpened ? 'user-menu--opened' : ''}`}>
      <div className="user-menu__avatar avatar">
        <Link to={AppRoute.PROFILE}>
          <img
            className="avatar__image"
            width="50"
            height="50"
            src={BASE_URL + userAvatarUrl}
            alt="user avatar"
          />
        </Link>
      </div>
      <div className="user-menu__wrapper">
        <button className="user-menu__button" onClick={toggleUserMenuOpened}>
          {userName} {userLastName}
        </button>
        <ul className="user-menu__list">
          <MenuItem to={AppRoute.PROFILE}>Account profile</MenuItem>
          <MenuItem to={AppRoute.BILLING}>Billing details</MenuItem>
          {!isInfluencer && <MenuItem to={AppRoute.PAYMENT_PLANS}>Payments Plans</MenuItem>}
          <MenuItem to={AppRoute.LOGOUT}>Sign out</MenuItem>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
