import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface Campaign {
  id: number; // campaign id
  campaign_id: number; // campaign id
  title: string;
  image: string;
  unread: number;
}

export interface Contact {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
  chatroom_id: number;
  unread: number;
  active: number; // boolean 0/1
}

export interface Message {
  id: number;
  campaign_id: number;
  sender_id: number;
  contact_id: number;
  text: string;
  type: string; // 'text'
  status: string; // 'sent'
  created_at: string;
  updated_at: string;
}

export interface AdditionalInfoState {
  isConnected: boolean;
  newMessages: number;
  campaignList: Campaign[];
  contactList: Contact[];
  messageList: Message[];
}

export const initialState = {
  isConnected: false,
  newMessages: 0,
  campaignList: [],
  contactList: [],
  messageList: [],
};

export const MessageSlice = createSlice({
  name: 'Message',
  initialState,
  reducers: {
    setConnected: (state: AdditionalInfoState, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setNewMessages: (state: AdditionalInfoState, action: PayloadAction<number>) => {
      state.newMessages = action.payload;
    },
    setCampaignList: (state: AdditionalInfoState, action: PayloadAction<Campaign[]>) => {
      state.campaignList = action.payload;
    },
    setContactList: (state: AdditionalInfoState, action: PayloadAction<Contact[]>) => {
      state.contactList = action.payload;
    },
    setMessage: (state: AdditionalInfoState, action: PayloadAction<Message>) => {
      state.messageList.push(action.payload);
    },
    setMessageList: (state: AdditionalInfoState, action: PayloadAction<Message[]>) => {
      const newMessages = action.payload;

      const notEditedMessages = state.messageList.filter(
        message => newMessages.findIndex(newMessage => newMessage.id === message.id) === -1,
      );

      state.messageList = [...notEditedMessages, ...newMessages].sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
    },
    setEmptyMessageList: (state: AdditionalInfoState) => {
      state.messageList = [];
    },
    setEmptyCampaignList: (state: AdditionalInfoState) => {
      state.campaignList = [];
    },
    setEmptyContactList: (state: AdditionalInfoState) => {
      state.contactList = [];
    },
  },
});

export default MessageSlice.reducer;

export const selectConnected = (state: RootState) => state.message.isConnected;
export const selectNewMessages = (state: RootState) => state.message.newMessages;
export const selectCampaignList = (state: RootState) => state.message.campaignList;
export const selectContactList = (state: RootState) => state.message.contactList;
export const selectMessageList = (state: RootState) => state.message.messageList;
