import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInfluencer } from '../../models/influencer';

import { RootState } from '../store';

interface InfluencerState {
  isLoading: boolean;
  list: IInfluencer[];
  campaignInfluencerList: IInfluencer[];
  influencerDetails: IInfluencer; // ANCHOR: could be changed on server side
}

const initialState = {
  isLoading: false,
  list: [] as IInfluencer[],
  campaignInfluencerList: [] as IInfluencer[],
  influencerDetails: {} as IInfluencer,
};

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {
    setIsLoading: (state: InfluencerState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInfluencerList: (state: InfluencerState, action: PayloadAction<IInfluencer[]>) => {
      state.list = action.payload;
    },
    setInfluencer: (state: InfluencerState, action: PayloadAction<IInfluencer>) => {
      const oldInfluencer = state.list.findIndex(influencer => influencer.id === action.payload.id);

      if (oldInfluencer === -1) {
        state.list.push(action.payload);
      } else {
        state.list = [
          ...state.list.slice(0, oldInfluencer),
          action.payload,
          ...state.list.slice(oldInfluencer + 1),
        ];
      }
    },
    setInfluencerDetails: (state: InfluencerState, action: PayloadAction<IInfluencer>) => {
      state.influencerDetails = action.payload;
    },
    setCampaignInfluencerList: (state: InfluencerState, action: PayloadAction<IInfluencer[]>) => {
      state.campaignInfluencerList = action.payload;
    },
    toggleBookmark: (state: InfluencerState, action: PayloadAction<IInfluencer>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [
          ...state.list.slice(0, index),
          { ...action.payload, bookmark: !action.payload.bookmark },
          ...state.list.slice(index + 1),
        ];
      }
    },
    deleteBookmark: (state: InfluencerState, action: PayloadAction<IInfluencer>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [...state.list.slice(0, index), ...state.list.slice(index + 1)];
      }
    },
  },
});

export default influencerSlice.reducer;

export const selectLoading = (state: RootState) => state.influencer.isLoading;
export const selectInfluencerList = (state: RootState) => state.influencer.list;
export const selectCampaignInfluencerList = (state: RootState) =>
  state.influencer.campaignInfluencerList;
