import { TD, TR } from '../../components/table';
import { Indicator } from '../../components/indicator';
import { InfinitySign } from '../../components/infinity';
import { Text } from '../../components/text';
import { ThreeDots } from '../../components/button';

import { IPresentation } from '../../models/presentation';

interface Props {
  presentation: IPresentation;
  onThreeDotsClick: (presentation: IPresentation) => void;
}

const PresentationRow = ({ presentation, onThreeDotsClick }: Props) => {
  const isRed = presentation.daysLeft <= '3' && presentation.daysLeft >= '1';

  const threeDotsHandler = () => {
    onThreeDotsClick(presentation);
  };

  return (
    <TR>
      <TD minimize>
        <Indicator
          green={presentation.status === 'Active'}
          orange={presentation.status === 'Exported'}
          red={isRed}
        />
      </TD>
      <TD>{presentation.title}</TD>
      <TD>
        Export Date:{' '}
        <Text red={isRed} gray={!isRed}>
          {presentation.exportedAt}
        </Text>
      </TD>
      <TD>
        Days Left:{' '}
        <Text red={isRed} gray={!isRed}>
          {presentation.daysLeft ? `${presentation.daysLeft} days` : <InfinitySign />}
        </Text>
      </TD>
      <TD>
        <Text gray>{presentation.items} items</Text>
      </TD>
      <TD alignRight>
        <ThreeDots border onClick={threeDotsHandler} />
      </TD>
    </TR>
  );
};

export { PresentationRow };
