import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { sendNewPassword } from '../../api/auth';

import { Button } from '../../components/button';
import ChatButton from '../../components/chat-button';
import { Input } from '../../components/form-field';

import { uiSlice } from '../../store/ui/reducer';
import { useAppDispatch } from '../../hooks/store';
import { UIType } from '../../constants/ui';
import { AppRoute } from '../../router/routes';

const ForgotNewPassword = () => {
  const dispatch = useAppDispatch();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const { type, token } = useParams();
  const navigate = useNavigate();
  const { setType } = uiSlice.actions;

  useEffect(() => {
    if (type === UIType.INFLUENCER) dispatch(setType(UIType.INFLUENCER));
    else dispatch(setType(UIType.ADVERTISER));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (!token) return;
    dispatch(sendNewPassword(token, password1, password2));
  };

  return (
    <section className="blank-page" style={{ justifyContent: 'flex-start', margin: '45px 170px' }}>
      <h2 className="visually-hidden">Blank page</h2>
      <p>Welcome to influencer Union</p>
      <p style={{ fontSize: '36px', lineHeight: '1.2', marginBottom: '90px' }}>
        Enter your account email address and select the submit button to validate your account, once
        validated the password reset fields will be displayed to choose a new password.
      </p>
      <form style={{ display: 'grid', width: '450px' }} onSubmit={onSubmit}>
        <Input
          type="password"
          label="New password"
          required
          value={password1}
          onChange={setPassword1}
        />
        <Input
          type="password"
          label="Confirm new password"
          required
          value={password2}
          onChange={setPassword2}
        />
        <div style={{ marginBottom: '40px' }}></div>
        <div style={{ margin: '0 auto' }}>
          <Button primary submit onClick={() => {}}>
            Submit New Password
          </Button>
        </div>
        <Button
          onClick={() => {
            navigate(AppRoute.LOGIN);
          }}>
          Go to login
        </Button>
      </form>
      <ChatButton />
    </section>
  );
};

export default ForgotNewPassword;
