import { useEffect } from 'react';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import ContentWrapper from '../../../components/content-wrapper';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../router/routes';
import { ICampaignReportList } from '../../../models/report';
import { selectReportList } from '../../../store/report/reducer';
import { getReportList } from '../../../api/report';
import { ReportStatusFilter, ReportTable } from '../../../components/report';
import SearchForm from '../../../components/search-form';
import { ICampaign } from '../../../models/campaign';

const Advertiser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const reportList = useAppSelector<ICampaignReportList>(selectReportList);

  useEffect(() => {
    dispatch(getReportList());
  }, [dispatch]);

  const threeDotsHandler = (report: ICampaign) => {
    navigate(AppRoute.CAMPAIGN_REPORT + `/` + report.id);
  };
  const searchHandler = (searchString: string) => {
    navigate(AppRoute.SEARCH_RESULT);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Campaign Report</h2>
        <SearchForm onInput={searchHandler} reset />
        <ReportStatusFilter />
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <ReportTable reportList={reportList} onThreeDotsClick={threeDotsHandler} />
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
