import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { InfluencerAnalytics, ISocialMediaSummary } from '../../models/influencer-analytics';

interface InfluencerAnalyticsState {
  isLoading: boolean;
  analytics: InfluencerAnalytics;
  socialMediaSummary: ISocialMediaSummary;
}

const initialState = {
  isLoading: false,
  analytics: {
    id: 0,
    user_id: 0,
    iu_score: 0,
    social_reach: [],
    engagement_rate: [],
    likes: [],
    popularity_dynamics: [],
    age: [],
    gender: [],
    topics: [],
    influencer_info: {
      bio: '',
      language: '',
      ethnicity: '',
      gender: '',
      location: '',
      age_range: '',
    },
    rate_card: [],
  },
  socialMediaSummary: [] as ISocialMediaSummary,
};

export const influencerAnalyticsSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {
    setIsLoading: (state: InfluencerAnalyticsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInfluencerAnalytics: (
      state: InfluencerAnalyticsState,
      action: PayloadAction<InfluencerAnalytics>,
    ) => {
      state.analytics = action.payload;
    },

    setSocialMediaSummary: (
      state: InfluencerAnalyticsState,
      action: PayloadAction<ISocialMediaSummary>,
    ) => {
      state.socialMediaSummary = action.payload;
    },
  },
});

export default influencerAnalyticsSlice.reducer;

export const selectLoading = (state: RootState) => state.influencerAnalytics.isLoading;
export const selectInfluencerAnalytics = (state: RootState) => state.influencerAnalytics.analytics;
export const selectSocialMediaSummary = (state: RootState) =>
  state.influencerAnalytics.socialMediaSummary;
