import Modal from '../modal';

import Select from '../../select/select';

import { ModalType } from '../../../constants/ui';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { filterSlice, selectFilters } from '../../../store/filter/reducer';
import { FilterRow, Filter as IFilter } from '../../../models/filter-model';

interface Props {
  setOpened: (value: boolean) => void;
}

const Filter = ({ setOpened }: Props) => {
  const dispatch = useAppDispatch();
  const filterList = useAppSelector(selectFilters);

  const { setFilter, setFilterOpen } = filterSlice.actions;

  const setSelectOpen = (filter: IFilter, isOpen: boolean) => {
    const newFilter = { ...filter };
    newFilter.isOpen = isOpen;

    dispatch(setFilterOpen(newFilter));
  };

  const setSelectValue = (filter: IFilter, value: FilterRow) => {
    const newFilter = { ...filter };
    newFilter.current = value;

    dispatch(setFilter(newFilter));
  };

  return (
    <Modal type={ModalType.SIDE} setOpened={setOpened} noEscape noOutsideClick noOverlay>
      <h3 className="modal__title">Filter</h3>

      <form className="form modal__form">
        {filterList.map(filter => {
          return (
            <div className="form__vertical-flex">
              <div style={{ flex: '1 1 100%', marginBottom: '15px' }}>
                <div style={{ marginBottom: '5px' }}>{filter.name.replaceAll('_', ' ')}</div>
                <Select
                  isOpen={filter.isOpen}
                  setIsOpen={isOpen => {
                    setSelectOpen(filter, isOpen);
                  }}
                  setSelectedOption={value => {
                    setSelectValue(filter, value);
                  }}
                  optionList={filter.states}
                  selectedOption={filter.current}
                  deletable></Select>
              </div>
            </div>
          );
        })}
      </form>
    </Modal>
  );
};

export default Filter;
