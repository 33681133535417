import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const Separator = ({ className, children }: Props) => {
  return <tr className={classNames([cls.separator, className])}>{children}</tr>;
};
