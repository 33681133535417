import { ICategory, IServerCategory } from '../models/category';

export const adoptCategoryToClient = ({
  id,
  status,
  name,
  img_url,
  order,
}: IServerCategory): ICategory => ({
  id,
  status: !!status,
  name,
  img_url,
  order,
});
