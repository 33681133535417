import Modal from '../modal';
import { Button } from '../../button';

import { ModalType } from '../../../constants/ui';

interface Props {
  setOpened: (value: boolean) => void;
  yesClick: () => void;
}

const PaypalEdit = ({ setOpened, yesClick }: Props) => {
  const noClick = () => setOpened(false);

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen noOutsideClick>
      <h3 className="modal__title">Change status to "archive"?</h3>
      <div className="modal__field-wrapper">
        <h4 className="modal__property-title">
          The selected campaign will be archived, this operation cannot be undone, are you sure: Yes
          / No
        </h4>

        <Button primary onClick={yesClick}>
          Yes
        </Button>
        <Button secondary onClick={noClick}>
          No
        </Button>
      </div>
    </Modal>
  );
};

export default PaypalEdit;
