import { IPaymentPlan } from '../../models/payment-plan';
import { Button } from '../button';

interface Props {
  isPaymentPlanPage: boolean;
  paymentPlan: IPaymentPlan;
  currentPaymentPlan: number;
  setCurrentPaymentPlan: (paymentPlan: number) => void;
  storedPaymentPlan?: number;
  updateCurrentPaymentPlan?: (paymentPlan: number) => void;
}

const PaymentPlan = ({
  isPaymentPlanPage,
  paymentPlan,
  currentPaymentPlan,
  setCurrentPaymentPlan,
  storedPaymentPlan,
  updateCurrentPaymentPlan,
}: Props) => {
  const handleChange = () => {
    setCurrentPaymentPlan(paymentPlan.id);
  };

  const handleUpdate = () => {
    if (!updateCurrentPaymentPlan) return;

    updateCurrentPaymentPlan(paymentPlan.id);
  };

  return (
    <li>
      <input
        className="visually-hidden"
        id={`payment-plan-${paymentPlan.id}`}
        type="radio"
        name="payment-plan"
        value={paymentPlan.id}
        onChange={handleChange}
        checked={paymentPlan.id === currentPaymentPlan}
      />
      <label className="payment-plan__label" htmlFor={`payment-plan-${paymentPlan.id}`}>
        {paymentPlan.name}
        <span className="payment-plan__value">${paymentPlan.cost}</span>
        <span className="payment-plan__currency">
          USD/month
          {isPaymentPlanPage && paymentPlan.id === storedPaymentPlan && (
            <>
              <br />
              Current plan
            </>
          )}
        </span>
        {isPaymentPlanPage &&
          paymentPlan.id === currentPaymentPlan &&
          paymentPlan.id !== storedPaymentPlan && (
            <Button primary onClick={handleUpdate}>
              Upgrade
            </Button>
          )}
      </label>
    </li>
  );
};

export default PaymentPlan;
