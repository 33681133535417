import { useEffect, useRef } from 'react';

import { DayGroup } from './day-group';

import { Contact, Message } from '../../store/message/reducer';

import cls from './message.module.scss';

interface Props {
  groupedMessageList: Message[][];
  userId: number;
  contact?: Contact;
}

export const MessagesList = ({ groupedMessageList, userId, contact }: Props) => {
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [groupedMessageList]);

  return (
    <div className={cls.list}>
      {groupedMessageList.map(group => {
        return (
          <DayGroup key={group[0].created_at} group={group} contact={contact} userId={userId} />
        );
      })}
      <div className={cls.emptyLine} ref={messageRef} />
    </div>
  );
};
