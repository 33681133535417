import { Contact as ContactType } from '../../store/message/reducer';

import { BASE_URL } from '../../constants/ui';

import cls from './message.module.scss';
import { NewMessagesBubble } from './new-messages-bubble';

interface Props {
  contact: ContactType | undefined;
  onClick: (contact: ContactType | undefined) => void;
}

export const Contact = ({ contact, onClick }: Props) => {
  const handleContactChange = () => onClick(contact);

  return contact ? (
    <div className={cls.contact} onClick={handleContactChange}>
      <NewMessagesBubble className={cls.leftSide} count={contact.unread} />
      <img
        className="avatar__image"
        src={BASE_URL + contact.avatar_url}
        width="50"
        height="50"
        alt="User avatar"
      />
      <div>{contact.first_name || contact.last_name}</div>
    </div>
  ) : (
    <div className={cls.contact} onClick={handleContactChange}>
      Choose contact...
    </div>
  );
};
