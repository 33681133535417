import { ModalType } from '../../../constants/ui';

import Modal from '../modal';

interface ModalProps {
  setOpened: (opened: boolean) => void;
}

const TermsConditions = ({ setOpened }: ModalProps) => {
  return (
    <Modal wide type={ModalType.POPUP} setOpened={setOpened}>
      <h3 className="modal__title">Terms & Conditions</h3>
      <div className="modal__terms">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum maxime rem vel velit,
          deleniti numquam natus amet laudantium reiciendis molestias nihil voluptatem dicta hic
          aliquam error illum harum pariatur optio. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Perferendis eaque et officiis exercitationem asperiores nam esse
          incidunt corrupti eius maxime facere voluptatem architecto est, eum in deserunt qui ipsa
          provident. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eaque et
          officiis exercitationem asperiores nam esse incidunt corrupti eius maxime facere
          voluptatem architecto est, eum in deserunt qui ipsa provident.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum maxime rem vel velit,
          deleniti numquam natus amet laudantium reiciendis molestias nihil voluptatem dicta hic
          aliquam error illum harum pariatur optio. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Perferendis eaque et officiis exercitationem asperiores nam esse
          incidunt corrupti eius maxime facere voluptatem architecto est, eum in deserunt qui ipsa
          provident. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eaque et
          officiis exercitationem asperiores nam esse incidunt corrupti eius maxime facere
          voluptatem architecto est, eum in deserunt qui ipsa provident. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Dolorum maxime rem vel velit, deleniti numquam natus amet
          laudantium reiciendis molestias nihil voluptatem dicta hic aliquam error illum harum
          pariatur optio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eaque
          et officiis exercitationem asperiores nam esse incidunt corrupti eius maxime facere
          voluptatem architecto est, eum in deserunt qui ipsa provident. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Perferendis eaque et officiis exercitationem asperiores nam
          esse incidunt corrupti eius maxime facere voluptatem architecto est, eum in deserunt qui
          ipsa provident.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum maxime rem vel velit,
          deleniti numquam natus amet laudantium reiciendis molestias nihil voluptatem dicta hic
          aliquam error illum harum pariatur optio. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Perferendis eaque et officiis exercitationem asperiores nam esse
          incidunt corrupti eius maxime facere voluptatem architecto est, eum in deserunt qui ipsa
          provident. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eaque et
          officiis exercitationem asperiores nam esse incidunt corrupti eius maxime facere
          voluptatem architecto est, eum in deserunt qui ipsa provident. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Dolorum maxime rem vel velit, deleniti numquam natus amet
          laudantium reiciendis molestias nihil voluptatem dicta hic aliquam error illum harum
          pariatur optio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eaque
          et officiis exercitationem asperiores nam esse incidunt corrupti eius maxime facere
          voluptatem architecto est, eum in deserunt qui ipsa provident. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Perferendis eaque et officiis exercitationem asperiores nam
          esse incidunt corrupti eius maxime facere voluptatem architecto est, eum in deserunt qui
          ipsa provident.
        </p>
      </div>
    </Modal>
  );
};

export default TermsConditions;
