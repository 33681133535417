import { Input } from '../form-field';

interface Props {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
  setOldPassword: (value: string) => void;
  setNewPassword1: (value: string) => void;
  setNewPassword2: (value: string) => void;
}

const ChangePassword = ({
  oldPassword,
  newPassword1,
  newPassword2,
  setOldPassword,
  setNewPassword1,
  setNewPassword2,
}: Props) => {
  return (
    <>
      <Input
        type="password"
        placeholder="Current password"
        label="Old password *"
        value={oldPassword}
        onChange={setOldPassword}
        required
      />
      <Input
        type="password"
        placeholder="New Password"
        label="New password *"
        value={newPassword1}
        onChange={setNewPassword1}
        required
      />
      <Input
        type="password"
        placeholder="Confirm Password"
        label="Repeat password *"
        value={newPassword2}
        onChange={setNewPassword2}
        required
      />
    </>
  );
};

export default ChangePassword;
