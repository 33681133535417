import { useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';

import { ModalType } from '../../../constants/ui';

interface Props {
  setOpened: (value: boolean) => void;
  title: string;
  setTitle: (title: string) => void;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const MyListEdit = ({ setOpened, title = '', setTitle }: Props) => {
  const [temporaryTitle, setTemporaryTitle] = useState(title);
  const [keepOpen, setKeepOpen] = useState(true);

  const handleTitleChange = (event: ChangeEvent) => {
    setTemporaryTitle(event.target.value);
  };
  const handleSave = () => {
    setTitle(temporaryTitle);
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">Create New List</h3>
      <div className="modal__field-wrapper">
        <div className="form__icon-wrapper">
          <input
            className="form__input"
            name="name"
            type="text"
            placeholder="New List name"
            value={temporaryTitle}
            onChange={handleTitleChange}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Create New List
        </Button>
      </div>
    </Modal>
  );
};

export default MyListEdit;
