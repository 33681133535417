import { useEffect } from 'react';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import ContentWrapper from '../../../components/content-wrapper';
import { PresentationDetailTable } from '../../../components/presentation';

import {
  selectPresentation,
  selectPresentationDetailList,
} from '../../../store/presentation/reducer';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { IPresentation, IPresentationDetail } from '../../../models/presentation';
import { useParams } from 'react-router-dom';
import {
  deletePresentationDetail,
  getPresentationDetails,
  getPresentationList,
} from '../../../api/presentation';

const Advertiser = () => {
  const dispatch = useAppDispatch();

  const id = Number(useParams().id) || 0;

  const presentationDetailList = useAppSelector<IPresentationDetail[]>(
    selectPresentationDetailList,
  );
  const currentPresentation = useAppSelector<IPresentation | undefined>(selectPresentation(id));

  useEffect(() => {
    if (!id) return;

    dispatch(getPresentationDetails(id));
    dispatch(getPresentationList());
  }, [dispatch, id]);

  const deleteHandler = (presentationDetail: IPresentationDetail) => {
    dispatch(deletePresentationDetail(presentationDetail));
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="top-panel__account-profile">
          Media kit / {currentPresentation?.title || ''}
        </h2>
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <PresentationDetailTable
          presentationDetailList={presentationDetailList}
          onDeleteClick={deleteHandler}
        />
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
