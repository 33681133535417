export enum PresentationStatus {
  ACTIVE = 'Active',
  EXPORTED = 'Exported',
  THREE_DAYS_LEFT = '3 days left',
}

export enum PresentationSection {
  INFLUENCER_ANALYTICS = 'Detailed analytics',
  SOCIAL_MEDIA_OVERVIEW = 'Social media overview',
  INFLUENCER_RATE_CARD = 'Influencer rate card',
  NOT_ALLOWED = 'N/A',
}
