interface PrevNextButtonsProps {
  step: number;
  onPrevStepClick: () => void;
  onNextStepClick: () => void;
}

const PrevNexButtons = ({ step, onPrevStepClick, onNextStepClick }: PrevNextButtonsProps) => {
  return (
    <div className="form__flex-field" style={{ marginTop: '20px' }}>
      {step !== 0 && (
        <button className="button button--transparent-red" type="button" onClick={onPrevStepClick}>
          Previous
        </button>
      )}
      {step < 3 && (
        <button className="button button--red" type="button" onClick={onNextStepClick}>
          Next
        </button>
      )}
      {step === 3 && (
        <button className="button button--red" type="submit">
          Submit
        </button>
      )}
    </div>
  );
};

export default PrevNexButtons;
