import { IResolveParams, LoginSocialFacebook } from 'reactjs-social-login';
import { INetwork } from '../../models/network';
import { Button } from '../button';
import { sendFacebookAccessToken, sendInstagramAccessToken } from '../../api/auth';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getToken } from '../../utils/user';

interface Props {
  network: INetwork;
  setNetworkStatus: (network: INetwork, status: boolean) => void;
  setNetworkURL: (network: INetwork, value: string) => void;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const Network = ({ network, setNetworkStatus, setNetworkURL }: Props) => {
  const dispatch = useAppDispatch();

  const handleStatusChange = (event: ChangeEvent) => {
    setNetworkStatus(network, event.target.checked);
  };
  const handleURLChange = (event: ChangeEvent) => {
    setNetworkURL(network, event.target.value);
  };

  const handleFacebookConnectClick = ({ provider, data }: IResolveParams) => {
    dispatch(sendFacebookAccessToken(data?.accessToken, data?.id, network.id));
  };
  const handleInstagramConnectClick = ({ provider, data }: IResolveParams) => {
    dispatch(sendInstagramAccessToken(data?.accessToken, data?.id, network.id));
  };
  const handleGoogleConnectClick = () => {
    window.location.href =
      'https://accounts.google.com/o/oauth2/auth?client_id=189363157868-1fqfcnn2gu905p73g45lqtibc2b4vd16.apps.googleusercontent.com&redirect_uri=https://dev.api.influencerunion.net/api/google-auth-callback&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/youtube.readonly%20https://www.googleapis.com/auth/youtube&response_type=code&access_type=offline&prompt=consent&state=' +
      getToken();
  };
  // dispatch(
  //   loginWithFacebook(data?.accessToken, data?.id, data?.picture?.data?.url, remember),
  // );

  return (
    <li>
      <label className="form__vertical-field">
        <span className="form__label">{network.title}</span>
        <input
          className="form__input"
          id={`network-${network.id}`}
          type="text"
          placeholder="https://"
          value={network.network_url}
          onChange={handleURLChange}
          disabled={!network.status}
        />

        {network.title === 'Facebook' && (
          <LoginSocialFacebook
            scope="email, read_insights, pages_read_engagement ,pages_read_user_content"
            appId={'3282331172059742'}
            onResolve={handleFacebookConnectClick}
            onReject={err => {
              console.log(err);
            }}>
            <Button
              secondary
              rounded
              onClick={() => {}}
              disabled={!network.status || network.connection_status}>
              {network.connection_status ? 'Connected' : 'Connect...'}
            </Button>
          </LoginSocialFacebook>
        )}

        {network.title === 'Instagram' && (
          <LoginSocialFacebook
            scope="instagram_basic, instagram_manage_insights, email, read_insights, pages_read_engagement ,pages_read_user_content"
            appId={'3282331172059742'}
            onResolve={handleInstagramConnectClick}
            onReject={err => {
              console.log(err);
            }}>
            <Button
              secondary
              rounded
              onClick={() => {}}
              disabled={!network.status || network.connection_status}>
              {network.connection_status ? 'Connected' : 'Connect...'}
            </Button>
          </LoginSocialFacebook>
        )}

        {network.title === 'YouTube' && (
          <Button
            secondary
            rounded
            onClick={handleGoogleConnectClick}
            disabled={!network.status || network.connection_status}>
            {network.connection_status ? 'Connected' : 'Connect...'}
          </Button>
        )}

        <input
          className="visually-hidden"
          id={`network-${network.id}-on`}
          type="checkbox"
          checked={network.status}
          onChange={handleStatusChange}
        />
        <label className="on-off" htmlFor={`network-${network.id}-on`}></label>
      </label>
    </li>
  );
};

export default Network;
