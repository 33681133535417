import { useEffect } from 'react';

const TIMEOUT = 10000;

const NotificationElement = (props: any) => {
  const { notification, removeNotification } = props;

  useEffect(() => {
    setTimeout(() => {
      removeNotification(notification);
    }, TIMEOUT);

    //return () => removeNotification(notification);
  }, [notification, removeNotification]);

  return (
    <li
      className={`notification__element ${
        notification.type === `success`
          ? `notification__element--success`
          : `notification__element--error`
      }`}
      key={notification.id}>
      {notification.message}
      <button
        className="notification__close"
        onClick={() => removeNotification(notification)}></button>
    </li>
  );
};

export default NotificationElement;
