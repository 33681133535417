import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface WireState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export const wireSlice = createSlice({
  name: 'wire',
  initialState,
  reducers: {
    setIsLoading: (state: WireState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default wireSlice.reducer;

export const getIsLoading = (state: RootState) => state.wire.isLoading;

// import { ActionType } from './actions';

// const initialState = {
//   name: '',
//   address1: '',
//   address2: '',
//   city: '',
//   state: '',
//   country: '',
//   ZIP: '',
//   IBAN: '',
//   accountType: '',
// };

// const wireReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_WIRE_TRANSFER_NAME:
//       return {
//         ...state,
//         name: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_ADDRESS1:
//       return {
//         ...state,
//         address1: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_ADDRESS2:
//       return {
//         ...state,
//         address2: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_CITY:
//       return {
//         ...state,
//         city: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_STATE:
//       return {
//         ...state,
//         state: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_ZIP:
//       return {
//         ...state,
//         ZIP: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_COUNTRY:
//       return {
//         ...state,
//         country: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_IBAN:
//       return {
//         ...state,
//         IBAN: action.payload,
//       };

//     case ActionType.SET_WIRE_TRANSFER_ACCOUNT_TYPE:
//       return {
//         ...state,
//         accountType: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export default wireReducer;

// export const selectWireTransferName = (state: RootState) => state.wire.name;
// export const selectWireTransferAddress1 = (state: RootState) => state.wire.address1;
// export const selectWireTransferAddress2 = (state: RootState) => state.wire.address2;
// export const selectWireTransferCity = (state: RootState) => state.wire.city;
// export const selectWireTransferState = (state: RootState) => state.wire.state;
// export const selectWireTransferZIP = (state: RootState) => state.wire.ZIP;
// export const selectWireTransferCountry = (state: RootState) => state.wire.country;
// export const selectWireTransferIBAN = (state: RootState) => state.wire.IBAN;
// export const selectWireTransferAccountType = (state: RootState) => state.wire.accountType;
