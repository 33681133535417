import NotificationElement from './notification-element';

import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { uiSlice, getNotificationList } from '../../store/ui/reducer';

const NotificationList = () => {
  const dispatch = useAppDispatch();
  const notificationList = useAppSelector(getNotificationList);
  const { removeNotification } = uiSlice.actions;

  const handleDelete = (notification: any) => {
    dispatch(removeNotification(notification.id));
  };

  if (!notificationList.length) return null;

  return (
    <div className="notification__wrapper">
      <ul className="notification__list">
        {notificationList.map((notification: any) => (
          <NotificationElement
            key={notification.id}
            notification={notification}
            removeNotification={handleDelete}
          />
        ))}
      </ul>
    </div>
  );
};

export default NotificationList;
