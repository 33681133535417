import { Chart, GoogleChartOptions } from 'react-google-charts';

const data = [
  ['', '', { role: 'style' }, { role: 'annotation' }],
  ['Under $25K', 25, '#4688F1', '25'],
  ['$25K-$50K', 24, '#E7453C', '24'],
  ['$50K-$75K', 17, '#F9BB2D', '17'],
  ['$75K-$100K', 11, '#3AA757', '11'],
  ['$100K-$150K', 12, '#FC6D22', '12'],
  ['$150K-$200K', 5, '#4CBDC5', '5'],
];

const options: GoogleChartOptions = {
  chart: {
    title: undefined,
    subtitle: undefined,
  },
  backgroundColor: 'transparent',
  legend: { position: 'none' },
  width: 500,
  height: 200,
  chartArea: { width: '60%', height: '80%' },
  bar: { groupWidth: '85%' },
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
};

const HouseholdIncome = () => {
  return <Chart chartType="BarChart" width="100%" data={data} options={options} />;
};

export { HouseholdIncome };
