import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { ICategory } from '../../models/category';

interface CategoriesState {
  isLoading: boolean;
  list: ICategory[];
}

const initialState = {
  isLoading: false,
  list: [] as ICategory[],
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setIsLoading: (state: CategoriesState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCategoryList: (state: CategoriesState, action: PayloadAction<Array<ICategory>>) => {
      state.list = action.payload;
    },
  },
});

export default categoriesSlice.reducer;

export const getIsLoading = (state: RootState) => state.categories.isLoading;
export const getCategoryList = (state: RootState) => {
  return state.categories.list;
};

// import { ActionType } from './actions';

// const initialState: any[] = [];

// const categoriesReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_CATEGORIES:
//       return [...action.payload];

//     case ActionType.ADD_CATEGORY:
//       return state.map((category: any) =>
//         category.id === action.payload
//           ? {
//               ...category,
//               checked: true,
//             }
//           : category,
//       );

//     case ActionType.DEL_CATEGORY:
//       return state.map((category: any) =>
//         category.id === action.payload
//           ? {
//               ...category,
//               checked: false,
//             }
//           : category,
//       );

//     default:
//       return state;
//   }
// };

// export default categoriesReducer;

// export const selectCategory = (state: RootState) => (categoryID: string) =>
//   state.categories.find((category: any) => category.id === categoryID);

// export const selectCategories = (state: RootState) => state.categories;
