import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IPresentation, IPresentationDetail } from '../../models/presentation';
import { PresentationStatus } from '../../constants/presentation';

interface StatusFilerList {
  [PresentationStatus.ACTIVE]: boolean;
  [PresentationStatus.EXPORTED]: boolean;
  [PresentationStatus.THREE_DAYS_LEFT]: boolean;
}

interface PresentationState {
  isLoading: boolean;
  list: IPresentation[];
  details: IPresentationDetail[];
  statusFilter: StatusFilerList;
}

const initialState = {
  isLoading: false,
  list: [],
  details: [],
  statusFilter: {
    [PresentationStatus.ACTIVE]: true,
    [PresentationStatus.EXPORTED]: true,
    [PresentationStatus.THREE_DAYS_LEFT]: true,
  },
};

export const presentationSlice = createSlice({
  name: 'Presentation',
  initialState,
  reducers: {
    setIsLoading: (state: PresentationState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setList: (state: PresentationState, action: PayloadAction<IPresentation[]>) => {
      state.list = action.payload;
    },
    addPresentation: (state: PresentationState, action: PayloadAction<IPresentation>) => {
      state.list.push(action.payload);
    },
    setDetails: (state: PresentationState, action: PayloadAction<IPresentationDetail[]>) => {
      state.details = action.payload;
    },
    deleteDetail: (state: PresentationState, action: PayloadAction<IPresentationDetail>) => {
      const index = state.details.findIndex(detail => detail.id === action.payload.id);

      state.details = [...state.details.slice(0, index), ...state.details.slice(index + 1)];
    },
    setStatusFilter: (state: PresentationState, action: PayloadAction<StatusFilerList>) => {
      state.statusFilter = action.payload;
    },
  },
});

export default presentationSlice.reducer;

export const getIsLoading = (state: RootState) => state.presentation.isLoading;
export const selectPresentationList = (state: RootState) => state.presentation.list;
export const selectPresentation = (id: number) => (state: RootState) =>
  state.presentation.list.find((presentation: IPresentation) => presentation.id === id);
export const selectPresentationDetailList = (state: RootState) => state.presentation.details;
export const selectStatusFilterList = (state: RootState) => state.presentation.statusFilter;
