import { useEffect, useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';
import Select from '../../select/select';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ModalType } from '../../../constants/ui';
import { IInfluencer } from '../../../models/influencer';
import {
  addToNewPresentation,
  addToPresentation,
  getPresentationList,
} from '../../../api/presentation';
import { IPresentation } from '../../../models/presentation';
import { selectPresentationList } from '../../../store/presentation/reducer';
import { IMyList } from '../../../models/my-list';
import { PresentationSection } from '../../../constants/presentation';

interface AdoptedToSelectList {
  id: number;
  name: string;
}

interface Props {
  setOpened: (value: boolean) => void;
  list?: IMyList;
  influencer?: IInfluencer;
}

const AddToPresentation = ({ setOpened, list, influencer }: Props) => {
  const dispatch = useAppDispatch();
  const presentationList = useAppSelector<Array<IPresentation>>(selectPresentationList);

  const [isSelectOpen, setSelectOpen] = useState(false);
  const [adoptedList, setAdoptedList] = useState<Array<AdoptedToSelectList>>([]);
  const [selectedContainer, setSelectedContainer] = useState<AdoptedToSelectList | undefined>(
    undefined,
  );
  const [newContainerName, setNewContainerName] = useState('');

  useEffect(() => {
    dispatch(getPresentationList());
  }, [dispatch]);

  useEffect(() => {
    const adoptedToSelectLists = presentationList.map(list => ({
      id: list.id,
      name: list.title,
    }));

    setAdoptedList(adoptedToSelectLists);
  }, [presentationList]);

  const newNameChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewContainerName(e.target.value);
  };

  const addHandle = () => {
    if (newContainerName) {
      dispatch(
        addToNewPresentation(newContainerName, PresentationSection.INFLUENCER_ANALYTICS, list),
      );
    } else if (selectedContainer) {
      const currentContainer = presentationList.find(
        presentation => presentation.id === selectedContainer.id,
      );
      if (!currentContainer) return;
      dispatch(addToPresentation(currentContainer, PresentationSection.INFLUENCER_ANALYTICS, list));
    }
  };

  const bothEmpty = !selectedContainer && !newContainerName;
  const bothFilled = selectedContainer && newContainerName.length > 0;

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen noOutsideClick>
      <h3 className="modal__title">Add to Media Kit</h3>
      <p className="modal__sub-title">Add campaign object(s) into a&nbsp;presentation container</p>
      <div className="modal__field-wrapper" style={{ flexDirection: 'column' }}>
        <div>
          <Select
            isOpen={isSelectOpen}
            selectedOption={selectedContainer}
            setIsOpen={setSelectOpen}
            setSelectedOption={setSelectedContainer}
            optionList={adoptedList}
            deletable
          />
        </div>
        <h3 className="modal__title">OR</h3>
        <div className="form__icon-wrapper">
          <input
            className="form__input"
            type="text"
            placeholder="New container name"
            value={newContainerName}
            onChange={newNameChangeHandle}
          />
        </div>
        <Button primary onClick={addHandle} long disabled={bothEmpty || bothFilled}>
          Add to Media Kit
        </Button>
      </div>
    </Modal>
  );
};

export default AddToPresentation;
