import { useEffect, useState } from 'react';

import Modal from '../modal';
import Select from '../../select';
import { Button } from '../../button';

import { useAppSelector, useAppDispatch } from '../../../hooks/store';
import { selectInfluencerList } from '../../../store/influencer/reducer';

import { ModalType } from '../../../constants/ui';
import { IInfluencer } from '../../../models/influencer';
import { getAdvertiserBookmarkList } from '../../../api/influencer';
import { addInfluencerToMyList } from '../../../api/my-lists';

interface Props {
  listId: number;
  setOpened: (value: boolean) => void;
}

interface AdoptedToSelectList {
  id: number;
  name: string;
}

const AddInfluencer = ({ listId, setOpened }: Props) => {
  const dispatch = useAppDispatch();

  const [keepOpen, setKeepOpen] = useState(true);
  const influencerList = useAppSelector<Array<IInfluencer>>(selectInfluencerList);

  const [isSelectOpen, setSelectOpen] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState({ id: 0, name: '' });
  const [adoptedInfluencerList, setAdoptedInfluencerList] = useState<Array<AdoptedToSelectList>>(
    [],
  );

  useEffect(() => {
    dispatch(getAdvertiserBookmarkList());
  }, []);

  useEffect(() => {
    const adoptedToSelectInfluencerList = influencerList.map(influencer => ({
      id: influencer.id,
      name: influencer.name,
    }));

    setAdoptedInfluencerList(adoptedToSelectInfluencerList);
  }, [influencerList]);

  const handleSave = () => {
    const foundInfluencer = influencerList.find(
      influencer => influencer.id === selectedInfluencer.id,
    );

    if (!foundInfluencer) return;

    dispatch(addInfluencerToMyList(foundInfluencer, listId));
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen} overflowable>
      <h3 className="modal__title">Add Influencer</h3>

      <p className="modal__sub-title">
        Add an influencer to your list by selecting from your Bookmark list below
      </p>

      <div className="modal__field-wrapper">
        <div style={{ flex: '1 1 100%' }}>
          <Select
            isOpen={isSelectOpen}
            placeholder={'Select from your Bookmark list'}
            selectedOption={selectedInfluencer}
            setIsOpen={setSelectOpen}
            setSelectedOption={setSelectedInfluencer}
            optionList={adoptedInfluencerList}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Add to list
        </Button>
      </div>
    </Modal>
  );
};

export default AddInfluencer;
