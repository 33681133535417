import { PresentationSection, PresentationStatus } from '../constants/presentation';
import {
  IPresentation,
  IPresentationDetail,
  IServerPresentation,
  IServerPresentationDetail,
} from '../models/presentation';
import { getEnumKeyByEnumValue } from '../utils/enum';

export const adoptPresentationToClient = (presentation: IServerPresentation): IPresentation => {
  const presentationKey = getEnumKeyByEnumValue(PresentationStatus, presentation.status);

  return {
    id: Number(presentation.id) || 0,
    title: String(presentation.title) || 'N/A',
    status: presentationKey
      ? PresentationStatus[presentationKey]
      : PresentationStatus.THREE_DAYS_LEFT,
    exportedAt: String(presentation.exported_at).substring(0, 10) || 'N/A',
    daysLeft: String(presentation.days_left),
    items: String(presentation.items),
  };
};

export const adoptPresentationListToClient = (data: IServerPresentation[]): IPresentation[] => {
  if (!Array.isArray(data)) return [];

  return data.map(adoptPresentationToClient);
};

export const adoptPresentationDetailToClient = (
  details: IServerPresentationDetail,
): IPresentationDetail => {
  const sectionKey = getEnumKeyByEnumValue(PresentationSection, details.item_type);

  return {
    id: Number(details.id) || 0,
    title: String(details.title) || 'N/A',
    kitId: Number(details.kitId) || 0,
    avatarUrl: String(details.avatar_url),
    influencerId: Number(details.influencer_id) || 0,
    itemType: sectionKey ? PresentationSection[sectionKey] : PresentationSection.NOT_ALLOWED,
  };
};

export const adoptPresentationDetailListToClient = (
  detailList: IServerPresentationDetail[],
): IPresentationDetail[] => {
  if (!Array.isArray(detailList)) return [];

  return detailList.map(adoptPresentationDetailToClient);
};
