import { useState } from 'react';

import Modal from '../modal';

import { Button } from '../../button';

import { BASE_URL, ModalType } from '../../../constants/ui';
import { ICampaign } from '../../../models/campaign';
import { useMessage } from '../../../provider/message-provider';

interface Props {
  campaign: ICampaign;
  setOpened: (value: boolean) => void;
}

const ContactToBrand = ({ campaign, setOpened }: Props) => {
  const message = useMessage();

  const [keepOpen, setKeepOpen] = useState(true);

  const buttonHandler = () => {
    message.createNewChatRoom(campaign.id, campaign.userId);
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <div className="avatar avatar--modal">
        <img
          className="avatar__image"
          src={BASE_URL + campaign.image}
          width="75"
          height="75"
          alt="campaign logo"
        />
      </div>
      <h3 className="modal__title">{campaign.companyName}</h3>

      <p className="modal__sub-title">
        Manifest your interest to participate in the&nbsp;
        <b>{campaign.title.replaceAll(' ', `\u00A0`)}</b> campaign
      </p>

      <div className="modal__field-wrapper">
        <Button primary long onClick={buttonHandler}>
          I’m interested
        </Button>
      </div>
    </Modal>
  );
};

export default ContactToBrand;
