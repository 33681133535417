import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import Modal from '../modal';
import { Button } from '../../button';
import Select from '../../select';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ModalType } from '../../../constants/ui';
import { getCategoryList } from '../../../store/categories/reducer';
import { getUserCategoryList } from '../../../api/user';
import { ICampaign } from '../../../models/campaign';
import { ICategory } from '../../../models/category';
import { newCampaign, updateCampaign } from '../../../api/campaign';

interface Props {
  setOpened: (value: boolean) => void;
  editMode: 'new' | 'edit' | 'copy';
  campaign?: ICampaign | null;
}

type InputEvent = React.ChangeEvent<HTMLInputElement>;
type TextAreaEvent = React.ChangeEvent<HTMLTextAreaElement>;

const EditCampaign = ({ setOpened, editMode, campaign }: Props) => {
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector<Array<ICategory>>(getCategoryList);

  const [tempTitle, setTempTitle] = useState('');
  const [tempStartDate, setTempStartDate] = useState(new Date());
  const [tempEndDate, setTempEndDate] = useState(new Date());
  const [tempDetails, setTempDetails] = useState('');
  const [tempGoals, setTempGoals] = useState('');
  const [tempInstruction, setInstruction] = useState('');
  const [tempCategory, setTempCategory] = useState<ICategory>();
  const [tempHashtags, setTempHashtags] = useState('');

  const [tempImageLink, setTempImageLink] = useState('');
  const [tempBudget, setTempBudget] = useState(0);
  const [tempPublic, setTempPublic] = useState(false);

  const onTitleChange = (event: InputEvent) => setTempTitle(event.target.value);
  const onStartDateChange = (date: Date) => setTempStartDate(date);
  const onEndDateChange = (date: Date) => setTempEndDate(date);
  const onDetailsChange = (event: TextAreaEvent) => setTempDetails(event.target.value);
  const onGoalsChange = (event: InputEvent) => setTempGoals(event.target.value);
  const onInstructionChange = (event: TextAreaEvent) => setInstruction(event.target.value);
  const onTempHashtagsChange = (event: InputEvent) => {
    const hashtags = event.target.value
      .split(',')
      .reduce((acc, item) => {
        const hashtag = item.replace(/\W/g, '');
        if (hashtag) acc.push('#' + hashtag);
        else acc.push(hashtag);
        return acc;
      }, [] as string[])
      .join(',');
    setTempHashtags(hashtags);
  };
  const onTempImageLinkChange = (event: InputEvent) => setTempImageLink(event.target.value);
  const onTempBudgetChange = (event: InputEvent) => setTempBudget(Number(event.target.value));
  const onTempPublicChange = (event: InputEvent) => setTempPublic(event.target.checked);

  const [isSelectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    dispatch(getUserCategoryList());
  }, []);

  useEffect(() => {
    if (!campaign) return;
    //id
    //userId
    setTempTitle(campaign.title);
    setTempStartDate(new Date(campaign.startDate));
    setTempEndDate(new Date(campaign.endDate));
    setTempDetails(campaign.details);
    setTempGoals(campaign.goals);
    setTempCategory(
      campaign
        ? categoryList.find((category: ICategory) => category.id === campaign.category)
        : undefined,
    );
    setTempBudget(campaign.budget);
    setInstruction(campaign.instructions);
    setTempHashtags(campaign.hashtags);
    setTempImageLink(campaign.image);
    setTempPublic(campaign.public);
  }, []);

  const saveButtonHandler = () => {
    const tempNewCampaign = {
      id: 0,
      userId: 0,
      title: tempTitle,
      bookmark: false,
      details: tempDetails,
      goals: tempGoals,
      instructions: tempInstruction,
      campaignHashtag: '',
      category: tempCategory ? tempCategory.id : 0,
      categoryName: '',
      hashtags: tempHashtags,
      image: tempImageLink,
      budget: tempBudget,
      influencers: 0,
      status: 0,
      startDate: tempStartDate.toString(),
      endDate: tempEndDate.toString(),
      public: tempPublic,
    };

    if (editMode === 'new' || editMode === 'copy') {
      dispatch(newCampaign(tempNewCampaign));
    } else {
      if (campaign) {
        dispatch(
          updateCampaign({
            ...tempNewCampaign,
            id: campaign.id,
            userId: campaign.userId,
            campaignHashtag: campaign.campaignHashtag,
            influencers: campaign.influencers,
            status: campaign.status,
          }),
        );
      }
    }
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} noEscape noOutsideClick>
      <h3 className="modal__title">
        {editMode === 'new'
          ? 'Create New Campaign'
          : editMode === 'copy'
          ? 'Duplicate Campaign'
          : 'Update Campaign'}
      </h3>

      <form className="form modal__form">
        <div className="form__vertical-flex">
          <input
            className="form__input"
            type="text"
            placeholder="Campaign name"
            value={tempTitle}
            onChange={onTitleChange}
          />
          <div className="form__icon-wrapper form__icon-wrapper--date">
            <DatePicker
              className="form__input form__field form__field--date"
              selected={tempStartDate}
              onChange={(date: any) => onStartDateChange(date)}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="form__icon-wrapper form__icon-wrapper--date">
            <DatePicker
              className="form__input form__field form__field--date"
              selected={tempEndDate}
              onChange={(date: any) => onEndDateChange(date)}
              dateFormat="yyyy-MM-dd"
            />
          </div>

          <textarea
            className="form__textarea"
            placeholder="Campaign Details"
            value={tempDetails}
            onChange={onDetailsChange}
          />

          <input
            className="form__input"
            type="text"
            placeholder="Campaign Goals"
            value={tempGoals}
            onChange={onGoalsChange}
          />

          <div style={{ flex: '1 1 100%' }}>
            <Select
              isOpen={isSelectOpen}
              selectedOption={tempCategory}
              setIsOpen={setSelectOpen}
              setSelectedOption={setTempCategory}
              optionList={categoryList}
            />
          </div>

          <input
            className="form__input"
            type="number"
            placeholder="Campaign Budget"
            value={tempBudget}
            onChange={onTempBudgetChange}
          />

          <textarea
            className="form__textarea"
            placeholder="Influencers Instructions"
            value={tempInstruction}
            onChange={onInstructionChange}
          />

          <input
            className="form__input"
            type="text"
            placeholder="Must Have: hashtags separated by commas"
            value={tempHashtags}
            onChange={onTempHashtagsChange}
          />

          <label
            className="form__icon-wrapper form__icon-wrapper--image"
            htmlFor="campaign-image-file"
          />
          <input
            className="visually-hidden"
            id="campaign-image-file"
            type="file"
            accept="image/png, image/jpeg"
          />

          <input
            className="visually-hidden"
            id="campaign-Public"
            type="checkbox"
            checked={tempPublic}
            onChange={onTempPublicChange}
          />
          <label className="checkbox" htmlFor="campaign-Public">
            Use this checkbox to Public or UnPublic your Campaign
          </label>
        </div>

        <Button primary long onClick={saveButtonHandler}>
          {editMode === 'new'
            ? 'Create New Campaign'
            : editMode === 'copy'
            ? 'Duplicate Campaign'
            : 'Update Campaign'}
        </Button>
      </form>
    </Modal>
  );
};

export default EditCampaign;
