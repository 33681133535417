import { Chart, GoogleChartOptions } from 'react-google-charts';

import cls from '../widget.module.scss';

const generateTooltip = (influencer: string, value: number) => `
  <div style="padding: 5px;">
    <div style="white-space: nowrap">${influencer}</div>
    <div>ER% : <span style="font-weight: 700">${value}%<span></div>
  </div>
`;

const data = [
  ['', '', { role: 'style' }, { role: 'annotation' }, { role: 'tooltip', p: { html: true } }],
  ['Rebecca Zamolo', 20, '#4688F1', '20%', generateTooltip('Rebecca Zamolo', 20)],
  ['Alice Johnson', 10, '#E7453C', '10%', generateTooltip('Alice Johnson', 10)],
  ['Kylie Jenner', 30, '#F9BB2D', '30%', generateTooltip('Kylie Jenner', 30)],
];

const options: GoogleChartOptions = {
  chart: {
    title: undefined,
    subtitle: undefined,
  },
  bar: { groupWidth: '85%' },
  backgroundColor: 'transparent',
  legend: { position: 'none' },
  height: 150,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  hAxis: {
    textPosition: 'none',
  },
};

const CampaignInfluencers = () => {
  return (
    <>
      <p className={cls.header}>Campaign</p>
      <p className={cls.subheader}>Influencers (ER%)</p>
      <div style={{ margin: '-20px -20px -20px -20px' }}>
        <Chart chartType="ColumnChart" width="100%" data={data} options={options} />
      </div>
    </>
  );
};

export { CampaignInfluencers };
