import { ICurrentPaymentPlan, IPaymentPlan, IServerPaymentPlan } from '../models/payment-plan';

export const adoptPaymentListToClient = (list: IServerPaymentPlan[]): IPaymentPlan[] => {
  return list.map(item => {
    return {
      id: item.id,
      name: item.name,
      cost: item.cost,
      description: item.description.split('\\u23CE'),
    };
  });
};

export const adoptCurrentPaymentToClient = (data: { payment_plan: number }): number => {
  return data?.payment_plan || 0;
};

export const adoptCurrentPaymentToServer = (currentPlan: number): ICurrentPaymentPlan => {
  return { payment_plan: currentPlan };
};
