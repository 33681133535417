import { useState, ChangeEvent, FormEvent, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LoginSocialFacebook, LoginSocialGoogle, IResolveParams } from 'reactjs-social-login';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getType } from '../../store/ui/reducer';
import { login, loginWithFacebook, loginWithGoogle } from '../../api/auth';

import ChatButton from '../../components/chat-button';

import { UIType } from '../../constants/ui';
import { AppRoute } from '../../router/routes';

import bgImage from '../../image/bg-image.png';
import fbLogo from '../../image/fb-logo.svg';
import googleLogo from '../../image/google-button.svg';
import { getUserInfo } from '../../api/user';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const dispatch = useAppDispatch();
  const uiType = useAppSelector(getType);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(login(email, password, remember));
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const handleRememberChange = (e: ChangeEvent<HTMLInputElement>) => setRemember(!remember);

  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState<any>();

  const onLoginStart = useCallback(() => {}, []);

  useEffect(() => {
    console.log('provider', provider);
    console.log('profile', profile);
  }, [provider, profile]);

  return (
    <main className="login-page__wrapper">
      <div className="login-page__bg-container">
        <img className="login-page__bg-image" src={bgImage} alt="an influencer on a street" />
        <div className="login-page__bg-flex">
          <h1>Welcome to Influencer Union!</h1>
        </div>
      </div>
      <div className="login-page__container">
        <h2 className="login-page__title">Sign in to your account</h2>
        <form className="login-page__form login-form" onSubmit={handleSubmit}>
          <label className="login-form__field">
            <span className="login-form__label">Email</span>
            <input
              className="login-form__input"
              type="email"
              name="email"
              placeholder="Enter your email address"
              required
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <label className="login-form__field">
            <span className="login-form__label">Password</span>
            <input
              className="login-form__input"
              type="password"
              name="password"
              placeholder="Enter your password"
              required
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
          <p className="login-form__description">
            Forgotten your password? <Link to={AppRoute.FORGOT_PASSWORD}>Click here</Link>
          </p>
          <div className="login-form__flex-field">
            <button className="button button--red" type="submit">
              Sign in
            </button>
            <input
              className="visually-hidden"
              type="checkbox"
              id="remember-me"
              checked={remember}
              onChange={handleRememberChange}
            />
            <label className="checkbox" htmlFor="remember-me">
              Remember me
            </label>
          </div>

          {uiType === UIType.INFLUENCER && (
            <div className="login-form__flex-field social">
              <LoginSocialFacebook
                scope="email"
                appId={'1002649000070513'}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                  dispatch(
                    loginWithFacebook(
                      data?.accessToken,
                      data?.id,
                      data?.picture?.data?.url,
                      remember,
                    ),
                  );
                }}
                onReject={err => {
                  console.log(err);
                }}>
                <button className="social__link social__link--fb" type="button">
                  <img src={fbLogo} alt="facebook logo" />
                </button>
              </LoginSocialFacebook>
              <LoginSocialGoogle
                scope="https://www.googleapis.com/auth/userinfo.profile"
                client_id="189363157868-s0puoochptovrmid4rnsr2s71pjtg0p5.apps.googleusercontent.com"
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                  dispatch(
                    loginWithGoogle(data?.access_token, data?.id, data?.picture || '', remember),
                  );
                }}
                onReject={err => {
                  console.log(err);
                }}>
                <button className="social__link social__link--gg" type="button">
                  <img src={googleLogo} alt="google logo" />
                </button>
              </LoginSocialGoogle>
            </div>
          )}

          <div className="login-form__flex-field">
            <p className="login-form__description">
              Don't have an account yet? <Link to={AppRoute.REGISTER}>Sign Up</Link>
            </p>
          </div>
        </form>
      </div>

      <ChatButton />
    </main>
  );
};

export default Login;
