import InfluencerService from '../service/influencer-service';

import { AppDispatch } from '../store/store';
import { influencerSlice } from '../store/influencer/reducer';

import { adoptInfluencerListToClient } from '../adapters/influencer-adapter';
import { log } from '../helpers/log';
import { IInfluencer } from '../models/influencer';
import { Filter } from '../models/filter-model';
import { noteSlice } from '../store/note/reducer';

export const getInfluencerList =
  (
    category?: number,
    egotistic?: boolean,
    queryString?: string | null,
    filterList?: Array<Filter>,
  ) =>
  async (dispatch: AppDispatch) => {
    const { setIsLoading, setInfluencerList } = influencerSlice.actions;

    dispatch(setIsLoading(true));

    const params = [];
    category && params.push('category=' + category);
    egotistic && params.push('egotistic=' + egotistic);
    queryString && params.push('q=' + queryString);

    filterList
      ?.filter(filter => filter.current)
      .forEach(filter => params.push(filter.name + '=' + filter.current?.id));

    try {
      const response = await InfluencerService.getInfluencerList(params.join('&'));

      console.log(response.data);
      dispatch(setInfluencerList(adoptInfluencerListToClient(response.data)));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const getCampaignInfluencerList = (campaign: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setCampaignInfluencerList } = influencerSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await InfluencerService.getCampaignInfluencerList(campaign);

    console.log(response.data);
    dispatch(setCampaignInfluencerList(adoptInfluencerListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getAdvertiserBookmarkList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setInfluencerList } = influencerSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await InfluencerService.getAdvertiserBookmarkList();

    console.log(response.data);
    dispatch(setInfluencerList(adoptInfluencerListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const toggleInfluencerBookmark =
  (influencer: IInfluencer) => async (dispatch: AppDispatch) => {
    const { setIsLoading, toggleBookmark } = influencerSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await InfluencerService.toggleInfluencerBookmark(influencer.id);

      console.log(response.data);
      dispatch(toggleBookmark(influencer));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const deleteInfluencerBookmark =
  (influencer: IInfluencer) => async (dispatch: AppDispatch) => {
    const { setIsLoading, deleteBookmark } = influencerSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await InfluencerService.toggleInfluencerBookmark(influencer.id);

      console.log(response.data);
      dispatch(deleteBookmark(influencer));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getInfluencerNote = (influencer: IInfluencer) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setNote } = noteSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await InfluencerService.getInfluencerNote(influencer.id);

    console.log(response.data);
    dispatch(setNote(response.data.note || ''));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateInfluencerNote =
  (influencer: IInfluencer, note: string) => async (dispatch: AppDispatch) => {
    const { setIsLoading } = noteSlice.actions;
    const { setInfluencer } = influencerSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await InfluencerService.updateInfluencerNote(influencer.id, note);

      console.log(response.data);

      dispatch(
        setInfluencer({
          ...influencer,
          note: Boolean(note),
        }),
      );
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getInfluencerDetails =
  (influencerId: number, note: string) => async (dispatch: AppDispatch) => {
    const { setIsLoading } = noteSlice.actions;
    const { setInfluencerDetails } = influencerSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await InfluencerService.getInfluencerDetails(influencerId);

      console.log(response.data);

      dispatch(setInfluencerDetails(response.data));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
