import api from '../http';
import { AxiosResponse } from 'axios';

export default class InfluencerAnalyticsService {
  static async getInfluencerAnalytics(id: number): Promise<AxiosResponse> {
    console.log('getInfluencerAnalytics:', `/influencer-analytics?user_id=${id}`);
    return api.get(`/influencer-analytics?user_id=${id}`);
  }

  static async getSocialMediaSummary(id: number): Promise<AxiosResponse> {
    console.log('getSocialMediaSummary:', `/social/summary/${id}`);
    return api.get(`/social/summary/${id}`);
  }
}
