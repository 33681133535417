import PhoneInput from 'react-phone-input-2';

import { SaveCancelButtons } from '../button';

import { IBillingInfo } from '../../models/billing-address';

interface Props {
  isBillingPage?: boolean;
  billing: IBillingInfo;
  setBilling: (billing: IBillingInfo) => void;
}

type ChangeElement = React.ChangeEvent<HTMLInputElement>;

const BillingAddressSection = (props: Props) => {
  const { isBillingPage, billing, setBilling } = props;

  const handleNameChange = (e: ChangeElement) => {
    setBilling({ ...billing, fullName: e.target.value });
  };
  const handleAddress1Change = (e: ChangeElement) => {
    setBilling({ ...billing, address1: e.target.value });
  };
  const handleAddress2Change = (e: ChangeElement) => {
    setBilling({ ...billing, address2: e.target.value });
  };
  const handleCityChange = (e: ChangeElement) => {
    setBilling({ ...billing, city: e.target.value });
  };
  const handleStateChange = (e: ChangeElement) => {
    setBilling({ ...billing, state: e.target.value });
  };
  const handleCountryChange = (e: ChangeElement) => {
    setBilling({ ...billing, country: e.target.value });
  };
  const handleZipChange = (e: ChangeElement) => {
    setBilling({ ...billing, zip: e.target.value });
  };
  const handlePhoneChange = (phone: string) => {
    setBilling({ ...billing, billingPhone: phone });
  };

  return (
    <div className="account-section">
      <h3 className="account-section__title">Billing Address</h3>
      <div className="info-block form__vertical-flex">
        <div className="form__icon-wrapper form__icon-wrapper--name">
          <input
            className="form__input form__field form__field--name"
            name="name"
            type="text"
            placeholder="Full Name"
            value={billing.fullName}
            onChange={handleNameChange}
          />
        </div>
        <input
          className="form__input form__field form__field--w100"
          name="address-1"
          type="text"
          placeholder="Address 1"
          value={billing.address1}
          onChange={handleAddress1Change}
        />
        <input
          className="form__input form__field form__field--w100"
          name="address-2"
          type="text"
          placeholder="Address 2"
          value={billing.address2}
          onChange={handleAddress2Change}
        />
        <input
          className="form__input form__field form__field--w100"
          name="city"
          type="text"
          placeholder="City"
          value={billing.city}
          onChange={handleCityChange}
        />
        <input
          className="form__input form__field form__field--w100"
          name="state"
          type="text"
          placeholder="State"
          value={billing.state}
          onChange={handleStateChange}
        />
        <input
          className="form__input form__field form__field--w100"
          name="country"
          type="text"
          placeholder="Country"
          value={billing.country}
          onChange={handleCountryChange}
        />
        <input
          className="form__input form__field form__field--w100"
          name="zip-code"
          type="text"
          placeholder="Zip Code"
          value={billing.zip}
          onChange={handleZipChange}
        />
        <PhoneInput
          country={'us'}
          value={billing.billingPhone}
          onChange={handlePhoneChange}
          buttonClass="form__flag"
          inputClass="form__phone-number"
          dropdownClass="form__country-container"
        />

        {isBillingPage && <SaveCancelButtons />}
      </div>
    </div>
  );
};

export default BillingAddressSection;
