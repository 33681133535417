export const PAYMENT_LIST = [
  { id: 1, name: 'Credit Card' },
  { id: 2, name: 'Paypal account' },
  { id: 3, name: 'E-transfer (with automatic deposit only)' },
  { id: 4, name: 'Wire Transfer' },
  { id: 5, name: 'Cryptocurrency' },
];

export const CRYPTOCURRENCY_LIST = [
  { id: 1, name: 'Ethereum (ETH)' },
  { id: 2, name: 'Bitcoin (BTC)' },
  { id: 3, name: 'Stable Coin (USDC)' },
];

export enum AccountType {
  CHECKING = 'checking',
  SAVING = 'saving',
}
