import { classNames } from '../../../utils/class-names';
import cls from './widget.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;

  info?: boolean;
}

const Widget = ({ className, children, info }: Props) => {
  const mods = {
    [cls.info]: info,
  };

  return <div className={classNames([cls.widget, className], mods)}>{children}</div>;
};

export { Widget };
