import { useEffect } from 'react';

import Info from './profile-info';
import { SaveCancelButtons } from '../../components/button';
import CategoryList from '../../components/category-list';

import { categoriesSlice } from '../../store/categories/reducer';
import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { getUserCategoryList, updateUserCategoryList } from '../../api/user';
import { getCategoryList } from '../../store/categories/reducer';
import { ICategory } from '../../models/category';

const ProfileCategoryList = () => {
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(getCategoryList);
  const { setCategoryList } = categoriesSlice.actions;

  useEffect(() => {
    dispatch(getUserCategoryList());
  }, []);

  const handleCategoryChange = (categoryList: ICategory[]) => {
    dispatch(setCategoryList(categoryList));
  };
  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(getUserCategoryList());
  };
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(updateUserCategoryList(categoryList));
  };

  return (
    <>
      <h2 className="tab__title">Select Category</h2>

      <div className="info-block">
        <form className="profile-page__form form" onReset={handleReset} onSubmit={handleSubmit}>
          <CategoryList categoryList={categoryList} setCategoryList={handleCategoryChange} />

          <SaveCancelButtons />
        </form>
      </div>

      <Info left="Edit info" right="Add/Edit Categories" />
    </>
  );
};

export default ProfileCategoryList;
