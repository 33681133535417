import { useMessage } from '../../provider/message-provider';
import { Contact, Message as MessageType } from '../../store/message/reducer';
import { classNames } from '../../utils/class-names';
import { formatChatTime } from '../../utils/format';
import { Button } from '../button';

import cls from './message.module.scss';

enum TypeOfMessage {
  SYSTEM = 'system',
  REQUEST = 'request',
  ACCEPT = 'accept',
  IGNORE = 'ignore',
  TEXT = 'text',
  TERMS_REQUEST = 'terms request',
  TERMS_ACCEPT = 'terms accept',
  TERMS_MORE = 'terms need more info',
}

interface Props {
  className?: string;
  userId: number;
  message: MessageType;
  contact?: Contact;
}

const MsgSystem = ({ className, message }: Props) => {
  return (
    <div
      className={classNames([cls.system, className])}
      data-content={formatChatTime(message.created_at)}>
      Added to campaign
    </div>
  );
};

const MsgRequest = ({ className, message, userId, contact }: Props) => {
  const { editMessage, sendAcceptRequest } = useMessage();

  const handleAccept = () => {
    editMessage(message.id, {
      ...message,
      type: TypeOfMessage.ACCEPT,
    });
    contact && sendAcceptRequest(contact);
  };

  const handleReject = () => {
    editMessage(message.id, {
      ...message,
      type: TypeOfMessage.IGNORE,
    });
  };

  return (
    <div className={classNames([cls.request, className])}>
      <div>
        {userId !== message.sender_id
          ? 'Influencer interesting your campaign'
          : 'You have sent a request to the advertiser'}
      </div>
      <br />
      {userId !== message.sender_id && (
        <>
          <Button className={cls.thinButton} secondary rounded onClick={handleAccept}>
            Accept
          </Button>
          <Button className={cls.thinButton} secondary rounded onClick={handleReject}>
            Ignore
          </Button>
        </>
      )}
    </div>
  );
};

const MsgAccept = ({ className, message, userId }: Props) => {
  return (
    <div className={classNames([cls.request, className])}>
      {userId !== message.sender_id
        ? "You have accepted the influencer's request"
        : 'Your request has been accepted by the advertiser'}
    </div>
  );
};

const MsgIgnore = ({ className, message, userId }: Props) => {
  return (
    <div className={classNames([cls.request, className])}>
      {userId !== message.sender_id
        ? "You have ignored the influencer's request"
        : 'You sent a request to the advertiser'}
    </div>
  );
};

const MsgTermsRequest = ({ className, message, userId }: Props) => {
  const { editMessage } = useMessage();

  const handleAccept = () => {
    editMessage(message.id, {
      ...message,
      type: TypeOfMessage.TERMS_ACCEPT,
    });
  };

  const handleReject = () => {
    editMessage(message.id, {
      ...message,
      type: TypeOfMessage.TERMS_MORE,
    });
  };

  return (
    <div className={classNames([cls.request, className])}>
      <br />
      Advertiser sent a request to approve the terms of the campaign
      <hr className={cls.hr} />
      <pre className={cls.pre}>{message.text}</pre>
      {userId !== message.sender_id && (
        <>
          <Button className={cls.thinButton} secondary rounded onClick={handleAccept}>
            Accept
          </Button>
          <Button className={cls.thinButton} secondary rounded onClick={handleReject}>
            Need more info
          </Button>
        </>
      )}
    </div>
  );
};

const MsgTermsAccept = ({ className, message, userId }: Props) => {
  return (
    <div className={classNames([cls.request, className])}>
      {message.text}
      <hr />
      {userId !== message.sender_id
        ? "You have accepted the advertiser's request"
        : 'Your request has been accepted by the influencer'}
    </div>
  );
};

const MsgTermsMore = ({ className, message, userId }: Props) => {
  return (
    <div className={classNames([cls.request, className])}>
      <pre className={cls.pre}>{message.text}</pre>
      <hr />
      {userId !== message.sender_id
        ? "Need more info about campaign's terms"
        : "Need more info about campaign's terms"}
    </div>
  );
};

const MsgText = ({ className, message }: Props) => {
  return (
    <div
      className={classNames([cls.message, className])}
      data-content={formatChatTime(message.created_at)}>
      <pre className={cls.pre}>{message.text}</pre>
    </div>
  );
};

export const Message = ({ className, message, userId, contact }: Props) => {
  switch (message.type) {
    case TypeOfMessage.SYSTEM:
      return MsgSystem({ className, message, userId, contact });
    case TypeOfMessage.REQUEST:
      return MsgRequest({ className, message, userId, contact });
    case TypeOfMessage.ACCEPT:
      return MsgAccept({ className, message, userId, contact });
    case TypeOfMessage.IGNORE:
      return MsgIgnore({ className, message, userId, contact });
    case TypeOfMessage.TERMS_REQUEST:
      return MsgTermsRequest({ className, message, userId, contact });
    case TypeOfMessage.TERMS_ACCEPT:
      return MsgTermsAccept({ className, message, userId, contact });
    case TypeOfMessage.TERMS_MORE:
      return MsgTermsMore({ className, message, userId, contact });
    case TypeOfMessage.TEXT:
    default:
      return MsgText({ className, message, userId, contact });
  }
};
