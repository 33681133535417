import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { UIType } from '../../constants/ui';
import { AppRoute } from '../../router/routes';
import { ViewOptions } from '../../models/view-options';

interface uiState {
  type: UIType;
  sidePanelOpened: boolean;
  userMenuOpened: boolean;
  isLoading: boolean;
  notificationList: any[];
  notificationId: number;
  isEditCampaignModalOpen: boolean;
  searchString: string;
  searchEgotistic: boolean;
  viewOptions: ViewOptions;
}

const getInitialViewOptions = () => {
  const localStorageValue = localStorage.getItem('view-options');
  if (localStorageValue) return JSON.parse(localStorageValue);

  return {
    profile: true,
    socialReach: true,
    iuScore: true,
    rateCard: true,
    bio: true,
    location: true,
    languages: true,
    gender: true,
    age: true,
    ethnicity: true,
    hashtags: true,
    mentions: true,
    posts: true,
  };
};

const initialState = {
  type: window.location.pathname === AppRoute.INFLUENCER ? UIType.INFLUENCER : UIType.ADVERTISER,
  sidePanelOpened: false,
  userMenuOpened: false,
  isLoading: true,
  notificationList: [],
  notificationId: 0,
  isEditCampaignModalOpen: false,
  searchString: '',
  searchEgotistic: false,
  viewOptions: getInitialViewOptions(),
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setType: (state: uiState, action: PayloadAction<UIType>) => {
      state.type = action.payload;
    },
    setSidePanelOpened: (state: uiState, action: PayloadAction<boolean>) => {
      state.sidePanelOpened = action.payload;
    },
    setUserMenuOpened: (state: uiState, action: PayloadAction<boolean>) => {
      state.userMenuOpened = action.payload;
    },
    setIsLoading: (state: uiState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addNotification: (state: uiState, action: PayloadAction<{ type: string; message: string }>) => {
      state.notificationList = [
        ...state.notificationList,
        {
          id: state.notificationId,
          type: action.payload.type,
          message: action.payload.message,
        },
      ];
      state.notificationId += 1;
    },
    removeNotification: (state: uiState, action: PayloadAction<number>) => {
      state.notificationList = state.notificationList.filter(
        notification => notification.id !== action.payload,
      );
    },
    setEditCampaignModalOpened: (state: uiState, action: PayloadAction<boolean>) => {
      state.isEditCampaignModalOpen = action.payload;
    },
    setSearchString: (state: uiState, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setSearchEgotistic: (state: uiState, action: PayloadAction<boolean>) => {
      state.searchEgotistic = action.payload;
    },
    setViewOptions: (state: uiState, action: PayloadAction<ViewOptions>) => {
      state.viewOptions = action.payload;

      localStorage.setItem('view-options', JSON.stringify(action.payload));
    },
  },
});

export default uiSlice.reducer;

export const getType = (state: RootState) => state.ui.type;
export const getIsInfluencer = (state: RootState) => state.ui.type === UIType.INFLUENCER;
export const getIsSidePanelOpened = (state: RootState) => state.ui.sidePanelOpened;
export const getIsUserMenuOpened = (state: RootState) => state.ui.userMenuOpened;
export const getIsLoading = (state: RootState) => state.ui.isLoading;
export const getNotificationList = (state: RootState) => state.ui.notificationList;
export const selectEditCampaignModalOpen = (state: RootState) => state.ui.isEditCampaignModalOpen;
export const selectSearchString = (state: RootState) => state.ui.searchString;
export const selectSearchEgotistic = (state: RootState) => state.ui.searchEgotistic;
export const selectViewOptions = (state: RootState) => state.ui.viewOptions;
