import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import SearchForm from '../../../components/search-form';
import ContentWrapper from '../../../components/content-wrapper';
import MyLists from '../../../components/my-lists/';
import MyListEdit from '../../../components/modals/my-list-edit';
import { Button } from '../../../components/button';

import { addMyList, getMyListsList, removeMyList } from '../../../api/my-lists';

import { useAppSelector, useAppDispatch } from '../../../hooks/store';
import { selectMyListsList } from '../../../store/my-list/reducer';

import { AppRoute } from '../../../router/routes';
import { IMyList } from '../../../models/my-list';
import AddToCampaign from '../../../components/modals/add-to-campaign/add-to-campaign';

const Advertiser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [newListModalOpen, setNewListModalOpen] = useState(false);
  const [isAdToCampaignModalOpen, setAdToCampaignModalOpen] = useState(false);
  const [currentList, setCurrentList] = useState<IMyList>();

  const lists = useAppSelector(selectMyListsList);

  useEffect(() => {
    dispatch(getMyListsList());
  }, []);

  const searchHandler = (searchString: string) => {
    navigate(AppRoute.SEARCH_RESULT);
  };

  const handleNewListSubmit = (title: string) => {
    dispatch(addMyList(title));
    setNewListModalOpen(false);
  };
  const handleRemoveMyList = (list: IMyList) => {
    dispatch(removeMyList(list));
  };
  const handleAddToCampaign = (list: IMyList) => {
    setCurrentList(list);
    setAdToCampaignModalOpen(true);
  };
  const handleMore = (list: IMyList) => {
    navigate(AppRoute.MY_LISTS + `/${list.id}`);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>
        <SearchForm placeholder="Search & find your category" onInput={searchHandler} reset />
        <Button
          onClick={() => {
            setNewListModalOpen(true);
          }}
          addIcon>
          Create New List
        </Button>
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <div className="account-section">
          <h3 className="account-section__title">My Lists</h3>
        </div>

        <MyLists
          lists={lists}
          onAddClick={handleAddToCampaign}
          onRemoveClick={handleRemoveMyList}
          onMoreClick={handleMore}
        />

        {newListModalOpen && (
          <MyListEdit setOpened={setNewListModalOpen} title={''} setTitle={handleNewListSubmit} />
        )}
        {isAdToCampaignModalOpen && currentList && (
          <AddToCampaign
            listId={currentList.id}
            setOpened={setAdToCampaignModalOpen}></AddToCampaign>
        )}
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
