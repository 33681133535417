interface AccountInfoProgressProps {
  isInfluencer: boolean;
  step: number;
}

const AccountInfoProgress = ({ isInfluencer, step }: AccountInfoProgressProps) => {
  const getClassName = (currentPage: number) =>
    `account-info-progress__element ${
      currentPage <= step ? 'account-info-progress__element--red' : ''
    }`;

  return (
    <div className="account-info-progress">
      <h2 className="account-info-progress__title">Finalizing your account</h2>
      {isInfluencer && (
        <div className="account-info-progress__list">
          <span className={getClassName(0)}>Credit Card Information</span>
          <span className={getClassName(1)}>Select Categories</span>
          <span className={getClassName(2)}>Select Network</span>
          <span className={getClassName(3)}>Create Rate Card</span>
        </div>
      )}
      {!isInfluencer && (
        <div className="account-info-progress__list">
          <span className={getClassName(0)}>Credit Card Information</span>
          <span className={getClassName(1)}>Select Payment Plan</span>
          <span className={getClassName(2)}>Select Categories</span>
          <span className={getClassName(3)}>Select Networks</span>
        </div>
      )}
    </div>
  );
};

export default AccountInfoProgress;
