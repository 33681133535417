import { useState } from 'react';

import { useAppDispatch } from '../../hooks/store';
import { updateUserPassword } from '../../api/user';

import ChangePassword from '../../components/change-password';
import Info from './profile-info';
import { SaveCancelButtons } from '../../components/button';

const ProfileChangePassword = () => {
  const dispatch = useAppDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setOldPassword('');
    setNewPassword1('');
    setNewPassword2('');
  };
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    dispatch(updateUserPassword(oldPassword, newPassword1, newPassword2));
  };

  return (
    <>
      <h2 className="tab__title">Change password</h2>

      <div className="info-block">
        <form className="profile-page__form form" onSubmit={handleSubmit} onReset={handleReset}>
          <ChangePassword
            oldPassword={oldPassword}
            newPassword1={newPassword1}
            newPassword2={newPassword2}
            setOldPassword={setOldPassword}
            setNewPassword1={setNewPassword1}
            setNewPassword2={setNewPassword2}
          />

          <SaveCancelButtons />
        </form>
      </div>

      <Info left="Edit info" right="Change password" />
    </>
  );
};

export default ProfileChangePassword;
