import { AppDispatch } from '../store/store';
import { log } from '../helpers/log';
import { influencerAnalyticsSlice } from '../store/influencer-analytics/reducer';
import InfluencerAnalyticsService from '../service/influencer-analytics-service';
import {
  adoptInfluencerAnalytics,
  adoptSocialMediaSummary,
} from '../adapters/influencer-analytics-adapter';

export const getInfluencerAnalytics = (id: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setInfluencerAnalytics } = influencerAnalyticsSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await InfluencerAnalyticsService.getInfluencerAnalytics(id);

    console.log(response.data);

    dispatch(setInfluencerAnalytics(adoptInfluencerAnalytics(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

// FIXME: remove mock data
const mockData = {
  facebook: {
    followersCount: 379,
    viewCount: 0,
    reactionsCount: 2,
    commentCount: 3,
    shareCount: 1,
  },
  instagram: {
    followersCount: 9,
    viewCount: 1,
    reactionsCount: 46,
    commentCount: 11,
    shareCount: 0,
  },
  google: {
    subscriberCount: 1,
    viewCount: 8,
    likeCount: 1,
    commentCount: 1,
    shareCount: 0,
    favoriteCount: 0,
  },
};
export const getSocialMediaSummary = (id: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setSocialMediaSummary } = influencerAnalyticsSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await InfluencerAnalyticsService.getSocialMediaSummary(id);

    console.log(response.data.data);

    dispatch(setSocialMediaSummary(adoptSocialMediaSummary(response.data.data)));
    //dispatch(setSocialMediaSummary(adoptSocialMediaSummary(mockData)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
