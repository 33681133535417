import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface WireBankState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export const wireBankSlice = createSlice({
  name: 'wireBank',
  initialState,
  reducers: {
    setIsLoading: (state: WireBankState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default wireBankSlice.reducer;

export const getIsLoading = (state: RootState) => state.wireBank.isLoading;

// import { ActionType } from './actions';

// const initialState = {
//   name: '',
//   address1: '',
//   address2: '',
//   city: '',
//   state: '',
//   country: '',
//   ZIP: '',
//   routingNumber: '',
//   accountType: '',
// };

// const wireBankReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_WIRE_BANK_NAME:
//       return {
//         ...state,
//         name: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_ADDRESS1:
//       return {
//         ...state,
//         address1: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_ADDRESS2:
//       return {
//         ...state,
//         address2: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_CITY:
//       return {
//         ...state,
//         city: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_STATE:
//       return {
//         ...state,
//         state: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_ZIP:
//       return {
//         ...state,
//         ZIP: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_COUNTRY:
//       return {
//         ...state,
//         country: action.payload,
//       };

//     case ActionType.SET_WIRE_BANK_ACCOUNT_TYPE:
//       return {
//         ...state,
//         accountType: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export default wireBankReducer;

// export const selectWireBankName = (state: RootState) => state.wireBank.name;
// export const selectWireBankAddress1 = (state: RootState) => state.wireBank.address1;
// export const selectWireBankAddress2 = (state: RootState) => state.wireBank.address2;
// export const selectWireBankCity = (state: RootState) => state.wireBank.city;
// export const selectWireBankState = (state: RootState) => state.wireBank.state;
// export const selectWireBankZIP = (state: RootState) => state.wireBank.ZIP;
// export const selectWireBankCountry = (state: RootState) => state.wireBank.country;
// export const selectWireBankRoutingNumber = (state: RootState) => state.wireBank.routingNumber;
// export const selectWireBankAccountType = (state: RootState) => state.wireBank.accountType;
