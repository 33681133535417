interface Props {
  left: string;
  right: string;
}

const Info = ({ left, right }: Props) => {
  return (
    <div className="tab__button-wrapper">
      <span className="tab__button">{left}</span> | <span className="tab__button">{right}</span>
    </div>
  );
};

export default Info;
