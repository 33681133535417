import Page from '../../components/page';
import Influencer from './layout/influencer';
import Advertiser from './layout/advertiser';

import { useAppSelector } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';

const Dashboard = () => {
  const isInfluencer = useAppSelector(getIsInfluencer);

  return (
    <Page dashboard>
      {isInfluencer && <Influencer />}
      {!isInfluencer && <Advertiser />}
    </Page>
  );
};

export default Dashboard;
