import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentHistory } from '../../models/payment-history';
import { IPaymentPlan } from '../../models/payment-plan';

import { RootState } from '../store';

interface PaymentState {
  isLoading: boolean;
  list: IPaymentPlan[];
  currentPlan: number;
  historyList: IPaymentHistory[];
}

const initialState = {
  isLoading: false,
  list: [],
  currentPlan: 0,
  historyList: [],
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsLoading: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPaymentPlanList: (state: PaymentState, action: PayloadAction<IPaymentPlan[]>) => {
      state.list = action.payload;
    },
    setCurrentPaymentPlan: (state: PaymentState, action: PayloadAction<number>) => {
      state.currentPlan = action.payload;
    },
    setPaymentHistoryList: (state: PaymentState, action: PayloadAction<IPaymentHistory[]>) => {
      state.historyList = action.payload;
    },
  },
});

export default paymentSlice.reducer;

export const getIsLoading = (state: RootState) => state.payment.isLoading;
export const selectPaymentPlanList = (state: RootState) => state.payment.list;
export const selectCurrentPaymentPlan = (state: RootState) => state.payment.currentPlan;
export const selectPaymentHistoryList = (state: RootState) => state.payment.historyList;
