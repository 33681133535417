import CategoryList from '../../components/category-list/category-list';
import { ICategory } from '../../models/category';

interface Props {
  categoryList: ICategory[];
  setCategoryList: (categoryList: ICategory[]) => void;
  children: React.ReactNode;
}

const AccountInfoCategories = ({ categoryList, setCategoryList, children }: Props) => {
  return (
    <section>
      <div className="account-section">
        <h3 className="account-section__title">Select Categories</h3>
        <div className="info-block">
          <CategoryList categoryList={categoryList} setCategoryList={setCategoryList} />
        </div>
      </div>
      <p className="account-section__description">Please select at least one category</p>

      {children}
    </section>
  );
};

export default AccountInfoCategories;
