import cls from './styles.module.scss';
import { classNames } from '../../utils/class-names';

interface Props {
  type: string;
  placeholder?: string;
  value?: string | number;
  label: string;
  required?: boolean;
  onChange: (value: string) => void;
}

export const Input = ({ type, placeholder, value, label, required, onChange, ...props }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <label className={classNames([cls.verticalField])}>
      <span className={cls.label}>{label}</span>
      <input
        className={cls.input}
        type={type}
        placeholder={placeholder}
        required={required ? true : false}
        value={value}
        onChange={handleChange}
      />
    </label>
  );
};
