// @ts-nocheck
import { useEffect, useState } from 'react';
import { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import Info from './profile-info';
import { SaveCancelButtons } from '../../components/button';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { Input } from '../../components/form-field';
import Select from '../../components/select/select';
import { Map } from '../../components/map';
import { Text } from '../../components/text';

import {
  selectAgeRange,
  selectAgeRangeList,
  selectBio,
  selectEthnicity,
  selectEthnicityList,
  selectGender,
  selectGenderList,
  selectLanguage,
  selectLanguageList,
  selectMapView,
} from '../../store/additional-info/reducer';
import { getAdditionalInfo, getAdditionalInfoLists, setUserAdditionalInfo } from '../../api/user';
import { MapView } from '../../models/additional-user-info';

const ProfilePersonalInfo = () => {
  const dispatch = useAppDispatch();

  const genderList = useAppSelector(selectGenderList);
  const ageRangeList = useAppSelector(selectAgeRangeList);
  const ethnicityList = useAppSelector(selectEthnicityList);
  const languageList = useAppSelector(selectLanguageList);

  const gender = useAppSelector(selectGender);
  const ageRange = useAppSelector(selectAgeRange);
  const ethnicity = useAppSelector(selectEthnicity);
  const language = useAppSelector(selectLanguage);
  const bio = useAppSelector(selectBio);
  const mapView = useAppSelector<MapView>(selectMapView);

  const [genderOpen, setGenderOpen] = useState(false);
  const [ethnicityOpen, setEthnicityOpen] = useState(false);
  const [ageRangeOpen, setAgeRangeOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);

  const [temporaryGender, setTemporaryGender] = useState();
  const [temporaryEthnicity, setTemporaryEthnicity] = useState();
  const [temporaryAgeRange, setTemporaryAgeRange] = useState();
  const [temporaryLanguage, setTemporaryLanguage] = useState();
  const [temporaryBio, setTemporaryBio] = useState('');

  const [temporaryLocationPlace, setTemporaryLocationPlace] = useState(mapView.place);
  const [markerPosition, setMarkerPosition] = useState<MapView.marker>(mapView.marker);
  const [mapPosition, setMapPosition] = useState<MapView.map>(mapView.map);

  useEffect(() => {
    if (!mapView) return;

    setMarkerPosition(mapView.marker);
    setMapPosition(mapView.map);
    setTemporaryLocationPlace(mapView.place);
  }, [mapView]);
  useEffect(() => {
    setTemporaryGender(genderList.find(item => item.id === gender));
    setTemporaryEthnicity(ethnicityList.find(item => item.id === ethnicity));
    setTemporaryAgeRange(ageRangeList.find(item => item.id === ageRange));
    setTemporaryLanguage(languageList.find(item => item.id === language));
    setTemporaryBio(bio);

    setTemporaryLocationPlace(mapView.place);
    setMarkerPosition(mapView.marker);
    setMapPosition(mapView.map);
  }, [
    gender,
    ageRange,
    ethnicity,
    language,
    bio,
    mapView,
    genderList,
    ethnicityList,
    ageRangeList,
    languageList,
  ]);

  useEffect(() => {
    dispatch(getAdditionalInfoLists());
    dispatch(getAdditionalInfo());
  }, []);

  const handleBioChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemporaryBio(evt.target.value);
  };

  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(getAdditionalInfo());
  };
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    dispatch(
      setUserAdditionalInfo({
        ageRange: temporaryAgeRange,
        gender: temporaryGender,
        ethnicity: temporaryEthnicity,
        language: temporaryLanguage,
        bio: temporaryBio,
        mapView: {
          place: temporaryLocationPlace,
          marker: markerPosition,
          map: mapPosition,
        },
      }),
    );
  };

  return (
    <>
      <h2 className="tab__title">User information</h2>

      <div className="info-block">
        <form className="profile-page__form form" onReset={handleReset} onSubmit={handleSubmit}>
          <div className="form__field-list form__field-list--2column">
            <Select
              label={'Gender'}
              isOpen={genderOpen}
              setIsOpen={setGenderOpen}
              selectedOption={temporaryGender}
              setSelectedOption={setTemporaryGender}
              optionList={genderList}
              deletable
            />
            <Select
              label={'Ethnicity'}
              isOpen={ethnicityOpen}
              setIsOpen={setEthnicityOpen}
              selectedOption={temporaryEthnicity}
              setSelectedOption={setTemporaryEthnicity}
              optionList={ethnicityList}
              deletable
            />
            <Select
              label={'Age Range'}
              isOpen={ageRangeOpen}
              setIsOpen={setAgeRangeOpen}
              selectedOption={temporaryAgeRange}
              setSelectedOption={setTemporaryAgeRange}
              optionList={ageRangeList}
              deletable
            />
            <Select
              label={'Language'}
              isOpen={languageOpen}
              setIsOpen={setLanguageOpen}
              selectedOption={temporaryLanguage}
              setSelectedOption={setTemporaryLanguage}
              optionList={languageList}
              deletable
            />
            <div className={''} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text label>Bio</Text>
              <div style={{ padding: ' 0 0 10px 0', height: '100%' }}>
                <textarea
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'var(--border-style)',
                    borderRadius: '5px',
                    padding: '10px',
                    font: 'inherit',
                    resize: 'none',
                  }}
                  placeholder="Tell us about yourself"
                  value={temporaryBio}
                  onChange={handleBioChange}></textarea>
              </div>
            </div>
            <div className={'map-wrapper'} style={{ padding: '0 0 10px' }}>
              <Input
                type="text"
                label="Location"
                placeholder="Country, Province, City"
                value={temporaryLocationPlace}
                onChange={setTemporaryLocationPlace}
              />
              <Map
                markerPosition={markerPosition}
                mapPosition={mapPosition}
                setMarkerPosition={setMarkerPosition}
                setMapPosition={setMapPosition}
              />
            </div>
          </div>
          <SaveCancelButtons />
        </form>
      </div>
      <Info left="Edit info" right="edit Personal info" />
    </>
  );
};

export default ProfilePersonalInfo;
