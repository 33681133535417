import SidePanel from '../side-panel';
import ChatButton from '../chat-button';

import { useAppSelector, useAppDispatch } from '../../hooks/store';
import {
  uiSlice,
  getIsInfluencer,
  getIsSidePanelOpened,
  getIsUserMenuOpened,
} from '../../store/ui/reducer';

// TODO only children should be here
interface PageProps {
  dashboard?: boolean;
  fullWidth?: boolean;
  setCreateCampaignModalOpen?: (open: boolean) => void;
  children: React.ReactNode;
}

const Page = ({ children }: PageProps) => {
  const dispatch = useAppDispatch();
  const isInfluencer = useAppSelector(getIsInfluencer);
  const isSidePanelOpened = useAppSelector(getIsSidePanelOpened);
  const isUserMenuOpened = useAppSelector(getIsUserMenuOpened);

  const setSidePanelOpenedAction = uiSlice.actions.setSidePanelOpened;
  const setUserMenuOpenedAction = uiSlice.actions.setUserMenuOpened;

  const setSidePanelOpened = () => dispatch(setSidePanelOpenedAction(!isSidePanelOpened));
  const setUserMenuOpened = () => dispatch(setUserMenuOpenedAction(!isUserMenuOpened));

  return (
    <main className={`container ${isSidePanelOpened ? 'container--opened-side-panel' : ''}`}>
      <h1 className="visually-hidden">Influencer Union</h1>

      <SidePanel
        isInfluencer={isInfluencer}
        setSidePanelOpened={setSidePanelOpened}
        isUserMenuOpened={isUserMenuOpened}
        setUserMenuOpened={setUserMenuOpened}
      />

      <div className="main-content">
        {children}
        <ChatButton />
      </div>
    </main>
  );
};

export default Page;
