import cls from './button.module.scss';
import { classNames } from '../../utils/class-names';

interface OptionProps {
  primary?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  rounded?: boolean;
  long?: boolean;
  addIcon?: boolean;
  moveRight?: boolean;
  more?: boolean;
  delete?: boolean;
  checked?: boolean;

  view?: boolean;
  filter?: boolean;
  bookmark?: boolean;
}

interface Props extends OptionProps {
  className?: string;
  onClick: () => void;
  submit?: boolean;
  reset?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Button = ({
  className,
  reset,
  submit,
  onClick,
  disabled,
  children,
  checked,
  ...options
}: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.primary]: options.primary,
    [cls.secondary]: options.secondary,
    [cls.transparent]: options.transparent,
    [cls.rounded]: options.rounded,
    [cls.long]: options.long,
    [cls.addIcon]: options.addIcon,
    [cls.moveRight]: options.moveRight,
    [cls.more]: options.more,
    [cls.delete]: options.delete,
  };

  return (
    <button
      className={classNames([cls.button, className], mods)}
      type={(submit && 'submit') || (reset && 'reset') || 'button'}
      onClick={onClick}
      disabled={disabled}>
      {options.view && (
        <svg
          width="19"
          height="13"
          viewBox="0 0 19 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.5 0.837128C5.86985 0.837128 2.57784 2.82322 0.148665 6.04916C-0.0495551 6.31345 -0.0495551 6.68268 0.148665 6.94698C2.57784 10.1768 5.86985 12.1629 9.5 12.1629C13.1302 12.1629 16.4222 10.1768 18.8513 6.95086C19.0496 6.68657 19.0496 6.31734 18.8513 6.05304C16.4222 2.82322 13.1302 0.837128 9.5 0.837128ZM9.76041 10.4877C7.35067 10.6393 5.36069 8.65323 5.51227 6.2396C5.63665 4.24963 7.24962 2.63666 9.23959 2.51228C11.6493 2.3607 13.6393 4.34679 13.4877 6.76042C13.3595 8.74651 11.7465 10.3595 9.76041 10.4877ZM9.63992 8.64545C8.34177 8.72707 7.26905 7.65824 7.35456 6.36009C7.42063 5.28737 8.29124 4.42064 9.36397 4.35068C10.6621 4.26906 11.7348 5.3379 11.6493 6.63604C11.5794 7.71265 10.7088 8.57938 9.63992 8.64545Z"
            fill="currentColor"
          />
        </svg>
      )}
      {options.filter && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.33243 6.61553C5.47935 6.77541 5.56001 6.98426 5.56001 7.20031V13.5667C5.56001 13.9498 6.02236 14.1442 6.29603 13.8749L8.07198 11.8397C8.30964 11.5545 8.44071 11.4133 8.44071 11.131V7.20175C8.44071 6.9857 8.52281 6.77685 8.66828 6.61695L13.7642 1.08746C14.1459 0.672643 13.8521 0 13.2875 0H0.713214C0.148597 0 -0.146675 0.671203 0.236458 1.08746L5.33243 6.61553Z"
            fill="currentColor"
          />
        </svg>
      )}
      {options.bookmark && (
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.38 0.59C15.22 0.37 15.01 0.21 14.75 0.10C14.59 0.03 14.41 0.00 14.23 0.00V0H1.76C1.58 0 1.40 0.03 1.24 0.10C0.98 0.21 0.77 0.37 0.61 0.59C0.46 0.81 0.38 1.06 0.38 1.33V16.66C0.38 16.93 0.46 17.18 0.61 17.40C0.77 17.62 0.98 17.79 1.24 17.89C1.40 17.96 1.58 18 1.76 18C2.13 18 2.46 17.86 2.75 17.60L8.00 12.56L13.24 17.60C13.52 17.86 13.85 17.98 14.23 17.98C14.43 17.98 14.60 17.95 14.75 17.89C15.01 17.79 15.22 17.62 15.38 17.40C15.53 17.18 15.61 16.93 15.61 16.66V1.33C15.61 1.06 15.53 0.81 15.38 0.59ZM14.09 16.29L9.05 11.46L8.00 10.45L6.94 11.46L1.90 16.29V1.52H14.09V16.29Z"
            fill="currentColor"
          />
          {checked && <path d="M15.5 0H0V18L8 11.5L15.5 17V1.5Z" fill="currentColor" />}
        </svg>
      )}
      {children}
    </button>
  );
};

export { Button };
