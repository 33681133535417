import React, { useState } from 'react';
import { Button } from '../../components/button';
import ChatButton from '../../components/chat-button';
import { Input } from '../../components/form-field';

import { sendPasswordEmail } from '../../api/auth';
import { useAppDispatch } from '../../hooks/store';

const ForgotEmailRequest = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    dispatch(sendPasswordEmail(email));
  };

  return (
    <section className="blank-page" style={{ justifyContent: 'flex-start', margin: '45px 170px' }}>
      <h2 className="visually-hidden">Blank page</h2>
      <p>Welcome to influencer Union</p>
      <p style={{ fontSize: '36px', lineHeight: '1.2', marginBottom: '170px' }}>
        Enter your account email address and select the submit button to validate your account, once
        validated the password reset fields will be displayed to choose a new password.
      </p>
      <form style={{ display: 'grid', width: '450px' }} onSubmit={onSubmit}>
        <Input
          type="email"
          label="Account email address"
          required
          value={email}
          onChange={setEmail}
        />
        <div style={{ marginBottom: '40px' }}></div>
        <div style={{ margin: '0 auto' }}>
          <Button primary submit onClick={() => {}}>
            Submit account email
          </Button>
        </div>
      </form>
      <ChatButton />
    </section>
  );
};

export default ForgotEmailRequest;
