import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INetwork } from '../../models/network';

import { RootState } from '../store';

interface NetworksState {
  isLoading: boolean;
  list: INetwork[];
}

const initialState = {
  isLoading: false,
  list: [],
};

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setIsLoading: (state: NetworksState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNetworkList: (state: NetworksState, action: PayloadAction<Array<INetwork>>) => {
      state.list = action.payload;
    },
  },
});

export default networksSlice.reducer;

export const getIsLoading = (state: RootState) => state.networks.isLoading;
export const getNetworkList = (state: RootState) => state.networks.list;

// import { ActionType } from './actions';

// const initialState: any[] = [];

// const networksReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_NETWORKS:
//       return [...action.payload];

//     case ActionType.ADD_NETWORK:
//       return state.map(network =>
//         network.id === action.payload
//           ? {
//               ...network,
//               checked: true,
//             }
//           : network,
//       );

//     case ActionType.DEL_NETWORK:
//       return state.map(network =>
//         network.id === network.payload
//           ? {
//               ...network,
//               checked: false,
//             }
//           : network,
//       );

//     case ActionType.SET_NETWORK_PATH:
//       return state.map(network =>
//         network.id === action.payload.id
//           ? {
//               ...network,
//               path: action.payload.path,
//             }
//           : network,
//       );

//     default:
//       return state;
//   }
// };

// export default networksReducer;

// export const selectNetwork = (state: RootState) => (networkID: string) =>
//   state.networks.find((network: any) => network.id === networkID);

// export const selectNetworks = (state: RootState) => state.networks;
