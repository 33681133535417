import { TD, TR } from '../table';
import { Indicator } from '../indicator';
import { Text } from '../text';
import { ThreeDots } from '../button';

import { CampaignStatus } from '../../constants/campaign';
import { ICampaign } from '../../models/campaign';

interface Props {
  report: ICampaign;
  onThreeDotsClick: (report: ICampaign) => void;
}

export const ReportRow = ({ report, onThreeDotsClick }: Props) => {
  const threeDotsHandler = () => {
    onThreeDotsClick(report);
  };

  return (
    <TR key={report.id}>
      <TD minimize>
        <Indicator status={report.status}></Indicator>
      </TD>
      <TD>{report.title}</TD>
      <TD>
        Start Date:{' '}
        <Text gray red={report.status === CampaignStatus.CLOSED}>
          {report.startDate}
        </Text>
      </TD>
      <TD>
        Post & Likes: <Text gray>{report.posts}</Text>
      </TD>
      <TD>
        AVG Engagement: <Text gray>{report.avgEngagement}%</Text>
      </TD>
      <TD>
        Budget: <Text gray>${report.budget}</Text>
      </TD>
      <TD>
        <Text gray>
          {report.influencers}
          {report.influencers === 1 ? ' influencer' : ' influencers'}
        </Text>
      </TD>
      <TD>
        <ThreeDots border onClick={threeDotsHandler} />
      </TD>
    </TR>
  );
};
