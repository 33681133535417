import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IRateCard } from '../../models/rate-card';

interface RateCardsState {
  isLoading: boolean;
  list: IRateCard[];
}

const initialState = {
  isLoading: false,
  list: [],
};

export const rateCardsSlice = createSlice({
  name: 'rateCards',
  initialState,
  reducers: {
    setIsLoading: (state: RateCardsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setRateCardList: (state: RateCardsState, action: PayloadAction<Array<IRateCard>>) => {
      state.list = action.payload;
    },
  },
});

export default rateCardsSlice.reducer;

export const getIsLoading = (state: RootState) => state.rateCards.isLoading;
export const getRateCardsList = (state: RootState) => {
  return state.rateCards.list;
};
