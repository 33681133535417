import { Chart, GoogleChartOptions } from 'react-google-charts';

import { classNames } from '../../../utils/class-names';
import cls from '../widget.module.scss';

const data = [
  ['Label', 'Value'],
  ['2023-09-01', 69],
  ['2023-09-02', 47],
  ['2023-09-03', 60],
  ['2023-09-04', 68],
  ['2023-09-05', 59],
  ['2023-09-06', 83],
  ['2023-09-07', 48],
  ['2023-09-08', 58],
  ['2023-09-09', 53],
  ['2023-09-10', 56],
  ['2023-09-11', 64],
  ['2023-09-12', 65],
  ['2023-09-13', 69],
  ['2023-09-14', 47],
  ['2023-09-15', 60],
  ['2023-09-16', 68],
  ['2023-09-17', 59],
  ['2023-09-18', 83],
  ['2023-09-19', 48],
  ['2023-09-20', 58],
  ['2023-09-21', 53],
  ['2023-09-22', 56],
  ['2023-09-23', 64],
  ['2023-09-24', 65],
  ['2023-09-25', 48],
  ['2023-09-26', 58],
  ['2023-09-27', 53],
  ['2023-09-28', 56],
  ['2023-09-29', 64],
  ['2023-09-30', 65],
];

const options: GoogleChartOptions = {
  backgroundColor: 'transparent',
  curveType: 'function',
  legend: 'none',
  height: 290,
  chartArea: {
    top: 33,
    left: 30,
    width: '100%',
    height: '60%',
  },
  hAxis: {
    allowContainerBoundaryTextCutoff: false,
    slantedText: true,
    slantedTextAngle: 30,
  },
  fontSize: 10,
};

const InfluenceOverTime = () => {
  return (
    <>
      <div className={cls.header}>Influence over time</div>
      <div style={{ marginBottom: '-20px' }}>
        <Chart chartType="LineChart" width="100%" data={data} options={options} />
      </div>
    </>
  );
};

export { InfluenceOverTime };
