import L from 'leaflet';
import { MapContainer, TileLayer, Marker, useMap, useMapEvent, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { MapEvents } from './map-events';

import markerPath from '../../image/marker.png';
import { MapView } from '../../models/additional-user-info';
import { useEffect } from 'react';

const icon = new L.Icon({
  iconUrl: markerPath,
  iconSize: new L.Point(25, 41),
  iconAnchor: [12, 41],
});

const MoveMapView = (props: { mapPosition: MapView['map'] }) => {
  const map = useMap();
  const { mapPosition } = props;

  useEffect(() => {
    map.setView([mapPosition.center[0], mapPosition.center[1]], mapPosition.zoom);
  }, [mapPosition, map]);

  return null;
};

interface Props {
  markerPosition: MapView['marker'];
  mapPosition: MapView['map'];
  setMarkerPosition: (position: MapView['marker']) => void;
  setMapPosition: (mapPosition: MapView['map']) => void;
}

export const Map = (props: Props) => {
  const { markerPosition, mapPosition, setMarkerPosition, setMapPosition } = props;

  return (
    <div
      style={{
        height: '200px',
        border: 'var(--border-style)',
        borderRadius: '5px',
        overflow: 'hidden',
      }}>
      <MapContainer
        style={{ height: 'inherit', width: 'inherit' }}
        center={{ lat: mapPosition?.center[0] || 0, lng: mapPosition?.center[1] || 0 }}
        zoom={mapPosition.zoom || 1}
        zoomControl={false}
        scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markerPosition && (
          <Marker
            position={{ lat: markerPosition[0] || 0, lng: markerPosition[1] || 0 }}
            icon={icon}
            draggable={true}
            eventHandlers={{
              dragend: e => {
                setMarkerPosition([e.target._latlng.lat, e.target._latlng.lng]);
              },
            }}
          />
        )}
        <MapEvents setMarkerPosition={setMarkerPosition} setMapPosition={setMapPosition} />
        <MoveMapView mapPosition={mapPosition} />
      </MapContainer>
    </div>
  );
};

export default Map;
