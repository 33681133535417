import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import SearchForm from '../../../components/search-form';
import ContentWrapper from '../../../components/content-wrapper';
import InfluencerRow from '../../../components/influencer-row';
import { Button } from '../../../components/button/button';
import View from '../../../components/modals/view';
import Filter from '../../../components/modals/filter';
import AddNoteToInfluencer from '../../../components/modals/add-note-to-influencer';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getCategoryList as selectCategoryList } from '../../../store/categories/reducer';
import { selectInfluencerList } from '../../../store/influencer/reducer';
import {
  selectSearchString,
  selectSearchEgotistic,
  selectViewOptions,
} from '../../../store/ui/reducer';
import { selectFilters } from '../../../store/filter/reducer';
import { getFilterList } from '../../../api/filter';

import { ICategory } from '../../../models/category';
import { IInfluencer } from '../../../models/influencer';
import { ViewOptions } from '../../../models/view-options';

import { getUserCategoryList } from '../../../api/user';
import { getInfluencerList, toggleInfluencerBookmark } from '../../../api/influencer';
import { AppRoute } from '../../../router/routes';
import AddToCampaign from '../../../components/modals/add-to-campaign/add-to-campaign';
import { useMessage } from '../../../provider/message-provider';

const Influencer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const influencerList = useAppSelector(selectInfluencerList);
  const categoryList = useAppSelector(selectCategoryList);
  const searchString = useAppSelector(selectSearchString);
  const filterList = useAppSelector(selectFilters);
  const searchEgotistic = useAppSelector(selectSearchEgotistic);
  const options = useAppSelector<ViewOptions>(selectViewOptions);

  const message = useMessage();

  const [filterModalWindow, setFilterModalWindow] = useState(false);
  const [viewModalWindow, setViewModalWindow] = useState(false);
  const [editNoteModalWindow, setEditNoteModalWindow] = useState(false);
  const [messagingModalWindow, setMessagingModalWindow] = useState(false);
  const [currentInfluencer, setCurrentInfluencer] = useState<IInfluencer>();

  const { id } = useParams();
  const currentCategory = categoryList.find((category: ICategory) => category.id === Number(id));

  useEffect(() => {
    dispatch(getUserCategoryList());
    dispatch(getFilterList());
  }, []);
  useEffect(() => {
    dispatch(getInfluencerList(Number(id), searchEgotistic, searchString, filterList));
  }, [dispatch, id, searchString, searchEgotistic, filterList]);

  const toggleFilterModalWindow = () => setFilterModalWindow(!filterModalWindow);
  const toggleViewModalWindow = () => setViewModalWindow(!viewModalWindow);

  const toggleBookmark = (influencer: IInfluencer) => {
    dispatch(toggleInfluencerBookmark(influencer));
  };

  const openEditNote = (influencer: IInfluencer) => {
    setCurrentInfluencer(influencer);
    setEditNoteModalWindow(!editNoteModalWindow);
  };

  const influencerAnalyticsHandler = (influencer: IInfluencer) => {
    navigate(`${AppRoute.INFLUENCER_ANALYTICS}/${influencer.id}`);
  };
  const messageHandler = (influencer: IInfluencer) => {
    setCurrentInfluencer(influencer);
    setMessagingModalWindow(true);
  };
  const showMessagingPage = (campaignId: number) => {
    if (currentInfluencer) {
      message.createNewChatRoom(campaignId, currentInfluencer.id);
      navigate(`${AppRoute.MESSAGING}/${campaignId}/${currentInfluencer.id}`);
    }
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>

        <SearchForm placeholder="Search & find your category" value={searchString} />
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button primary filter onClick={toggleFilterModalWindow}>
            Filter
          </Button>
          <Button secondary view onClick={toggleViewModalWindow}>
            View
          </Button>
        </div>
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <div className="account-section">
          <h3 className="account-section__title section__header--search">{`${
            influencerList.length
          } results found within ${currentCategory?.name || ''} ${
            searchString ? ` for "${searchString}"` : ''
          }`}</h3>

          {influencerList && (
            <div className="influencers influencers__wrapper">
              <table className="influencers__table">
                <thead>
                  <tr>
                    {options.profile && <th className="influencers__col-profile">Profile</th>}
                    {options.socialReach && (
                      <th className="influencers__col-social-reach">Social reach</th>
                    )}
                    {options.iuScore && <th className="influencers__col-iu-score">IU Score</th>}
                    {options.rateCard && <th className="influencers__col-rate-card">Rate Card</th>}
                    {options.bio && <th className="influencers__col-bio">Bio</th>}
                    {options.location && <th className="influencers__col-location">Location</th>}
                    {options.languages && <th className="influencers__col-languages">Languages</th>}
                    {options.gender && <th className="influencers__col-gender">Gender</th>}
                    {options.age && <th className="influencers__col-age-range">Age Range</th>}
                    {options.ethnicity && <th className="influencers__col-ethnicity">Ethnicity</th>}
                    {options.hashtags && <th className="influencers__col-hashtags">Hashtags</th>}
                    {options.mentions && <th className="influencers__col-mentions">Mentions</th>}
                    {options.posts && <th className="influencers__col-posts">Number of Posts</th>}
                  </tr>
                </thead>
                <tbody>
                  {influencerList.map(influencer => (
                    <InfluencerRow
                      options={options}
                      key={influencer.id}
                      influencer={influencer}
                      onBookmark={toggleBookmark}
                      onMoreClick={influencerAnalyticsHandler}
                      onNoteClick={openEditNote}
                      onMessagingClick={messageHandler}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {messagingModalWindow && (
          <AddToCampaign setOpened={setMessagingModalWindow} onSubscribe={showMessagingPage} />
        )}
        {filterModalWindow && <Filter setOpened={toggleFilterModalWindow} />}
        {viewModalWindow && <View setOpened={toggleViewModalWindow} />}
        {editNoteModalWindow && currentInfluencer && (
          <AddNoteToInfluencer influencer={currentInfluencer} setOpened={setEditNoteModalWindow} />
        )}
      </ContentWrapper>
    </Page>
  );
};

export default Influencer;
