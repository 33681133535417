import { useState } from 'react';

const data = [
  ['Norway', 100],
  ['Denmark', 92],
  ['Italy', 87],
  ['France', 85],
  ['Spain', 82],
  ['Portugal', 81],
  ['Germany', 75],
  ['Netherlands', 74],
  ['Belgium', 71],
  ['Switzerland', 65],
  ['Austria', 62],
  ['Czech Republic', 54],
  ['Slovakia', 48],
  ['Hungary', 41],
  ['Poland', 37],
  ['Romania', 30],
  ['Bulgaria', 22],
  ['Greece', 19],
  ['Turkey', 14],
  ['Russia', 13],
  ['Ukraine', 12],
  ['Belarus', 12],
  ['Finland', 11],
  ['Sweden', 11],
  ['Iceland', 11],
  ['United Kingdom', 10],
  ['Ireland', 10],
  ['Canada', 9],
  ['United States', 9],
  ['Mexico', 8],
  ['Brazil', 8],
  ['Argentina', 8],
  ['Chile', 8],
  ['Peru', 7],
  ['Colombia', 7],
  ['Venezuela', 7],
  ['Ecuador', 6],
  ['Uruguay', 6],
  ['Paraguay', 6],
  ['Australia', 5],
  ['New Zealand', 4],
  ['China', 4],
  ['Japan', 4],
  ['South Korea', 3],
  ['India', 3],
  ['Pakistan', 3],
  ['Bangladesh', 2],
  ['Indonesia', 2],
  ['Philippines', 2],
  ['Thailand', 2],
  ['Vietnam', 2],
  ['Egypt', 1],
  ['South Africa', 1],
  ['Nigeria', 1],
  ['Morocco', 1],
  ['Algeria', 1],
  ['Tunisia', 1],
];

const AudienceGeography = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const ITEM_PER_PAGE = 5;
  const TOTAL_ITEMS = data.length;

  const totalPages = Math.ceil(TOTAL_ITEMS / ITEM_PER_PAGE);
  const start = currentPage * ITEM_PER_PAGE;
  const end = start + ITEM_PER_PAGE;
  const currentData = data.slice(start, end);

  const handlePrevPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  return (
    <div style={{ minWidth: '300px' }}>
      {currentData.map((item, index) => {
        return (
          <div
            key={index}
            style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px' }}>
            <div style={{ fontSize: '14px', flexBasis: '50%' }}>
              {currentPage * ITEM_PER_PAGE + index + 1}&nbsp;&nbsp;{item[0]}
            </div>
            <div style={{ fontSize: '14px', flexBasis: '10%', textAlign: 'end' }}>{item[1]}</div>
            <div
              style={{
                position: 'relative',
                flexBasis: '40%',
                width: '10px',
                height: '10px',
                backgroundColor: '#E5E5E5',
              }}>
              <div
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: item[1] + '%',
                  backgroundColor: '#4688F1',
                }}></div>
            </div>
          </div>
        );
      })}
      <div>
        <button onClick={handlePrevPage}>-</button>
        <button onClick={handleNextPage}>+</button>
        <span style={{ fontSize: '12px' }}>
          Showing {currentPage * ITEM_PER_PAGE + 1}-
          {Math.min(currentPage * ITEM_PER_PAGE + ITEM_PER_PAGE, TOTAL_ITEMS)} of {TOTAL_ITEMS}{' '}
          Regions
        </span>
      </div>
    </div>
  );
};

export { AudienceGeography };
