import { Button } from '../button';

import { UIType } from '../../constants/ui';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getIsAuthenticated } from '../../store/auth/reducer';
import { getType } from '../../store/ui/reducer';
import { uiSlice } from '../../store/ui/reducer';
import { logout } from '../../api/auth';

const Toggler = () => {
  const uiType = useAppSelector(getType);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const { setType } = uiSlice.actions;

  const dispatch = useAppDispatch();

  return (
    <>
      <style>
        {`
          .toggler__wrapper {
            position: fixed;
            bottom: 0;
            left: 0;

            padding: 5px;
            overflow: hidden;

            height: 50px;
            transition: height 0.3s ease-in-out;

            display: flex;
            flex-direction: column;
            gap: 5px;

            border: 1px solid #e5e5e5;
            border-radius: 5px;
            background: #f5f5f550;
          }
          .toggler__wrapper:hover {
            height: 105px;
          }
          .toggler__wrapper button {
            flex-shrink: 0;
          }
          .toggler__version {
            font-size: 9px;
            text-align: end;
            font-weight: bold;
          }
          .toggler__header {
            text-align: center;
            margin: 5px;
            color: #ca62c1;
          }
        `}
      </style>
      <div className="toggler__wrapper">
        <span className="toggler__version">2023.01.27</span>
        <h4 className="toggler__header">{uiType}</h4>
        <Button
          primary={isAuthenticated}
          onClick={() => {
            dispatch(logout());
          }}>
          logout
        </Button>
      </div>
    </>
  );
};

export default Toggler;
