import { Table, TBody } from '../../components/table';
import { IPresentationDetail } from '../../models/presentation';
import { PresentationDetailRow } from './detail-row';

interface Props {
  presentationDetailList: IPresentationDetail[];
  onDeleteClick: (Detail: IPresentationDetail) => void;
}

const PresentationDetailTable = ({ presentationDetailList, onDeleteClick }: Props) => {
  return (
    <Table>
      <TBody>
        {presentationDetailList.map(detailRow => (
          <PresentationDetailRow
            key={detailRow.id}
            detailRow={detailRow}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </TBody>
    </Table>
  );
};

export { PresentationDetailTable };
