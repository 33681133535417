import Influencer from './layout/influencer';

import { useAppSelector } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';

const Messaging = () => {
  const isInfluencer = useAppSelector(getIsInfluencer);

  return isInfluencer ? <Influencer /> : <Influencer />;
};

export default Messaging;
