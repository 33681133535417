import { Currency } from '../form-field';
import { IRateCard } from '../../models/rate-card';

interface Props {
  rateCard: IRateCard;
  setRateCardValue: (rateCard: IRateCard, value: string) => void;
}

const RateCard = ({ rateCard, setRateCardValue }: Props) => {
  const handleChange = (value: string) => {
    setRateCardValue(rateCard, value);
  };

  return (
    <label className="form__vertical-field" key={rateCard.id}>
      <span className="form__label">{rateCard.media_name_slug}</span>
      <Currency setValue={handleChange} value={rateCard.user_rate} />
    </label>
  );
};

export default RateCard;
