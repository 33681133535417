import MyList from './my-list';

import { IMyList } from '../../models/my-list';

interface Props {
  lists: IMyList[];
  onAddClick: (list: IMyList) => void;
  onRemoveClick: (list: IMyList) => void;
  onMoreClick: (list: IMyList) => void;
}

const MyLists = ({ lists, onAddClick, onRemoveClick, onMoreClick }: Props) => {
  return (
    <div className="card-list">
      {lists.map(list => (
        <MyList
          key={list.id}
          list={list}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          onMoreClick={onMoreClick}></MyList>
      ))}
    </div>
  );
};

export default MyLists;
