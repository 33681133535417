import { Link } from 'react-router-dom';

import UserMenu from '../user-menu';
import MainMenu from '../main-menu';

import { AppRoute } from '../../router/routes';
import Logo from '../../image/logo.png';

interface SidePanelProps {
  isInfluencer: boolean;
  isUserMenuOpened: boolean;
  setSidePanelOpened: () => void;
  setUserMenuOpened: () => void;
}

const SidePanel = ({
  isInfluencer,
  isUserMenuOpened,
  setSidePanelOpened,
  setUserMenuOpened,
}: SidePanelProps) => {
  return (
    <aside className="menu">
      <h2 className="visually-hidden">Platform menu</h2>
      <nav className="menu__wrapper">
        <div className="menu__logo-wrapper">
          <Link className="menu__logo" to={AppRoute.HOME}>
            <img src={Logo} alt="logo" />
          </Link>
          <button className="menu__button" onClick={setSidePanelOpened}>
            <span />
          </button>
        </div>

        <UserMenu
          isInfluencer={isInfluencer}
          isUserMenuOpened={isUserMenuOpened}
          setUserMenuOpened={setUserMenuOpened}
        />
        <MainMenu isInfluencer={isInfluencer} />
      </nav>
    </aside>
  );
};

export default SidePanel;
