// @ts-ignore-next-line
import noImage from '../../image/no-image.gif';
import { BASE_URL } from '../../constants/ui';
import { formatCurrencyFromValue, formatSocialReachFromValue } from '../../utils/format';
import { IInfluencer } from '../../models/influencer';
import { getIUScoreColorModification } from '../../utils/iu-score';
import { ViewOptions } from '../../models/view-options';

interface Props {
  influencer: IInfluencer;
  isBookmark?: boolean;
  onAdd?: (influencer: IInfluencer) => void;
  onBookmark: (influencer: IInfluencer) => void;
  onMoreClick: (influencer: IInfluencer) => void;
  onNoteClick: (influencer: IInfluencer) => void;
  onMessagingClick: (influencer: IInfluencer) => void;
  options: ViewOptions;
}

const InfluencerRow = ({
  influencer,
  isBookmark,
  onAdd,
  onBookmark,
  onMoreClick,
  onNoteClick,
  onMessagingClick,
  options,
}: Props) => {
  const addHandler = () => {
    if (onAdd) onAdd(influencer);
  };
  const bookmarkHandler = () => onBookmark(influencer);
  const moreHandler = () => onMoreClick(influencer);
  const noteHandler = () => onNoteClick(influencer);
  const messagingHandler = () => onMessagingClick(influencer);

  return (
    <tr key={influencer.id}>
      {options.profile && (
        <td className="influencers__row-profile">
          <div className="influencers__row-flex" data-influencer="1">
            {isBookmark && <button className="influencers__row-plus" onClick={addHandler} />}
            <button className="influencers__row-name influencers__row-name--no-link">
              <img
                className="avatar__image"
                src={influencer.avatar ? BASE_URL + influencer.avatar : noImage}
                width="50"
                height="50"
                alt="influencer avatar"
              />
              {influencer.name}
            </button>
            <button className="influencers__row-message" onClick={messagingHandler} />
            <button
              className={`influencers__row-bookmark ${
                influencer.bookmark ? 'influencers__row-bookmark--checked' : ''
              }`}
              onClick={bookmarkHandler}
            />
            <button
              className={`influencers__row-note ${
                influencer.note ? 'influencers__row-note--checked' : ''
              }`}
              onClick={noteHandler}
            />
            <button className="influencers__row-more" onClick={moreHandler} />
          </div>
        </td>
      )}
      {options.socialReach && (
        <td className="influencers__row-social-reach">
          <ul className="social social__reach-list">
            {influencer.socialReach.map((item, index) => (
              <li
                className={`social__reach ${item.type ? 'social__reach--' + item.type : ''}`}
                key={index}>
                {formatSocialReachFromValue(item.value)}
              </li>
            ))}
          </ul>
        </td>
      )}
      {options.iuScore && (
        <td className="influencers__row-iu-score">
          <span className={`iu-score--${getIUScoreColorModification(influencer.iuScore)}`}>
            {influencer.iuScore}
          </span>
        </td>
      )}
      {options.rateCard && (
        <td className="influencers__row-rate-card">
          <span className="cost-per-post">{influencer.rateCard.title}</span>
          <div className="influencers__tooltip">
            <div className="influencers__tooltip-title">Rate Card</div>
            {influencer.rateCard.rows.map((item, index) => (
              <div
                className={`influencers__tooltip-row ${
                  item.type ? 'influencers__tooltip-row--' + item.type : ''
                }`}
                key={index}>
                <span>{item.postType}: </span>
                <span>{formatCurrencyFromValue(item.value)}</span>
              </div>
            ))}
          </div>
        </td>
      )}
      {options.bio && <td className="influencers__row-bio">{influencer.bio}</td>}
      {options.location && <td className="influencers__row-location">{influencer.location}</td>}
      {options.languages && (
        <td className="influencers__row-languages">
          <ul className="languages languages__list">
            {influencer.languages.map((item, index) => (
              <li className="languages__element" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </td>
      )}
      {options.gender && (
        <td className="influencers__row-gender">
          <span className="rounded-gray-rectangle">{influencer.gender}</span>
        </td>
      )}
      {options.age && (
        <td className="influencers__row-age-range">
          <span className="rounded-gray-rectangle">{influencer.ageRange}</span>
        </td>
      )}
      {options.ethnicity && (
        <td className="influencers__row-ethnicity">
          <span className="rounded-gray-rectangle">{influencer.ethnicity}</span>
        </td>
      )}
      {options.hashtags && (
        <td className="influencers__row-hashtags">
          {influencer.hashtags.map(item => item).join(', ')}
        </td>
      )}
      {options.mentions && (
        <td className="influencers__row-mentions">
          {influencer.mentions.map(item => item).join(', ')}
        </td>
      )}
      {options.posts && (
        <td className="influencers__row-posts">
          <span>{formatSocialReachFromValue(influencer.posts)}</span>
        </td>
      )}
    </tr>
  );
};

export default InfluencerRow;
