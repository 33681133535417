import Influencer from './layout/influencer';
import Advertiser from './layout/advertiser';

import { useAppSelector } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';

const BookmarkList = () => {
  const isInfluencer = useAppSelector(getIsInfluencer);

  return isInfluencer ? <Influencer /> : <Advertiser />;
};

export default BookmarkList;
