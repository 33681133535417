import { Map } from '../../../components/map';

const Influencer = () => (
  <>
    <section className="dashboard">
      <div className="dashboard__flex">
        <div className="dashboard__container">
          <span className="dashboard__info">Rebecca Zamolo</span>
          <span className="dashboard__vertical-line"></span>
          <span className="dashboard__info">Start Date 2021-09-25</span>
        </div>
      </div>
      {/* include _influencer-widgets-block.pug */}
      {/* include _influencer-filter-block.pug */}

      <div className="dashboard__container">{/* include _interest-diagram.pug */}</div>
      <div className="dashboard__container">
        <div className="dashboard__flex-2-col">
          {/* include _audience-diagram.pug */}
          <div className="row1">
            <h3 className="dashboard__header">Age / Gender</h3>
            <div className="dashboard__flex-2-col">
              {/* include _age-diagram.pug */}
              {/* include _gender-diagram.pug */}
              <Map
                markerPosition={[]}
                mapPosition={{ center: [0, 0], zoom: 0 }}
                setMarkerPosition={() => {}}
                setMapPosition={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
export default Influencer;
