import RateCard from './rate-card';

import { IRateCard } from '../../models/rate-card';

interface Props {
  rateCardList: IRateCard[];
  setRateCardValue: (rateCard: IRateCard, value: string) => void;
}

const RateCardGroup = ({ rateCardList, setRateCardValue }: Props) => (
  <li className="form__flex-130">
    {rateCardList.map(rateCard => (
      <RateCard key={rateCard.id} rateCard={rateCard} setRateCardValue={setRateCardValue} />
    ))}
  </li>
);

export default RateCardGroup;
