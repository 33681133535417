import PaymentService from '../service/payment-service';

import { AppDispatch } from '../store/store';
import { paymentSlice } from '../store/payment/reducer';
import { uiSlice } from '../store/ui/reducer';

import {
  adoptCurrentPaymentToClient,
  adoptCurrentPaymentToServer,
  adoptPaymentListToClient,
} from '../adapters/payment-plan-adapter';
import { adoptPaymentHistoryToClient } from '../adapters/payment-history-adapter';
import { log } from '../helpers/log';

export const getPaymentPlanList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setPaymentPlanList } = paymentSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await PaymentService.getPaymentPlanList();

    dispatch(setPaymentPlanList(adoptPaymentListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getCurrentPaymentPlan = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setCurrentPaymentPlan } = paymentSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await PaymentService.getCurrentPaymentPlan();

    dispatch(setCurrentPaymentPlan(adoptCurrentPaymentToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateCurrentPaymentPlan = (paymentPlan: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setCurrentPaymentPlan } = paymentSlice.actions;
  const { addNotification } = uiSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await PaymentService.updateCurrentPaymentPlan(
      adoptCurrentPaymentToServer(paymentPlan),
    );

    dispatch(setCurrentPaymentPlan(adoptCurrentPaymentToClient(response.data)));
  } catch (err: any) {
    if (err.response.data && err.response.data.validator) {
      Object.entries(err.response.data.validator).forEach((validator: any) => {
        dispatch(
          addNotification({
            type: 'error',
            message: `${validator[0]}: ${validator[1].map((element: any) => element).join('; ')} `,
          }),
        );
      });
    }

    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getPaymentHistoryList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setPaymentHistoryList } = paymentSlice.actions;

  dispatch(setIsLoading(true));
  try {
    const response = await PaymentService.getPaymentHistoryList();

    dispatch(setPaymentHistoryList(adoptPaymentHistoryToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
