import { Chart, GoogleChartOptions } from 'react-google-charts';

const data = [
  ['', '', { role: 'style' }, { role: 'annotation' }],
  ['Under High School', 9, '#4688F1', '9%'],
  ['High School Degree', 27, '#E7453C', '27%'],
  ['Some Collage/Associate Degree', 30, '#F9BB2D', '30%'],
  ["Bachelor's Degree", 21, '#3AA757', '21%'],
  ['Advanced Degree', 13, '#FC6D22', '13%'],
];

const options: GoogleChartOptions = {
  chart: {
    title: undefined,
    subtitle: undefined,
  },
  backgroundColor: 'transparent',
  legend: { position: 'none' },
  width: 500,
  height: 200,
  chartArea: { width: '60%', height: '80%' },
  bar: { groupWidth: '85%' },
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
};

const EducationChart = () => {
  return <Chart chartType="BarChart" width="100%" data={data} options={options} />;
};

export { EducationChart };
