import Page from '../../components/page';

import applause from '../../image/image.png';

const Home = () => {
  return (
    <Page dashboard>
      <section className="blank-page">
        <h2 className="visually-hidden">Blank page</h2>
        <img src={applause} width="138" height="138" alt="applause" />
        <p>Welcome to our platform</p>
      </section>
    </Page>
  );
};

export default Home;
