import { useParams } from 'react-router-dom';
import ContentWrapper from '../../../components/content-wrapper';
import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import { useEffect } from 'react';
import { getCampaign } from '../../../api/campaign';
import { selectCampaign } from '../../../store/campaign/reducer';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getUser } from '../../../store/auth/reducer';
import { FiveInRow } from '../../../components/widgets/five-in-row';
import { CampaignEngagement } from '../../../components/widgets/campaign-engagement';
import { CampaignPosts } from '../../../components/widgets/campaign-posts';
import { CampaignBudget } from '../../../components/widgets/campaign-budget';
import { CampaignInfluencers } from '../../../components/widgets/campaign-influencers';
import { CampaignAvgEngagement } from '../../../components/widgets/campaign-avg-engagement';
import { InfluenceOverTime } from '../../../components/widgets/influence-over-time';
import { Widget } from '../../../components/widgets/widget';
import { AudienceGeography } from '../../../components/widgets/audince-geography';
import { AgeChart } from '../../../components/widgets/age-chart';
import { GenderChart } from '../../../components/widgets/gender-chart';
import { HouseholdIncome } from '../../../components/widgets/household-income';
import { EducationChart } from '../../../components/widgets/education-chart';

const Influencer = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign)(id ? +id : 0);
  const user = useAppSelector(getUser);

  useEffect(() => {
    if (!id) return;

    dispatch(getCampaign(id));
  }, [id, dispatch]);

  return (
    <>
      <Page>
        <TopPanel>
          <h2 className="top-panel__account-profile">
            Influencer Campaign / {campaign?.companyName}
          </h2>
        </TopPanel>

        <ContentWrapper className="tab__wrapper--dashboard">
          <Widget info>
            {user?.first_name} {user?.last_name} | Start date: {campaign?.startDate}
          </Widget>

          <FiveInRow>
            <Widget>
              <CampaignPosts />
            </Widget>
            <Widget>
              <CampaignEngagement />
            </Widget>
            <Widget>
              <CampaignBudget />
            </Widget>
            <Widget>
              <CampaignInfluencers />
            </Widget>
            <Widget>
              <CampaignAvgEngagement />
            </Widget>
          </FiveInRow>
          <Widget>
            <InfluenceOverTime />
          </Widget>
          <Widget>
            <FiveInRow>
              <div>
                <div style={{ marginBottom: '10px' }}>Audience Geography</div>
                <AudienceGeography />
              </div>
              <div>
                <div style={{ marginBottom: '10px' }}>Age Range</div>
                <AgeChart />
              </div>
              <div>
                <div style={{ marginBottom: '10px' }}>Gender</div>
                <GenderChart />
              </div>
            </FiveInRow>
          </Widget>
          <Widget>
            <FiveInRow>
              <div>
                <div>Household Income</div>
                <HouseholdIncome />
              </div>
              <div>
                <div>Education</div>
                <EducationChart />
              </div>
            </FiveInRow>
          </Widget>
        </ContentWrapper>
      </Page>
    </>
  );
};
export default Influencer;
