import { useMapEvents } from 'react-leaflet';
import { MapView } from '../../models/additional-user-info';

interface Props {
  setMarkerPosition: (position: MapView['marker']) => void;
  setMapPosition: (mapPosition: MapView['map']) => void;
}

export const MapEvents = ({ setMarkerPosition, setMapPosition }: Props) => {
  useMapEvents({
    click: e => {
      const { lat, lng } = e.latlng;
      setMarkerPosition([lat, lng]);
    },
    dragend: e => {
      const center = e.target.getCenter();
      const zoom = e.target.getZoom();
      setMapPosition({ center: [center.lat, center.lng], zoom });
    },
    zoomend: e => {
      const center = e.target.getCenter();
      const zoom = e.target.getZoom();
      setMapPosition({ center: [center.lat, center.lng], zoom });
    },
  });

  return null;
};
