import { TD, TR } from '../table';
import { Text } from '../text';

import { ICampaign } from '../../models/campaign';
import { formatCurrencyFromValue, formatStringFromDate } from '../../utils/format';
import cls from './styles.module.scss';
import { BASE_URL } from '../../constants/ui';
import { Icon } from '../button/icon';

interface Props {
  campaign: ICampaign;
  isInfluencer: boolean;
  onMessageClick: (campaign: ICampaign) => void;
  onBookmarkClick: (campaign: ICampaign) => void;
  onNoteClick: (campaign: ICampaign) => void;
}

export const InfluencerCampaignRow = ({
  campaign,
  isInfluencer,
  onMessageClick,
  onBookmarkClick,
  onNoteClick,
}: Props) => {
  const messageHandler = () => onMessageClick(campaign);
  const bookmarkHandler = () => onBookmarkClick(campaign);
  const noteHandler = () => onNoteClick(campaign);

  return (
    <TR>
      <TD profile>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            className="avatar__image"
            src={campaign.image ? BASE_URL + campaign.image : ''}
            width="50"
            height="50"
            alt="Campaign avatar"
          />
          <Text className={cls.campaignNameStyle}>{campaign.title}</Text>
          <Icon className={cls.shrink0} large message onClick={messageHandler} />
          <Icon
            className={cls.shrink0}
            large
            bookmark
            checked={campaign.bookmark}
            onClick={bookmarkHandler}
          />
          <Icon className={cls.shrink0} large note checked={campaign.note} onClick={noteHandler} />
        </div>
      </TD>
      <TD minimize>{campaign.companyName}</TD>
      <TD>{formatStringFromDate(new Date(campaign.startDate))}</TD>
      <TD>{formatCurrencyFromValue(campaign.budget)}</TD>
      <TD>{campaign.goals}</TD>
      <TD>{campaign.details}</TD>
      <TD>{campaign.categoryName}</TD>
    </TR>
  );
};
