export const formatStringFromDateForServer = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate();

  return `${year}-${month}-${day}`; // bsc server expects this format, not the one below :facepalm:
};

export const formatStringFromDate = (date: Date): string => {
  const options: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return date.toLocaleDateString('en-US', options);
};

export const formatChatDate = (date: string): string => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatChatTime = (date: string): string => {
  return new Date(date).toLocaleTimeString('en-US', { timeStyle: 'short' });
};

export const formatSocialNetworkNameToShortName = (socialNetworkName: string): string => {
  switch (socialNetworkName) {
    case 'facebook':
      return 'fb';
    case 'instagram':
      return 'in';
    case 'youtube':
      return 'yo';
    case 'twitter':
      return 'tw';
    default:
      return '';
  }
};

export const formatCurrencyFromValue = (value: number): string => {
  if (!value) return '';
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return formatter.format(value);
};

export const formatSocialReachFromValue = (value: number, isShort: boolean = false): string => {
  if (!value) return '';

  if (isShort) {
    if (value > 1_000_000_000) return `${((value / 100_000_000) >> 0) / 10}B`;
    if (value > 1_000_000) return `${((value / 100_000) >> 0) / 10}M`;
    if (value > 1_000) return `${((value / 100) >> 0) / 10}K`;
  } else {
    if (value > 1_000_000_000) return `${(value / 1_000_000_000) >> 0} billion`;
    if (value > 1_000_000) return `${(value / 1_000_000) >> 0} million`;
    if (value > 1_000) return `${(value / 1_000) >> 0} thousand`;
  }
  return `${value}`;
};
