export const getToken = (): string => {
  const remember = localStorage.getItem('remember') === 'true';

  const storage = remember ? localStorage : sessionStorage;

  return storage.getItem('token') || '';
};

export const setToken = (remember: boolean, token: string) => {
  if (remember) {
    localStorage.setItem('token', token);
    sessionStorage.removeItem('token');
  } else {
    localStorage.removeItem('token');
    sessionStorage.setItem('token', token);
  }

  localStorage.setItem('remember', remember.toString());
};

export const removeToken = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
};
