import { Routes, Route } from 'react-router-dom';

import { useAppSelector } from '../hooks/store';
import { getIsAuthenticated, getEmailVerified, getProfileFilled } from '../store/auth/reducer';
import { getIsLoading } from '../store/ui/reducer';
import {
  publicRoutes,
  privateRoutes,
  emailVerificationRoutes,
  firstEntryRoutes,
} from '../router/routes';

import applause from '../image/image.png';

const getRouteArray = (isAuthenticated = false, emailVerified = false, profileFilled = false) => {
  if (!isAuthenticated) return publicRoutes;
  if (isAuthenticated && !emailVerified) return emailVerificationRoutes;
  if (isAuthenticated && !profileFilled) return firstEntryRoutes;
  return privateRoutes;
};

const AppRouter = () => {
  const isLoading = useAppSelector(getIsLoading);

  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const emailVerified = useAppSelector(getEmailVerified);
  const profileFilled = useAppSelector(getProfileFilled);

  if (isLoading)
    return (
      <section className="blank-page">
        <h2 className="visually-hidden">Blank page</h2>
        <img src={applause} width="138" height="138" alt="applause" />
        <p>Welcome to our platform</p>
      </section>
    );

  return (
    <Routes>
      {getRouteArray(isAuthenticated, emailVerified, profileFilled).map(route => (
        <Route key={route.path} {...route} />
      ))}
    </Routes>
  );
};

export default AppRouter;
