import api from '../http';
import { AxiosResponse } from 'axios';
import { AuthResponse } from '../models/auth-response';

import { IRegisterServerUser } from '../models/user-model';

export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/log_api', { email, password });
  }

  static async loginWithFacebook(
    data: any,
    remember: boolean,
  ): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/social-login', { ...data, remember });
  }

  static async sendFacebookAccessToken(data: any): Promise<AxiosResponse> {
    return api.get<AuthResponse>(
      `/social/meta/connect?access_token=${data.access_token}&id=${data.id}&network_id=${data.network_id}`,
    );
  }

  static async sendInstagramAccessToken(data: any): Promise<AxiosResponse> {
    return api.get<AuthResponse>(
      `/social/meta/connect?access_token=${data.access_token}&id=${data.id}&network_id=${data.network_id}`,
    );
  }

  static async loginWithGoogle(data: any, remember: boolean): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/social-login', { ...data, remember });
  }

  static async getUserInfo(): Promise<AxiosResponse<AuthResponse>> {
    return api.get<AuthResponse>('/user');
  }

  static async register(user: IRegisterServerUser): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/register_api', { ...user });
  }

  static async sendEmail(): Promise<AxiosResponse<{ email: string; status: string }>> {
    return api.post<{ email: string; status: string }>('/send-mail', {});
  }

  static async emailVerification(id: string, token: string): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/verify-mail', { user_id: id, token });
  }

  static async sendPasswordEmail(email: string): Promise<AxiosResponse> {
    return api.post('/forgot-password', { email });
  }

  static async sendNewPassword(
    token: string,
    new_password: string,
    new_confirm_password: string,
  ): Promise<AxiosResponse<{ message: string }>> {
    return api.post('/verify-token', { token, new_password, new_confirm_password });
  }

  static async logout(): Promise<void> {
    return api.get('/logout');
  }
}
