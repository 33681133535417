import { Button } from '../button';

interface Props {
  isInfluencer: boolean;
  isBillingPage?: boolean;
  billingEmail: string;
  setBillingEmailAddressModalOpen: (isOpen: boolean) => void;
}

const BillingEmailAddressSection = (props: Props) => {
  const { isInfluencer, isBillingPage, billingEmail, setBillingEmailAddressModalOpen } = props;

  const updateInfo = () => {
    setBillingEmailAddressModalOpen(true);
  };

  return (
    <>
      {isInfluencer && isBillingPage && (
        <div className="account-section">
          <div>
            <div className="info-block">
              <div>
                <p className="info-block__1st-line">
                  Billing email: <span>{billingEmail ? billingEmail : 'None'}</span>
                </p>
              </div>
              <Button primary onClick={updateInfo}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
      {isInfluencer && !isBillingPage && (
        <div className="account-section">
          <h3 className="account-section__title">Billing email address</h3>
          <div>
            <div className="info-block">
              <div>
                <p className="info-block__1st-line">
                  Billing email: <span>{billingEmail ? billingEmail : 'None'}</span>
                </p>
                <p className="info-block__2st-line">
                  Press the update button if you wish to use a different email address for billing
                </p>
              </div>
              <Button primary onClick={updateInfo}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
      {!isInfluencer && isBillingPage && (
        <>
          <div>
            <p className="info-block__1st-line">
              Billing email: <span>{billingEmail ? billingEmail : 'None'}</span>
            </p>
          </div>
          <Button primary onClick={updateInfo}>
            Update
          </Button>
        </>
      )}
      {!isInfluencer && !isBillingPage && (
        <div className="account-section">
          <h3 className="account-section__title">Billing email address</h3>
          <div>
            <div className="info-block">
              <div>
                <p className="info-block__1st-line">
                  Billing email: <span>{billingEmail ? billingEmail : 'None'}</span>
                </p>
                <p className="info-block__2st-line">
                  Press the update button if you wish to use a different email address for billing
                </p>
              </div>
              <Button primary onClick={updateInfo}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BillingEmailAddressSection;
