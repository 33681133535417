import { IPaymentHistory, IServerPaymentHistory } from '../models/payment-history';

export const adoptPaymentHistoryToClient = (data: IServerPaymentHistory[]): IPaymentHistory[] => {
  return data.map(item => ({
    id: item.id,
    userID: item.user_id,
    paymentDate: item.payment_date,
    amount: item.amount,
    description: item.description,
    status: item.status,
  }));
};
