import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  solid?: boolean;
}

export const Table = ({ className, children, solid }: Props) => {
  const mods = {
    [cls.solid]: solid,
    [cls.separated]: !solid,
  };
  return <table className={classNames([cls.table, className], mods)}>{children}</table>;
};
