import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface NoteState {
  isLoading: boolean;
  value: string;
}

const initialState = {
  isLoading: false,
  value: '',
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    setIsLoading: (state: NoteState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNote: (state: NoteState, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export default noteSlice.reducer;

export const getIsLoading = (state: RootState) => state.note.isLoading;
export const selectNoteValue = (state: RootState) => state.note.value;
