import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { Filter } from '../../models/filter-model';

interface FilterState {
  isLoading: boolean;
  list: Array<Filter>;
}

const initialState = {
  isLoading: false,
  list: [],
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setIsLoading: (state: FilterState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setList: (state: FilterState, action: PayloadAction<Array<Filter>>) => {
      state.list = action.payload;
    },
    setFilterOpen: (state: FilterState, action: PayloadAction<Filter>) => {
      state.list = state.list.map(filter =>
        filter.name === action.payload.name
          ? {
              ...filter,
              isOpen: action.payload.isOpen,
            }
          : filter,
      );
    },
    setFilter: (state: FilterState, action: PayloadAction<Filter>) => {
      state.list = state.list.map(filter =>
        filter.name === action.payload.name ? action.payload : filter,
      );
    },
  },
});

export default filterSlice.reducer;

export const selectLoading = (state: RootState): boolean => state.filter.isLoading;
export const selectFilters = (state: RootState): Array<Filter> => state.filter.list;
