import { useParams } from 'react-router-dom';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import SearchForm from '../../../components/search-form';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import {
  Campaign as CampaignType,
  Contact as ContactType,
  MessageSlice,
  Message as MessageType,
  selectCampaignList,
  selectContactList,
  selectMessageList,
  selectNewMessages,
} from '../../../store/message/reducer';

import { useEffect, useRef, useState } from 'react';
import { useMessage } from '../../../provider/message-provider';
import { getUser } from '../../../store/auth/reducer';
import { groupMessagesByDate } from '../../../utils/message';
import { MessagesList } from '../../../components/message/messages-list';
import { CampaignList } from '../../../components/message/campaign-list';
import { ContactList } from '../../../components/message/contact-list';
import { MessageEditor } from '../../../components/message/message-editor';

const Influencer = () => {
  const dispatch = useAppDispatch();
  const newMessages = useAppSelector(selectNewMessages);
  const { getCampaignList, getContactList, getMessageList, sendMewMessage } = useMessage();
  const { setEmptyMessageList, setEmptyCampaignList, setEmptyContactList } = MessageSlice.actions;

  const messageEditorRef = useRef<HTMLTextAreaElement>(null);

  const { campaignId, contactId } = useParams();

  const [campaignListCollapsed, setCampaignListCollapsed] = useState(false);
  const [contactListCollapsed, setContactListCollapsed] = useState(true);
  const [currentCampaignId, setCurrentCampaignId] = useState(0);
  const [currentContactId, setCurrentContactId] = useState(0);

  const campaignList = useAppSelector<CampaignType[]>(selectCampaignList);
  const contactList = useAppSelector<ContactType[]>(selectContactList);
  const messageList = useAppSelector<MessageType[]>(selectMessageList);
  const user = useAppSelector(getUser);

  const handleCampaignListOpen = () => {
    setCampaignListCollapsed(!campaignListCollapsed);
    setContactListCollapsed(campaignListCollapsed);
  };
  const handleContactListOpen = () => {
    setCampaignListCollapsed(true);
    setContactListCollapsed(!contactListCollapsed);
  };
  const handleCampaignChange = (campaign: CampaignType | undefined) => {
    setCampaignListCollapsed(true);

    if (campaign?.campaign_id === currentCampaignId) return;
    dispatch(setEmptyMessageList());

    getContactList(campaign?.campaign_id || 0);
    setCurrentCampaignId(campaign?.campaign_id || 0);
    setCurrentContactId(0);
    setContactListCollapsed(false);

    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, '', `/messaging/${campaign?.campaign_id}`);
  };
  const handleContactChange = (contact: ContactType | undefined) => {
    setContactListCollapsed(true);

    if (contact?.id === currentContactId) return;
    dispatch(setEmptyMessageList());
    setCurrentContactId(contact?.id || 0);

    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, '', `/messaging/${currentCampaignId}/${contact?.id}`);
  };

  const handleNewMessage = () => {
    const text = messageEditorRef.current?.value;
    if (text) {
      sendMewMessage(
        contactList.find(contact => contact.id === currentContactId)?.chatroom_id || 0,
        currentContactId,
        text,
      );
    }
    messageEditorRef.current!.value = '';
    messageEditorRef.current!.style.height = 'auto';
  };

  useEffect(() => {
    dispatch(setEmptyMessageList());
    getCampaignList();

    if (campaignId) {
      setCampaignListCollapsed(true);
      setCurrentCampaignId(parseInt(campaignId));
      getContactList(parseInt(campaignId));
    }
    if (contactId) {
      setContactListCollapsed(true);
      setCurrentContactId(parseInt(contactId));
    }

    return () => {
      dispatch(setEmptyMessageList());
      dispatch(setEmptyCampaignList());
      dispatch(setEmptyContactList());
    };
  }, []);

  useEffect(() => {
    if (!currentCampaignId || !currentContactId) return;

    getMessageList(
      contactList.find(contact => contact.id === currentContactId)?.chatroom_id || 0,
      20,
      new Date().toISOString(),
    );
  }, [contactList, currentCampaignId, currentContactId]);

  const generateMessagesHeader = () => {
    const campaign = campaignList.find(campaign => campaign.campaign_id === currentCampaignId);
    const contact = contactList.find(contact => contact.id === currentContactId);

    return `${campaign?.title || 'Campaign'} / ${
      contact?.first_name || contact?.last_name
        ? contact?.first_name + ' ' + contact?.last_name
        : 'Contact'
    }`;
  };

  const groupedMessageList = groupMessagesByDate(messageList);

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>
        <SearchForm placeholder="Search & find your category" />
      </TopPanel>
      {/* <!-- ------------------------- Messaging page start -------------------------- --> */}
      <section className="messaging-page">
        <section className="tab tab__wrapper tab__wrapper--campaign">
          <section className="messaging">
            <div className="messaging__flex">
              {/* <!-- ---------------------- start of Message section ----------------------- --> */}
              <div className="messaging__column">
                <h3 className="messaging__header">{generateMessagesHeader()}</h3>

                <div className="messaging__body">
                  <CampaignList
                    campaignListCollapsed={campaignListCollapsed}
                    currentCampaignId={currentCampaignId}
                    campaignList={campaignList}
                    onOpenClick={handleCampaignListOpen}
                    onChange={handleCampaignChange}
                  />
                  <ContactList
                    contactListCollapsed={contactListCollapsed}
                    currentContactId={currentContactId}
                    contactList={contactList}
                    onOpenClick={handleContactListOpen}
                    onChange={handleContactChange}
                  />
                  <MessageEditor
                    newMessages={newMessages}
                    messageEditorRef={messageEditorRef}
                    onSendClick={handleNewMessage}
                    disabled={!currentCampaignId || !currentContactId}
                  />
                </div>
              </div>
              {/* <!-- ---------------------- end of Message section ----------------------- --> */}

              <div style={{ flex: '1 1 45%' }}>
                <h3 className="messaging__header">Messages</h3>

                <MessagesList
                  groupedMessageList={groupedMessageList}
                  contact={contactList.find(contact => contact.id === currentContactId)}
                  userId={user.id}
                />
              </div>
            </div>
          </section>
          {/* <!-- ----------------------- end of main section ------------------------ --> */}
        </section>
      </section>
      {/* <!-- -------------------------- Messaging page end --------------------------- --> */}
    </Page>
  );
};

export default Influencer;
