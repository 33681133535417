import { useState } from 'react';

import BillingEmailAddressSection from '../../components/billing-email-address-section';
import BillingEmailEdit from '../../components/modals/billing-email-edit/';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { selectBillingInfo } from '../../store/billing/reducer';
import { updateBillingInfo } from '../../api/billing';

interface Props {
  isInfluencer: boolean;
}

const BillingEmail = ({ isInfluencer }: Props) => {
  const dispatch = useAppDispatch();
  const billingInfo = useAppSelector(selectBillingInfo);

  const [isBillingEmailAddressModalOpen, setBillingEmailAddressModalOpen] = useState(false);

  const setBillingEmail = (email: string) => {
    dispatch(updateBillingInfo({ ...billingInfo, billingEmail: email }));
  };

  return (
    <>
      <BillingEmailAddressSection
        isInfluencer={isInfluencer}
        isBillingPage={true}
        billingEmail={billingInfo.billingEmail}
        setBillingEmailAddressModalOpen={setBillingEmailAddressModalOpen}
      />
      {isBillingEmailAddressModalOpen && (
        <BillingEmailEdit
          billingEmail={billingInfo.billingEmail}
          setBillingEmail={setBillingEmail}
          setOpened={setBillingEmailAddressModalOpen}
        />
      )}
    </>
  );
};

export default BillingEmail;
