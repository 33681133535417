import { useEffect, useState } from 'react';

import Modal from '../modal';
import Select from '../../select';
import { Button } from '../../button';

import { useAppSelector, useAppDispatch } from '../../../hooks/store';

import { ModalType } from '../../../constants/ui';
import { getCampaignList } from '../../../api/campaign';
import { ICampaign } from '../../../models/campaign';
import { selectCampaignList } from '../../../store/campaign/reducer';

interface Props {
  listId?: number;
  setOpened: (value: boolean) => void;
  onSubscribe?: (campaignId: number) => void;
}

interface AdoptedToSelectList {
  id: number;
  name: string;
}

const AddToCampaign = ({ listId, setOpened, onSubscribe }: Props) => {
  const dispatch = useAppDispatch();

  const [keepOpen, setKeepOpen] = useState(true);
  const campaignList = useAppSelector<Array<ICampaign>>(selectCampaignList);

  const [isSelectOpen, setSelectOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({ id: 0, name: '' });
  const [lists, setLists] = useState<Array<AdoptedToSelectList>>([]);

  useEffect(() => {
    dispatch(getCampaignList());
  }, []);

  useEffect(() => {
    const adoptedToSelectCampaignList = campaignList.map(campaign => ({
      id: campaign.id,
      name: campaign.title,
    }));

    setLists(adoptedToSelectCampaignList);
  }, [campaignList]);

  const handleSave = () => {
    // net implemented on server side for add list to campaign
    if (onSubscribe) onSubscribe(selectedCampaign.id);
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen} overflowable>
      <h3 className="modal__title">Add to Campaign</h3>

      <div className="modal__field-wrapper">
        <div style={{ flex: '1 1 100%' }}>
          <Select
            isOpen={isSelectOpen}
            placeholder={'Select a campaign'}
            selectedOption={selectedCampaign}
            setIsOpen={setSelectOpen}
            setSelectedOption={setSelectedCampaign}
            optionList={lists}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Add to Campaign
        </Button>
      </div>
    </Modal>
  );
};

export default AddToCampaign;
