import UserService from '../service/user-service';

import { AppDispatch } from '../store/store';
import { uiSlice } from '../store/ui/reducer';
import { authSlice } from '../store/auth/reducer';
import { categoriesSlice } from '../store/categories/reducer';
import { networksSlice } from '../store/networks/reducer';
import { rateCardsSlice } from '../store/rate-cards/reducer';

import {
  adoptAdditionalInfoToServer,
  adoptAdditionalUserInfoListsToClient,
  adoptAdditionalUserInfoToClient,
  adoptDataToServer,
  adoptUserToClient,
} from '../adapters/user-adapter';
import { adoptCategoryToClient } from '../adapters/category-adapter';
import { adoptNetworkToClient } from '../adapters/network-adapter';
import { IBillingInfo } from '../models/billing-address';
import { ICategory } from '../models/category';
import { INetwork } from '../models/network';
import { IRateCard } from '../models/rate-card';
import { IPaymentMethod } from '../models/payment-method';
import { IUser } from '../models/user-model';

import { errorsToString } from '../helpers/error';
import { log } from '../helpers/log';
import { UIType } from '../constants/ui';
import { getToken } from '../utils/user';
import { additionalInfoSlice } from '../store/additional-info/reducer';
import { AdditionalUserInfo } from '../models/additional-user-info';

export const getUserInfo = () => async (dispatch: AppDispatch) => {
  const { setIsLoading: setIsUiLoading, setType } = uiSlice.actions;
  const { setAuthenticated, setUser, setIsLoading } = authSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const token = getToken();

    if (!token) {
      throw new Error('No token found');
    }
    const response = await UserService.getUserInfo();

    dispatch(setUser(adoptUserToClient(response.data)));
    dispatch(setAuthenticated(true));

    if (response.data.profile_type === UIType.INFLUENCER) dispatch(setType(UIType.INFLUENCER));
    else dispatch(setType(UIType.ADVERTISER));
  } catch (err: any) {
    // not error. It just for situation when no token found (after logout or after first entry)
  } finally {
    dispatch(setIsLoading(false));
    dispatch(setIsUiLoading(false));
  }
};

export const getUserCategoryList = () => async (dispatch: AppDispatch) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading, setCategoryList } = categoriesSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await UserService.getUserCategoryList();
    dispatch(setCategoryList(response.data.map(adoptCategoryToClient)));
  } catch (err: any) {
    dispatch(
      addNotification({
        type: 'error',
        message: `${err.response.status}: Can't fetch category list. ${err.response.data.error} (${
          err.response?.data?.message
        } ${errorsToString(err.response?.data?.errors)}) `,
      }),
    );
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getUserNetworkList = () => async (dispatch: AppDispatch) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading, setNetworkList } = networksSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await UserService.getUserNetworkList();
    dispatch(setNetworkList(response.data.map(adoptNetworkToClient)));
  } catch (err: any) {
    dispatch(
      addNotification({
        type: 'error',
        message: `${err.response.status}: Can't fetch network list. ${err.response.data.error} (${
          err.response?.data?.message
        } ${errorsToString(err.response?.data?.errors)}) `,
      }),
    );
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getUserRateCardList = () => async (dispatch: AppDispatch) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading, setRateCardList } = rateCardsSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await UserService.getUserRateCardList();
    dispatch(setRateCardList(response.data));
  } catch (err: any) {
    dispatch(
      addNotification({
        type: 'error',
        message: `${err.response.status}: Can't upload info. ${err.response.data.error} (${
          err.response?.data?.message
        } ${errorsToString(err.response?.data?.errors)}) `,
      }),
    );
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateFirstEntryData =
  (
    billingAddress: IBillingInfo,
    categoryList: ICategory[],
    networkList: INetwork[],
    rateCardList: IRateCard[],
    paymentPlan: number,
    paymentMethod: IPaymentMethod,
    billingEmail: string,
  ) =>
  async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading, setUser } = authSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const body = adoptDataToServer(
        billingAddress,
        categoryList,
        networkList,
        rateCardList,
        paymentPlan,
        paymentMethod,
        billingEmail,
      );

      const response = await UserService.updateFirstEntry(body);
      dispatch(setUser(adoptUserToClient(response.data[1])));
    } catch (err: any) {
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateUserPassword = (
  oldPassword: string,
  newPassword1: string,
  newPassword2: string,
) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading } = authSlice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));

    try {
      const body = {
        current_password: oldPassword,
        new_password: newPassword1,
        new_confirm_password: newPassword2,
      };

      const response = await UserService.updateUserPassword(body);

      if (response.status === 200) {
        dispatch(
          addNotification({
            type: 'success',
            message: 'Password updated successfully',
          }),
        );
      }
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: `${err.response.status}: ${err.response.data.error} (${
            err.response?.data?.message
          } ${errorsToString(err.response?.data?.errors)}) `,
        }),
      );

      if (err.response.data && err.response.data.error === 'Validation failed') {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateAvatar = (avatarPath: string) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading, setAvatarPath } = authSlice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));

    try {
      const formData = new FormData();
      formData.append('image', avatarPath);

      const response = await UserService.updateAvatar(formData);
      dispatch(setAvatarPath(response.data));
    } catch (err: any) {
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateUserInfo = (info: IUser) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading, setUser } = authSlice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));

    try {
      const body = info;

      const response = await UserService.updateUserInfo(body);
      dispatch(setUser(adoptUserToClient(response.data)));

      if (response.status === 200) {
        dispatch(
          addNotification({
            type: 'success',
            message: '"User info" updated successfully',
          }),
        );
      }
    } catch (err: any) {
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateUserCategoryList = (categoryList: ICategory[]) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading } = authSlice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));

    try {
      const body = categoryList;

      const response = await UserService.updateUserCategoryList(body);

      if (response.status === 200) {
        dispatch(
          addNotification({
            type: 'success',
            message: '"Category list" updated successfully',
          }),
        );
      }
    } catch (err: any) {
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateUserNetworkList = (networkList: INetwork[]) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading } = authSlice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));

    try {
      const body = networkList;

      const response = await UserService.updateUserNetworkList(body);

      if (response.status === 200) {
        dispatch(
          addNotification({
            type: 'success',
            message: '"Network list" updated successfully',
          }),
        );
      }
    } catch (err: any) {
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateUserRateCardList = (rateCardList: IRateCard[]) => {
  const { addNotification } = uiSlice.actions;
  const { setIsLoading } = authSlice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));

    try {
      const body = rateCardList;

      const response = await UserService.updateUserRateCardList(body);

      if (response.status === 200) {
        dispatch(
          addNotification({
            type: 'success',
            message: '"Rate card list" updated successfully',
          }),
        );
      }
    } catch (err: any) {
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getAdditionalInfoLists = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setAdditionalInfoLists } = additionalInfoSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await UserService.getAdditionalInfoList();
    dispatch(setAdditionalInfoLists(adoptAdditionalUserInfoListsToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAdditionalInfo = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setAdditionalInfo } = additionalInfoSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await UserService.getAdditionalInfo();
    dispatch(setAdditionalInfo(adoptAdditionalUserInfoToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const setUserAdditionalInfo =
  (additionalInfo: AdditionalUserInfo) => async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading, setAdditionalInfo } = additionalInfoSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await UserService.updateAdditionalInfo(
        adoptAdditionalInfoToServer(additionalInfo),
      );
      dispatch(setAdditionalInfo(adoptAdditionalUserInfoToClient(response.data)));
      dispatch(
        addNotification({
          type: 'success',
          message: 'User information updated successfully',
        }),
      );
    } catch (err: any) {
      console.log(err);
      if (err.response.data && err.response.data.validator) {
        Object.entries(err.response.data.validator).forEach((validator: any) => {
          dispatch(
            addNotification({
              type: 'error',
              message: `${validator[0]}: ${validator[1]
                .map((element: any) => element)
                .join('; ')} `,
            }),
          );
        });
      }

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
