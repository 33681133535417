import { ICategory } from '../../models/category';

interface Props {
  category: ICategory;
  setCategory: (category: ICategory, status: boolean) => void;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const Category = ({ category, setCategory }: Props) => {
  const handleStatusChange = (event: ChangeEvent) => {
    setCategory(category, event.target.checked);
  };

  return (
    <li className="categories__element categories__element--4column">
      <input
        className="visually-hidden"
        id={`category-${category.id}`}
        type="checkbox"
        checked={category.status}
        onChange={handleStatusChange}
      />
      <label className="checkbox" htmlFor={`category-${category.id}`}>
        {category.name}
      </label>
    </li>
  );
};

export default Category;
