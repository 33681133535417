import ReportService from '../service/report-service';

import { AppDispatch } from '../store/store';
import { reportSlice } from '../store/report/reducer';

import { adoptReportListToClient } from '../adapters/report-adapter';
import { log } from '../helpers/log';

export const getReportList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setList } = reportSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await ReportService.getReportList();

    console.log(response.data);
    dispatch(setList(adoptReportListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
