import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { IMyList } from '../../models/my-list';
import { IInfluencer } from '../../models/influencer';

interface MyListState {
  isLoading: boolean;
  list: IMyList[];
  influencerList: IInfluencer[];
}

const initialState = {
  isLoading: false,
  list: [] as IMyList[],
  influencerList: [],
};

export const myListSlice = createSlice({
  name: 'myList',
  initialState,
  reducers: {
    setIsLoading: (state: MyListState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setMyListsList: (state: MyListState, action: PayloadAction<IMyList[]>) => {
      state.list = action.payload;
    },
    setMyList: (state: MyListState, action: PayloadAction<IInfluencer[]>) => {
      state.influencerList = action.payload;
    },
    addToMyList: (state: MyListState, action: PayloadAction<IMyList>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [
          ...state.list.slice(0, index),
          action.payload,
          ...state.list.slice(index + 1),
        ];
      } else {
        state.list.push(action.payload);
      }
    },
    removeInfluencerFromMyList: (state: MyListState, action: PayloadAction<IInfluencer>) => {
      const index = state.influencerList.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.influencerList = [
          ...state.influencerList.slice(0, index),
          ...state.influencerList.slice(index + 1),
        ];
      }
    },
    addInfluencerToMyList: (state: MyListState, action: PayloadAction<IInfluencer>) => {
      const index = state.influencerList.findIndex(item => item.id === action.payload.id);

      if (index === -1) {
        state.influencerList = [...state.influencerList, action.payload];
      } else {
        state.influencerList = [...state.influencerList];
      }
    },
    removeMyList: (state: MyListState, action: PayloadAction<IMyList>) => {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list = [...state.list.slice(0, index), ...state.list.slice(index + 1)];
      }
    },
  },
});

export default myListSlice.reducer;

export const selectIsLoading = (state: RootState) => state.myLists.isLoading;
export const selectMyListsList = (state: RootState) => state.myLists.list;
export const selectMyList = (id: number) => (state: RootState) =>
  state.myLists.list.find(item => item.id === id);

export const selectInfluencerListFromMyList = (state: RootState) => state.myLists.influencerList;
