import { Button } from '../button/button';

import { IMyList } from '../../models/my-list';

import { formatStringFromDate } from '../../utils/format';

interface Props {
  list: IMyList;
  onAddClick: (list: IMyList) => void;
  onRemoveClick: (list: IMyList) => void;
  onMoreClick: (list: IMyList) => void;
}

const MyList = ({ list, onAddClick, onRemoveClick, onMoreClick }: Props) => {
  const handleAddClick = () => onAddClick(list);
  const handleRemoveClick = () => onRemoveClick(list);
  const handleMoreClick = () => onMoreClick(list);
  return (
    <div className="card">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 className="card__title">{list.title}</h4>
        <Button onClick={handleRemoveClick} delete>
          {' '}
        </Button>
      </div>
      <div className="card__start-date">{formatStringFromDate(new Date(list.date))}</div>
      <div className="card__influencers">{`${list.influencers} influencer${
        list.influencers > 1 ? 's' : ''
      }${list.isExported ? ` - Exported` : ''}`}</div>
      <div className="card__flex">
        <Button onClick={handleAddClick} rounded>
          Add to Campaign
        </Button>
        <Button onClick={handleMoreClick} rounded>
          More
          <div className="button--more">
            <span></span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default MyList;
