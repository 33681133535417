import api from "../http";
import { AxiosResponse } from "axios";

export default class ContractService {
  static async getMyContractList(): Promise<AxiosResponse> {
    console.log("getMyContractList:", `/contract/list`);
    return api.get(`/contract/list`);
  }

  static async updateContract(
    id: number,
    status: number
  ): Promise<AxiosResponse> {
    console.log("updateContract:", `/contract/update`);
    return api.post(`/contract/update`, {
      id: id,
      contract_approved_by_influencer: status,
    });
  }

  static async createContract(data: any): Promise<AxiosResponse> {
    console.log("createContract:", `/contract/create`);
    return api.post(`/contract/create`, data);
  }

  static async updateContractStatus(
    id: number,
    status: number
  ): Promise<AxiosResponse> {
    console.log("updateContractStatus:", `/contract/update-status`);
    return api.post(`/contract/update-status`, {
      id: id,
      status: status,
    });
  }
}
