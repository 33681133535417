import { Filter, ServerFilterRow, serverFilterList } from '../models/filter-model';

export const adoptFilterListToClient = (filterList: serverFilterList): Array<Filter> => {
  const entries = Object.entries(filterList);
  const response = entries.map(([filterName, filterBody]) => {
    return {
      isOpen: false,
      name: filterName,
      states: filterBody.map((filterRow: ServerFilterRow) => ({
        id: filterRow.id,
        name: filterRow.value,
      })),
      current: undefined,
    };
  });

  return response;
};
