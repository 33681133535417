import { UIType } from '../constants/ui';
import { IRateCard } from '../models/rate-card';
import { IUser, IRegisterFormUser, IRegisterServerUser } from '../models/user-model';
import { INetwork } from '../models/network';
import { ICategory } from '../models/category';
import { IBillingInfo, IServerBillingInfo } from '../models/billing-address';
import { IPaymentMethod } from '../models/payment-method';
import {
  AdditionalUserInfo,
  AdditionalUserInfoLists,
  AdoptedAdditionalUserInfo,
  ServerAdditionalUserInfo,
  ServerAdditionalUserInfoLists,
} from '../models/additional-user-info';

export const adoptRegisterUserToServer = (user: IRegisterFormUser): IRegisterServerUser => ({
  first_name: user?.firstName || '',
  last_name: user?.lastName || '',
  email: user?.email || '',
  company_name: user?.company || '',
  phone: user?.phone || '',
  password: user?.password1 || '',
  profile_type: user?.uiType || UIType.ADVERTISER,
  cie_type: user?.companyType || 'company',
  password_confirmation: user?.password2 || '',
  terms_agreement: Boolean(user?.termsAgreement) ? '1' : '0',
});

export const adoptUserToClient = (user: any): IUser => ({
  id: Number(user.id) || 0,
  type: user.type || '',
  first_name: user.first_name || '', // 'John';
  last_name: user.last_name || '', // 'Doe';
  id_badge: user.id_badge || '', // '0T8y5A';
  phone: user.phone || '', // '5145635633';
  email: user.email || '', // 'test@example.com';
  gender: user.gender || '', // 'male';
  location: user.location || '', // 'CA';
  avatar_url: user.avatar_url || '', // 'https://app.influencerunion.net/image/avatar/1634581416Daniel_pict.jpg';
  company_name: user.company_name || '', // null;
  website: user.website || '', // null;
  profile_type: user.profile_type || '', // 'influencer';
  cie_type: user.cie_type || '', // null;
  profile_flag: user.profile_flag || 0, // 0/1;
  email_verified_at: user.email_verified_at || false, // true;
  payment_plan: user.payment_plan || 0, // 0;
  facebook_id: user.facebook_id || null, // null;
  created_at: user.created_at || '', // '2022-01-11T02:59:50.000000Z';
  updated_at: user.updated_at || '', // '2022-01-11T02:59:50.000000Z';
});

export const adoptDataToServer = (
  billingAddress: IBillingInfo,
  categoryList: ICategory[],
  networkList: INetwork[],
  rateCardList: IRateCard[],
  paymentPlan: number,
  paymentMethod: IPaymentMethod,
  billingEmail: string,
): {
  billing_info: IServerBillingInfo;
  category_list: ICategory[];
  social_rate_list: IRateCard[];
  network_list: INetwork[];
  payment_plan: number;
} => ({
  billing_info: {
    full_name: billingAddress?.fullName || '',
    payment_method: paymentMethod?.name || '',
    address1: billingAddress?.address1 || '',
    address2: billingAddress?.address2 || '',
    city: billingAddress?.city || '',
    state: billingAddress?.state || '',
    country_name: billingAddress?.country || '',
    zip_code: billingAddress?.zip || '',
    billing_email: billingEmail,
    billing_phone: billingAddress?.billingPhone || '',
    bank_account_number: '',
    bank_routing_number: '',
    bank_account_type: '',
    crypto_account_type: '',
    crypto_account_number: '',
  },
  category_list: categoryList,
  network_list: networkList,
  social_rate_list: rateCardList,
  payment_plan: paymentPlan,
});

export const adoptAdditionalUserInfoListsToClient = (
  additionalInfoLists: ServerAdditionalUserInfoLists,
): AdditionalUserInfoLists => {
  const { age_range = [], ethnicity = [], language = [], gender = [] } = additionalInfoLists;

  const additionalUserInfoLists = {} as AdditionalUserInfoLists;

  additionalUserInfoLists.ageRange = age_range.map(item => ({
    id: item.id || 0,
    name: item.value || '',
  }));
  additionalUserInfoLists.ethnicity = ethnicity.map(item => ({
    id: item.id || 0,
    name: item.value || '',
  }));
  additionalUserInfoLists.language = language.map(item => ({
    id: item.id || 0,
    name: item.value || '',
  }));
  additionalUserInfoLists.gender = gender.map(item => ({
    id: item.id || 0,
    name: item.value || '',
  }));

  return additionalUserInfoLists;
};

export const adoptAdditionalUserInfoToClient = (
  additionalInfo: ServerAdditionalUserInfo,
): AdoptedAdditionalUserInfo => {
  const additionalUserInfo = {} as AdoptedAdditionalUserInfo;

  additionalUserInfo.ageRange = additionalInfo?.age_range || 0;
  additionalUserInfo.ethnicity = additionalInfo?.ethnicity || 0;
  additionalUserInfo.language = additionalInfo?.language || 0;
  additionalUserInfo.gender = additionalInfo?.gender || 0;
  additionalUserInfo.bio = additionalInfo?.bio || '';

  let temporaryLocation = additionalInfo?.location || {};
  if (typeof temporaryLocation === 'string') {
    temporaryLocation = JSON.parse(temporaryLocation);
  }

  additionalUserInfo.mapView = {
    marker: [temporaryLocation.marker?.[0] || 0, temporaryLocation.marker?.[1] || 0],
    map: {
      center: [temporaryLocation?.map?.center?.[0] || 0, temporaryLocation?.map?.center?.[1] || 0],
      zoom: temporaryLocation?.map?.zoom || 0,
    },
    place: temporaryLocation?.place || '',
  };

  return additionalUserInfo;
};

export const adoptAdditionalInfoToServer = (
  additionalUserInfo: AdditionalUserInfo,
): ServerAdditionalUserInfo => {
  return {
    age_range: additionalUserInfo.ageRange?.id || 0,
    ethnicity: additionalUserInfo.ethnicity?.id || 0,
    language: additionalUserInfo.language?.id || 0,
    gender: additionalUserInfo.gender?.id || 0,
    bio: additionalUserInfo.bio || '',
    location: {
      marker: [...additionalUserInfo.mapView.marker],
      map: {
        center: [...additionalUserInfo.mapView.map.center],
        zoom: additionalUserInfo.mapView.map.zoom,
      },
      place: additionalUserInfo.mapView.place || '',
    },
  };
};
