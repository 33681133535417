import { Button } from '../button';

interface IAddEditDeleteButtonsProps {
  isCurrentPaymentMethodFilled: boolean;
  setOpenEditModal: () => void;
  deletePaymentInfo: () => void;
}

const AddEditDeleteButtons = ({
  isCurrentPaymentMethodFilled,
  setOpenEditModal,
  deletePaymentInfo,
}: IAddEditDeleteButtonsProps) => {
  return (
    <div style={{ display: 'flex', gap: '10px', marginLeft: '20px' }}>
      {isCurrentPaymentMethodFilled && (
        <>
          <Button primary onClick={setOpenEditModal}>
            Edit
          </Button>
          <Button secondary onClick={deletePaymentInfo}>
            Delete
          </Button>
        </>
      )}
      {!isCurrentPaymentMethodFilled && (
        <Button primary onClick={setOpenEditModal}>
          Add
        </Button>
      )}
    </div>
  );
};

export default AddEditDeleteButtons;
