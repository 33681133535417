import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface CreditCardState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export const creditCardSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsLoading: (state: CreditCardState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default creditCardSlice.reducer;

export const getIsLoading = (state: RootState) => state.creditCard.isLoading;

// import { ActionType } from './actions';

// const initialState = {
//   number: undefined,
//   expiry: undefined,
//   CVC: undefined,
//   name: undefined,
// };

// const creditCardReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_CREDIT_CARD_NUMBER:
//       return {
//         ...state,
//         number: action.payload,
//       };

//     case ActionType.SET_CREDIT_CARD_EXPIRY:
//       return {
//         ...state,
//         expiry: action.payload,
//       };

//     case ActionType.SET_CREDIT_CARD_CVC:
//       return {
//         ...state,
//         CVC: action.payload,
//       };

//     case ActionType.SET_CREDIT_CARD_NAME:
//       return {
//         ...state,
//         name: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export default creditCardReducer;

// export const selectCreditCardNumber = (state: RootState) => state.creditCard.number;
// export const selectCreditCardExpiry = (state: RootState) => state.creditCard.expiry;
// export const selectCreditCardCVC = (state: RootState) => state.creditCard.CVC;
// export const selectCreditCardName = (state: RootState) => state.creditCard.name;
