import { useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';

import { ModalType } from '../../../constants/ui';
import { IPayPal } from '../../../models/paypal';

interface Props {
  setOpened: (value: boolean) => void;
  payPal: IPayPal;
  setPayPal: (payPal: IPayPal) => void;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const PaypalEdit = ({ setOpened, payPal, setPayPal }: Props) => {
  const [temporaryPayPal, setTemporaryPayPal] = useState<IPayPal>(payPal);
  const [keepOpen, setKeepOpen] = useState(true);

  const handleNameChange = (event: ChangeEvent) => {
    setTemporaryPayPal({ ...temporaryPayPal, name: event.target.value });
  };
  const handleEmailChange = (event: ChangeEvent) => {
    setTemporaryPayPal({ ...temporaryPayPal, email: event.target.value });
  };
  const handleSave = () => {
    setPayPal(temporaryPayPal);
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">Paypal Account Information</h3>
      <div className="modal__field-wrapper">
        <div className="form__icon-wrapper form__icon-wrapper--name">
          <input
            className="form__input form__field form__field--name"
            name="name"
            type="text"
            placeholder="Name of Paypal account"
            value={temporaryPayPal.name}
            onChange={handleNameChange}
          />
        </div>
        <div className="form__icon-wrapper form__icon-wrapper--email">
          <input
            className="form__input form__field form__field--email"
            name="email"
            type="text"
            placeholder="Email address of Paypal account"
            value={temporaryPayPal.email}
            onChange={handleEmailChange}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Add Paypal Account
        </Button>
      </div>
    </Modal>
  );
};

export default PaypalEdit;
