import { useEffect } from 'react';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import ContentWrapper from '../../../components/content-wrapper';
import { PresentationStatusFilter, PresentationTable } from '../../../components/presentation';

import { getPresentationList } from '../../../api/presentation';
import { selectPresentationList } from '../../../store/presentation/reducer';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { IPresentation } from '../../../models/presentation';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../router/routes';

const Advertiser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const presentationList = useAppSelector<IPresentation[]>(selectPresentationList);

  useEffect(() => {
    dispatch(getPresentationList());
  }, [dispatch]);

  const threeDotsHandler = (presentation: IPresentation) => {
    navigate(AppRoute.PRESENTATION_DETAILS + `/` + presentation.id);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="top-panel__account-profile">Media kit / Proposal</h2>
        <PresentationStatusFilter />
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <PresentationTable
          presentationList={presentationList}
          onThreeDotsClick={threeDotsHandler}
        />
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
