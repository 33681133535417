import { EmptyButton } from '../button';
import { Indicator } from '../indicator';
import { Text } from '../text';

import { campaignSlice, selectStatusFilterList } from '../../store/campaign/reducer';
import { useAppDispatch, useAppSelector } from '../../hooks/store';

import { CampaignStatus } from '../../constants/campaign';

const CampaignStatusFilter = () => {
  const dispatch = useAppDispatch();
  const statusFilerList = useAppSelector(selectStatusFilterList);

  const { setStatusFilter } = campaignSlice.actions;

  const toggleFilterValue = (filterName: CampaignStatus) =>
    dispatch(
      setStatusFilter({
        ...statusFilerList,
        [filterName]: !statusFilerList[filterName],
      }),
    );

  const activeHandler = () => toggleFilterValue(CampaignStatus.ACTIVE);
  const draftHandler = () => toggleFilterValue(CampaignStatus.DRAFT);
  const closedHandler = () => toggleFilterValue(CampaignStatus.CLOSED);
  const archiveHandler = () => toggleFilterValue(CampaignStatus.ARCHIVE);

  return (
    <div className="campaign campaign__status-legend">
      <Text>Filter:</Text>
      <EmptyButton onClick={activeHandler}>
        <Indicator green disabled={!statusFilerList[CampaignStatus.ACTIVE]} />{' '}
        <Text gray={!statusFilerList[CampaignStatus.ACTIVE]}>Active</Text>
      </EmptyButton>
      <EmptyButton onClick={draftHandler}>
        <Indicator orange disabled={!statusFilerList[CampaignStatus.DRAFT]} />{' '}
        <Text gray={!statusFilerList[CampaignStatus.DRAFT]}>Draft</Text>
      </EmptyButton>
      <EmptyButton onClick={closedHandler}>
        <Indicator red disabled={!statusFilerList[CampaignStatus.CLOSED]} />{' '}
        <Text gray={!statusFilerList[CampaignStatus.CLOSED]}>Red</Text>
      </EmptyButton>
      <EmptyButton onClick={archiveHandler}>
        <Indicator gray disabled={!statusFilerList[CampaignStatus.ARCHIVE]} />{' '}
        <Text gray={!statusFilerList[CampaignStatus.ARCHIVE]}>Gray</Text>
      </EmptyButton>
    </div>
  );
};

export default CampaignStatusFilter;
