import { IBillingInfo, IServerBillingInfo } from '../models/billing-address';

export const adoptBillingInfoToClient = (info: IServerBillingInfo): IBillingInfo => {
  return {
    fullName: info.full_name || '',
    paymentMethod: parseInt(info.payment_method) || 0,
    address1: info.address1 || '',
    address2: info.address2 || '',
    city: info.city || '',
    state: info.state || '',
    country: info.country_name || '',
    zip: info.zip_code || '',
    billingEmail: info.billing_email || '',
    billingPhone: info.billing_phone || '',
    bankAccountNumber: info.bank_account_number || '',
    bankRoutingNumber: info.bank_routing_number || '',
    bankAccountType: info.bank_account_type || '',
    cryptoAccountType: info.crypto_account_type || '',
    cryptoAccountNumber: info.crypto_account_number || '',
  };
};

export const adoptBillingInfoToServer = (info: IBillingInfo): IServerBillingInfo => {
  return {
    full_name: info.fullName || '',
    payment_method: info.paymentMethod.toString() || '',
    address1: info.address1 || '',
    address2: info.address2 || '',
    city: info.city || '',
    state: info.state || '',
    country_name: info.country || '',
    zip_code: info.zip || '',
    billing_email: info.billingEmail || '',
    billing_phone: info.billingPhone || '',
    bank_account_number: info.bankAccountNumber || '',
    bank_routing_number: info.bankRoutingNumber || '',
    bank_account_type: info.bankAccountType || '',
    crypto_account_type: info.cryptoAccountType || '',
    crypto_account_number: info.cryptoAccountNumber || '',
  };
};
