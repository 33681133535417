import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import SearchForm from '../../../components/search-form';
import ContentWrapper from '../../../components/content-wrapper';
import { Button } from '../../../components/button/button';
import AddToCampaign from '../../../components/modals/add-to-campaign';
import InfluencerBigCard from '../../../components/influencer-big-card';

import {
  getInfluencerListFromMyList,
  getMyListsList,
  removeInfluencerFromMyList,
} from '../../../api/my-lists';

import { useAppSelector, useAppDispatch } from '../../../hooks/store';
import { selectInfluencerListFromMyList, selectMyList } from '../../../store/my-list/reducer';

import { AppRoute } from '../../../router/routes';
import { IInfluencer } from '../../../models/influencer';
import { IMyList } from '../../../models/my-list';
import AddInfluencer from '../../../components/modals/add-influencer';
import AddToPresentation from '../../../components/modals/add-to-presentation/add-to-presentation';

const Advertiser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isAddToCampaignModalOpen, setAdToCampaignModalOpen] = useState(false);
  const [isAddInfluencerModalOpen, setAddInfluencerModalOpen] = useState(false);
  const [isAddToPresentationModalOpen, setAddToPresentationModalOpen] = useState(false);

  const id = Number(useParams().id) || 0;

  const influencerList = useAppSelector(selectInfluencerListFromMyList);
  const myListsList = useAppSelector(selectMyList(id));

  useEffect(() => {
    if (!id) return;

    dispatch(getInfluencerListFromMyList(id));
    dispatch(getMyListsList());
  }, [dispatch, id]);
  const searchHandler = (searchString: string) => {
    navigate(AppRoute.SEARCH_RESULT);
  };
  const deleteHandler = (influencer: IInfluencer) => {
    if (!id) return;
    dispatch(removeInfluencerFromMyList(influencer, { id } as IMyList));
  };
  const addToCampaignHandler = () => {
    setAdToCampaignModalOpen(true);
  };
  const addInfluencerHandler = () => {
    setAddInfluencerModalOpen(true);
  };
  const addToPresentationHandler = () => {
    setAddToPresentationModalOpen(true);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>
        <SearchForm placeholder="Search & find your category" onInput={searchHandler} reset />
        <div style={{ display: 'flex' }}>
          <Button onClick={addInfluencerHandler} addIcon>
            Add Influencer
          </Button>
          <Button onClick={addToPresentationHandler}>Add to media kit / proposal</Button>
        </div>
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <h3 className="account-section__title page__title--with-button">
          Influencer List
          <Button onClick={addToCampaignHandler} rounded>
            Add to campaign
          </Button>
        </h3>
        <p className="page__under-title">Start Date: 2021-12-05</p>

        <div className="influencer-card-list">
          {influencerList.map((influencer: IInfluencer) => (
            <InfluencerBigCard
              key={influencer.id}
              influencer={influencer}
              onDeleteClick={deleteHandler}
            />
          ))}
        </div>
        {isAddToCampaignModalOpen && (
          <AddToCampaign listId={id} setOpened={setAdToCampaignModalOpen}></AddToCampaign>
        )}
        {isAddInfluencerModalOpen && (
          <AddInfluencer listId={id} setOpened={setAddInfluencerModalOpen}></AddInfluencer>
        )}
        {isAddToPresentationModalOpen && myListsList && (
          <AddToPresentation setOpened={setAddToPresentationModalOpen} list={myListsList} />
        )}
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
