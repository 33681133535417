import { useState, useEffect } from 'react';

import AccountInfoProgress from './account-info-progress';
import AccountInfoPayment from './account-info-payment';
import AccountInfoPaymentPlans from './account-info-payment-plans';
import AccountInfoCategories from './account-info-categories';
import AccountInfoNetworks from './account-info-networks';
import AccountInfoRateCards from './account-info-rate-cards';

import CardModal from '../../components/modals/card-edit';
import PayPalModal from '../../components/modals/paypal-edit';
import ETransferModal from '../../components/modals/e-transfer-edit';
import WireModal from '../../components/modals/wire-edit';
import CryptocurrencyModal from '../../components/modals/cryptocurrency-edit';
import BillingEmailModal from '../../components/modals/billing-email-edit';

import PrevNextButtons from './prev-next-buttons';
import ChatButton from '../../components/chat-button';

import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';
import { getUserEmail } from '../../store/auth/reducer';
import { selectPaymentPlanList } from '../../store/payment/reducer';
import { getCategoryList } from '../../store/categories/reducer';
import { getNetworkList } from '../../store/networks/reducer';

import {
  getUserCategoryList,
  getUserNetworkList,
  getUserRateCardList,
  updateFirstEntryData,
} from '../../api/user';
import { getPaymentPlanList as getUserPaymentPlanList } from '../../api/payment';
import { AccountType, PAYMENT_LIST } from '../../constants/payment';

import { ICreditCard } from '../../models/credit-card';
import { IBillingInfo } from '../../models/billing-address';
import { ICategory } from '../../models/category';
import { INetwork } from '../../models/network';
import { rateCardsSlice, getRateCardsList } from '../../store/rate-cards/reducer';
import { ICryptocurrency } from '../../models/cryptocurrency';
import { IETransfer } from '../../models/e-transfer';
import { IWire } from '../../models/wire';
import { IPaymentPlan } from '../../models/payment-plan';

const FirstEntry = () => {
  const dispatch = useAppDispatch();
  const isInfluencer = useAppSelector(getIsInfluencer);
  const [step, setStep] = useState(0);

  // payment information
  const [paymentMethod, setPaymentMethod] = useState({} as any);
  // credit card information
  const [isCardEditModalOpen, setCardEditModalOpen] = useState(false);
  const [creditCard, setCreditCard] = useState({} as ICreditCard);
  // paypal information
  const [isPayPalModalOpen, setPayPalModalOpen] = useState(false);
  const [payPal, setPayPal] = useState({
    name: '',
    email: '',
  });
  // transfer information
  const [isETransferModalOpen, setETransferModalOpen] = useState(false);
  const [eTransfer, setETransfer] = useState<IETransfer>({
    name: '',
    email: '',
  });
  // wire information
  const [isWireModalOpen, setWireModalOpen] = useState(false);
  const [wire, setWire] = useState<IWire>({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    iban: '',
    bankName: '',
    bankAddress1: '',
    bankAddress2: '',
    bankCity: '',
    bankState: '',
    bankCountry: '',
    bankZip: '',
    bankRoutingNumber: '',
    accountType: AccountType.CHECKING,
  });
  // crypto information
  const [isCryptocurrencyModalOpen, setCryptocurrencyModalOpen] = useState(false);
  const [cryptocurrency, setCryptocurrency] = useState<ICryptocurrency>({
    cryptocurrency: undefined,
    account: '',
  });
  // billing information
  const [billing, setBilling] = useState<IBillingInfo>({
    fullName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    billingPhone: '',
  } as IBillingInfo);
  // billing email
  const [isBillingEmailAddressModalOpen, setBillingEmailAddressModalOpen] = useState(false);
  const [billingEmail, setBillingEmail] = useState(useAppSelector(getUserEmail));

  const paymentPlanList = useAppSelector<IPaymentPlan[]>(selectPaymentPlanList);
  const [currentPaymentPlan, setCurrentPaymentPlan] = useState(0);
  const categoryList = useAppSelector(getCategoryList);
  const [temporaryCategoryList, setTemporaryCategoryList] = useState<ICategory[]>(categoryList);
  const networkList = useAppSelector(getNetworkList);
  const [temporaryNetworkList, setTemporaryNetworkList] = useState<INetwork[]>(networkList);

  //-----------------------------------------------------
  const { setRateCardList } = rateCardsSlice.actions;
  const rateCardList = useAppSelector(getRateCardsList);

  useEffect(() => {
    dispatch(getUserCategoryList());
    dispatch(getUserNetworkList());
    dispatch(getUserRateCardList());
    dispatch(getUserPaymentPlanList());

    setPaymentMethod(PAYMENT_LIST[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTemporaryCategoryList(categoryList);
  }, [categoryList]);
  useEffect(() => {
    setTemporaryNetworkList(networkList);
  }, [networkList]);
  useEffect(() => {
    if (paymentPlanList.length > 0) {
      setCurrentPaymentPlan(paymentPlanList[0].id);
    }
  }, [paymentPlanList]);

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    dispatch(
      updateFirstEntryData(
        billing,
        temporaryCategoryList,
        temporaryNetworkList,
        rateCardList,
        currentPaymentPlan,
        paymentMethod,
        billingEmail,
      ),
    );
  };

  const buttonsOptions = {
    step: step,
    onNextStepClick: handleNextStep,
    onPrevStepClick: handlePrevStep,
  };

  const isCurrentPaymentMethodFilled = (): boolean => {
    const method = paymentMethod?.id;

    switch (method) {
      case 1: // credit-card
        return Boolean(
          creditCard.name ||
            creditCard.number ||
            creditCard.expiryMM ||
            creditCard.expiryYY ||
            creditCard.cvv,
        );
      case 2: // paypal
        return Boolean(payPal.name || payPal.email);
      case 3: // e-transfer
        return Boolean(eTransfer.name || eTransfer.email);
      case 4: // wire
        return Boolean(
          wire.name || wire.address1 || wire.city || wire.state || wire.country || wire.zip,
        );
      case 5: // cryptocurrency
        return Boolean(cryptocurrency.cryptocurrency || cryptocurrency.account);
      default:
        return false;
    }
  };

  const setOpenEditModal = () => {
    switch (paymentMethod?.id) {
      case 1: // credit-card
        setCardEditModalOpen(true);
        break;
      case 2: // paypal
        setPayPalModalOpen(true);
        break;
      case 3: // e-transfer
        setETransferModalOpen(true);
        break;
      case 4: // wire
        setWireModalOpen(true);
        break;
      case 5: // cryptocurrency
        setCryptocurrencyModalOpen(true);
        break;
      default:
        break;
    }
  };

  const deletePaymentInfo = () => {
    switch (paymentMethod?.id) {
      case 1: // credit-card
        setCreditCard({
          name: '',
          number: '',
          expiryMM: '',
          expiryYY: '',
          cvv: '',
        });
        break;
      case 2: // paypal
        setPayPal({
          name: '',
          email: '',
        });
        break;
      case 3: // e-transfer
        setETransfer({
          name: '',
          email: '',
        });
        break;
      case 4: // wire
        setWire({
          name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zip: '',
          iban: '',
          bankName: '',
          bankAddress1: '',
          bankAddress2: '',
          bankCity: '',
          bankState: '',
          bankCountry: '',
          bankZip: '',
          bankRoutingNumber: '',
          accountType: AccountType.CHECKING,
        });
        break;
      case 5: // cryptocurrency
        setCryptocurrency({
          cryptocurrency: undefined,
          account: '',
        });
        break;
      default:
        break;
    }
  };

  return (
    <main className="account-info-page">
      <div className="account-info-page__wrapper">
        <h1 className="account-info-page__title">Welcome to Influencer Union</h1>
        <p className="account-info-page__description">
          Please fill out the requested information within next few screens in order to finalize
          setting up your account
        </p>
        <form action="" onSubmit={handleSubmit}>
          <AccountInfoProgress isInfluencer={isInfluencer} step={step} />

          {isInfluencer && step === 0 && (
            <AccountInfoPayment
              isInfluencer={isInfluencer}
              paymentMethod={paymentMethod}
              onPaymentMethodChange={setPaymentMethod}
              isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled()}
              setOpenEditModal={setOpenEditModal}
              deletePaymentInfo={deletePaymentInfo}
              billing={billing}
              setBilling={setBilling}
              billingEmail={billingEmail}
              setBillingEmailAddressModalOpen={setBillingEmailAddressModalOpen}>
              <PrevNextButtons {...buttonsOptions} />

              {isCardEditModalOpen && (
                <CardModal
                  setOpened={setCardEditModalOpen}
                  creditCard={creditCard}
                  setCreditCard={setCreditCard}
                />
              )}
              {isPayPalModalOpen && (
                <PayPalModal setOpened={setPayPalModalOpen} payPal={payPal} setPayPal={setPayPal} />
              )}
              {isETransferModalOpen && (
                <ETransferModal
                  setOpened={setETransferModalOpen}
                  eTransfer={eTransfer}
                  setETransfer={setETransfer}
                />
              )}
              {isWireModalOpen && (
                <WireModal wide setOpened={setWireModalOpen} wire={wire} setWire={setWire} />
              )}
              {isCryptocurrencyModalOpen && (
                <CryptocurrencyModal
                  setOpened={setCryptocurrencyModalOpen}
                  cryptocurrency={cryptocurrency}
                  setCryptocurrency={setCryptocurrency}
                />
              )}
              {isBillingEmailAddressModalOpen && (
                <BillingEmailModal
                  billingEmail={billingEmail}
                  setBillingEmail={setBillingEmail}
                  setOpened={setBillingEmailAddressModalOpen}
                />
              )}
            </AccountInfoPayment>
          )}
          {isInfluencer && step === 1 && (
            <AccountInfoCategories
              categoryList={temporaryCategoryList}
              setCategoryList={setTemporaryCategoryList}>
              <PrevNextButtons {...buttonsOptions} />
            </AccountInfoCategories>
          )}
          {isInfluencer && step === 2 && (
            <AccountInfoNetworks
              networkList={temporaryNetworkList}
              setNetworkList={setTemporaryNetworkList}>
              <PrevNextButtons {...buttonsOptions} />
            </AccountInfoNetworks>
          )}
          {isInfluencer && step === 3 && (
            <AccountInfoRateCards
              rateCardList={rateCardList}
              networkList={temporaryNetworkList}
              setRateCardList={setRateCardList}>
              <PrevNextButtons {...buttonsOptions} />
            </AccountInfoRateCards>
          )}

          {!isInfluencer && step === 0 && (
            <AccountInfoPayment
              isInfluencer={isInfluencer}
              setOpenEditModal={setOpenEditModal}
              deletePaymentInfo={deletePaymentInfo}
              isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled()}
              billing={billing}
              setBilling={setBilling}
              billingEmail={billingEmail}
              setBillingEmailAddressModalOpen={setBillingEmailAddressModalOpen}
              creditCardInfo={creditCard.number}>
              <PrevNextButtons {...buttonsOptions} />

              {isCardEditModalOpen && (
                <CardModal
                  setOpened={setCardEditModalOpen}
                  creditCard={creditCard}
                  setCreditCard={setCreditCard}
                />
              )}
              {isBillingEmailAddressModalOpen && (
                <BillingEmailModal
                  billingEmail={billingEmail}
                  setBillingEmail={setBillingEmail}
                  setOpened={setBillingEmailAddressModalOpen}
                />
              )}
            </AccountInfoPayment>
          )}
          {!isInfluencer && step === 1 && (
            <AccountInfoPaymentPlans
              paymentPlanList={paymentPlanList}
              currentPaymentPlan={currentPaymentPlan}
              setCurrentPaymentPlan={setCurrentPaymentPlan}>
              <PrevNextButtons {...buttonsOptions} />
            </AccountInfoPaymentPlans>
          )}
          {!isInfluencer && step === 2 && (
            <AccountInfoCategories
              categoryList={temporaryCategoryList}
              setCategoryList={setTemporaryCategoryList}>
              <PrevNextButtons {...buttonsOptions} />
            </AccountInfoCategories>
          )}
          {!isInfluencer && step === 3 && (
            <AccountInfoNetworks
              networkList={temporaryNetworkList}
              setNetworkList={setTemporaryNetworkList}>
              <PrevNextButtons {...buttonsOptions} />
            </AccountInfoNetworks>
          )}
        </form>
      </div>

      <ChatButton />
    </main>
  );
};

export default FirstEntry;
