import { useEffect } from 'react';
import { uiSlice } from '../store/ui/reducer';
import { useAppDispatch } from './store';

const SearchReset = () => {
  const dispatch = useAppDispatch();
  const { setSearchString, setSearchEgotistic } = uiSlice.actions;

  useEffect(() => {
    dispatch(setSearchString(''));
    dispatch(setSearchEgotistic(false));
  }, []);

  return null;
};

export default SearchReset;
