import { CampaignStatus } from '../constants/campaign';
import { ICampaign, IServerCampaign } from '../models/campaign';
import { ICampaignReportList, IServerCampaignReportList } from '../models/report';
import { getEnumKeyByEnumValue } from '../utils/enum';

export const adoptReportToClient = (campaign: IServerCampaign): ICampaign => {
  const statusKey = getEnumKeyByEnumValue(CampaignStatus, campaign.status);

  return {
    id: Number(campaign.id) || 0,
    userId: Number(campaign.user_id) || 0,
    title: String(campaign.title) || 'N/A',
    bookmark: Boolean(campaign.bookmark),
    details: campaign.details || '',
    goals: campaign.goals || '',
    instructions: campaign.instructions || '',
    campaignHashtag: campaign.campaign_hashtag || '',
    category: Number(campaign.category) || 0,
    hashtags: campaign.hashtags,
    categoryName: campaign.category_name || '',
    image: campaign.image || '',
    budget: Number(campaign.budget),
    status: statusKey ? CampaignStatus[statusKey] : CampaignStatus.DRAFT,
    influencers: Number(campaign.influencer_count) || 0,
    startDate: String(campaign.start_date) || 'N/A',
    endDate: campaign.end_date,
    public: Boolean(campaign.public),
    posts: Number(campaign.posts_count) || 0,
    avgEngagement: Number(campaign.avg_engagement) || 0,
    companyName: campaign.company_name || '',
    note: Boolean(campaign.note),
  };
};

export const adoptReportListToClient = (
  reportList: IServerCampaignReportList,
): ICampaignReportList => {
  return {
    total: {
      campaignCount: Number(reportList.total?.campaign_count) || 0,
      postCount: Number(reportList.total?.post_count) || 0,
      budget: Number(reportList.total?.budget) || 0,
      influencerCount: Number(reportList.total?.influencer_count) || 0,
      avgEngagement: Number(reportList.total?.avg_engagement) || 0,
    },
    campaigns: !Array.isArray(reportList.campaigns)
      ? []
      : reportList.campaigns.map(adoptReportToClient),
  };
};
