import { Contact } from './contact';

import { Contact as ContactType } from '../../store/message/reducer';

import { classNames } from '../../utils/class-names';
import cls from './message.module.scss';

interface Props {
  contactListCollapsed: boolean;
  currentContactId: number;
  contactList: ContactType[];
  onOpenClick: () => void;
  onChange: (contact: ContactType | undefined) => void;
}

export const ContactList = ({
  contactListCollapsed,
  currentContactId,
  contactList,
  onOpenClick,
  onChange,
}: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.collapsed]: contactListCollapsed,
  };

  return (
    <div className={classNames([cls.contactList], mods)}>
      <div className={cls.current}>
        <Contact
          contact={contactList.find(contact => contact.id === currentContactId)}
          onClick={onOpenClick}
        />
      </div>

      <div className={cls.elementList}>
        {contactList.map(contact => {
          return (
            <div key={contact.id} className={cls.element}>
              <Contact contact={contact} onClick={onChange} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
