import { ISocialMediaSummary } from '../models/influencer-analytics';

const parseLocation = (info: any): any => {
  let location;

  if (typeof info === 'string') {
    try {
      location = JSON.parse(info);
    } catch (err) {
      location = {};
    }
  } else if (typeof info === 'object') {
    location = info;
  }

  return location;
};

export const adoptInfluencerAnalytics = (info: any): any => {
  const adoptedInfo = {
    id: String(info.id) || '', //  3,
    userId: String(info.user_id) || '', //  3,
    uiScore: Number(info.iu_score) || 0, //  72,
    // socialReach:[ //  [
    //     {
    //       info:info.info, //  25336,
    //       type:info.type, //  twitter
    //     },
    //     {
    //       info:info.info, //  1360000,
    //       type:info.type, //  youtube
    //     }
    //   ],
    //   engagementRate:[ //  [
    //     {
    //       info:info.info, //  1,
    //       type:info.type, //  twitter
    //     },
    //     {
    //       info:info.info, //  0,
    //       type:info.type, //  youtube
    //     }
    //   ],
    //   info.likes, //  [
    //     {
    //       info.info, //  0.12,
    //       info.type, //  twitter
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  youtube
    //     }
    //   ],
    //   info.age, //  [
    //     {
    //       info.info, //  0,
    //       info.type, //  12-18
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  19-25
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  26-32
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  33-39
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  40-46
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  47+
    //     }
    //   ],
    //   info.gender, //  [
    //     {
    //       info.info, //  0,
    //       info.type, //  female
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  male
    //     },
    //     {
    //       info.info, //  0,
    //       info.type, //  other
    //     }
    //   ],
    topics: info.topics, //  [],
    influencerInfo: {
      bio: String(info.influencer_info.bio) || '', //  Beatae consequatur sunt aliquam consequatur.,
      language: String(info.influencer_info.language) || '', //  14,
      ethnicity: String(info.influencer_info.ethnicity) || '', //  14,
      gender: String(info.influencer_info.gender) || '', //  14,
      location: parseLocation(info.influencer_info.location) || '', //  '',
      ageRange: String(info.influencer_info.age_range) || '', //  14
    },
    rateCard: info.rate_card, //  []
  };

  return adoptedInfo;
};

export const adoptSocialMediaSummary = (info: any): ISocialMediaSummary => {
  const adoptedInfo = info as ISocialMediaSummary;

  return adoptedInfo;
};
