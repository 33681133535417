import { formatSocialReachFromValue } from '../../utils/format';
import { IInfluencer } from '../../models/influencer';
import { BASE_URL } from '../../constants/ui';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/routes';

interface Props {
  influencer: IInfluencer;
  onDeleteClick: (influencer: IInfluencer) => void;
}

const InfluencerBigCard = ({ influencer, onDeleteClick }: Props) => {
  const navigate = useNavigate();

  const moreHandler = () => {
    navigate(AppRoute.INFLUENCER_ANALYTICS + '/' + influencer.id);
  };

  const deleteHandler = () => {
    onDeleteClick(influencer);
  };

  return (
    <section className="influencer-card">
      <div className="influencer-card__avatar-wrapper">
        <img
          className="influencer-card__avatar"
          src={BASE_URL + influencer.avatar}
          width="196"
          height="220"
          alt="influencers avatar"
        />
      </div>
      <div className="influencer-card__wrapper">
        <h4 className="influencer-card__title">
          {influencer.name}
          <button className="button button--more" type="button" onClick={moreHandler}>
            <span></span>
          </button>
        </h4>
        <ul className="influencer-card__category-list">
          {influencer.categories.map(category => (
            <li className="influencer-card__category" key={category}>
              {category}
            </li>
          ))}
        </ul>
        <div className="influencer-card__social-activity">
          {influencer.socialReach.slice(0, 4).map(social => (
            <div
              className={`influencer-card__network influencer-card__network--${social.type}`}
              key={social.type}>
              <div>
                <div>{formatSocialReachFromValue(social.value, true)}</div>
                <div>Followers</div>
              </div>
              <div>
                <div>
                  {influencer.engagementRate.find(rate => rate.type === social.type)?.value}%
                </div>
                <div>Engagement rate</div>
              </div>
            </div>
          ))}
        </div>
        <div className="influencer-card__button-wrapper">
          <button
            className="button button--transparent button--delete js-button-delete"
            type="button"
            onClick={deleteHandler}>
            Delete
          </button>
        </div>
      </div>
    </section>
  );
};

export default InfluencerBigCard;
