import BillingInfoService from '../service/billing-service';

import { IBillingInfo } from '../models/billing-address';

import { AppDispatch } from '../store/store';
import { billingSlice } from '../store/billing/reducer';
import { uiSlice } from '../store/ui/reducer';

import {
  adoptBillingInfoToClient,
  adoptBillingInfoToServer,
} from '../adapters/billing-info-adapter';
import { log } from '../helpers/log';

export const getBillingInfo = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setInfo } = billingSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await BillingInfoService.getBillingInfo();

    dispatch(setInfo(adoptBillingInfoToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateBillingInfo = (data: IBillingInfo) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setInfo } = billingSlice.actions;
  const { addNotification } = uiSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await BillingInfoService.updateBillingInfo(adoptBillingInfoToServer(data));

    dispatch(setInfo(adoptBillingInfoToClient(response.data)));
    dispatch(
      addNotification({
        type: 'success',
        message: `Billing info successfully updated`,
      }),
    );
  } catch (err: any) {
    if (err.response.data && err.response.data.validator) {
      Object.entries(err.response.data.validator).forEach((validator: any) => {
        dispatch(
          addNotification({
            type: 'error',
            message: `${validator[0]}: ${validator[1].map((element: any) => element).join('; ')} `,
          }),
        );
      });
    }

    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
