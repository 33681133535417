import { IMyList, IServerMyList } from '../models/my-list';

export const adoptMyListToClient = (myList: IServerMyList): IMyList => {
  return {
    id: Number(myList.id) || 0,
    userId: Number(myList.user_id) || 0,
    title: myList.title || '',
    influencers: Number(myList.influencer_count) || 0,
    date: myList.created_at || '',
    isExported: myList.is_exported || false,
  };
};

export const adoptMyListsListToClient = (data: IServerMyList[]): IMyList[] => {
  if (!Array.isArray(data)) return [];
  return data.map(adoptMyListToClient);
};
