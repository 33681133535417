import { useEffect } from 'react';

import Page from '../../../components/page/page';
import TopPanel from '../../../components/top-panel/top-panel';
import { Button } from '../../../components/button';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { selectInfluencerAnalytics } from '../../../store/influencer-analytics/reducer';
import { getInfluencerAnalytics } from '../../../api/influencer-analytics';
import { useParams } from 'react-router-dom';
import { getUser } from '../../../store/auth/reducer';
import ContentWrapper from '../../../components/content-wrapper/content-wrapper';
import { Widget } from '../../../components/widgets/widget';
import { FiveInRow } from '../../../components/widgets/five-in-row';
import { AgeChart } from '../../../components/widgets/age-chart';
import { GenderChart } from '../../../components/widgets/gender-chart';

const Advertiser = () => {
  const dispatch = useAppDispatch();
  const influencerAnalytics = useAppSelector(selectInfluencerAnalytics);

  console.log(influencerAnalytics);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    dispatch(getInfluencerAnalytics(Number(id)));
  }, [id, dispatch]);

  return (
    <Page>
      <TopPanel>
        <h2 className="top-panel__account-profile">Overview /</h2>
        <div style={{ display: 'flex' }}>
          <Button bookmark onClick={() => {}}>
            Bookmark
          </Button>
          <Button onClick={() => {}}>Add to list</Button>
          <Button onClick={() => {}}>Add to media kit / proposal</Button>
        </div>
      </TopPanel>

      <ContentWrapper className="tab__wrapper--dashboard">
        <Widget></Widget>
        <FiveInRow>
          <Widget>
            <div style={{ marginBottom: '10px' }}>Age / Gender</div>
            <div style={{ display: 'flex' }}>
              <AgeChart />
              <GenderChart />
            </div>
          </Widget>
          <Widget>
            <div style={{ marginBottom: '10px' }}>Topics / Score</div>
            <div style={{ display: 'flex' }}>
              <AgeChart />
              <GenderChart />
            </div>
          </Widget>
        </FiveInRow>
        influencer personal information
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <div>
                Influencer information
                <p>Gender</p>
                Female
                <p>Age Range</p>
                18 - 25
                <p>Ethnicity</p>
                Caucasian
                <p>Language</p>
                English
              </div>
              <div>
                Bio
                <Widget>Bio</Widget>
              </div>
            </div>
            <Widget>Rate Card</Widget>
          </div>
          <div>Big map</div>
        </div>
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
