import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface ETransferState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export const eTransferSlice = createSlice({
  name: 'eTransfer',
  initialState,
  reducers: {
    setIsLoading: (state: ETransferState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default eTransferSlice.reducer;

export const getIsLoading = (state: RootState) => state.eTransfer.isLoading;

// import { ActionType } from './actions';

// const initialState = {
//   name: undefined,
//   email: undefined,
// };

// const eTransferReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case ActionType.SET_E_TRANSFER_NAME:
//       return {
//         ...state,
//         name: action.payload,
//       };

//     case ActionType.SET_E_TRANSFER_EMAIL:
//       return {
//         ...state,
//         email: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// export default eTransferReducer;

// export const selectETransferName = (state: RootState) => state.eTransfer.name;
// export const selectETransferEmail = (state: RootState) => state.eTransfer.email;
