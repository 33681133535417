import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBillingInfo } from '../../models/billing-address';

import { RootState } from '../store';

interface BillingState {
  isLoading: boolean;
  info: IBillingInfo;
}

const initialState = {
  isLoading: false,
  info: {
    fullName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    billingPhone: '',
  } as IBillingInfo,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setIsLoading: (state: BillingState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInfo: (state: BillingState, action: PayloadAction<IBillingInfo>) => {
      state.info = action.payload;
    },
  },
});

export default billingSlice.reducer;

export const getIsLoading = (state: RootState) => state.billing.isLoading;
export const selectBillingInfo = (state: RootState) => state.billing.info;
