import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[] | undefined;
  minimize?: boolean;
  alignRight?: boolean;
  profile?: boolean;
  onClick?: () => void;
}

export const TD = ({ className, children, minimize, alignRight, onClick, profile }: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.minimize]: minimize,
    [cls.alignRight]: alignRight,
    [cls.profile]: profile,
    [cls.cursorPointer]: Boolean(onClick),
  };

  return (
    <td className={classNames([cls.td, className], mods)} onClick={onClick}>
      {children}
    </td>
  );
};
