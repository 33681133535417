import { Button } from '.';

export const SaveCancelButtons = () => {
  return (
    <div className="form__flex-field profile-page__ok-cancel">
      <Button reset secondary onClick={() => {}}>
        Cancel
      </Button>
      <Button submit primary onClick={() => {}}>
        Save
      </Button>
    </div>
  );
};
