import cls from './five-in-row.module.scss';

interface Props {
  children: React.ReactNode;
}

const FiveInRow = ({ children }: Props) => {
  return <div className={cls.flex}>{children}</div>;
};

export { FiveInRow };
