import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './app-router';
import Toggler from './toggler';
import NotificationList from './notification/notification-list';

import { getUserInfo } from '../api/user';
import { useAppDispatch } from '../hooks/store';
import { MessageProvider } from '../provider/message-provider/message-provider';

const App = () => {
  const dispatch = useAppDispatch();

  // just for loolz
  // remove warning message from console when using react-google-charts
  useEffect(() => {
    const originalWarn = console.warn;

    console.warn = function (...args) {
      const arg = args && args[0];

      if (arg && arg.includes("Attempting to load version '51' of Google Charts")) return;

      originalWarn(...args);
    };

    return () => {
      console.warn = originalWarn;
    };
  }, []);

  useEffect(() => {
    dispatch(getUserInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <MessageProvider>
        <AppRouter />
      </MessageProvider>
      <Toggler />
      <NotificationList />
      {/* <MessageContext /> */}
    </BrowserRouter>
  );
};

export default App;
