import api from '../http';
import { AxiosResponse } from 'axios';

import { IServerBillingInfo } from '../models/billing-address';

export default class BillingService {
  static async getBillingInfo(): Promise<AxiosResponse> {
    return api.get('/billing-info');
  }
  static async updateBillingInfo(data: IServerBillingInfo): Promise<AxiosResponse> {
    return api.post('/billing-info', data);
  }
}
