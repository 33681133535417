import NetworkList from '../../components/network-list';
import { INetwork } from '../../models/network';

interface Props {
  networkList: INetwork[];
  setNetworkList: (networkList: INetwork[]) => void;
  children: React.ReactNode;
}

const AccountInfoCategories = ({ networkList, setNetworkList, children }: Props) => {
  return (
    <section>
      <div className="account-section">
        <h3 className="account-section__title">Network Selection</h3>
        <div className="info-block">
          <NetworkList networkList={networkList} setNetworkList={setNetworkList} />
        </div>
      </div>
      <p className="account-section__description">Please select at least one Network</p>
      {children}
    </section>
  );
};

export default AccountInfoCategories;
