import { useEffect } from 'react';

import { getPaymentHistoryList } from '../../api/payment';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { IPaymentHistory } from '../../models/payment-history';
import { selectPaymentHistoryList } from '../../store/payment/reducer';

interface Props {
  isInfluencer: boolean;
}

const PaymentHistorySection = ({ isInfluencer }: Props) => {
  const dispatch = useAppDispatch();

  const paymentHistoryList = useAppSelector<IPaymentHistory[]>(selectPaymentHistoryList);

  useEffect(() => {
    dispatch(getPaymentHistoryList());
  }, []);

  return (
    <div className="account-section">
      <h3 className="account-section__title">Payment history</h3>
      <div className="info-block">
        <table className="payment-history">
          <thead className="payment-history__head">
            <tr className="payment-history__head-row">
              <th>Date</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="payment-history__body">
            {paymentHistoryList.map(item => {
              return (
                <tr key={item.id} className="payment-history__body-row">
                  <td>{item.paymentDate.substring(0, 10)}</td>
                  <td>$ {item.amount.toFixed(2)}</td>
                  <td>{item.description}</td>
                  <td>{item.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentHistorySection;
