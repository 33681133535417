import { useEffect, useState } from 'react';
import { Button } from '../button';

import cls from './message.module.scss';
import { NewMessagesBubble } from './new-messages-bubble';

interface Props {
  newMessages: number;
  messageEditorRef: React.RefObject<HTMLTextAreaElement>;
  onSendClick: () => void;
  disabled: boolean;
}

export const MessageEditor = ({ newMessages, messageEditorRef, onSendClick, disabled }: Props) => {
  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    const textarea = messageEditorRef.current;

    if (!textarea) return;
    textarea.style.height = textarea.scrollHeight + 1 + 'px'; // 1px for remove scroll. It's a hack
  }, [value]);

  return (
    <>
      <textarea
        className={cls.messageEditor}
        name="message-body"
        onChange={handleChange}
        placeholder="Type your message..."
        ref={messageEditorRef}
        disabled={disabled}
      />
      <div className="messaging__flex messaging__flex--submit" style={{ marginTop: '-20px' }}>
        <Button primary onClick={onSendClick} disabled={disabled}>
          Send Message
        </Button>
        {/* <div
          className="messaging__character-count"
          style={{ position: 'relative', paddingRight: '30px' }}>
          Message Count:
          <NewMessagesBubble className={cls.rightSide} count={newMessages} />
        </div> */}
      </div>
    </>
  );
};
