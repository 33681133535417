import PaymentPlanList from '../../components/payment-plan-list';
import PaymentPlanFeatures from '../../components/payment-plan-feature';
import { IPaymentPlan } from '../../models/payment-plan';

interface Props {
  paymentPlanList: IPaymentPlan[];
  currentPaymentPlan: number;
  setCurrentPaymentPlan: (paymentPlan: number) => void;
  children: React.ReactNode;
}

const AccountInfoPaymentPlan = ({
  paymentPlanList,
  currentPaymentPlan,
  setCurrentPaymentPlan,
  children,
}: Props) => {
  return (
    <section>
      <div className="payment-plan__wrapper">
        <div className="account-section payment-plan">
          <h3 className="account-section__title">Payment Plans</h3>

          <PaymentPlanList
            paymentPlanList={paymentPlanList}
            currentPaymentPlan={currentPaymentPlan}
            setCurrentPaymentPlan={setCurrentPaymentPlan}
          />
        </div>
        <div className="account-section plan-feature">
          <h3 className="account-section__title">Features</h3>

          <PaymentPlanFeatures
            paymentPlan={paymentPlanList.find(index => index.id === currentPaymentPlan)}
          />
        </div>
      </div>

      {children}
    </section>
  );
};

export default AccountInfoPaymentPlan;
