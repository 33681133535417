import { Message } from '../store/message/reducer';
import { formatChatDate } from './format';

export const groupMessagesByDate = (messageList: Message[]): Message[][] => {
  const groupedMessages: Message[][] = [];
  let currentGroup: Message[] = [];

  messageList.forEach((message, index) => {
    if (index === 0) {
      currentGroup.push(message);
    } else {
      const previousMessage = messageList[index - 1];
      if (formatChatDate(message.created_at) === formatChatDate(previousMessage.created_at)) {
        currentGroup.push(message);
      } else {
        groupedMessages.push(currentGroup);
        currentGroup = [message];
      }
    }
  });

  if (currentGroup.length) {
    groupedMessages.push(currentGroup);
  }

  return groupedMessages;
};
