import { Campaign } from './campaign';

import { Campaign as CampaignType } from '../../store/message/reducer';

import { classNames } from '../../utils/class-names';
import cls from './message.module.scss';

interface Props {
  campaignListCollapsed: boolean;
  currentCampaignId: number;
  campaignList: CampaignType[];
  onOpenClick: () => void;
  onChange: (campaign: CampaignType | undefined) => void;
}

export const CampaignList = ({
  campaignListCollapsed,
  currentCampaignId,
  campaignList,
  onOpenClick,
  onChange,
}: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.collapsed]: campaignListCollapsed,
  };

  return (
    <div className={classNames([cls.campaignList], mods)}>
      <div className={cls.current}>
        <Campaign
          campaign={campaignList.find(campaign => campaign.id === currentCampaignId)}
          onClick={onOpenClick}
        />
      </div>

      <div className={cls.elementList}>
        {campaignList.map(campaign => {
          return (
            <div key={campaign.id} className={cls.element}>
              <Campaign campaign={campaign} onClick={onChange} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
