import PaymentSection from '../../components/payment-section';
import BillingAddressSection from '../../components/billing-address-section';
import BillingEmailAddressSection from '../../components/billing-email-address-section';

import { IBillingInfo } from '../../models/billing-address';

interface Props {
  isInfluencer: boolean;
  paymentMethod?: { id: number; name: string };
  isCurrentPaymentMethodFilled: boolean;
  onPaymentMethodChange?: (value: { id: number; name: string }) => void;
  setOpenEditModal: () => void;
  deletePaymentInfo: () => void;
  billing: IBillingInfo;
  setBilling: (value: IBillingInfo) => void;
  billingEmail: string;
  setBillingEmailAddressModalOpen: (value: boolean) => void;
  creditCardInfo?: string;
  children?: React.ReactNode;
}

const AccountInfoPayment = ({
  isInfluencer,
  paymentMethod,
  onPaymentMethodChange,
  setOpenEditModal,
  deletePaymentInfo,
  isCurrentPaymentMethodFilled,
  billing,
  setBilling,
  billingEmail,
  setBillingEmailAddressModalOpen,
  creditCardInfo,
  children,
}: Props) => {
  return (
    <section id="section-1">
      <PaymentSection
        isInfluencer={isInfluencer}
        paymentMethod={paymentMethod}
        onPaymentMethodChange={onPaymentMethodChange}
        setOpenEditModal={setOpenEditModal}
        deletePaymentInfo={deletePaymentInfo}
        isCurrentPaymentMethodFilled={isCurrentPaymentMethodFilled}
        creditCardInfo={creditCardInfo}
      />

      <BillingAddressSection billing={billing} setBilling={setBilling} />

      <BillingEmailAddressSection
        isInfluencer={isInfluencer}
        billingEmail={billingEmail}
        setBillingEmailAddressModalOpen={setBillingEmailAddressModalOpen}
      />

      {children}
    </section>
  );
};

export default AccountInfoPayment;
