import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[] | undefined;
  minimize?: boolean;
  alignRight?: boolean;
  profile?: boolean;
  w140px?: boolean;
  onClick?: () => void;
}

export const TH = ({
  className,
  children,
  minimize,
  alignRight,
  profile,
  w140px,
  onClick,
}: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.minimize]: minimize,
    [cls.alignRight]: alignRight,
    [cls.profile]: profile,
    [cls.w140px]: w140px,
    [cls.cursorPointer]: Boolean(onClick),
  };

  return (
    <th className={classNames([cls.th, className], mods)} onClick={onClick}>
      {children}
    </th>
  );
};
