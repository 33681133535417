import { useEffect } from 'react';

import CurrentPlanSection from '../../components/current-plan-section';

import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { selectPaymentPlanList, selectCurrentPaymentPlan } from '../../store/payment/reducer';

import { getCurrentPaymentPlan, getPaymentPlanList } from '../../api/payment';
import { IPaymentPlan } from '../../models/payment-plan';

interface Props {
  isInfluencer: boolean;
}

const BillingCurrentPlan = ({ isInfluencer }: Props) => {
  const dispatch = useAppDispatch();

  const paymentPlanList = useAppSelector<IPaymentPlan[]>(selectPaymentPlanList);
  const currentPaymentPlan = useAppSelector(selectCurrentPaymentPlan);

  useEffect(() => {
    dispatch(getPaymentPlanList());
    dispatch(getCurrentPaymentPlan());
  }, []);

  return (
    <CurrentPlanSection paymentPlanList={paymentPlanList} currentPaymentPlan={currentPaymentPlan} />
  );
};

export default BillingCurrentPlan;
