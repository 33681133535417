import RateCardList from '../../components/rate-card-list';
import { INetwork } from '../../models/network';
import { IRateCard } from '../../models/rate-card';

interface Props {
  rateCardList: IRateCard[];
  networkList: INetwork[];
  setRateCardList: (rateCardList: IRateCard[]) => void;
  children: React.ReactNode;
}

const AccountInfoRateCards = ({ rateCardList, networkList, setRateCardList, children }: Props) => {
  return (
    <section>
      <div className="account-section">
        <h3 className="account-section__title">Rate card</h3>
        <div className="info-block">
          <p className="info-block__remark">Enter rate in US dollars</p>
          <RateCardList
            rateCardList={rateCardList}
            networkList={networkList}
            setRateCardList={setRateCardList}
          />
        </div>
      </div>
      <p className="account-section__description">
        Please create a rate card for the chosen network
      </p>

      {children}
    </section>
  );
};

export default AccountInfoRateCards;
