import api from '../http';
import { AxiosResponse } from 'axios';

export default class InfluencerService {
  static async getInfluencerList(params?: string): Promise<AxiosResponse> {
    console.log('getInfluencerList:', `/influencer${params ? `?${params}` : ``}`);
    return api.get(`/influencer${params ? `?${params}` : ``}`);
  }
  static async getAdvertiserBookmarkList(): Promise<AxiosResponse> {
    console.log('getAdvertiserBookmarkList:', `/influencer-bookmark`);
    return api.get(`/influencer-bookmark`);
  }
  static async getCampaignInfluencerList(campaign: number): Promise<any> {
    console.log('getInfluencerList:', `/campaignInfluencer/${campaign}`);
    // return api.get(`/campaignInfluencer/${campaign}`);
    return new Promise(res => res({ data: [] }));
  }
  static async toggleInfluencerBookmark(influencer: number): Promise<any> {
    console.log('toggleInfluencerBookmark:', `/influencer-bookmark, {id:${influencer}}`);
    return api.post(`/influencer-bookmark`, { id: influencer });
  }
  static async getInfluencerNote(influencer: number): Promise<any> {
    console.log('getInfluencerNote:', `/influencer-note?influencer_id=${influencer}}`);
    return api.get(`/influencer-note?influencer_id=${influencer}`);
  }
  static async updateInfluencerNote(influencer: number, note: string): Promise<any> {
    console.log(
      'updateInfluencerNote:',
      `/influencer-note, {influencer_id:${influencer}, note:${note}}`,
    );
    return api.post(`/influencer-note`, { influencer_id: influencer, note });
  }
  static async getInfluencerDetails(influencer: number): Promise<any> {
    console.log('getInfluencerDetails:', `/influencer-details?id=${influencer}`);
    return api.get(`/influencer-details?id=${influencer}`);
  }
}
