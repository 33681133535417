import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as Contracts } from '../../image/contracts.svg';
import { ReactComponent as Dashboard } from '../../image/dashboard.svg';
import { ReactComponent as MyLists } from '../../image/my-lists.svg';
import { ReactComponent as Campaigns } from '../../image/campaigns.svg';
import { ReactComponent as Search } from '../../image/search.svg';
import { ReactComponent as Bookmarks } from '../../image/bookmarks.svg';
import { ReactComponent as Report } from '../../image/report.svg';
import { ReactComponent as Messenger } from '../../image/messenger.svg';
import { ReactComponent as Presentation } from '../../image/presentation.svg';
import { AppRoute } from '../../router/routes';
import { useAppSelector } from '../../hooks/store';
import { selectNewMessages } from '../../store/message/reducer';
import { NewMessagesBubble } from '../message/new-messages-bubble';

interface Props {
  to: AppRoute;
  children: React.ReactNode;
  multiRoute?: Boolean;
}

const MenuItem = ({ to, children, multiRoute }: Props) => (
  <li>
    <NavLink
      className={({ isActive }) => {
        const classes = [];
        classes.push('main-menu__link');

        if (isActive || multiRoute) classes.push('main-menu__link--active');

        return classes.join(' ');
      }}
      to={to}
    >
      {children}
    </NavLink>
  </li>
);

const pathIncludeRoutes = (pathname: string, routes: AppRoute[]) =>
  Boolean(routes.find((route) => pathname.includes(route)));

interface MainMenuProps {
  isInfluencer: boolean;
}

const UserMenu = ({ isInfluencer }: MainMenuProps) => {
  const { pathname } = useLocation();
  const newMessages = useAppSelector(selectNewMessages);

  return (
    <div className="main-menu">
      <ul className="main-menu__list">
        {isInfluencer && (
          <>
            <MenuItem to={AppRoute.DASHBOARD}>
              <Dashboard />
              <span>Dashboard</span>
            </MenuItem>
            <MenuItem to={AppRoute.CAMPAIGN_LIST}>
              <MyLists />
              <span>My Campaigns</span>
            </MenuItem>
            <MenuItem
              to={AppRoute.SEARCH}
              multiRoute={pathIncludeRoutes(pathname, [AppRoute.SEARCH_RESULT])}
            >
              <Search />
              <span>Search</span>
            </MenuItem>
            <MenuItem to={AppRoute.BOOKMARK_LIST}>
              <Bookmarks />
              <span>Bookmarks</span>
            </MenuItem>
            <MenuItem to={AppRoute.MESSAGING}>
              <NewMessagesBubble count={newMessages} />
              <Messenger />
              <span>Messages</span>
            </MenuItem>
          </>
        )}
        {!isInfluencer && (
          <>
            <MenuItem to={AppRoute.CAMPAIGN_LIST}>
              <Campaigns />
              <span>Campaigns</span>
            </MenuItem>
            <MenuItem to={AppRoute.MY_LISTS}>
              <MyLists />
              <span>My Lists</span>
            </MenuItem>
            <MenuItem
              to={AppRoute.SEARCH}
              multiRoute={pathIncludeRoutes(pathname, [AppRoute.SEARCH_RESULT])}
            >
              <Search />
              <span>Search</span>
            </MenuItem>
            <MenuItem to={AppRoute.BOOKMARK_LIST}>
              <Bookmarks />
              <span>Bookmarks</span>
            </MenuItem>
            <MenuItem to={AppRoute.CAMPAIGN_REPORT}>
              <Report />
              <span>Campaign Report</span>
            </MenuItem>
            <MenuItem to={AppRoute.MESSAGING}>
              <NewMessagesBubble count={newMessages} />
              <Messenger />
              <span>Messages</span>
            </MenuItem>
            <MenuItem to={AppRoute.PRESENTATION_LIST}>
              <Presentation />
              <span>Media kit / proposal</span>
            </MenuItem>
          </>
        )}
      </ul>

      <ul
        className="main-menu__list"
        style={{ borderTop: '1px solid #c4c4c4' }}
      >
        <MenuItem to={AppRoute.CONTRACT_LIST}>
          <Contracts />
          <span>Contracts</span>
        </MenuItem>
      </ul>
    </div>
  );
};

export default UserMenu;
