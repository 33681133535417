import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '../../components/page';
import TopPanel from '../../components/top-panel';
import SearchForm from '../../components/search-form';
import ContentWrapper from '../../components/content-wrapper';

import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { getUserCategoryList } from '../../api/user';
import { getCategoryList } from '../../store/categories/reducer';
import { getIsInfluencer } from '../../store/ui/reducer';

import { BASE_URL } from '../../constants/ui';
import { AppRoute } from '../../router/routes';

const Search = () => {
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(getCategoryList);
  const isInfluencer = useAppSelector(getIsInfluencer);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserCategoryList());
  }, []);

  const searchHandler = (searchString: string) => {
    navigate(AppRoute.SEARCH_RESULT);
  };

  const categoryClickHandle = (id: number) => {
    navigate(AppRoute.SEARCH_RESULT + '/' + id);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>
        <SearchForm placeholder="Search & find your category" onInput={searchHandler} reset />
      </TopPanel>

      <ContentWrapper>
        <div className="account-section">
          {isInfluencer && (
            <h3 className="account-section__title">Find campaign in these topics</h3>
          )}
          {!isInfluencer && (
            <h3 className="account-section__title">Find influencers in these topics</h3>
          )}

          <ul className="category-page__list">
            {categoryList.map(category => {
              return (
                <li className="category-page__element" key={category.id}>
                  <button
                    className="category-page__link"
                    onClick={() => {
                      categoryClickHandle(category.id);
                    }}>
                    <img
                      src={BASE_URL + category.img_url}
                      width="210"
                      height="197"
                      alt={`${category.name} category`}
                    />
                    <span>{category.name}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </ContentWrapper>
    </Page>
  );
};

export default Search;
