import { Chart, GoogleChartOptions } from 'react-google-charts';

import cls from '../widget.module.scss';

const data = [
  ['Label', 'Value'],
  ['', 20],
];

const options: GoogleChartOptions = {
  backgroundColor: 'transparent',
  height: 190,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  redFrom: 0,
  redTo: 25,
  yellowFrom: 25,
  yellowTo: 75,
  greenFrom: 75,
  greenTo: 100,
  minorTicks: 4,
};

const CampaignAvgEngagement = () => {
  return (
    <>
      <div className={cls.header} style={{ position: 'absolute', top: '5px', left: '5px' }}>
        AVG
      </div>
      <div className={cls.header} style={{ position: 'absolute', top: '5px', right: '5px' }}>
        ER%
      </div>
      <div
        style={{
          position: 'absolute',
          width: '190px',
          height: '190px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <Chart chartType="Gauge" width="100%" data={data} options={options} />
      </div>
    </>
  );
};

export { CampaignAvgEngagement };
