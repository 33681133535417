import { CampaignStatus } from '../../constants/campaign';
import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface IProps {
  className?: string;
  red?: boolean;
  orange?: boolean;
  green?: boolean;
  gray?: boolean;
  status?: CampaignStatus;
  disabled?: boolean;
}

export const Indicator = ({ className, red, orange, green, gray, status, disabled }: IProps) => {
  const mods: Record<string, boolean | undefined> = {
    // The order of the options is important. The red should be the last to let
    // the styles beat the green and orange indicator styles.
    [cls.green]: green,
    [cls.orange]: orange,
    [cls.red]: red,
    [cls.gray]: gray,
    [cls.disabled]: disabled,
  };

  // need to do something with it.
  if (status) mods[['', cls.orange, cls.green, cls.red, cls.gray][status]] = true;

  return <div className={classNames([cls.indicator, className], mods)} />;
};
