import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import SearchForm from '../../../components/search-form';
import ContentWrapper from '../../../components/content-wrapper';

import { useAppSelector } from '../../../hooks/store';
import { getCategoryList as selectCategoryList } from '../../../store/categories/reducer';
import { selectCampaignList } from '../../../store/campaign/reducer';
import { selectSearchString } from '../../../store/ui/reducer';

import { useAppDispatch } from '../../../hooks/store';
import { ICategory } from '../../../models/category';
import { getInfluencerBookmarkList } from '../../../api/campaign';
import { getUserCategoryList } from '../../../api/user';

import { deleteCampaignBookmark } from '../../../api/campaign';
import { ICampaign } from '../../../models/campaign';
import { TBody, TH, THead, TR, Table, TableWrapper } from '../../../components/table';
import { InfluencerCampaignRow } from '../../../components/infleuncer-campaign-row';

import AddNoteToCampaign from '../../../components/modals/add-note-to-campaign/add-note-to-campaign';
import ContactToBrand from '../../../components/modals/contact-to-brand';

const Influencer = () => {
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(selectCategoryList);
  const campaignList = useAppSelector(selectCampaignList);
  const searchString = useAppSelector(selectSearchString);

  const { id } = useParams();
  const currentCategory = categoryList.find((category: ICategory) => category.id === Number(id));

  const [messageModalOpened, setMessageModalOpened] = useState<Boolean>();
  const [editNoteModalWindow, setEditNoteModalWindow] = useState<Boolean>();
  const [currentCampaign, setCurrentCampaign] = useState<ICampaign>();

  useEffect(() => {
    dispatch(getUserCategoryList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getInfluencerBookmarkList());
  }, [dispatch, id, searchString]);

  const messageHandler = (campaign: ICampaign) => {
    setCurrentCampaign(campaign);
    setMessageModalOpened(true);
  };

  const deleteBookmark = (campaign: ICampaign) => {
    dispatch(deleteCampaignBookmark(campaign));
  };

  const noteHandler = (campaign: ICampaign) => {
    setCurrentCampaign(campaign);
    setEditNoteModalWindow(true);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>
        <SearchForm placeholder="Search & find your category" />
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <div className="account-section">
          <h3 className="account-section__title section__header--search">{`${
            campaignList.length
          } results found ${currentCategory?.name ? `within ${currentCategory.name}` : ''} ${
            searchString ? ` for "${searchString}"` : ''
          }`}</h3>

          <TableWrapper>
            <Table solid>
              <THead>
                <TR>
                  <TH>Campaign name</TH>
                  <TH w140px>Brand, Company or Agency</TH>
                  <TH w140px>Start date</TH>
                  <TH w140px>Budget</TH>
                  <TH w140px>Goals</TH>
                  <TH w140px>Details</TH>
                  <TH w140px>Topics</TH>
                </TR>
              </THead>
              <TBody>
                {campaignList.map(campaign => (
                  <InfluencerCampaignRow
                    key={campaign.id}
                    campaign={campaign}
                    isInfluencer={true}
                    onMessageClick={messageHandler}
                    onBookmarkClick={deleteBookmark}
                    onNoteClick={noteHandler}
                  />
                ))}
              </TBody>
            </Table>
          </TableWrapper>
        </div>
        {editNoteModalWindow && currentCampaign && (
          <AddNoteToCampaign campaign={currentCampaign} setOpened={setEditNoteModalWindow} />
        )}
        {messageModalOpened && currentCampaign && (
          <ContactToBrand campaign={currentCampaign} setOpened={setMessageModalOpened} />
        )}
      </ContentWrapper>
    </Page>
  );
};

export default Influencer;
