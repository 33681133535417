import cls from '../widget.module.scss';

const CampaignBudget = () => {
  return (
    <>
      <p className={cls.header}>Campaign</p>
      <p className={cls.subheader}>Budget</p>
      <p className={cls.value}>0$</p>
    </>
  );
};

export { CampaignBudget };
