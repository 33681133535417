export const MODAL_ANIMATION_DURATION = 500;

export enum UIType {
  INFLUENCER = "influencer",
  ADVERTISER = "advertiser",
}

export enum ModalType {
  POPUP = "popup",
  SIDE = "side",
}

// export const BASE_URL =
//   window.location.hostname === 'localhost'
//     ? 'http://localhost:7005'
//     : 'https://dev.influencerunion.net';

export const BASE_URL = window.location.hostname.includes("influencerunion.net")
  ? "https://dev.api.influencerunion.net"
  : "http://34.120.172.108";
