import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { ICampaignReportList } from '../../models/report';
import { CampaignStatus } from '../../constants/campaign';
import { ICampaign } from '../../models/campaign';

interface StatusFilerList {
  [CampaignStatus.ACTIVE]: boolean;
  [CampaignStatus.DRAFT]: boolean;
  [CampaignStatus.CLOSED]: boolean;
  [CampaignStatus.ARCHIVE]: boolean;
}

interface ReportState {
  isLoading: boolean;
  list: ICampaignReportList;
  details: undefined;
  statusFilter: StatusFilerList;
}

const initialState = {
  isLoading: false,
  list: {
    total: {
      campaignCount: 0,
      postCount: 0,
      budget: 0,
      influencerCount: 0,
      avgEngagement: 0,
    },
    campaigns: [],
  },
  details: undefined,
  statusFilter: {
    [CampaignStatus.ACTIVE]: true,
    [CampaignStatus.DRAFT]: true,
    [CampaignStatus.CLOSED]: true,
    [CampaignStatus.ARCHIVE]: true,
  },
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setIsLoading: (state: ReportState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setList: (state: ReportState, action: PayloadAction<ICampaignReportList>) => {
      state.list = action.payload;
    },
    setStatusFilter: (state: ReportState, action: PayloadAction<StatusFilerList>) => {
      state.statusFilter = action.payload;
    },
  },
});

export default reportSlice.reducer;

export const getIsLoading = (state: RootState) => state.report.isLoading;
export const selectReportList = (state: RootState) => state.report.list;
export const selectReport = (id: number) => (state: RootState) => {
  state.report.list.campaigns.find((report: ICampaign) => report.id === id);
};
export const selectStatusFilterList = (state: RootState) => state.report.statusFilter;
