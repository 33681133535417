// import { PaymentPlan } from '../../constants/payment';

import { IPaymentPlan } from '../../models/payment-plan';

interface Props {
  paymentPlan: IPaymentPlan | undefined;
}

const PaymentPlanFeature = ({ paymentPlan }: Props) => {
  if (!paymentPlan) {
    return null;
  }

  return (
    <div className="plan-feature__element" id="plan-feature-basic">
      <p className="plan-feature__title">{paymentPlan.name}</p>
      <ul className="plan-feature__list">
        {paymentPlan.description.map(feature => (
          <li className="plan-feature__item" key={feature}>
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaymentPlanFeature;
