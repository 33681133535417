import { EmptyButton } from '../button';
import { Text } from '../text';
import { Indicator } from '../indicator';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { presentationSlice, selectStatusFilterList } from '../../store/presentation/reducer';

import { PresentationStatus } from '../../constants/presentation';

export const PresentationStatusFilter = () => {
  const dispatch = useAppDispatch();
  const statusFilerList = useAppSelector(selectStatusFilterList);

  const { setStatusFilter } = presentationSlice.actions;

  const toggleFilterValue = (filterName: PresentationStatus) =>
    dispatch(
      setStatusFilter({
        ...statusFilerList,
        [filterName]: !statusFilerList[filterName],
      }),
    );

  const activeHandler = () => toggleFilterValue(PresentationStatus.ACTIVE);
  const exportedHandler = () => toggleFilterValue(PresentationStatus.EXPORTED);
  const threeDaysLeftHandler = () => toggleFilterValue(PresentationStatus.THREE_DAYS_LEFT);

  return (
    <div className="campaign campaign__status-legend">
      <Text>Filter:</Text>
      <EmptyButton onClick={activeHandler}>
        <Indicator green disabled={!statusFilerList[PresentationStatus.ACTIVE]} />{' '}
        <Text gray={!statusFilerList[PresentationStatus.ACTIVE]}>Active</Text>
      </EmptyButton>
      <EmptyButton onClick={exportedHandler}>
        <Indicator orange disabled={!statusFilerList[PresentationStatus.EXPORTED]} />{' '}
        <Text gray={!statusFilerList[PresentationStatus.EXPORTED]}>Exported</Text>
      </EmptyButton>
      <EmptyButton onClick={threeDaysLeftHandler}>
        <Indicator red disabled={!statusFilerList[PresentationStatus.THREE_DAYS_LEFT]} />{' '}
        <Text gray={!statusFilerList[PresentationStatus.THREE_DAYS_LEFT]}>3 days left</Text>
      </EmptyButton>
    </div>
  );
};
