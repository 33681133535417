import { useState } from 'react';

import { ModalType } from '../../../constants/ui';
import { AccountType } from '../../../constants/payment';

import Modal from '../modal';
import { Button } from '../../button';
import { IWire } from '../../../models/wire';

interface Props {
  setOpened: (value: boolean) => void;
  wide?: boolean;
  wire: IWire;
  setWire: (value: IWire) => void;
}

type EditEvent = React.ChangeEvent<HTMLInputElement>;

const WireEdit = ({ setOpened, wide, wire, setWire }: Props) => {
  const [temporaryWire, setTemporaryWire] = useState<IWire>({ ...wire });
  const [keepOpen, setKeepOpen] = useState(true);

  const handleNameChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, name: event.target.value });
  };
  const handleAddress1Change = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, address1: event.target.value });
  };
  const handleAddress2Change = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, address2: event.target.value });
  };
  const handleCityChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, city: event.target.value });
  };
  const handleStateChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, state: event.target.value });
  };
  const handleCountryChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, country: event.target.value });
  };
  const handleZipChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, zip: event.target.value });
  };
  const handleIbanChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, iban: event.target.value });
  };
  const handleBankNameChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankName: event.target.value });
  };
  const handleBankAddress1Change = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankAddress1: event.target.value });
  };
  const handleBankAddress2Change = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankAddress2: event.target.value });
  };
  const handleBankCityChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankCity: event.target.value });
  };
  const handleBankStateChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankState: event.target.value });
  };
  const handleBankCountryChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankCountry: event.target.value });
  };
  const handleBankZipChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankZip: event.target.value });
  };
  const handleBankRoutingNumberChange = (event: EditEvent) => {
    setTemporaryWire({ ...temporaryWire, bankRoutingNumber: event.target.value });
  };
  const handleAccountTypeChange = (event: EditEvent) => {
    setTemporaryWire({
      ...temporaryWire,
      accountType:
        event.target.value === AccountType.CHECKING ? AccountType.CHECKING : AccountType.SAVING,
    });
  };
  const handleSave = () => {
    setWire({ ...temporaryWire });
    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} wide={wide} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title Wire">Transfer Information</h3>
      <div className="modal__field-wrapper">
        <div className="modal__vertical-wrapper">
          <div className="form__icon-wrapper form__icon-wrapper--name">
            <input
              className="form__input form__field form__field--name"
              name="wire-account-name"
              type="text"
              placeholder="Full Name of the account"
              value={temporaryWire.name}
              onChange={handleNameChange}
            />
          </div>
          <input
            className="form__input form__field form__field--w100"
            name="wire-address-1"
            type="text"
            placeholder="Address 1"
            value={temporaryWire.address1}
            onChange={handleAddress1Change}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-address-2"
            type="text"
            placeholder="Address 2"
            value={temporaryWire.address2}
            onChange={handleAddress2Change}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-city"
            type="text"
            placeholder="City"
            value={temporaryWire.city}
            onChange={handleCityChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-state"
            type="text"
            placeholder="State"
            value={temporaryWire.state}
            onChange={handleStateChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-country"
            type="text"
            placeholder="Country"
            value={temporaryWire.country}
            onChange={handleCountryChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-zip-code"
            type="text"
            placeholder="Zip Code"
            value={temporaryWire.zip}
            onChange={handleZipChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-iban"
            type="text"
            placeholder="Account number or IBAN"
            value={temporaryWire.iban}
            onChange={handleIbanChange}
          />
          <div className="form__flex-field form__field--w100">
            <input
              className="visually-hidden"
              id="wire-checking-account"
              name="wire-account-type"
              value={AccountType.CHECKING}
              type="radio"
              checked
              onChange={handleAccountTypeChange}
            />
            <label className="checkbox" htmlFor="wire-checking-account">
              Checking account
            </label>
            <input
              className="visually-hidden"
              id="wire-saving-account"
              name="wire-account-type"
              value={AccountType.SAVING}
              type="radio"
              onChange={handleAccountTypeChange}
            />
            <label className="checkbox" htmlFor="wire-saving-account">
              Saving account
            </label>
          </div>
        </div>
        <div className="modal__vertical-wrapper">
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-account-name"
            type="text"
            placeholder="Bank Name"
            value={temporaryWire.bankName}
            onChange={handleBankNameChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-address-1"
            type="text"
            placeholder="Address 1"
            value={temporaryWire.bankAddress1}
            onChange={handleBankAddress1Change}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-address-2"
            type="text"
            placeholder="Address 2"
            value={temporaryWire.bankAddress2}
            onChange={handleBankAddress2Change}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-city"
            type="text"
            placeholder="City"
            value={temporaryWire.bankCity}
            onChange={handleBankCityChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-state"
            type="text"
            placeholder="State"
            value={temporaryWire.bankState}
            onChange={handleBankStateChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-country"
            type="text"
            placeholder="country"
            value={temporaryWire.bankCountry}
            onChange={handleBankCountryChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-zip-code"
            type="text"
            placeholder="Zip Code"
            value={temporaryWire.bankZip}
            onChange={handleBankZipChange}
          />
          <input
            className="form__input form__field form__field--w100"
            name="wire-bank-routing-number"
            type="text"
            placeholder="Bank Routing number"
            value={temporaryWire.bankRoutingNumber}
            onChange={handleBankRoutingNumberChange}
          />
          <div className="form__field form__field--small">
            <strong>Note:</strong>$45 wire transfer fee applies for every transaction
          </div>
        </div>

        <Button primary long onClick={handleSave}>
          Add Wire Transfer
        </Button>
      </div>
    </Modal>
  );
};

export default WireEdit;
