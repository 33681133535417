import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoute } from '../../../router/routes';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import ContentWrapper from '../../../components/content-wrapper';
import SearchForm from '../../../components/search-form';
import CampaignStatusFilter from '../../../components/campaign-status-filter/';
import { Button } from '../../../components/button';
import CampaignRow from '../../../components/campaign-row';
import EditCampaign from '../../../components/modals/edit-campaign';
import ArchiveNotice from '../../../components/modals/archive-notice';
import CampaignMore from '../../../components/modals/campaign-more';
import CampaignDetails from '../../../components/modals/campaign-details';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { archiveCampaign } from '../../../api/campaign';
import { getReportList } from '../../../api/report';
import { selectStatusFilterList } from '../../../store/campaign/reducer';
import { selectReportList } from '../../../store/report/reducer';
import { getIsInfluencer, selectEditCampaignModalOpen, uiSlice } from '../../../store/ui/reducer';
import { ICampaign } from '../../../models/campaign';
import { IInfluencer } from '../../../models/influencer';

import { ReactComponent as AddIcon } from '../../../image/add.svg';
import { CampaignStatus } from '../../../constants/campaign';
import { TBody, Table } from '../../../components/table';

const CampaignList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { setEditCampaignModalOpened } = uiSlice.actions;
  const isInfluencer = useAppSelector(getIsInfluencer);
  const campaignReportList = useAppSelector(selectReportList);
  const statusFilerList = useAppSelector(selectStatusFilterList);
  const isEditCampaignModalOpen = useAppSelector(selectEditCampaignModalOpen);

  //const [isCreateCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);
  const [isCampaignDetailsModalOpen, setCampaignDetailsModalOpen] = useState(false);
  const [isChooseAnalyticsModalOpen, setChooseAnalyticsModalOpen] = useState(false);
  const [isArchiveNoticeModalOpen, setArchiveNoticeModalOpen] = useState(false);
  const [editMode, setEditMode] = useState<'new' | 'copy' | 'edit'>('new');

  const [currentCampaign, setCurrentCampaign] = useState<ICampaign | null>(null);

  useEffect(() => {
    dispatch(getReportList());
  }, [dispatch]);

  const clickHandler = (campaign: ICampaign) => {
    setCurrentCampaign(campaign);
    setCampaignDetailsModalOpen(true);
  };
  const newClickHandler = () => {
    setCurrentCampaign(null);
    setEditMode('new');
    dispatch(setEditCampaignModalOpened(true));
  };
  const editClickHandler = (campaign: ICampaign) => {
    setCurrentCampaign(campaign);
    setEditMode('edit');
    dispatch(setEditCampaignModalOpened(true));
  };
  const copyClickHandler = (campaign: ICampaign) => {
    setCurrentCampaign(campaign);
    setEditMode('copy');
    dispatch(setEditCampaignModalOpened(true));
  };
  const archiveClickHandler = (campaign: ICampaign) => {
    setCurrentCampaign(campaign);
    setArchiveNoticeModalOpen(true);
  };
  const moreClickHandler = (campaign: ICampaign) => {
    if (isInfluencer) navigate(AppRoute.CAMPAIGN_REPORT + '/' + campaign.id);

    setCurrentCampaign(campaign);
    setChooseAnalyticsModalOpen(true);
  };
  const closeCampaignEditModalHandler = () => {
    dispatch(setEditCampaignModalOpened(false));
  };
  const yesClickNotificationHandler = () => {
    if (!currentCampaign) return;

    setArchiveNoticeModalOpen(false);
    dispatch(archiveCampaign(currentCampaign));
  };

  const campaignAnalyticsHandler = (campaign: ICampaign) => {
    if (!campaign || !campaign.id) return;

    navigate(`${AppRoute.CAMPAIGN_REPORT}/${campaign.id}`);
  };
  const influencerAnalyticsHandler = (influencer: IInfluencer | undefined) => {
    if (!influencer || !influencer.id) return;

    navigate(`${AppRoute.INFLUENCER_ANALYTICS}/${influencer.id}`);
  };

  const searchHandler = (searchString: string) => {
    navigate(AppRoute.SEARCH_RESULT);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>
        <SearchForm onInput={searchHandler} reset />
        <CampaignStatusFilter />
        {!isInfluencer && (
          <Button moveRight onClick={newClickHandler}>
            <AddIcon />
            <span style={{ textOverflow: 'ellipsis' }}>Create New Campaign</span>
          </Button>
        )}
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <div className="account-section">
          <h3 className="account-section__title">Campaigns</h3>
        </div>

        <Table>
          <TBody>
            {campaignReportList.campaigns
              .filter((campaign: ICampaign) => statusFilerList[campaign.status as CampaignStatus])
              .map((campaign: ICampaign) => (
                <CampaignRow
                  key={campaign.id}
                  isInfluencer={isInfluencer}
                  campaign={campaign}
                  onClick={clickHandler}
                  onEdit={editClickHandler}
                  onCopy={copyClickHandler}
                  onArchive={archiveClickHandler}
                  onMore={moreClickHandler}
                />
              ))}
          </TBody>
        </Table>

        {isEditCampaignModalOpen && (
          <EditCampaign
            setOpened={closeCampaignEditModalHandler}
            editMode={editMode}
            campaign={currentCampaign}
          />
        )}
        {isCampaignDetailsModalOpen && currentCampaign && (
          <CampaignDetails setOpened={setCampaignDetailsModalOpen} campaign={currentCampaign} />
        )}
        {isArchiveNoticeModalOpen && (
          <ArchiveNotice
            setOpened={setArchiveNoticeModalOpen}
            yesClick={yesClickNotificationHandler}
          />
        )}
        {isChooseAnalyticsModalOpen && currentCampaign && (
          <CampaignMore
            setOpened={setChooseAnalyticsModalOpen}
            campaign={currentCampaign}
            onCampaignAnalyticsClick={campaignAnalyticsHandler}
            onInfluencerAnalyticsClick={influencerAnalyticsHandler}
          />
        )}
      </ContentWrapper>
    </Page>
  );
};

export default CampaignList;
