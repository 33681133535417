import api from '../http';
import { AxiosResponse } from 'axios';
import { IServerNewCampaign, IServerCampaign } from '../models/campaign';

export default class CampaignService {
  static async getCampaignList(params?: string): Promise<AxiosResponse> {
    console.log('getCampaignList:', `/campaign${params ? `?${params}` : ``}`);
    return api.get(`/campaign${params ? `?${params}` : ``}`);
  }
  static async getCampaign(id: string): Promise<AxiosResponse> {
    console.log('getCampaign:', `/campaign/${id}`);
    return api.get(`/campaign/${id}`);
  }
  static async getInfluencerBookmarkList(): Promise<AxiosResponse<Array<IServerCampaign>>> {
    console.log('getInfluencerBookmarkList:', `/campaign-bookmark`);
    return api.get(`/campaign-bookmark`);
  }
  static async addCampaign(data: IServerNewCampaign): Promise<AxiosResponse> {
    console.log('addCampaign:', `/campaign, ${JSON.stringify(data)}`);
    return api.post('/campaign', data);
  }
  static async updateCampaign(data: IServerCampaign): Promise<AxiosResponse> {
    return api.post('/update-campaign', data);
  }
  static async moveToArchive(data: IServerCampaign): Promise<AxiosResponse> {
    return api.delete(`/campaign?id=${data.id}`);
  }
  static async toggleCampaignBookmark(campaign: number): Promise<AxiosResponse> {
    console.log('toggleCampaignBookmark:', `/campaign-bookmark, {id:${campaign}}`);
    return api.post(`/campaign-bookmark`, { id: campaign });
  }
  static async getCampaignNote(campaign: number): Promise<AxiosResponse> {
    console.log('getCampaignNote:', `/campaign-note?campaign_id=${campaign}`);
    return api.get(`/campaign-note?campaign_id=${campaign}`);
  }
  static async updateCampaignNote(campaign: number, note: string): Promise<AxiosResponse> {
    console.log('updateCampaignNote:', `/campaign-note, {campaign_id:${campaign}, note:${note}}`);
    return api.post(`/campaign-note`, { campaign_id: campaign, note });
  }
}
