import { IInfluencer, IServerInfluencer } from '../models/influencer';
import { formatSocialNetworkNameToShortName } from '../utils/format';

export const adoptInfluencerToClient = (influencer: IServerInfluencer): IInfluencer => {
  return {
    id: Number(influencer.id) || 0,
    name: String(influencer.name || 'N/A'),
    avatar: String(influencer.avatar || ''),
    bookmark: Boolean(influencer.bookmark),
    categories: Array.isArray(influencer.categories)
      ? influencer.categories.map(item => String(item || 'N/A'))
      : [],
    note: Boolean(influencer.note),
    engagementRate: Array.isArray(influencer.engagement_rate)
      ? influencer.engagement_rate.slice(0, 3).map(item => ({
          type: formatSocialNetworkNameToShortName(String(item.type || '')),
          value: Number(item.info) || 0,
        }))
      : [],
    // max 3 items
    socialReach: Array.isArray(influencer.social_reach)
      ? influencer.social_reach.slice(0, 3).map(item => ({
          type: formatSocialNetworkNameToShortName(String(item.type || '')),
          value: Number(item.info) || 0,
        }))
      : [],
    iuScore: Number(influencer.iu_score) || 0,
    rateCard: {
      title: String(influencer.rate_card?.title || 'N/A'),
      //max 6 items
      rows: Array.isArray(influencer.rate_card?.rows)
        ? influencer.rate_card.rows.slice(0, 6).map(item => ({
            type: formatSocialNetworkNameToShortName(String(item.type || '')),
            postType: String(item.post_type || 'N/A'),
            value: Number(item.info) || 0,
          }))
        : [],
    },
    bio: String(influencer.bio || 'N/A'),
    location: String(influencer.location || 'N/A'),
    // max 3 items
    languages: Array.isArray(influencer.languages)
      ? influencer.languages.slice(0, 3).map(item => String(item || 'N/A'))
      : [],
    gender: String(influencer.gender || 'N/A'),
    ageRange: String(influencer.gender || 'N/A'),
    ethnicity: influencer.ethnicity || 'N/A',
    // max 3 items
    hashtags: Array.isArray(influencer.hashtags)
      ? influencer.hashtags.slice(0, 3).map(item => String(item || 'N/A'))
      : [],
    //max 3 items
    mentions: Array.isArray(influencer.mentions)
      ? influencer.mentions.map(item => String(item || 'N/A'))
      : [],
    posts: Number(influencer.post_count) || 0,
  };
};

export const adoptInfluencerListToClient = (data: IServerInfluencer[]): IInfluencer[] => {
  if (!Array.isArray(data)) return [];
  return data.map(adoptInfluencerToClient);
};
