import { classNames } from '../../utils/class-names';
import cls from './empty.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  onClick: () => void;
  disabled?: boolean;
}

export const EmptyButton = ({ className, children, onClick, disabled }: Props) => {
  return (
    <button className={classNames([cls.button, className])} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};
