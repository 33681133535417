import { useEffect, useState } from 'react';
import { Button } from '../../../components/button';
import ContentWrapper from '../../../components/content-wrapper';
import { Table, TBody, TD, TR } from '../../../components/table';
import { log } from '../../../helpers/log';
import ContractService from '../../../service/contract-service';
import Modal from '../../../components/modals/modal';
import { ModalType } from '../../../constants/ui';
import ContractCreate from '../contract-create';

const Advertiser = () => {
  // return (
  //   <>
  //     <section id="payment-section">
  //       <div className="account-section">
  //         <h3 className="account-section__title">Contracts</h3>

  //         {/* - var campaignList = []
  //               - campaignList.push({name: 'Rebecca Zamolo', influencerHash:'#RebeccaZamolo4B6G8J', date: '2021-10-05', campaign: 'Levis 501', campaignHash: '#InfluencerUnion4B6G8J', budget: '$10,000.00' })
  //               - campaignList.push({name: 'Kylie Jenner', influencerHash:'#RebeccaZamolo4B6G8J', date: '2021-10-05', campaign: 'Levis 501', campaignHash: '#InfluencerUnion4B6G8J', budget: '$10,000.00' })
  //               - campaignList.push({name: 'Kylie Jenner', influencerHash:'#RebeccaZamolo4B6G8J', date: '2021-10-05', campaign: 'Levis 501', campaignHash: '#InfluencerUnion4B6G8J', budget: '$10,000.00' })
  //               - campaignList.push({name: 'Alice Jonson', influencerHash:'#RebeccaZamolo4B6G8J', date: '2021-10-05', campaign: 'Levis 501', campaignHash: '#InfluencerUnion4B6G8J', budget: '$10,000.00' })
  //               - campaignList.push({name: 'Alice Jonson', influencerHash:'#RebeccaZamolo4B6G8J', date: '2021-10-05', campaign: 'Levis 501', campaignHash: '#InfluencerUnion4B6G8J', budget: '$10,000.00' }) */}

  //         {/* each campaign in campaignList */}
  //         {/* include _campaign-row.pug */}
  //       </div>
  //     </section>
  //   </>
  // );
  const [contracts, setContracts] = useState<any>([]);
  const [isChooseViewContractModalOpen, setChooseViewContractModalOpen] =
    useState(false);

  const handleClick = (c: any) => {
    setChooseViewContractModalOpen(true);
  };

  const getMyContractList = async () => {
    try {
      const response = await ContractService.getMyContractList();
      console.log(response);
      setContracts(response.data.data);
    } catch (err: any) {
      log(err);
    }
  };

  const getContractInfo = (contract: any) => {
    if (contract.contract_approved_by_influencer === 0) {
      return 'Waiting for Influencers approval';
    } else {
      return (
        <Button primary onClick={() => handleClick(contract)}>
          View Contract
        </Button>
      );
    }
  };

  const handleClickApproval = async (c: any, status: number) => {
    try {
      const response = await ContractService.updateContractStatus(c.id, status);
      setContracts(response.data.data);
    } catch (err: any) {
      log(err);
    }
  };

  const getContractStatus = (contract: any) => {
    if (contract.status === 0) return 'Contract Initiated';
    if (contract.status === 1) return 'Contract Created';
    if (contract.status === 2)
      return (
        <>
          <Button primary onClick={() => handleClickApproval(contract, 3)}>
            Approve
          </Button>{' '}
          <Button secondary onClick={() => handleClickApproval(contract, 4)}>
            Reject
          </Button>
        </>
      );
    if (contract.status === 3) return 'Paid';
    if (contract.status === 4) return 'Disputed';
    return null;
  };

  useEffect(() => {
    getMyContractList();
  }, []);

  return (
    <ContentWrapper className="tab__wrapper--campaign">
      <div className="account-section">
        <h3 className="account-section__title">Contracts</h3>
      </div>
      <ContractCreate influencerId={5} advertiserId={113} campaignId={30} />
      <Table>
        <TBody>
          {contracts.map((contract: any) => (
            <TR key={contract.id}>
              <TD>Campaign: {contract.campaign.title}</TD>
              <TD>
                Influencer: {contract.influencer.first_name}{' '}
                {contract.influencer.last_name}
              </TD>
              <TD>Link: {contract.contract_identity}</TD>
              <TD>Status: {getContractStatus(contract)} </TD>
              <TD>View Contract: {getContractInfo(contract)}</TD>
            </TR>
          ))}
        </TBody>
      </Table>
      {isChooseViewContractModalOpen && (
        <Modal
          type={ModalType.POPUP}
          setOpened={setChooseViewContractModalOpen}
        >
          <h3 className="modal__title"> Contract Information</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </Modal>
      )}
    </ContentWrapper>
  );
};

export default Advertiser;
