import { useEffect, useState } from 'react';
import { Button } from '../../../components/button';
import ContentWrapper from '../../../components/content-wrapper';
import Modal from '../../../components/modals/modal';
import { Table, TBody, TD, TR } from '../../../components/table';
import { ModalType } from '../../../constants/ui';
import { log } from '../../../helpers/log';
import ContractService from '../../../service/contract-service';

const Influencer = () => {
  const [contracts, setContracts] = useState<any>([]);
  const [currentContract, setCurrentContract] = useState<any>(null);
  const [isChooseViewContractModalOpen, setChooseViewContractModalOpen] =
    useState(false);

  const handleClick = (c: any) => {
    setChooseViewContractModalOpen(true);
    setCurrentContract(c);
  };

  const handleSubmitClick = async (c: any) => {
    try {
      const response = await ContractService.updateContractStatus(c.id, 2);
      setContracts(response.data.data);
    } catch (err: any) {
      log(err);
    }
  };

  const getContractInfo = (contract: any) => {
    if (
      contract.contract_approved_by_influencer === 0 ||
      contract.status !== 0
    ) {
      return (
        <Button primary onClick={() => handleClick(contract)}>
          View Contract
        </Button>
      );
    }
    return null;
  };

  const getContractStatus = (contract: any) => {
    if (contract.status === 0) return 'Contract Initiated';
    if (contract.status === 1)
      return (
        <Button secondary onClick={() => handleSubmitClick(contract)}>
          Submit for Approval
        </Button>
      );
    if (contract.status === 2) return 'Submitted for Review';
    if (contract.status === 3) return 'Paid';
    if (contract.status === 4) return 'Disputed';
    return null;
  };

  const getMyContractList = async () => {
    try {
      const response = await ContractService.getMyContractList();
      console.log(response);
      setContracts(response.data.data);
    } catch (err: any) {
      log(err);
    }
  };

  const updateContract = async () => {
    try {
      const response = await ContractService.updateContract(
        currentContract.id,
        1
      );
      setContracts(response.data.data);
      setChooseViewContractModalOpen(false);
    } catch (err: any) {
      log(err);
    }
  };

  useEffect(() => {
    getMyContractList();
  }, []);

  return (
    <ContentWrapper className="tab__wrapper--campaign">
      <div className="account-section">
        <h3 className="account-section__title">Contracts</h3>
      </div>
      <Table>
        <TBody>
          {contracts.map((contract: any) => (
            <TR key={contract.id}>
              <TD>Campaign: {contract.campaign.title}</TD>
              <TD>
                Advertiser: {contract.advertiser.first_name}{' '}
                {contract.advertiser.last_name}
              </TD>
              <TD>Link: {contract.contract_identity}</TD>
              <TD>Status: {getContractStatus(contract)} </TD>
              <TD>View Contract: {getContractInfo(contract)}</TD>
            </TR>
          ))}
        </TBody>
      </Table>

      {isChooseViewContractModalOpen && (
        <Modal
          type={ModalType.POPUP}
          setOpened={setChooseViewContractModalOpen}
        >
          <h3 className="modal__title"> Contract Information</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          {currentContract.contract_approved_by_influencer === 0 && (
            <Button primary onClick={updateContract}>
              Agree and Submit
            </Button>
          )}
        </Modal>
      )}
    </ContentWrapper>
  );
};

export default Influencer;
