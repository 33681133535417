const Influencer = () => (
  <>
    <section className="payment-section">
      <div className="account-section">
        <h3 className="account-section__title">Campaigns</h3>

        {/* - var campaignList = []
          - campaignList.push({name: 'Levis 501', status: 'active', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Crayola LLS', status: 'draft', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Rockstar, Inc.', status: 'closed', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Levis 501', status: 'active', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Crayola LLS', status: 'active', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Rockstar, Inc.', status: 'active', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Levis 501', status: 'draft', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Crayola LLS', status: 'active', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Rockstar, Inc.', status: 'closed', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Levis 501', status: 'draft', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Crayola LLS', status: 'active', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'})
          - campaignList.push({name: 'Rockstar, Inc.', status: 'closed', start:'2021-09-25', likes: '24', engagement: '10%', budget: '$1000'}) */}

        {/* each campaign in campaignList */}
        {/* include _campaign-row.pug */}
      </div>
    </section>
    {/* include _modal-campaign-details */}
  </>
);
export default Influencer;
