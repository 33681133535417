import Page from '../../components/page';
import TopPanel from '../../components/top-panel';
import ContentWrapper from '../../components/content-wrapper';

import BillingCurrentPlan from './billing-current-plan';
import BillingPaymentSection from './billing-payment-section';
import BillingAddress from './billing-address';
import BillingEmail from './billing-email';

import { useAppSelector } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';
import PaymentHistorySection from '../../components/payment-history-section';

const Billing = () => {
  const isInfluencer = useAppSelector(getIsInfluencer);

  return (
    <Page>
      <TopPanel>
        <h2 className="top-panel__account-profile">Billing Details</h2>
      </TopPanel>

      <ContentWrapper>
        {!isInfluencer && <BillingCurrentPlan isInfluencer={isInfluencer} />}

        {isInfluencer && <BillingPaymentSection isInfluencer={isInfluencer} />}

        {!isInfluencer && (
          <div className="account-section">
            <h3 className="account-section__title">Payment</h3>
            <div>
              <form className="info-block info-block--brand-billing-details">
                <BillingPaymentSection isInfluencer={isInfluencer} />
              </form>
              <form className="info-block info-block--brand-billing-details">
                <BillingEmail isInfluencer={isInfluencer} />
              </form>
            </div>
          </div>
        )}

        {isInfluencer && <BillingEmail isInfluencer={isInfluencer} />}
        <BillingAddress />
        <PaymentHistorySection isInfluencer={isInfluencer} />
      </ContentWrapper>
    </Page>
  );
};

export default Billing;
