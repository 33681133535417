import { MouseEvent, useEffect } from 'react';

// options = {
//
//   isOpen: true || false,
//   setIsOpen: () => {},
//
//   selectedOption: {
//     id: 1,
//     name: 'Option 1',
//   } || undefined,
//   setSelectedOption: () => {},

//   optionList: [
//     { id: 1, name: 'option1' },
//     { id: 2, name: 'option2' },
//     { id: 3, name: 'option3' },
//     { id: 4, name: 'option4' },
//   ],
//};

interface Props {
  className?: string;
  label?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  placeholder?: string;
  selectedOption?: {
    id: number;
    name: string;
  };
  setSelectedOption: (option: any) => void;
  optionList: { id: number; name: string }[];
  deletable?: boolean;
}

const Select = ({
  className,
  label,
  isOpen,
  placeholder,
  selectedOption,
  setIsOpen,
  setSelectedOption,
  optionList,
  deletable,
}: Props) => {
  const toggle = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onOptionClick = (option: { id: number; name: string }) => () => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const onRemoveClick = (e: MouseEvent<HTMLDivElement>) => {
    setSelectedOption(undefined);
    setIsOpen(false);
  };

  const closeSelect = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', closeSelect);
    } else {
      document.removeEventListener('click', closeSelect);
    }

    return () => document.removeEventListener('click', closeSelect);
    // -> closeSelect is not a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className={label ? 'custom-select-with-label' : ''}>
      <div className={`custom-select ${className ? className : ''}`}>
        {label && <span className={'custom-select-label'}>{label}</span>}
        <div
          className={`select-selected ${isOpen ? 'select-arrow-active' : ''}`}
          data-chosen={selectedOption ? true : undefined}
          onClick={toggle}>
          <div style={{ overflow: 'hidden' }}>
            {selectedOption?.name || placeholder || 'Select an option ...'}
          </div>
          {deletable && selectedOption && (
            <div className="select-delete" onClick={onRemoveClick}></div>
          )}
        </div>

        {isOpen && (
          <ul className="select-items">
            {optionList.map(option => (
              <li
                className={option.id === selectedOption?.id ? 'same-as-selected' : ''}
                onClick={onOptionClick(option)}
                key={option.id}>
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select;
