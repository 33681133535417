import { useEffect, useState } from 'react';

import Modal from '../modal';
import { Button } from '../../button';

import { ModalType } from '../../../constants/ui';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getCampaignNote, updateCampaignNote } from '../../../api/campaign';
import { selectNoteValue } from '../../../store/note/reducer';

import { noteSlice } from '../../../store/note/reducer';
import { ICampaign } from '../../../models/campaign';

interface Props {
  setOpened: (value: boolean) => void;
  campaign: ICampaign;
}

type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement>;

const AddNoteToCampaign = ({ setOpened, campaign }: Props) => {
  const dispatch = useAppDispatch();
  const note = useAppSelector(selectNoteValue);

  const [keepOpen, setKeepOpen] = useState(true);

  const { setNote } = noteSlice.actions;

  useEffect(() => {
    dispatch(getCampaignNote(campaign));
  }, []);

  const handleNoteChange = (event: ChangeEvent) => {
    dispatch(setNote(event.target.value));
  };

  const handleSave = () => {
    dispatch(updateCampaignNote(campaign, note));

    setKeepOpen(false);
  };

  return (
    <Modal type={ModalType.POPUP} setOpened={setOpened} keepOpen={keepOpen}>
      <h3 className="modal__title">Add note for {campaign.title}</h3>
      <div className="modal__field-wrapper">
        <div className="form__icon-wrapper">
          <textarea
            className="form__textarea"
            placeholder="Note"
            value={note}
            onChange={handleNoteChange}
          />
        </div>

        <Button primary long onClick={handleSave}>
          Save Note
        </Button>
      </div>
    </Modal>
  );
};

export default AddNoteToCampaign;
