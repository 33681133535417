import { INetwork, IServerNetwork } from '../models/network';

export const adoptNetworkToClient = ({
  id,
  title,
  short_title,
  network_url,
  status,
  connection_status,
}: IServerNetwork): INetwork => ({
  id,
  title,
  short_title,
  network_url,
  status: !!status,
  connection_status,
});

export const adoptNetworkListToClient = (data: { data: IServerNetwork[] }): INetwork[] => {
  const networks = data.data || [];

  return networks.map(adoptNetworkToClient);
};
