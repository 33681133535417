import Modal from '../modal';

import { BASE_URL, ModalType } from '../../../constants/ui';
import { ICampaign } from '../../../models/campaign';
import { formatCurrencyFromValue, formatStringFromDate } from '../../../utils/format';

interface Props {
  setOpened: (value: boolean) => void;
  campaign: ICampaign;
}

const EditCampaign = ({ setOpened, campaign }: Props) => {
  return (
    <Modal type={ModalType.SIDE} setOpened={setOpened}>
      <h3 className="modal__title">Campaign Details</h3>

      <h4 className="modal__property-title">Name of the Campaign:</h4>
      <p className="modal__property-description">{campaign.title}</p>
      <h4 className="modal__property-title modal__property-title--calendar">
        Campaign Start Date:
      </h4>
      <p className="modal__property-description">
        {formatStringFromDate(new Date(campaign.startDate))}
      </p>
      <h4 className="modal__property-title modal__property-title--calendar">Campaign End Date:</h4>
      <p className="modal__property-description">
        {formatStringFromDate(new Date(campaign.endDate))}
      </p>
      <h4 className="modal__property-title">Campaign Details:</h4>
      <p className="modal__property-description">{campaign.details}</p>
      <h4 className="modal__property-title">Campaign Goals:</h4>
      <p className="modal__property-description">{campaign.goals}</p>
      <h4 className="modal__property-title">Campaign Category:</h4>
      <p className="modal__property-description">{campaign.categoryName}</p>
      <h4 className="modal__property-title">Campaign Budget:</h4>
      <p className="modal__property-description">{formatCurrencyFromValue(campaign.budget)}</p>
      <h4 className="modal__property-title">Influencers Instructions:</h4>
      <p className="modal__property-description">{campaign.instructions}</p>
      <h4 className="modal__property-title">Campaign hashtag:</h4>
      <p className="modal__property-description">#InfluencerUnion{campaign.campaignHashtag}</p>
      <h4 className="modal__property-title">Hashtags:</h4>
      <p className="modal__property-description">{campaign.hashtags}</p>
      <h4 className="modal__property-title">Campaign image:</h4>
      <img
        className="modal__property-image"
        src={BASE_URL + campaign.image}
        width="200"
        height="150"
        alt="Represent campaign"
      />
    </Modal>
  );
};

export default EditCampaign;
