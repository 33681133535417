interface ContentWrapperProps {
  children: React.ReactNode;
  className?: string;
}

const ContentWrapper = ({ children, className = '' }: ContentWrapperProps) => {
  return (
    <section className="tab">
      <section className={`tab__wrapper ${className}`}>{children}</section>
    </section>
  );
};

export default ContentWrapper;
