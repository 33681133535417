import MyListService from '../service/my-lists-service';

import { AppDispatch } from '../store/store';
import { uiSlice } from '../store/ui/reducer';
import { myListSlice } from '../store/my-list/reducer';

import { log } from '../helpers/log';
import { adoptMyListsListToClient, adoptMyListToClient } from '../adapters/my-list-adapter';
import { IMyList } from '../models/my-list';
import { IInfluencer } from '../models/influencer';
import {
  adoptInfluencerListToClient,
  adoptInfluencerToClient,
} from '../adapters/influencer-adapter';

export const getMyListsList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setMyListsList } = myListSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await MyListService.getMyListsList();

    console.log(response.data);
    dispatch(setMyListsList(adoptMyListsListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getMyList = (id: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setMyListsList } = myListSlice.actions;
  dispatch(setIsLoading(true));

  try {
    const response = await MyListService.getMyListsList();
    console.log(response.data);
    dispatch(setMyListsList(adoptMyListsListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getInfluencerListFromMyList = (listID: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setMyList } = myListSlice.actions;
  dispatch(setIsLoading(true));

  try {
    const response = await MyListService.getInfluencerListFromMyList(listID);
    console.log(response.data);
    dispatch(setMyList(adoptInfluencerListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const addMyList = (title: string) => async (dispatch: AppDispatch) => {
  const { setIsLoading, addToMyList } = myListSlice.actions;
  dispatch(setIsLoading(true));

  try {
    const response = await MyListService.addMyList(title);
    console.log(response.data);
    dispatch(addToMyList(adoptMyListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const removeMyList = (myList: IMyList) => async (dispatch: AppDispatch) => {
  const { setIsLoading, removeMyList } = myListSlice.actions;
  dispatch(setIsLoading(true));

  try {
    const response = await MyListService.removeMyList(myList.id);
    console.log(response.data);
    dispatch(removeMyList(myList));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addInfluencerToMyList =
  (influencer: IInfluencer, list: IMyList | number) => async (dispatch: AppDispatch) => {
    const { addNotification } = uiSlice.actions;
    const { setIsLoading, addInfluencerToMyList: actionAddInfluencerToMyList } =
      myListSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await MyListService.addInfluencerToMyList(
        influencer.id,
        typeof list === 'number' ? list : list.id,
      );

      dispatch(actionAddInfluencerToMyList(adoptInfluencerToClient(response.data)));
      dispatch(
        addNotification({
          type: 'success',
          message: `'${influencer.name}' successfully added to '${
            typeof list === 'number' ? `#${list}` : list.title
          }' list`,
        }),
      );
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: err?.response?.data?.error || 'something weird happened',
        }),
      );

      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const removeInfluencerFromMyList =
  (influencer: IInfluencer, list: IMyList) => async (dispatch: AppDispatch) => {
    const { setIsLoading, removeInfluencerFromMyList } = myListSlice.actions;
    const { addNotification } = uiSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await MyListService.removeInfluencerFromMyList(influencer.id, list.id);
      console.log(response.data);
      dispatch(removeInfluencerFromMyList(influencer));
    } catch (err: any) {
      dispatch(
        addNotification({
          type: 'error',
          message: err?.response?.data?.error || 'something weird happened',
        }),
      );
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
