import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IUser } from '../../models/user-model';

interface AuthState {
  isAuthenticated: boolean;
  user: IUser;
  sendEmailVerification: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  isAuthenticated: false,
  user: {} as IUser,
  sendEmailVerification: false,
  error: '',
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state: AuthState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setAvatarPath: (state: AuthState, action: PayloadAction<string>) => {
      state.user.avatar_url = action.payload;
    },
    setUserName: (state: AuthState, action: PayloadAction<string>) => {
      state.user.first_name = action.payload;
    },
    setUserLastName: (state: AuthState, action: PayloadAction<string>) => {
      state.user.last_name = action.payload;
    },
    setUserCompanyName: (state: AuthState, action: PayloadAction<string>) => {
      state.user.company_name = action.payload;
    },
    setUserPhoneName: (state: AuthState, action: PayloadAction<string>) => {
      state.user.phone = action.payload;
    },
    setUserAvatarUrl: (state: AuthState, action: PayloadAction<string>) => {
      state.user.avatar_url = action.payload;
    },
    setUserWebsite: (state: AuthState, action: PayloadAction<string>) => {
      state.user.website = action.payload;
    },
    setUserEmail: (state: AuthState, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    sendEmailVerification: (state: AuthState, action: PayloadAction<boolean>) => {
      state.sendEmailVerification = action.payload;
    },
    setIsLoading: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default authSlice.reducer;

export const getIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const getIsWaitingVerification = (state: RootState) => !state.auth.user.profile_flag;
export const getUser = (state: RootState) => state.auth.user;
export const getUserId = (state: RootState) => state.auth.user.id || 0;
export const getUserName = (state: RootState) => state.auth.user.first_name || '-=noname=-';
export const getUserLastName = (state: RootState) => state.auth.user.last_name || '';
export const getUserAvatarUrl = (state: RootState) => state.auth.user.avatar_url || '';
export const getUserCompanyName = (state: RootState) => state.auth.user.company_name || '';
export const getUserPhoneName = (state: RootState) => state.auth.user.phone || '';
export const getUserWebsite = (state: RootState) => state.auth.user.website || '';
export const getUserEmail = (state: RootState) => state.auth.user.email || '';
export const getSendEmailVerification = (state: RootState) => state.auth.sendEmailVerification;
export const getEmailVerified = (state: RootState) => Boolean(state.auth.user.email_verified_at);
export const getProfileFilled = (state: RootState) => Boolean(state.auth.user.profile_flag);
export const getIsLoading = (state: RootState) => state.auth.isLoading;
