import { useState, useEffect } from 'react';
import AccountInfo from '../../components/account-info/account-info';
import Info from './profile-info';
import { SaveCancelButtons } from '../../components/button';

import { getUserInfo, updateAvatar, updateUserInfo } from '../../api/user';
import { getUser } from '../../store/auth/reducer';

import { useAppDispatch, useAppSelector } from '../../hooks/store';

interface Props {
  isInfluencer: boolean;
}

const ProfileAccountInfo = ({ isInfluencer }: Props) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUser);

  const [avatarPath, setAvatarPath] = useState<string>('');

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [website, setWebsite] = useState<string>('');

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  useEffect(() => {
    setFirstName(userInfo.first_name);
    setLastName(userInfo.last_name);
    setCompanyName(userInfo.company_name);
    setPhone(userInfo.phone);
    setWebsite(userInfo.website);
  }, [userInfo]);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (avatarPath) {
      dispatch(updateAvatar(avatarPath));
    }
    dispatch(
      updateUserInfo({
        ...userInfo,
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        phone,
        website,
      }),
    );
  };
  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(getUserInfo());
    setAvatarPath('');
  };

  return (
    <>
      <h2 className="tab__title">
        Account Profile
        <span className="tab__description">Account creation Date 22/02/20</span>
      </h2>

      <div className="info-block">
        <form className="profile-page__form form" onReset={handleReset} onSubmit={handleSubmit}>
          <AccountInfo
            isInfluencer={isInfluencer}
            avatarPath={avatarPath}
            setAvatarPath={setAvatarPath}
            userInfo={{
              avatarUrl: userInfo.avatar_url,
              firstName,
              lastName,
              companyName,
              phone,
              website,
              email: userInfo.email,
            }}
            userInfoSetter={{
              setFirstName,
              setLastName,
              setCompanyName,
              setPhone,
              setWebsite,
            }}
          />

          <SaveCancelButtons />
        </form>
      </div>

      <Info left="Edit info" right="Edit Profile" />
    </>
  );
};

export default ProfileAccountInfo;
