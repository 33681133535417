import { useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import { Input } from '../form-field';

import { BASE_URL } from '../../constants/ui';

interface Props {
  isInfluencer: boolean;
  avatarPath: string;
  setAvatarPath: (value: string) => void;
  userInfo: {
    avatarUrl: string;
    firstName: string;
    lastName: string;
    companyName: string;
    phone: string;
    website: string;
    email: string;
  };
  userInfoSetter: {
    setFirstName: (value: string) => void;
    setLastName: (value: string) => void;
    setCompanyName: (value: string) => void;
    setPhone: (value: string) => void;
    setWebsite: (value: string) => void;
  };
}

const AccountInfo = (props: Props) => {
  const {
    isInfluencer,
    avatarPath,
    setAvatarPath,
    userInfo: { avatarUrl, firstName, lastName, companyName, phone, website, email },
    userInfoSetter: { setFirstName, setLastName, setCompanyName, setPhone, setWebsite },
  } = props;

  const avatarRef = useRef(null);

  const fileSelectHandler = (evt: any) => {
    const param = evt.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const img = avatarRef.current! as HTMLImageElement;
        img.src = reader.result as string;
      },
      false,
    );

    setAvatarPath(param);
    reader.readAsDataURL(param);
  };

  useEffect(() => {
    if (!avatarPath) {
      const img = avatarRef.current! as HTMLImageElement;
      img.src = BASE_URL + avatarUrl;
    }
  }, [avatarUrl, avatarPath]);

  return (
    <>
      <div className="avatar avatar--modal profile-page__form-avatar">
        <img
          className="avatar__image"
          src={BASE_URL + avatarUrl}
          width="75"
          height="75"
          alt="avatar"
          ref={avatarRef}
        />

        <label className="avatar__button">
          <input
            className="visually-hidden"
            type="file"
            name="avatar"
            accept="image/png, image/jpeg"
            onChange={fileSelectHandler}
          />
        </label>
      </div>
      {!isInfluencer && (
        <span
          style={{ position: 'absolute', top: '50px', right: '20px', width: 'calc(50% - 75px)' }}>
          Adding a company logo is recommended as it will be use in all the search results.
        </span>
      )}
      <Input
        type="text"
        placeholder="Ada"
        label="First Name *"
        required
        value={firstName}
        onChange={setFirstName}
      />
      <Input
        type="text"
        placeholder="Lovelace"
        label="Last Name *"
        required
        value={lastName}
        onChange={setLastName}
      />
      <Input
        type="text"
        placeholder="Enter your company name"
        label={`Company name ${!isInfluencer ? '*' : ''}`}
        required={!isInfluencer ? true : false}
        value={companyName}
        onChange={setCompanyName}
      />
      <label>
        <span className="form__label">Phone</span>
      </label>
      <div className="login-form__flex-field login-form__flex-field--sign-up">
        <PhoneInput
          country={'us'}
          value={phone}
          onChange={setPhone}
          buttonClass="form__flag"
          inputClass="form__phone-number"
          dropdownClass="form__country-container"
        />
      </div>
      <Input
        type="text"
        placeholder="http://"
        label="Website"
        value={website}
        onChange={setWebsite}
      />
      <p
        style={{
          fontSize: '12px',
          lineHeight: '14px',
          color: '#4C505F',
        }}>
        Email
      </p>
      <p style={{ fontSize: '12px', lineHeight: '14px', color: '#C4C4C4' }}>{email}</p>
    </>
  );
};

export default AccountInfo;
