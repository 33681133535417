import { Link } from 'react-router-dom';
import { IPaymentPlan } from '../../models/payment-plan';

import { AppRoute } from '../../router/routes';

interface Props {
  paymentPlanList: IPaymentPlan[];
  currentPaymentPlan: number;
}

const BillingCurrentPlanSection = ({ paymentPlanList, currentPaymentPlan }: Props) => {
  if (!currentPaymentPlan) return null;

  const paymentPlan = paymentPlanList.find(item => item.id === currentPaymentPlan);

  return (
    <div className="account-section">
      <h3 className="account-section__title">Current plan</h3>
      <ul className="payment-plan payment-plan__list">
        <li>
          <input
            className="visually-hidden"
            type="radio"
            id="payment-plan-basic"
            value={paymentPlan?.name || ''}
            defaultChecked
          />
          <label
            className="payment-plan__label payment-plan__label--one-row"
            htmlFor="payment-plan-basic">
            {paymentPlan?.name || ''}
            <span className="payment-plan__value">${paymentPlan?.cost || 0}</span>
            <span className="payment-plan__currency">USD/month</span>
            <Link className="button button--red" to={AppRoute.PAYMENT_PLANS}>
              View plans
            </Link>
          </label>
        </li>
      </ul>
    </div>
  );
};

export default BillingCurrentPlanSection;
