import { TD, TR } from '../table';
import { Text } from '../text';
import { Indicator } from '../indicator';
import { ThreeDots } from '../button';

import { ICampaign } from '../../models/campaign';
import { CampaignStatus } from '../../constants/campaign';
import { formatCurrencyFromValue, formatStringFromDate } from '../../utils/format';
import cls from './styles.module.scss';

interface Props {
  campaign: ICampaign;
  isInfluencer: boolean;
  onClick: (campaign: ICampaign) => void;
  onEdit: (campaign: ICampaign) => void;
  onCopy: (campaign: ICampaign) => void;
  onArchive: (campaign: ICampaign) => void;
  onMore: (campaign: ICampaign) => void;
}

const CampaignRow = ({
  campaign,
  isInfluencer,
  onClick,
  onEdit,
  onCopy,
  onArchive,
  onMore,
}: Props) => {
  const clickHandler = () => onClick(campaign);
  const editHandler = () => onEdit(campaign);
  const copyHandler = () => onCopy(campaign);
  const archiveHandler = () => onArchive(campaign);
  const moreHandler = () => onMore(campaign);

  const isClosed = campaign.status === CampaignStatus.CLOSED;
  return isInfluencer ? (
    <TR>
      <TD minimize onClick={clickHandler}>
        <Indicator status={campaign.status} />
      </TD>
      <TD onClick={clickHandler}>{campaign.title}</TD>
      <TD>
        Start Date:{' '}
        <Text gray red={isClosed}>
          {formatStringFromDate(new Date(campaign.startDate))}
        </Text>
      </TD>
      <TD>
        Post & Likes:{' '}
        <Text gray red={isClosed}>
          {campaign.posts}
        </Text>
      </TD>
      <TD>
        AVG Engagement:{' '}
        <Text gray red={isClosed}>
          {campaign.avgEngagement}
        </Text>
      </TD>
      <TD>
        {campaign.budget ? `Fund: ` : ''}
        <Text gray red={isClosed}>
          {campaign.budget ? `${formatCurrencyFromValue(campaign.budget)}` : ''}
        </Text>
      </TD>
      <TD minimize>
        <ThreeDots border onClick={moreHandler} />
      </TD>
    </TR>
  ) : (
    <TR className={cls.showButtonsByHover}>
      <TD minimize onClick={clickHandler}>
        <Indicator status={campaign.status} />
      </TD>
      <TD onClick={clickHandler}>{campaign.title}</TD>
      <TD>
        Start Date: <Text gray>{formatStringFromDate(new Date(campaign.startDate))}</Text>
      </TD>
      <TD>
        {campaign.budget ? `Fund: ` : ''}
        <Text gray>{campaign.budget ? `${formatCurrencyFromValue(campaign.budget)}` : ''}</Text>
      </TD>
      <TD>
        <Text gray>#InfluencerUnion{campaign.campaignHashtag}</Text>
      </TD>
      <TD>
        <Text gray>
          {campaign.influencers
            ? `${campaign.influencers} influencer${campaign.influencers === 1 ? '' : 's'}`
            : ``}
        </Text>
      </TD>
      <TD className={cls.hiddenButtons}>
        <button className="campaign__edit" onClick={editHandler} />
        <button className="campaign__duplicate" onClick={copyHandler} />
        <button className="campaign__archive" onClick={archiveHandler} />
      </TD>
      <TD minimize>
        <ThreeDots border onClick={moreHandler} />
      </TD>
    </TR>
  );
};

export default CampaignRow;
