import { ICategory } from '../../models/category';
import Category from './category';

interface Props {
  categoryList: ICategory[];
  setCategoryList: (categoryList: ICategory[]) => void;
}

const CategoryList = ({ categoryList, setCategoryList }: Props) => {
  const setCategory = (category: ICategory, status: boolean) => {
    const newCategoryList = categoryList.map(item => {
      if (item.id === category.id) {
        return { ...item, status };
      }
      return item;
    });
    setCategoryList(newCategoryList);
  };

  return (
    <ul className="categories categories__list">
      {categoryList.map(category => (
        <Category key={category.id} category={category} setCategory={setCategory} />
      ))}
    </ul>
  );
};

export default CategoryList;
