import { Chart, GoogleChartOptions } from 'react-google-charts';

const data = [
  ['', 'percentage', { role: 'style' }, { role: 'annotation' }],
  ['Female', 84, '#4688F1', '84%'],
  ['Male', 16, '#E7453C', '16%'],
  ['Other', 3, '#F9BB2D', '3%'],
];

const options: GoogleChartOptions = {
  chart: {
    title: undefined,
    subtitle: undefined,
  },
  backgroundColor: 'transparent',
  legend: { position: 'left' },
  width: 300,
  height: 200,
  chartArea: { width: '100%', height: '100%' },
  pieHole: 0.5,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  hAxis: {
    textPosition: 'none',
  },
};

const GenderChart = () => {
  return <Chart chartType="PieChart" width="100%" data={data} options={options} />;
};

export { GenderChart };
