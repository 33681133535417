import { useState } from 'react';
import { Button } from '../../components/button';
import ContractService from '../../service/contract-service';
import Modal from '../../components/modals/modal';
import { ModalType } from '../../constants/ui';
import { ReactComponent as AddIcon } from '../../image/add.svg';
import TopPanel from '../../components/top-panel';

interface IContractCreateProps {
  influencerId: number;
  advertiserId: number;
  campaignId: number;
}

const ContractCreate = ({
  influencerId,
  advertiserId,
  campaignId,
}: IContractCreateProps) => {
  const [isChooseCreateContractModalOpen, setChooseCreateContractModalOpen] =
    useState(false);

  const [budget, setBudget] = useState<number>(0);
  const [noOfPosts, setNoOfPosts] = useState<number>(0);

  const createContractFn = async () => {
    try {
      const data = {
        influencer_id: influencerId,
        advertiser_id: advertiserId,
        campaign_id: campaignId,
        budget: budget,
        posts: noOfPosts,
      };
      await ContractService.createContract(data);
      setChooseCreateContractModalOpen(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleBudgetChange = (event: any) => {
    setBudget(event.target.value);
  };

  const handleNoOdfPostsChange = (event: any) => {
    setNoOfPosts(event.target.value);
  };

  return (
    <>
      <Button
        moveRight
        onClick={() => {
          setChooseCreateContractModalOpen(true);
        }}
      >
        <AddIcon />
        <span style={{ textOverflow: 'ellipsis' }}>Create New Contract</span>
      </Button>

      {isChooseCreateContractModalOpen && (
        <Modal
          type={ModalType.POPUP}
          setOpened={setChooseCreateContractModalOpen}
        >
          <h3 className="modal__title">Create Contract</h3>
          <div className="modal__field-wrapper">
            <div className="form__icon-wrapper">
              <input
                className="form__input"
                name="Name"
                type="text"
                value={'Kamyar Rostami'}
                readOnly={true}
              />
            </div>
          </div>
          <div className="modal__field-wrapper">
            <div className="form__icon-wrapper">
              <input
                className="form__input"
                name="Name"
                type="text"
                value={'Test Campaign'}
              />
            </div>
          </div>
          <div className="modal__field-wrapper">
            <div className="form__icon-wrapper">
              <input
                className="form__input"
                name="Budget"
                type="number"
                min={0}
                placeholder="Budget"
                value={budget}
                onChange={handleBudgetChange}
              />
            </div>
          </div>
          <div className="modal__field-wrapper">
            <div className="form__icon-wrapper">
              <input
                className="form__input"
                name="noOfPosts"
                type="number"
                min={0}
                placeholder="No of Posts"
                value={noOfPosts}
                onChange={handleNoOdfPostsChange}
              />
            </div>
          </div>
          <br></br>
          <Button primary onClick={createContractFn}>
            Submit
          </Button>
        </Modal>
      )}
    </>
  );
};

export default ContractCreate;
