import api from '../http';
import { AxiosResponse } from 'axios';

export default class CampaignService {
  static async getPresentationList(): Promise<AxiosResponse> {
    console.log('getPresentationList:', `/advertiser-media-kit`);
    return api.get(`/advertiser-media-kit`);
  }
  static async getPresentationDetails(id: number): Promise<AxiosResponse> {
    console.log('getPresentationDetails:', `/advertiser-media-kit?id=${id}`);
    return api.get(`/media-kit-details?id=${id}`);
  }
  static async createPresentation(title: string): Promise<AxiosResponse> {
    console.log('getPresentationDetails:', `/advertiser-media-kit, { title: ${title} }`);
    return api.post(`/advertiser-media-kit`, { title });
  }
  static async addToPresentation(id: number, params: string): Promise<AxiosResponse> {
    console.log('addToPresentation:', `/advertiser-media-kit?id=${id}&${params}`);
    return api.put(`/advertiser-media-kit?kit_id=${id}&${params}`);
  }
  static async deleteFromPresentation(detailId: number): Promise<AxiosResponse> {
    console.log('addToPresentation:', `/advertiser-media-kit?id=${detailId}`);
    return api.delete(`/media-kit-details?id=${detailId}`);
  }
}
