import { useState, useEffect, useRef, SyntheticEvent } from 'react';

import { ModalType, MODAL_ANIMATION_DURATION } from '../../../constants/ui';

interface ModalProps {
  type: ModalType.POPUP | ModalType.SIDE;
  wide?: boolean;
  children: React.ReactNode;
  setOpened: (opened: boolean) => void;
  keepOpen?: boolean;
  noOutsideClick?: boolean;
  noEscape?: boolean;
  noOverlay?: boolean;
  overflowable?: boolean;
}

const Modal = ({
  type,
  wide,
  children,
  setOpened,
  keepOpen = true,
  noOutsideClick = false,
  noEscape = false,
  noOverlay = false,
  overflowable = false,
}: ModalProps) => {
  const [isOpenAnimation, setOpenAnimation] = useState(false);
  const [isCloseAnimation, setCloseAnimation] = useState(false);
  const [isNoAnimate, setNoAnimate] = useState(false);
  const [overlayMouseDown, setOverlayMouseDown] = useState(false);

  const modalRef = useRef(null);

  useEffect(() => {
    show();
    document.addEventListener('keydown', onKeyDown);
    document.body.style.overflowY = 'hidden';

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.body.style.overflowY = 'auto';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!keepOpen) {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepOpen]);

  const show = () => {
    setOpenAnimation(true);

    setTimeout(() => {
      setNoAnimate(true);
      setOpenAnimation(false);
    }, MODAL_ANIMATION_DURATION);
  };

  const hide = () => {
    setCloseAnimation(true);
    setNoAnimate(false);

    setTimeout(() => {
      setCloseAnimation(false);
      setOpened(false);
    }, MODAL_ANIMATION_DURATION);
  };

  const onCloseClick = () => {
    hide();
  };

  const onOverlayMouseDown = (evt: SyntheticEvent<HTMLDivElement>) => {
    setOverlayMouseDown(true);
  };

  const onPopupMouseDown = (evt: SyntheticEvent<HTMLDivElement>) => {
    evt.stopPropagation();
  };

  const onOutsideClick = (evt: SyntheticEvent<HTMLDivElement>) => {
    if (noOutsideClick) return;
    if (!overlayMouseDown) return;

    if (evt.target === modalRef.current) {
      hide();
    }
  };

  const onKeyDown = (evt: KeyboardEvent) => {
    if (noEscape) return;

    if (evt.key === 'Escape') {
      hide();
    }
  };

  const getModalClassName = (noOverlay: boolean) => {
    const className = ['modal'];

    if (noOverlay) className.push('modal--no-overlay');
    if (wide) className.push('modal--wide');
    if (isOpenAnimation) className.push('modal--animate-in');
    if (isCloseAnimation) className.push('modal--animate-out');
    if (isNoAnimate) className.push('modal--opened');

    return className.join(' ');
  };

  return (
    <div
      className={getModalClassName(noOverlay)}
      onClick={onOutsideClick}
      onMouseDown={onOverlayMouseDown}
      ref={modalRef}>
      <div
        className={
          type === ModalType.POPUP
            ? `modal__popup-card ${overflowable ? 'modal__popup-card--overflow' : ''}`
            : 'modal__popup'
        }
        onMouseDown={onPopupMouseDown}>
        <div className="modal__header">
          <button className="modal__close-button" type="button" onClick={onCloseClick}></button>
        </div>

        {children}
      </div>
    </div>
  );
};

export default Modal;
