import { Campaign as CampaignType } from '../../store/message/reducer';

import { BASE_URL } from '../../constants/ui';
import cls from './message.module.scss';
import { NewMessagesBubble } from './new-messages-bubble';

const AvatarImage = ({ relativePath, alt }: { relativePath: string; alt: string }) => {
  return (
    <img className="avatar__image" src={BASE_URL + relativePath} width="50" height="50" alt={alt} />
  );
};

export const Campaign = ({
  campaign,
  onClick,
}: {
  campaign: CampaignType | undefined;
  onClick: (campaign: CampaignType | undefined) => void;
}) => {
  const handleClick = () => onClick(campaign);
  return campaign ? (
    <div className={cls.campaign} onClick={handleClick}>
      <NewMessagesBubble className={cls.leftSide} count={campaign.unread} />
      <AvatarImage relativePath={campaign?.image || ''} alt="Represent campaign" />
      <div>{campaign.title}</div>
    </div>
  ) : (
    <div className={cls.campaign} onClick={handleClick}>
      Choose campaign...
    </div>
  );
};
