import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  label?: boolean;
  gray?: boolean;
  red?: boolean;
}

export const Text = ({ className, children, label, gray, red }: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.gray]: gray,
    [cls.red]: red,
    [cls.label]: label,
  };

  return <span className={classNames([cls.text, className], mods)}>{children}</span>;
};
