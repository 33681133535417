import RateCardGroup from './rate-card-group';

import { INetwork } from '../../models/network';
import { IRateCard } from '../../models/rate-card';
import { useAppDispatch } from '../../hooks/store';
import { rateCardsSlice } from '../../store/rate-cards/reducer';

interface Props {
  rateCardList: IRateCard[];
  networkList: INetwork[];
  setRateCardList?: (rateCardList: IRateCard[]) => void;
}

const RateCardList = ({ rateCardList, networkList }: Props) => {
  const { setRateCardList } = rateCardsSlice.actions;
  const dispatch = useAppDispatch();

  const groupRateCardList = (rateCardList: IRateCard[]) =>
    rateCardList.reduce((acc, rateCard) => {
      if (acc[String(rateCard.network_id)]) {
        acc[String(rateCard.network_id)].push(rateCard);
      } else {
        acc[String(rateCard.network_id)] = [rateCard];
      }
      return acc;
    }, {} as { [key: string]: IRateCard[] });

  const filterRateCardList = (rateCardList: IRateCard[], networkIDList: number[]) =>
    rateCardList.filter(rateCard => networkIDList.includes(rateCard.network_id));

  const getActiveNetworkIDkList = (networkList: INetwork[]) => {
    return networkList.reduce(
      (acc: number[], network: INetwork) => (network.status ? [...acc, network.id] : acc),
      [],
    );
  };

  const groupedFilteredRateCardList = groupRateCardList(
    filterRateCardList(rateCardList, getActiveNetworkIDkList(networkList)),
  );

  const setRateCardValue = (rateCard: IRateCard, value: string) => {
    const newRateCardList = rateCardList.map(item => {
      if (item.id === rateCard.id) {
        return { ...item, user_rate: Number(value) || 0 };
      }
      return item;
    });

    dispatch(setRateCardList(newRateCardList));
  };

  return (
    <ul className="form__field-list">
      {Object.entries(groupedFilteredRateCardList).map(([networkID, rateCardList]) => (
        <RateCardGroup
          key={networkID}
          rateCardList={rateCardList}
          setRateCardValue={setRateCardValue}
        />
      ))}
    </ul>
  );
};

export default RateCardList;
