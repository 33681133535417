import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '../../../components/page';
import TopPanel from '../../../components/top-panel';
import SearchForm from '../../../components/search-form';
import ContentWrapper from '../../../components/content-wrapper';
import { Button } from '../../../components/button/button';
import View from '../../../components/modals/view';
import InfluencerRow from '../../../components/influencer-row/influencer-row';
import AddNoteToInfluencer from '../../../components/modals/add-note-to-influencer/';
import Filter from '../../../components/modals/filter/filter';
import AddToList from '../../../components/modals/add-to-list';

import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { selectInfluencerList } from '../../../store/influencer/reducer';
import { selectSearchString, selectViewOptions } from '../../../store/ui/reducer';
import { AppRoute } from '../../../router/routes';

import {
  getInfluencerList,
  getAdvertiserBookmarkList,
  deleteInfluencerBookmark,
} from '../../../api/influencer';

import { IInfluencer } from '../../../models/influencer';
import { ViewOptions } from '../../../models/view-options';

const Advertiser = () => {
  const dispatch = useAppDispatch();
  const influencerList = useAppSelector(selectInfluencerList);
  const searchString = useAppSelector(selectSearchString);
  const options = useAppSelector<ViewOptions>(selectViewOptions);
  const navigate = useNavigate();

  const [filterModalWindow, setFilterModalWindow] = useState(false);
  const [viewModalWindow, setViewModalWindow] = useState(false);
  const [editNoteModalWindow, setEditNoteModalWindow] = useState(false);
  const [addToListModalWindow, setAddToListModalWindow] = useState(false);

  const [selectedInfluencer, setSelectedInfluencer] = useState<IInfluencer>({} as IInfluencer);

  useEffect(() => {
    dispatch(getAdvertiserBookmarkList());
  }, []);
  useEffect(() => {
    if (!searchString) return;
    dispatch(getInfluencerList(undefined, undefined, searchString));
  }, [dispatch, searchString]);

  const toggleFilterModalWindow = () => setFilterModalWindow(!filterModalWindow);
  const toggleViewModalWindow = () => setViewModalWindow(!viewModalWindow);

  const deleteBookmark = (influencer: IInfluencer) => {
    dispatch(deleteInfluencerBookmark(influencer));
  };

  const searchHandler = (searchString: string) => {
    navigate(AppRoute.SEARCH_RESULT);
  };

  const showAnalyticsPage = (influencer: IInfluencer) => {
    navigate(`${AppRoute.INFLUENCER_ANALYTICS}/${influencer.id}`);
  };
  const showMessagingPage = (influencer: IInfluencer) => {
    navigate(`${AppRoute.MESSAGING}/${influencer.id}`);
  };
  const openEditNote = (influencer: IInfluencer) => {
    setSelectedInfluencer(influencer);
    setEditNoteModalWindow(!editNoteModalWindow);
  };
  const addToList = (influencer: IInfluencer) => {
    setSelectedInfluencer(influencer);
    setAddToListModalWindow(!addToListModalWindow);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="visually-hidden">Buttons/search panel</h2>

        <SearchForm placeholder="Search & find your category" onInput={searchHandler} reset />
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button primary filter onClick={toggleFilterModalWindow}>
            Filter
          </Button>
          <Button secondary view onClick={toggleViewModalWindow}>
            View
          </Button>
        </div>
      </TopPanel>

      <ContentWrapper className="tab__wrapper--campaign">
        <div className="account-section">
          <h3 className="account-section__title section__header--search">{`${influencerList.length} found`}</h3>

          {influencerList && (
            <div className="influencers influencers__wrapper">
              <table className="influencers__table">
                <thead>
                  <tr>
                    {options.profile && <th className="influencers__col-profile">Profile</th>}
                    {options.socialReach && (
                      <th className="influencers__col-social-reach">Social reach</th>
                    )}
                    {options.iuScore && <th className="influencers__col-iu-score">IU Score</th>}
                    {options.rateCard && <th className="influencers__col-rate-card">Rate Card</th>}
                    {options.bio && <th className="influencers__col-bio">Bio</th>}
                    {options.location && <th className="influencers__col-location">Location</th>}
                    {options.languages && <th className="influencers__col-languages">Languages</th>}
                    {options.gender && <th className="influencers__col-gender">Gender</th>}
                    {options.age && <th className="influencers__col-age-range">Age Range</th>}
                    {options.ethnicity && <th className="influencers__col-ethnicity">Ethnicity</th>}
                    {options.hashtags && <th className="influencers__col-hashtags">Hashtags</th>}
                    {options.mentions && <th className="influencers__col-mentions">Mentions</th>}
                    {options.posts && <th className="influencers__col-posts">Number of Posts</th>}
                  </tr>
                </thead>
                <tbody>
                  {influencerList.map(influencer => (
                    <InfluencerRow
                      options={options}
                      key={influencer.id}
                      influencer={influencer}
                      isBookmark
                      onAdd={addToList}
                      onBookmark={deleteBookmark}
                      onMoreClick={showAnalyticsPage}
                      onNoteClick={openEditNote}
                      onMessagingClick={showMessagingPage}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {filterModalWindow && <Filter setOpened={toggleFilterModalWindow} />}
        {viewModalWindow && <View setOpened={toggleViewModalWindow} />}
        {editNoteModalWindow && (
          <AddNoteToInfluencer setOpened={setEditNoteModalWindow} influencer={selectedInfluencer} />
        )}
        {addToListModalWindow && (
          <AddToList influencer={selectedInfluencer} setOpened={setAddToListModalWindow} />
        )}
      </ContentWrapper>
    </Page>
  );
};

export default Advertiser;
