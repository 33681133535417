import { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';

import { useAppDispatch, useAppSelector } from '../../hooks/store';

import { getToken } from '../../utils/user';
import { SOCKET_URL } from '../../constants/socket';
import { Campaign, Contact, Message as IMessage, MessageSlice } from '../../store/message/reducer';
import { MessageContext } from './message-context';
import { getIsAuthenticated } from '../../store/auth/reducer';

interface Props {
  children: React.ReactNode;
}

export const MessageProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [socket, setSocket] = useState<Socket>({} as Socket);
  const { setNewMessages, setCampaignList, setContactList, setMessage, setMessageList } =
    MessageSlice.actions;
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useEffect(() => {
    const socket = io(SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket'],
      upgrade: false,
    });

    socket.on('connect', () => handleConnect);
    socket.on('disconnect', () => handleDisconnect);

    socket.on('status', handleStatus);
    socket.on('new-message', handleNewMessage);
    socket.on('messages', handleMessageList);
    socket.on('campaign-list', handleCampaignList);
    socket.on('contact-list', handleContactList);
    socket.on('new-chatroom', handleNewChatRoom);

    socket.on('connect_error', handleConnectError);
    socket.on('connect_timeout', handleConnectTimeout);
    socket.on('error', handleError);
    socket.on('reconnect', handleReconnect);
    socket.on('reconnect_attempt', handleReconnectAttempt);
    socket.on('reconnecting', handleReconnecting);
    socket.on('reconnect_error', handleReconnectError);
    socket.on('reconnect_failed', handleReconnectFailed);

    setSocket(socket);

    return () => {
      socket.offAny();
      socket.removeAllListeners();
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    const token = getToken();
    socket.io.opts.extraHeaders = { access_token: token };
    socket.auth = { token: token };

    socket.connect();
    socket.emit('status'); // bcs server doesn't send status on connect
  }, [isAuthenticated, socket]);

  const handleConnect = () => {
    console.log('- connected to message server');
  };
  const handleDisconnect = () => {
    console.log('- disconnected from message server');
  };
  const handleStatus = (data: { all: number }) => {
    console.log('- status:', data);
    dispatch(setNewMessages(data.all));
  };
  const handleNewMessage = (data: IMessage) => {
    console.log('- new message:', data);
    dispatch(setMessage(data));
  };
  const handleMessageList = (data: IMessage[]) => {
    console.log('- messages:', data);
    dispatch(setMessageList(data));
  };
  const handleCampaignList = (data: Campaign[]) => {
    console.log('- campaign list:', data);
    dispatch(setCampaignList(data));
  };
  const handleContactList = (data: Contact[]) => {
    console.log('- contact list:', data);
    dispatch(setContactList(data));
  };
  const handleNewChatRoom = (data: any) => {
    console.log('- new chat room:', data);
    //dispatch(setContactList(data));
  };

  const handleConnectError = (error: any) => {
    console.log('- connect_error:', error);
  };
  const handleConnectTimeout = (timeout: any) => {
    console.log('- connect_timeout:', timeout);
  };
  const handleError = (error: any) => {
    console.log('- error:', error);
  };
  const handleReconnect = (attemptNumber: any) => {
    console.log('- reconnect:', attemptNumber);
  };
  const handleReconnectAttempt = (attemptNumber: any) => {
    console.log('- reconnect_attempt:', attemptNumber);
  };
  const handleReconnecting = (attemptNumber: any) => {
    console.log('- reconnecting:', attemptNumber);
  };
  const handleReconnectError = (error: any) => {
    console.log('- reconnect_error:', error);
  };
  const handleReconnectFailed = () => {
    console.log('- reconnect_failed');
  };

  return <MessageContext.Provider value={socket}>{children}</MessageContext.Provider>;
};
