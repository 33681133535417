import { useState, useEffect } from 'react';

import BillingAddressSection from '../../components/billing-address-section';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { selectBillingInfo } from '../../store/billing/reducer';
import { getBillingInfo, updateBillingInfo } from '../../api/billing';

import { IBillingInfo } from '../../models/billing-address';

const BillingAddress = () => {
  const dispatch = useAppDispatch();
  const billingInfo = useAppSelector(selectBillingInfo);

  const [temporaryBillingInfo, setTemporaryBilling] = useState<IBillingInfo>(billingInfo);

  useEffect(() => {
    dispatch(getBillingInfo());
  }, []);
  useEffect(() => {
    setTemporaryBilling(billingInfo);
  }, [billingInfo]);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(updateBillingInfo(temporaryBillingInfo));
  };
  const handleReset = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(getBillingInfo());
  };

  return (
    <form onReset={handleReset} onSubmit={handleSubmit}>
      <BillingAddressSection
        isBillingPage={true}
        billing={temporaryBillingInfo}
        setBilling={setTemporaryBilling}
      />
    </form>
  );
};

export default BillingAddress;
