import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Page from '../../components/page';
import TopPanel from '../../components/top-panel';
import ContentWrapper from '../../components/content-wrapper';
import PaymentPlanList from '../../components/payment-plan-list';
import PaymentPlanFeature from '../../components/payment-plan-feature';
import { Button } from '../../components/button';

import { useAppSelector, useAppDispatch } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';
import { selectPaymentPlanList, selectCurrentPaymentPlan } from '../../store/payment/reducer';

import { AppRoute } from '../../router/routes';
import {
  getCurrentPaymentPlan,
  getPaymentPlanList,
  updateCurrentPaymentPlan,
} from '../../api/payment';
import { IPaymentPlan } from '../../models/payment-plan';

const PaymentPlans = () => {
  const dispatch = useAppDispatch();

  const isInfluencer = useAppSelector(getIsInfluencer);
  const paymentPlanList = useAppSelector<IPaymentPlan[]>(selectPaymentPlanList);
  const currentPaymentPlan = useAppSelector(selectCurrentPaymentPlan);

  const [temporaryPaymentPlan, setTemporaryPaymentPlan] = useState(currentPaymentPlan);

  useEffect(() => {
    if (paymentPlanList.length) return;

    console.log('fetch payment plan list');
    dispatch(getPaymentPlanList());
    dispatch(getCurrentPaymentPlan());
  }, []);

  useEffect(() => {
    setTemporaryPaymentPlan(currentPaymentPlan);
  }, [currentPaymentPlan]);

  const handleUpdateCurrentPaymentPlan = (paymentPlan: number) => {
    dispatch(updateCurrentPaymentPlan(paymentPlan));
  };

  const getCheckedPaymentPlan = () => {
    console.log(paymentPlanList);
    if (!paymentPlanList.length) return undefined;

    return paymentPlanList.find(paymentPlan => paymentPlan.id === temporaryPaymentPlan);
  };

  return (
    <Page>
      <TopPanel>
        <h2 className="top-panel__account-profile">Plan Subscription</h2>
      </TopPanel>

      <ContentWrapper>
        {isInfluencer && <Navigate to={AppRoute.HOME} />}
        <div className="account-info-page__plans-section payment-plan-section payment-plan-section--view">
          <div className="account-section payment-plan">
            <h3 className="account-section__title">Payment Plans</h3>

            <PaymentPlanList
              isPaymentPlanPage={true}
              paymentPlanList={paymentPlanList}
              currentPaymentPlan={temporaryPaymentPlan}
              setCurrentPaymentPlan={setTemporaryPaymentPlan}
              storedPaymentPlan={currentPaymentPlan}
              updateCurrentPaymentPlan={handleUpdateCurrentPaymentPlan}
            />
          </div>

          <div className="payment-plan-section__promo-code">
            <input className="form__input" type="text" placeholder="Promo code" />
            <Button primary onClick={() => {}}>
              Apply
            </Button>
          </div>

          <div className="account-section plan-feature">
            <h3 className="account-section__title">Features</h3>

            <PaymentPlanFeature paymentPlan={getCheckedPaymentPlan()} />
          </div>
        </div>
      </ContentWrapper>
    </Page>
  );
};

export default PaymentPlans;
