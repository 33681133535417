import { Message } from './message';

import { Contact, Message as MessageType } from '../../store/message/reducer';

import { formatChatDate } from '../../utils/format';
import cls from './message.module.scss';

interface Props {
  group: MessageType[];
  userId: number;
  contact?: Contact;
}

export const DayGroup = ({ group, userId, contact }: Props) => {
  return (
    <div className={cls.dayGroup}>
      <div className={cls.date}>{formatChatDate(group[0].created_at)}</div>
      {group.map(message => {
        return (
          <Message
            key={message.id}
            className={userId === message.sender_id ? cls.my : ''}
            userId={userId}
            contact={contact}
            message={message}
          />
        );
      })}
    </div>
  );
};
