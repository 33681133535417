import { PresentationRow } from './row';

import { Table, TBody } from '../../components/table';

import { useAppSelector } from '../../hooks/store';
import { selectStatusFilterList } from '../../store/presentation/reducer';

import { IPresentation } from '../../models/presentation';
import { PresentationStatus } from '../../constants/presentation';

interface Props {
  presentationList: IPresentation[];
  onThreeDotsClick: (presentation: IPresentation) => void;
}

const PresentationTable = ({ presentationList, onThreeDotsClick }: Props) => {
  const statusFilterList = useAppSelector(selectStatusFilterList);

  return (
    <Table>
      <TBody>
        {presentationList
          .filter(presentation => statusFilterList[presentation.status as PresentationStatus])
          .map(presentation => (
            <PresentationRow
              key={presentation.id}
              presentation={presentation}
              onThreeDotsClick={onThreeDotsClick}
            />
          ))}
      </TBody>
    </Table>
  );
};

export { PresentationTable };
