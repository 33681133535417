import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from '../utils/user';
import { BASE_URL } from '../constants/ui';

export const API_URL = BASE_URL + '/api/';

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

api.interceptors.request.use((config: any) => {
  config.headers.authorization = `Bearer ${getToken()}`;
  return config;
});

api.interceptors.response.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._isRetry) {
      try {
        // const response = await axios.get(`${API_URL}refresh`, {
        //  withCredentials: true,
        // });
        // const remember = localStorage.getItem('remember') === 'true' ? true : false;
        // if (remember) localStorage.setItem('token', response.data.accessToken);
        // else sessionStorage.setItem('token', response.data.accessToken);
        // return api.request(originalRequest);
      } catch (err) {
        console.log('User unauthorized');
      }
    }
    // if not 401, do something with error
    throw error;
  },
);

export default api;
