import PresentationService from '../service/presentation-service';

import { AppDispatch } from '../store/store';

import { log } from '../helpers/log';
import { IInfluencer } from '../models/influencer';
import { presentationSlice } from '../store/presentation/reducer';
import {
  adoptPresentationDetailListToClient,
  adoptPresentationListToClient,
  adoptPresentationToClient,
} from '../adapters/presentation-adapter';
import { IPresentation, IPresentationDetail } from '../models/presentation';
import { PresentationSection } from '../constants/presentation';
import { uiSlice } from '../store/ui/reducer';
import { IMyList } from '../models/my-list';

export const getPresentationList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setList } = presentationSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await PresentationService.getPresentationList();

    console.log(response.data);
    dispatch(setList(adoptPresentationListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const createPresentation = (title: string) => async (dispatch: AppDispatch) => {
  const { setIsLoading, addPresentation } = presentationSlice.actions;
  const { addNotification } = uiSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await PresentationService.createPresentation(title);

    console.log(response.data);
    dispatch(addPresentation(adoptPresentationToClient(response.data)));
    dispatch(
      addNotification({
        type: 'success',
        message: `'${title}' container successfully created`,
      }),
    );
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getPresentationDetails = (id: number) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setDetails } = presentationSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await PresentationService.getPresentationDetails(id);

    console.log(response.data);
    dispatch(setDetails(adoptPresentationDetailListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const addToPresentation =
  (
    presentation: IPresentation,
    itemType: PresentationSection,
    list?: IMyList,
    influencer?: IInfluencer,
  ) =>
  async (dispatch: AppDispatch) => {
    const { setIsLoading } = presentationSlice.actions;
    const { addNotification } = uiSlice.actions;

    const params = [];
    list && params.push(`list_id=${list.id}`);
    influencer && params.push(`influencer_id=${influencer.id}`);
    params.push(`item_type=${itemType}`);

    dispatch(setIsLoading(true));

    try {
      const response = await PresentationService.addToPresentation(
        presentation.id,
        params.join('&'),
      );

      console.log(response.data);
      dispatch(
        addNotification({
          type: 'success',
          message: `'${influencer?.name || list?.title}' successfully added to '${
            presentation.title
          }' container`,
        }),
      );
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const deletePresentationDetail =
  (detail: IPresentationDetail) => async (dispatch: AppDispatch) => {
    const { setIsLoading, deleteDetail } = presentationSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await PresentationService.deleteFromPresentation(detail.id);

      console.log(response.data);
      dispatch(deleteDetail(detail));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const addToNewPresentation =
  (title: string, itemType: PresentationSection, list?: IMyList, influencer?: IInfluencer) =>
  async (dispatch: AppDispatch) => {
    const { setIsLoading, addPresentation } = presentationSlice.actions;
    const { addNotification } = uiSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await PresentationService.createPresentation(title);

      console.log(response.data);
      dispatch(
        addNotification({
          type: 'success',
          message: `'${title}' container successfully created`,
        }),
      );

      const adoptedPresentation = adoptPresentationToClient(response.data);

      dispatch(addPresentation(adoptedPresentation));
      dispatch(addToPresentation(adoptedPresentation, itemType, list, influencer));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
