import { classNames } from '../../utils/class-names';

import cls from './message.module.scss';

interface Props {
  className?: string;
  count: number;
}

export const NewMessagesBubble = ({ className, count }: Props) => {
  return count > 0 ? (
    <div className={classNames([cls.newMessagesBubble, className])}>{count}</div>
  ) : null;
};
