import { useEffect } from 'react';

import PaymentPlan from './payment-plan';
import { IPaymentPlan } from '../../models/payment-plan';

interface Props {
  isPaymentPlanPage?: boolean;
  paymentPlanList: IPaymentPlan[];
  currentPaymentPlan: number;
  setCurrentPaymentPlan: (paymentPlan: number) => void;
  storedPaymentPlan?: number;
  updateCurrentPaymentPlan?: (paymentPlan: number) => void;
}

const PaymentPlanList = ({
  isPaymentPlanPage = false,
  paymentPlanList,
  currentPaymentPlan,
  setCurrentPaymentPlan,
  storedPaymentPlan,
  updateCurrentPaymentPlan,
}: Props) => {
  useEffect(() => {
    if (currentPaymentPlan) return;
    if (!paymentPlanList.length) return;

    setCurrentPaymentPlan(paymentPlanList[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentPlanList]);

  return (
    <ul className="payment-plan__list">
      {paymentPlanList.map(paymentPlan => (
        <PaymentPlan
          isPaymentPlanPage={isPaymentPlanPage}
          key={paymentPlan.id}
          paymentPlan={paymentPlan}
          currentPaymentPlan={currentPaymentPlan}
          setCurrentPaymentPlan={setCurrentPaymentPlan}
          storedPaymentPlan={storedPaymentPlan}
          updateCurrentPaymentPlan={updateCurrentPaymentPlan}
        />
      ))}
    </ul>
  );
};

export default PaymentPlanList;
