import { SocialMediaSummary } from '../../../components/widgets/social-media-summary';
import { useAppSelector } from '../../../hooks/store';
import { getUserId } from '../../../store/auth/reducer';

const Influencer = () => {
  const userId = useAppSelector(getUserId);

  return (
    <>
      <section className="dashboard" id="dashboard-section">
        <div className="dashboard__container">
          <div className="dashboard__flex-2-col">
            <div className="row1">
              <div className="dashboard__chart-wrapper">
                <SocialMediaSummary id={userId} />
              </div>

              <div className="dashboard__flex-2-col">
                <div className="dashboard__chart-wrapper row1" id="chart-social-summary">
                  {/* include _dashboard-summary-bagel.pug */}
                </div>

                <div className="dashboard__chart-wrapper row2" id="chart-engagement-rate">
                  {/* include _dashboard-engagement-bagel.pug */}
                </div>
              </div>
            </div>

            <div className="dashboard__chart-wrapper row2" id="chart-popularity-dynamics">
              {/* include _dashboard-popularity-chart.pug */}
            </div>
          </div>
        </div>

        <div className="dashboard__flex-2-col">
          <div className="dashboard__container row3">
            <h3 className="dashboard__header">Age / Gender</h3>

            <div className="dashboard__flex-2-col">
              <div className="dashboard__chart-wrapper row1" id="chart-age">
                {/* include _dashboard-age-bagel.pug */}
              </div>

              <div className="dashboard__chart-wrapper row1" id="chart-gender">
                {/* include _dashboard-gender-bagel.pug */}
              </div>
            </div>
          </div>

          <div className="dashboard__container row3">
            <h3 className="dashboard__header">Topics / Score</h3>

            <div className="dashboard__flex-2-col">
              <div className="dashboard__chart-wrapper row1" id="chart-topics">
                {/* include _dashboard-topics-bar.pug */}
              </div>

              <div className="dashboard__chart-wrapper row1" id="chart-score">
                {/* include _dashboard-ui-score-bagel.pug */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Influencer;
