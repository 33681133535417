import { INetwork } from '../../models/network';

import Network from './network';

interface Props {
  isProfilePage?: boolean;
  networkList: INetwork[];
  setNetworkList: (networkList: INetwork[]) => void;
}

const NetworkList = ({ isProfilePage, networkList, setNetworkList }: Props) => {
  const setNetworkStatus = (network: INetwork, status: boolean) => {
    const newNetworkList = networkList.map(item => {
      if (item.id === network.id) {
        return { ...item, status: !item.status };
      }
      return item;
    });
    setNetworkList(newNetworkList);
  };

  const setNetworkURL = (network: INetwork, value: string) => {
    const newNetworkList = networkList.map(item => {
      if (item.id === network.id) {
        return { ...item, network_url: value };
      }
      return item;
    });
    setNetworkList(newNetworkList);
  };

  return (
    <ul
      className={isProfilePage ? 'form__field-list' : 'form__field-list form__field-list--2column'}>
      {networkList.map(network => (
        <Network
          key={network.id}
          network={network}
          setNetworkStatus={setNetworkStatus}
          setNetworkURL={setNetworkURL}
        />
      ))}
    </ul>
  );
};

export default NetworkList;
