import CampaignService from '../service/campaign-service';

import { AppDispatch } from '../store/store';
import { campaignSlice } from '../store/campaign/reducer';
import { uiSlice } from '../store/ui/reducer';
import { noteSlice } from '../store/note/reducer';

import {
  adoptCampaignListToClient,
  adoptCampaignToClient,
  adoptCampaignToServer,
  adoptNewCampaignToServer,
} from '../adapters/campaign-adapter';
import { log } from '../helpers/log';
import { ICampaign } from '../models/campaign';

export const getCampaignList =
  (category?: number, egotistic?: boolean, queryString?: string | null) =>
  async (dispatch: AppDispatch) => {
    const { setIsLoading, setCampaignList } = campaignSlice.actions;

    dispatch(setIsLoading(true));

    const params = [];
    category && params.push('category=' + category);
    egotistic && params.push('egotistic=' + 1);
    queryString && params.push('q=' + queryString);

    try {
      const response = await CampaignService.getCampaignList(params.join('&'));

      dispatch(setCampaignList(adoptCampaignListToClient(response.data)));
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getCampaign = (id: string) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setCampaign } = campaignSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await CampaignService.getCampaign(id);

    dispatch(setCampaign(adoptCampaignToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const newCampaign = (campaign: ICampaign) => async (dispatch: AppDispatch) => {
  const { setIsLoading } = campaignSlice.actions;
  const { addNotification, setEditCampaignModalOpened } = uiSlice.actions;

  dispatch(setIsLoading(true));

  try {
    await CampaignService.addCampaign(adoptNewCampaignToServer(campaign));

    dispatch(getCampaignList());
    dispatch(setEditCampaignModalOpened(false));
  } catch (err: any) {
    if (err.response.data && err.response.data.validator) {
      Object.entries(err.response.data.validator).forEach((validator: any) => {
        dispatch(
          addNotification({
            type: 'error',
            message: `${validator[0]}: ${validator[1].map((element: any) => element).join('; ')} `,
          }),
        );
      });
    }

    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateCampaign = (campaign: ICampaign) => async (dispatch: AppDispatch) => {
  const { setIsLoading } = campaignSlice.actions;
  const { addNotification, setEditCampaignModalOpened } = uiSlice.actions;

  dispatch(setIsLoading(true));

  try {
    await CampaignService.updateCampaign(adoptCampaignToServer(campaign));

    dispatch(getCampaignList());
    dispatch(setEditCampaignModalOpened(false));
  } catch (err: any) {
    if (err.response.data && err.response.data.validator) {
      Object.entries(err.response.data.validator).forEach((validator: any) => {
        dispatch(
          addNotification({
            type: 'error',
            message: `${validator[0]}: ${validator[1].map((element: any) => element).join('; ')} `,
          }),
        );
      });
    }

    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const archiveCampaign = (campaign: ICampaign) => async (dispatch: AppDispatch) => {
  const { setIsLoading } = campaignSlice.actions;
  const { addNotification } = uiSlice.actions;

  dispatch(setIsLoading(true));

  try {
    await CampaignService.moveToArchive(adoptCampaignToServer(campaign));

    dispatch(getCampaignList());
  } catch (err: any) {
    if (err.response.data && err.response.data.validator) {
      Object.entries(err.response.data.validator).forEach((validator: any) => {
        dispatch(
          addNotification({
            type: 'error',
            message: `${validator[0]}: ${validator[1].map((element: any) => element).join('; ')} `,
          }),
        );
      });
    }

    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const toggleCampaignBookmark = (campaign: ICampaign) => async (dispatch: AppDispatch) => {
  const { setIsLoading, toggleBookmark } = campaignSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await CampaignService.toggleCampaignBookmark(campaign.id);

    console.log(response.data);
    dispatch(toggleBookmark(campaign));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteCampaignBookmark = (campaign: ICampaign) => async (dispatch: AppDispatch) => {
  const { setIsLoading, deleteBookmark } = campaignSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await CampaignService.toggleCampaignBookmark(campaign.id);

    console.log(response.data);
    dispatch(deleteBookmark(campaign));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getInfluencerBookmarkList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setCampaignList } = campaignSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await CampaignService.getInfluencerBookmarkList();

    console.log(response.data);
    dispatch(setCampaignList(adoptCampaignListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getCampaignNote = (campaign: ICampaign) => async (dispatch: AppDispatch) => {
  const { setIsLoading, setNote } = noteSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await CampaignService.getCampaignNote(campaign.id);

    console.log(response.data);
    dispatch(setNote(response.data.note || ''));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateCampaignNote =
  (campaign: ICampaign, note: string) => async (dispatch: AppDispatch) => {
    const { setIsLoading } = noteSlice.actions;
    const { setCampaign } = campaignSlice.actions;

    dispatch(setIsLoading(true));

    try {
      const response = await CampaignService.updateCampaignNote(campaign.id, note);

      console.log(response.data);

      dispatch(
        setCampaign({
          ...campaign,
          note: Boolean(note),
        }),
      );
    } catch (err: any) {
      log(err);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
