import api from '../http';
import { AxiosResponse } from 'axios';

import { IUser } from '../models/user-model';
import { ICategory } from '../models/category';
import { INetwork } from '../models/network';
import { IRateCard } from '../models/rate-card';
import { ServerAdditionalUserInfo } from '../models/additional-user-info';

export default class UserService {
  static async getUserInfo(): Promise<AxiosResponse<IUser>> {
    return api.get<IUser>('/user');
  }
  static async updateAvatar(formData: FormData): Promise<AxiosResponse<string>> {
    return api.post<string>('/avatar-image', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  static async updateUserInfo(body: IUser): Promise<AxiosResponse> {
    return api.post('/user', body);
  }
  static async getUserCategoryList(): Promise<AxiosResponse> {
    return api.get<Array<ICategory>>('/category-list');
  }
  static async updateUserCategoryList(body: ICategory[]): Promise<AxiosResponse> {
    return api.post<Array<ICategory>>('/category-list', body);
  }
  static async getUserNetworkList(): Promise<AxiosResponse> {
    return api.get<Array<INetwork>>('/network-list');
  }
  static async updateUserNetworkList(body: INetwork[]): Promise<AxiosResponse> {
    return api.post<Array<INetwork>>('/network-list', body);
  }
  static async getUserRateCardList(): Promise<AxiosResponse> {
    return api.get<Array<IRateCard>>('/social-rate-list');
  }
  static async updateUserRateCardList(body: IRateCard[]): Promise<AxiosResponse> {
    return api.post<Array<IRateCard>>('/social-rate-list', body);
  }
  static async updateFirstEntry(body: any): Promise<AxiosResponse> {
    return api.post('/account-details', body);
  }
  static async updateUserPassword(body: any): Promise<AxiosResponse> {
    return api.post('/change-password', body);
  }
  static async getAdditionalInfoList(): Promise<AxiosResponse> {
    console.log('getAdditionalInfoList:', `/influencer-list`);
    return api.get('/influencer-list');
  }
  static async getAdditionalInfo(): Promise<AxiosResponse<ServerAdditionalUserInfo>> {
    console.log('getAdditionalInfo:', `/influencer-info`);
    return api.get('/influencer-info');
  }
  static async updateAdditionalInfo(
    additionalInfo: ServerAdditionalUserInfo,
  ): Promise<AxiosResponse> {
    console.log('updateAdditionalInfo:', `/influencer-info`, additionalInfo);
    return api.post(`/influencer-info`, additionalInfo);
  }
}
