import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import {
  AdditionalUserInfoLists,
  AdoptedAdditionalUserInfo,
  AgeRange,
  Ethnicity,
  Gender,
  Language,
  MapView,
} from '../../models/additional-user-info';

interface AdditionalInfoState {
  isLoading: boolean;
  ageRangeList: AgeRange[];
  ethnicityList: Ethnicity[];
  languageList: Language[];
  genderList: Gender[];
  ageRange: number | undefined;
  ethnicity: number | undefined;
  language: number | undefined;
  gender: number | undefined;
  bio: string;
  mapView: MapView;
}

const initialState = {
  isLoading: false,
  ageRangeList: [],
  ethnicityList: [],
  languageList: [],
  genderList: [],
  ageRange: undefined,
  ethnicity: undefined,
  language: undefined,
  gender: undefined,
  bio: '',
  mapView: {
    marker: [0, 0],
    map: {
      center: [0, 0],
      zoom: 0,
    },
    place: '',
  },
};

export const additionalInfoSlice = createSlice({
  name: 'additionalInfo',
  initialState,
  reducers: {
    setIsLoading: (state: AdditionalInfoState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAdditionalInfoLists: (
      state: AdditionalInfoState,
      action: PayloadAction<AdditionalUserInfoLists>,
    ) => {
      state.ageRangeList = action.payload.ageRange;
      state.ethnicityList = action.payload.ethnicity;
      state.languageList = action.payload.language;
      state.genderList = action.payload.gender;
    },
    setAdditionalInfo: (
      state: AdditionalInfoState,
      action: PayloadAction<AdoptedAdditionalUserInfo>,
    ) => {
      state.ageRange = action.payload.ageRange;
      state.ethnicity = action.payload.ethnicity;
      state.language = action.payload.language;
      state.gender = action.payload.gender;
      state.bio = action.payload.bio;
      state.mapView = action.payload.mapView;
    },
  },
});

export default additionalInfoSlice.reducer;

export const selectIsLoading = (state: RootState) => state.additionalInfo.isLoading;
export const selectAgeRangeList = (state: RootState) => state.additionalInfo.ageRangeList;
export const selectEthnicityList = (state: RootState) => state.additionalInfo.ethnicityList;
export const selectLanguageList = (state: RootState) => state.additionalInfo.languageList;
export const selectGenderList = (state: RootState) => state.additionalInfo.genderList;
export const selectAgeRange = (state: RootState) => state.additionalInfo.ageRange;
export const selectEthnicity = (state: RootState) => state.additionalInfo.ethnicity;
export const selectLanguage = (state: RootState) => state.additionalInfo.language;
export const selectGender = (state: RootState) => state.additionalInfo.gender;
export const selectBio = (state: RootState) => state.additionalInfo.bio;
export const selectMapView = (state: RootState) => state.additionalInfo.mapView;
