import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[] | undefined;
}

export const TBody = ({ className, children }: Props) => {
  return <tbody className={classNames([cls.tbody, className])}>{children}</tbody>;
};
