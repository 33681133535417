import Page from '../../components/page';
import TopPanel from '../../components/top-panel';
import ContentWrapper from '../../components/content-wrapper';

import ProfileAccountInfo from './profile-account-info';
import ProfileCategoryList from './profile-category-list';
import ProfileNetworkList from './profile-network-list';

import { useAppSelector } from '../../hooks/store';
import { getIsInfluencer } from '../../store/ui/reducer';
import { getUser } from '../../store/auth/reducer';
import ProfileRateCardList from './profile-rate-card-list';
import ProfileChangePassword from './profile-change-password';
import ProfilePersonalInfo from './profile-personal-info';

const Profile = () => {
  const isInfluencer = useAppSelector(getIsInfluencer);
  const userInfo = useAppSelector(getUser);

  return (
    <Page>
      <TopPanel>
        <h2 className="top-panel__account-profile">{`Account profile / ${userInfo.first_name} ${userInfo.last_name}`}</h2>
        {isInfluencer && (
          <div style={{ textAlign: 'right' }}>
            Your unique influencer Union ID Badge
            <div style={{ textAlign: 'right', color: '#3b5998', fontSize: '14px' }}>
              {`#${userInfo.first_name}${userInfo.last_name}${userInfo.id_badge}`}
            </div>
          </div>
        )}
      </TopPanel>

      <ContentWrapper>
        <ProfileAccountInfo isInfluencer={isInfluencer} />
        {isInfluencer && <ProfilePersonalInfo />}
        <ProfileCategoryList />
        <ProfileNetworkList />
        {isInfluencer && <ProfileRateCardList />}
        <ProfileChangePassword />
      </ContentWrapper>
    </Page>
  );
};

export default Profile;
