import FilterService from '../service/filter-service';

import { AppDispatch } from '../store/store';
import { filterSlice } from '../store/filter/reducer';

import { log } from '../helpers/log';
import { adoptFilterListToClient } from '../adapters/filter-list-adapter';

export const getFilterList = () => async (dispatch: AppDispatch) => {
  const { setIsLoading, setList } = filterSlice.actions;

  dispatch(setIsLoading(true));

  try {
    const response = await FilterService.getFilterList();

    dispatch(setList(adoptFilterListToClient(response.data)));
  } catch (err: any) {
    log(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};
