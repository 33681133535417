import cls from '../widget.module.scss';

const CampaignPosts = () => {
  return (
    <>
      <p className={cls.header}>Campaign</p>
      <p className={cls.subheader}>Posts</p>
      <p className={cls.value}>0</p>
    </>
  );
};

export { CampaignPosts };
