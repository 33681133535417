import { classNames } from '../../utils/class-names';
import cls from './styles.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}

export const TR = ({ className, children, onClick }: Props) => {
  const mods: Record<string, boolean | undefined> = {
    [cls.cursorPointer]: Boolean(onClick),
  };

  return (
    <tr className={classNames([cls.tr, className], mods)} onClick={onClick}>
      {children}
    </tr>
  );
};
