import { useEffect } from 'react';

import { useAppDispatch } from '../../hooks/store';
import { logout } from '../../api/auth';

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return null;
};

export default Logout;
