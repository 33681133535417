import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ChatButton from '../../components/chat-button';
import { Button } from '../../components/button';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getEmailVerified } from '../../store/auth/reducer';
import { logout } from '../../api/auth';
import { sendEmail } from '../../api/auth';
import { emailVerification } from '../../api/auth';

import holyImage from '../../image/image.png';
import { AppRoute } from '../../router/routes';

const EmailVerification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id, token } = useParams();
  const emailVerified = useAppSelector(getEmailVerified);

  useEffect(() => {
    if (emailVerified) navigate(AppRoute.HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerified]);

  useEffect(() => {
    if (id && token) dispatch(emailVerification(id, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  return (
    <main className="container">
      <h1 className="visually-hidden">Influencer Union</h1>
      <div className="main-content" style={{ width: '100%' }}>
        <section className="blank-page" style={{ maxWidth: '1300px', margin: '0 auto' }}>
          <h2 className="visually-hidden">Await verification</h2>
          <img src={holyImage} width="138" height="138" alt="applause" />
          <p>Welcome to Influencer Union</p>
          <p style={{ fontSize: '36px', lineHeight: '1.2', marginBottom: '40px' }}>
            An email has been sent to the provided email address. In order to gain access to the
            platform, please verify your inbox and select the email validation button.
          </p>
          <Link className="button button--red" to={AppRoute.LOGIN}>
            Access Influencer Union
          </Link>
          <Button
            onClick={() => {
              dispatch(logout());
              navigate(AppRoute.LOGIN);
            }}>
            Go to login
          </Button>
          <Button
            onClick={() => {
              dispatch(sendEmail());
            }}>
            Send email again
          </Button>
        </section>
      </div>
      <ChatButton />
    </main>
  );
};

export default EmailVerification;
